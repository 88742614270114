import { handleError } from 'Errors/PolErrorHandler';
import { EstimationUrlHelper } from 'Helpers';
import { useVisibility } from 'Hooks/Visibility';
import { I18n, Message } from 'Locales/I18nService';
import React from 'react';
import { ProjectsApi } from 'Services/Api/ProjectsApi';
import { DeleteButton } from 'Web/Components/Views/CustomButton/ButtonsFactory';
import { NavigationService } from 'Web/Services/NavigationService';

import { YesNoChoiceDialog } from '../../Components/ChoiceDialog/YesNoChoiceDialog';
import { useVersion } from '../../ProjectsUtils';

type ButtonDeleteProjectProps = { projectId: string; disabled?: boolean };
export const ButtonDeleteProject = ({ projectId, disabled }: ButtonDeleteProjectProps) => {
    const version = useVersion();
    const dialog = useVisibility();

    const confirmDelete = async (setValidating: (busy: boolean) => void) => {
        try {
            setValidating(true);
            await ProjectsApi.deleteProject(projectId);
            setValidating(false);
            dialog.hide();
            NavigationService.goToMyProjects(EstimationUrlHelper.toQueryParam({ version }));
        } catch (error) {
            setValidating(false);
            handleError(error);
        }
    };

    return (
        <>
            <DeleteButton
                onClick={dialog.show}
                disabled={disabled}
                classes={{}}
                styles={{
                    root: { height: 48, width: 'fit-content', float: 'right' },
                    icon: { width: 15, height: 16 },
                }}
                title={<Message id="ProjectDetails_DeleteProject" />}
            />
            {dialog.visible && (
                <YesNoChoiceDialog
                    open
                    title={I18n.get('ProjectDetails_deleteDialogTitle')}
                    subtitle={I18n.get('ProjectDetails_deleteDialogHeader')}
                    body={I18n.get('ProjectDetails_deleteDialogBody')}
                    onClickYes={confirmDelete}
                    onClickNo={dialog.hide}
                    onClose={dialog.hide}
                    noHighlight
                />
            )}
        </>
    );
};
