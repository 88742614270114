import { AppRoutes } from 'Configs/AppRoutes';
import { EstimationUrlHelper } from 'Helpers';
import { LocationDescriptor } from 'history';
import { Message } from 'Locales/I18nService';
import { ProjectDto } from 'Models';
import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonVariant, CustomButton } from 'Web/Components/Views/CustomButton/CustomButton';

import { useVersion } from '../../ProjectsUtils';

type ButtonResumeProps = { project?: ProjectDto; disabled?: boolean };
export const ButtonResume = ({ project, disabled }: ButtonResumeProps) => {
    const version = useVersion();

    const { configuration_id, quotation_list = [] } = project ?? {};
    //! If the project's CurrentConfiguration is null, use latest configuration.
    const configIdToLoad = configuration_id || quotation_list[0]?.configuration_id;

    const to: LocationDescriptor<unknown> = {
        pathname: AppRoutes.DEFAULT_ROUTE,
        state: { configId: configIdToLoad },
        search: EstimationUrlHelper.toQueryParam({ version }).toString(),
    };
    return (
        <Link to={to}>
            <CustomButton
                title={<Message id="ProjectDetails_Resume" />}
                variant={ButtonVariant.Blue}
                styles={{ root: { height: 50, width: 278 }, label: { fontWeight: 400 } }}
                disabled={disabled || !project}
            />
        </Link>
    );
};
