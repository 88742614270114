import React from 'react';

import { IRoom, RoomShape } from '../../../../Models/IRoom';
import { RoomMove } from '../../../../Utils/Room/RoomMove';
import { CoordPoint } from '../../../../Utils/Types';
import { FlooringDirection } from '../../Laize';
import { useLaizeCalcDialogState } from '../LaizeCalcDialogProvider';
import { ProductHelper } from '../../../../../../Helpers/ProductHelper';

export const formatPoint = (coord: CoordPoint) => `${coord.x},${coord.y}`;

export const MapUIWalls = () => {
    const { rooms = [] } = useLaizeCalcDialogState();
    return (
        <g id="mapWalls">
            {rooms.map((room) => (
                <MapUIRoomWalls key={room.roomId} {...room} />
            ))}
        </g>
    );
};

const MapUIRoomWalls = (room: IRoom) => {
    const allPointsPoints = room.walls?.map((w) => formatPoint(w.start)).join(' ');
    const wall = room.walls![0]!;
    const points = `${allPointsPoints} ${formatPoint(wall.start)}`;

    const isInLaize = isInLaizeCalc(room);
    const props: React.SVGProps<SVGPolygonElement> = {
        id: RoomMove.roomWallsId(wall.roomId!),
        points,
        strokeWidth: 1,
        stroke: isInLaize ? '#13123A' : 'rgba(19, 18, 58, 0.3)',
        fill: isInLaize ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)',
    };

    return <WallSvg isPolygon={room.shape !== RoomShape.Open_bottom_rectangle} {...props} />;
};

export type SvgGraphProps = React.SVGProps<SVGPolygonElement | SVGPolylineElement> & { isPolygon: boolean };
const WallSvg = ({ isPolygon, ...props }: SvgGraphProps) => {
    return isPolygon ? <polygon {...props} /> : <polyline {...props} />;
};

export const isInLaizeCalc = (room: IRoom) => {
    const productType = ProductHelper.getProductType(room.questionProduitPrincipal?.produitValue);
    return (
        productType &&
        room.flooringDirection &&
        ![FlooringDirection.ClassiqueHorizontal, FlooringDirection.ClassiqueVertical].includes(room.flooringDirection!)
    );
};
