import { I18n } from 'Locales/I18nService';
import React from 'react';
import { ProjectsApi } from 'Services/Api/ProjectsApi';
import { ExportFileType, ExportUtils } from 'Utils/ExportUtils';
import { Assets } from 'Web/assets/Assets';
import { ButtonVariant, CustomButton } from 'Web/Components/Views/CustomButton/CustomButton';
import { useIsBoUser } from 'Web/Hooks/useIsBoUser';

type ButtonExportProjectProps = { projectId: string; disabled?: boolean };
export const ButtonExportProject = ({ projectId, disabled }: ButtonExportProjectProps) => {
    const isBoUser = useIsBoUser();
    const [exporting, setExporting] = React.useState(false);

    const downloadExportedProject = async () => {
        try {
            setExporting(true);
            const data = await ProjectsApi.exportProject(projectId);
            ExportUtils.saveToFile(data, `exported-project-${projectId}`, ExportFileType.ZIP);
        } finally {
            setExporting(false);
        }
    };

    return (
        <CustomButton
            visible={isBoUser}
            onClick={downloadExportedProject}
            icon={<Assets.ExportIcon />}
            title={I18n.get('ProjectDetails_Export')}
            variant={ButtonVariant.BorderedBlue}
            isBusy={exporting}
            disabled={disabled}
            styles={{
                root: { marginRight: 16, border: '2px solid #13123A' },
                label: { fontSize: 16 },
            }}
        />
    );
};
