import { styled } from '@material-ui/core/styles';
import React from 'react';
import { FormattedNumber } from 'react-intl';

import { ThemeRes } from '../../../Themes/ThemeRes';

const FRACTION_DIGIT_NUMBER = 2;

type PriceLabelProps = { price?: number; variant?: 'default' | 'noComma' };

export const PriceLabel = ({ price, variant = 'default' }: PriceLabelProps) => {
    if (!price) {
        return <>-</>;
    } else if (variant === 'noComma') {
        const { integer, decimal } = getDecimal(price);
        return (
            <>
                <FormattedNumber
                    value={parseInt(integer)}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency="EUR"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}>
                    {(formattedValue: string) => {
                        //Remove the space between the number and the currency symbol
                        return <>{formattedValue.slice(0, -2) + formattedValue.slice(-1)}</>;
                    }}
                </FormattedNumber>
                {decimal.length > 0 && <FractionDigit>{decimal}</FractionDigit>}
            </>
        );
    } else {
        return (
            <FormattedNumber
                value={price}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="EUR"
                maximumFractionDigits={FRACTION_DIGIT_NUMBER}
                minimumFractionDigits={FRACTION_DIGIT_NUMBER}
            />
        );
    }
};

const FractionDigit = styled('span')({
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSansBold,
    fontSize: 12,
    verticalAlign: 'bottom',
    lineHeight: '21px',
});

export const PriceFloor = styled('span')({ fontSize: '18.49px', lineHeight: '21px' });
export const PriceDecimal = styled('span')({ fontSize: '12.26px', alignSelf: 'flex-end' });
export const StrikedPriceFloor = styled('span')({ fontSize: '13px', lineHeight: '16px' });
export const StrikedPriceDecimal = styled('span')({ fontSize: '9px', alignSelf: 'flex-end' });

function getDecimal(price: number) {
    const [integer, decimal = ''] = String(price).split('.');
    const digitsAfterComma = decimal.slice(0, FRACTION_DIGIT_NUMBER);
    return { integer, decimal: digitsAfterComma };
}
