import { makeStyles, Theme } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';

import { I18n } from '../../../../Locales/I18nService';
import { GTM } from '../../../../Plugins/GTM/GTM';
import { BrowserUtils } from '../../../../Utils/BrowserUtils';
import { Assets } from '../../../assets/Assets';
import { useIsMobile } from '../../../Context/PlatformContext/PlatformContext';
import { ColorTheme } from '../../../Themes/ColorTheme';
import { ThemeRes } from '../../../Themes/ThemeRes';
import { ButtonVariant, CustomButton } from './CustomButton';
import { useQuoteProvider } from 'Web/Pages/Quote/Controllers/QuoteProviderController';
import { StoreHelper } from 'Helpers';

export const onClickAppointment = (url: string) => {
    GTM.pushAppointmentEvent();
    const openBookAppointmentUrl = () => BrowserUtils.openUrlInNewTab(url);
    openBookAppointmentUrl();
};

type AppointmentButtonProps = { style?: CSSProperties } & ColorTheme;
export const AppointmentButton = ({ type = 'dark', style }: AppointmentButtonProps) => {
    const { getState } = useQuoteProvider();
    const isMobile = useIsMobile();
    const projetStore = StoreHelper.getProjectStore(getState());
    const isLight = type === 'light';
    const rootStyle: CSSProperties = {
        width: '100%',
        background: isLight ? '#13123A' : '#FFF',
        ...style,
    };
    const contentStyle: CSSProperties = {
        padding: '0px 13px 0px 13px',
    };
    const labelStyle: CSSProperties = {
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: ThemeRes.Fonts.fontFamilyNunitoSans,
        color: isLight ? '#FFF' : '#13123A',
    };

    return (
        <CustomButton
            title={I18n.get('Appointment_Button_Title')}
            icon={isMobile ? isLight ? <Assets.IconCalendar /> : <Assets.IconCalendarDark /> : undefined}
            styles={{ root: rootStyle, content: contentStyle, label: labelStyle }}
            onClick={() => onClickAppointment(StoreHelper.toBookAppointmentUrl(projetStore))}
        />
    );
};

export const AppointmentButtonBlue = () => {
    const { getState } = useQuoteProvider();
    const projetStore = StoreHelper.getProjectStore(getState());
    const onMakeAppointment = () => {
        GTM.pushAppointmentEvent();
        const openBookAppointmentUrl = () =>
            BrowserUtils.openUrlInNewTab(StoreHelper.toBookAppointmentUrl(projetStore));
        openBookAppointmentUrl();
    };

    const classes = useHoverButtonStyles();
    return (
        <CustomButton
            fullWidth
            icon={<Assets.IconAppointment />}
            styles={{ root: { flex: 1, height: 50 } }}
            title={<FormattedMessage id="Appointment_Button_ShortTitle" />}
            onClick={onMakeAppointment}
            variant={ButtonVariant.Blue}
            classes={classes}
        />
    );
};

const useHoverButtonStyles = makeStyles<Theme>({
    root: { '&:hover path': { fill: '#FFFFFF' } },
});
