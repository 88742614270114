import { IOpening } from '../../Models/IOpening';
import { IRoom } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { JstsPolygon, JstsUtils } from '../JstsUtils';
import { RoomItemUtils } from '../RoomItem/RoomItemUtils';
import { Wall } from '../Types';
import { WallMove } from '../Wall/WallMove';

export class InvalidPositionDetection {
    public static getInvalidRoomIds(rooms: Array<IRoom>, roomId: string): Array<string> {
        const roomPolyById: { [key: string]: JstsPolygon } = {};
        const invalidRoomIds: Set<string> = new Set<string>();
        for (const room of rooms) {
            if (!roomPolyById[room.roomId!] || room.roomId === roomId) {
                roomPolyById[room.roomId!] = JstsUtils.createJstsPolygon(room.coords!);
            }
        }

        //Check if the current room is invalid
        if (!roomPolyById[roomId].isValid()) {
            invalidRoomIds.add(roomId);
            return Array.from(invalidRoomIds);
        }
        //Check if the current room overlaps/contains other rooms
        for (const otherRoomId of rooms.map((x) => x.roomId!).filter((x) => x !== roomId)) {
            const overlaps = roomPolyById[roomId].overlaps(roomPolyById[otherRoomId]);
            if (overlaps) {
                invalidRoomIds.add(otherRoomId);
            } else {
                const contains =
                    roomPolyById[roomId].contains(roomPolyById[otherRoomId]) ||
                    roomPolyById[otherRoomId].contains(roomPolyById[roomId]);
                if (contains) {
                    invalidRoomIds.add(otherRoomId);
                }
            }
        }
        if (invalidRoomIds.size > 0) {
            invalidRoomIds.add(roomId);
        }
        return Array.from(invalidRoomIds);
    }

    public static getInvalidOpeningIds(
        targetWall: Wall,
        openings: Array<IOpening>
    ): { invalidOpeningIds: Array<string>; invalidRoomIds: Array<string> } | undefined {
        if (targetWall && WallMove.wallMoveMinSizeBlock(targetWall)) {
            const invalidOpeningIds = openings.filter((x) => x.roomId === targetWall.roomId).map((x) => x.openingId!);
            return {
                invalidOpeningIds,
                invalidRoomIds: invalidOpeningIds.map((x) => openings.find((y) => y.openingId === x)?.roomId!),
            };
        }
        return undefined;
    }

    public static roomItemPolyById: { [key: string]: JstsPolygon } = {};
    public static getInvalidRoomItemIds(roomItems: Array<IRoomItem>, roomItemId: string): Array<string> {
        const roomItemPolyById: Record<string, JstsPolygon> = {};
        const invalidRoomItemIds: Set<string> = new Set<string>();
        roomItems.forEach((r) => (roomItemPolyById[r.roomItemId!] = JstsUtils.createJstsPolygon(r.coordsReal!)));

        //Check if the current roomItem overlaps/contains other roomItems
        for (const otherRoomItemId of roomItems.map((x) => x.roomItemId!).filter((x) => x !== roomItemId)) {
            const overlaps = roomItemPolyById[roomItemId].overlaps(roomItemPolyById[otherRoomItemId]);
            if (overlaps) {
                invalidRoomItemIds.add(otherRoomItemId);
            } else {
                const contains =
                    roomItemPolyById[roomItemId].contains(roomItemPolyById[otherRoomItemId]) ||
                    roomItemPolyById[otherRoomItemId].contains(roomItemPolyById[roomItemId]);
                if (contains) {
                    invalidRoomItemIds.add(otherRoomItemId);
                }
            }
        }
        if (invalidRoomItemIds.size > 0) {
            invalidRoomItemIds.add(roomItemId);
        }
        return Array.from(invalidRoomItemIds);
    }

    public static getInvalidRoomItemIdsOnWallRoomMove(
        roomItems: Array<IRoomItem>,
        targetRoom: IRoom
    ): { invalidRoomItemIds: Array<string>; invalidRoomIds: Array<string> } {
        //! NOT COMPATIBLE WITH PLAN LIBRE
        const invalidRoomItem: IRoomItem[] = [];
        // const invalidRoomItem: Set<IRoomItem> = new Set<IRoomItem>();
        for (const roomItem of roomItems) {
            const isInvalid = RoomItemUtils.isRoomItemInsideRoom(roomItem, targetRoom, false);
            if (isInvalid) {
                invalidRoomItem.push(roomItem);
            }
        }

        return {
            invalidRoomItemIds: invalidRoomItem.map((x) => x.roomItemId!),
            invalidRoomIds: invalidRoomItem.map((x) => x.roomId!),
        };
    }
}
