import { Logger } from '../../../../../../../Errors/Logger';
import { QuestionsHelper } from '../../../../../../../Helpers/QuestionsHelper';
import { QuoteQuestionUtils } from '../../../../../../../Helpers/QuoteQuestionUtils';
import { QuestionItem, QuestionStep, QuestionType } from '../../../../../../../Models/Questions/QuestionDto';
import { useIncompatibilityContext } from '../../../../../../../Plugins/FloorPlan/Context/IncompatibilityContext';
import { IMapItem } from '../../../../../../../Plugins/FloorPlan/Models/IMapItem';
import { IRoom } from '../../../../../../../Plugins/FloorPlan/Models/IRoom';
import { QuestionPrepare } from '../../../../Helpers/QuestionPrepare';
import { Scroller, useScroller } from '../../../../Helpers/Scroller';
import { useRoomEdit } from './RoomEditController';

export const useRoomSections = (step: QuestionStep) => {
    const scroller = useScroller();
    const { state: room, update, getState } = useRoomEdit();

    const roomSections = useOpenNextSection<IRoom>({
        getItemId: (room) => room.roomId!,
        updateItem: (item) => update({ values: item, logs: { event: 'openSection', step } }),
    });

    const openNextSection = (step: QuestionStep) => {
        const room = getState();
        Logger.log('useRoomSections openNextSection', { step, room });
        roomSections.openSection(room, step);
    };

    const arePreviousStepsCompleted = () => {
        const room = getState();
        const previousCompleted = QuestionPrepare.allPreviousStepCompleted(room, step);
        const result = previousCompleted && room.completionStatus?.infosCompleted;
        return result;
    };

    const setExpanded = (open: boolean) => {
        const room = getState();
        let questions: Array<QuestionItem> | undefined = [];
        if (step === QuestionStep.StepPose) {
            room.openSections = { questionsPose: open };
            questions = room.questionsPose?.questions;
        }
        if (step === QuestionStep.StepSupport) {
            room.openSections = { questionsSupport: open };
            questions = room.questionsSupport?.questions;
        }
        if (step === QuestionStep.StepPreparation) {
            room.openSections = { questionsPreparation: open };
            questions = room.questionsPreparation?.questions;
        }
        if (step === QuestionStep.StepFinitions) {
            room.openSections = { questionsFinition: open };
            questions = room.questionsFinition?.questions;
        }
        update({ values: room, logs: { event: 'setExpanded', open, step } });

        if (open) {
            const nextQuestionToScroll = QuoteQuestionUtils.getFirstVisibleNotValidQuestion(questions);
            Logger.log('useRoomSections setExpanded', { nextQuestionToScroll, questions });
            if (nextQuestionToScroll) {
                scroller.scrollToQuestion({
                    scrollerId: Scroller.ID,
                    itemId: room.roomId!,
                    toQuestion: nextQuestionToScroll,
                    questions,
                    delay: Scroller.LongDelay
                });
            }
        }
    };

    const expanded = QuestionPrepare.isStepQuestionsOpened(room, step);

    return { expanded, setExpanded, openNextSection, arePreviousStepsCompleted };
};

type OpenNextSectionParams<T extends Partial<IMapItem>> = {
    getItemId: (item: T) => string;
    updateItem: (item: T) => void;
};
export const useOpenNextSection = <T extends Partial<IMapItem>>({
    getItemId,
    updateItem,
}: OpenNextSectionParams<T>) => {
    const scroller = useScroller();
    const incompatibility = useIncompatibilityContext();

    const openSection = (item: T, fromStep: QuestionStep, noScroll?: boolean) => {
        const nextStep = QuestionPrepare.getNextStep(item, fromStep);

        Logger.log('openSection from', { fromStep, nextStep, item });

        if (nextStep?.step === QuestionStep.StepPose) {
            item.openSections = { questionsPose: true };
        }
        if (nextStep?.step === QuestionStep.StepSupport) {
            item.openSections = { questionsSupport: true };
        }
        if (nextStep?.step === QuestionStep.StepPreparation) {
            item.openSections = { questionsPreparation: true };
        }
        if (nextStep?.step === QuestionStep.StepFinitions) {
            item.openSections = { questionsFinition: true };
        }
        if (!nextStep) {
            item.openSections = { amenagementsSection: true };
            scroller.scollToBottom(Scroller.ID);
        }

        updateItem(item);

        if (nextStep) {
            const questions = nextStep.questions!;
            const findFirstIncompatibility = QuestionsHelper.findFirstIncompatibility(
                questions.filter((q) => q.showQuestion && q.type === QuestionType.Respiration)
            );

            Logger.log('useOpenNextSection nextStep', { nextStep, findFirstIncompatibility, questions });

            if (findFirstIncompatibility.findIncompatibility) {
                incompatibility.showIncompatibilityDialog(item.roomId!, findFirstIncompatibility);
            } else if (!noScroll) {
                const questionToScroll = QuestionsHelper.firstQuestionWithNoResponse(nextStep.questions);
                const respirationQuestion = nextStep.questions?.find(
                    (q) => q.type === QuestionType.Respiration && Boolean(q.showQuestion)
                );

                Logger.log('useOpenNextSection questionToScroll', { nextStep, questionToScroll });

                if (questionToScroll) {
                    scroller.scrollToQuestion({
                        scrollerId: Scroller.ID,
                        itemId: getItemId(item),
                        toQuestion: respirationQuestion || questionToScroll,
                        questions,
                        delay: Scroller.LongDelay
                    });
                }
            }
        }
    };

    return { openSection };
};
