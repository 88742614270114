import React from 'react';

import { styled } from '@material-ui/core';
import { ThemeRes } from 'Web/Themes/ThemeRes';
import { StringKey } from 'Locales/fr';
import { Message } from 'Locales/I18nService';
import { StyledComponent } from '@material-ui/styles';

export const NunitoSansTitle = styled('div')({
    fontWeight: 400,
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSans,
    color: '#000000',
});

export const NunitoSansBoldTitle = styled('div')({
    //fontWeight: 'bold',
    fontWeight: 700,
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSansBold,
});

export const GlendaleTitle = styled('div')({
    fontWeight: 400,
    fontFamily: ThemeRes.Fonts.fontFamilyGlendale,
    textTransform: 'uppercase',
    color: '#000000',
});

export const AvenirTitle = styled('div')({
    fontWeight: 400,
    fontFamily: ThemeRes.Fonts.fontFamilyAvenir,
    color: '#000000',
});

export const TextTitle = (Component: StyledComponent<any>, id: StringKey) => {
    return (
        <Component>
            <Message id={id} />
        </Component>
    );
};

export const TextContent = (Component: StyledComponent<any>, content: React.ReactNode) => {
    return <Component>{content}</Component>;
};

// type TextTitleProps ={Component:StyledComponent<any>| React.Component<any>}
// const TextTitle = ({Component}:TextTitleProps)=>{
//     return <Component>
//     </Component>
// }
