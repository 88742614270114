import React from 'react';

import { ObjectUtils } from '../../../Helpers/ObjectUtils';
import { useWindowLog } from '../../../Hooks/WindowLog';
import { useViewState } from '../../../Web/Hooks/ViewState/ViewState';
import { IRoom, RoomType } from '../Models/IRoom';
import { MapContextState, useMapContext } from './MapContext';
import { IMapItem } from '../Models/IMapItem';
import { IOpening } from '../Models/IOpening';
import { IRoomItem } from '../Models/IRoomItem';

const UndoRedoControllerDefaultValue: UndoRedoController<MapContextState> = {
    getState: () => ({}),
    undo: () => ({}),
    redo: () => ({}),
    store: () => ({}),
    clear: () => ({}),
    canRedo: false,
    canUndo: false,
};

type UndoRedoMapContextValue = {
    sol: UndoRedoController<MapContextState>;
    wall: UndoRedoController<MapContextState>;
    store(mapState: MapContextState): void;
    undo(type?: RoomType): MapContextState | undefined;
    redo(type?: RoomType): MapContextState | undefined;
    clear(params?: { futureOnly?: boolean; type?: RoomType }): void;
};
const UndoRedoMapContext = React.createContext<UndoRedoMapContextValue>({
    sol: UndoRedoControllerDefaultValue,
    wall: UndoRedoControllerDefaultValue,
    store: () => ({}),
    undo: () => ({}),
    redo: () => ({}),
    clear: () => ({}),
});
export const useUndoRedoMap = () => React.useContext<UndoRedoMapContextValue>(UndoRedoMapContext);

export function UndoRedoMapProvider({ children }: React.PropsWithChildren<unknown>) {
    const map = useMapContext();
    const sol = useUndoRedo<MapContextState>({ getPresent: () => toUndoRedoMapStack(map.getState()) });
    const wall = useUndoRedo<MapContextState>({ getPresent: () => toUndoRedoMapStack(map.getState()) });

    const undo = (type?: RoomType) => {
        const targetType = type ?? map.getState().type;
        const oldState = targetType === RoomType.Sol ? sol.undo() : wall.undo();
        if (oldState) {
            return mergeWithCurrentQuestions(oldState, map.getState());
        }
        return undefined;
    };

    const redo = (type?: RoomType) => {
        const targetType = type ?? map.getState().type;
        const oldState = targetType === RoomType.Sol ? sol.redo() : wall.redo();
        if (oldState) {
            return mergeWithCurrentQuestions(oldState, map.getState());
        }
        return undefined;
    };

    const store = (mapState: MapContextState) => {
        if (mapState.type === RoomType.Sol) {
            sol.store(toUndoRedoMapStack(mapState));
        } else {
            wall.store(toUndoRedoMapStack(mapState));
        }
    };
    const clear = ({ type, futureOnly }: { futureOnly?: boolean; type?: RoomType } = {}) => {
        const targetType = type ?? map.getState().type;
        if (targetType === RoomType.Sol) {
            sol.clear(futureOnly);
        } else {
            wall.clear(futureOnly);
        }
    };

    useWindowLog(() => (window.UndoRedoSolMapStack = sol.getState));
    useWindowLog(() => (window.UndoRedoWallMapStack = wall.getState));

    return (
        <UndoRedoMapContext.Provider value={{ sol, wall, undo, redo, store, clear }}>
            {children}
        </UndoRedoMapContext.Provider>
    );
}
export const toUndoRedoMapStack = (state: MapContextState): MapContextState => ({
    walls: ObjectUtils.clone(state.walls),
    rooms: ObjectUtils.clone(state.rooms),
    openings: ObjectUtils.clone(state.openings),
    roomItems: ObjectUtils.clone(state.roomItems),
    selectedRoomId: state.selectedRoomId,
    selectedOpeningId: state.selectedOpeningId,
    selectedRoomItemId: state.selectedRoomItemId,
    selectAmenagementOpen: state.selectAmenagementOpen,
    selectRoomShapeOpen: state.selectRoomShapeOpen,
    showHabitation: state.showHabitation,
    showStoreLocator: state.showStoreLocator,
    showFinDeParcours: state.showFinDeParcours,
    logs: state.logs,
});

export const mergeWithCurrentQuestions = (old: MapContextState, current: MapContextState): MapContextState => {
    const {
        rooms: oldRooms = [],
        openings: oldOpenings = [],
        roomItems: oldRoomItems = [],
        walls: oldWalls = [],
        ...state
    } = old;
    const { rooms = [], openings = [], roomItems = [] } = current;

    const merge = <T extends IMapItem>(
        olds: T[],
        currents: T[],
        sameId: (old: T, current: T) => boolean,
        copy: (current: T) => T
    ) => {
        return olds.map((old) => {
            const current = currents.find((c) => sameId(old, c));
            const result = !current
                ? old
                : {
                      ...old,
                      openSections: current.openSections,
                      ...copy(current),
                      ...copyQuestions(current),
                  };
            return ObjectUtils.clone(result);
        });
    };

    return {
        rooms: merge(oldRooms, rooms, (o, c) => o.roomId === c.roomId, copyRoom),
        openings: merge(oldOpenings, openings, (o, c) => o.openingId === c.openingId, copyOpening),
        roomItems: merge(oldRoomItems, roomItems, (o, c) => o.roomItemId === c.roomItemId, copyRoomItem),
        walls: ObjectUtils.clone(oldWalls),
        selectedRoomId: state.selectedRoomId,
        selectedOpeningId: state.selectedOpeningId,
        selectedRoomItemId: state.selectedRoomItemId,
        selectAmenagementOpen: state.selectAmenagementOpen,
        selectRoomShapeOpen: state.selectRoomShapeOpen,
        showHabitation: state.showHabitation,
        showStoreLocator: state.showStoreLocator,
        showFinDeParcours: state.showFinDeParcours,
        logs: state.logs,
    };
};

const copyQuestions = (item: IMapItem): IMapItem => {
    return {
        name: item.name,
        namedByUser: item.namedByUser,

        questionsBaseContext: item.questionsBaseContext,
        questionsTva: item.questionsTva,
        questionsPose: item.questionsPose,
        questionsSupport: item.questionsSupport,
        questionsPreparation: item.questionsPreparation,
        questionsFinition: item.questionsFinition,
        questionsCopiedFromId: item.questionsCopiedFromId,
    };
};
const copyRoom = (room: IRoom): IRoom => {
    return {
        questionProduitPrincipal: room.questionProduitPrincipal,
        roomType: room.roomType,
        localisation: room.localisation,
        roomFloor: room.roomFloor,
        roomWall: room.roomWall,
        roomExistingFlooring: room.roomExistingFlooring,
        roomExistingFloorType: room.roomExistingFloorType,
        roomHeating: room.roomHeating,
        roomHeatingType: room.roomHeatingType,
        flooringDirection: room.flooringDirection,
        contournements: room.contournements,
    };
};
const copyOpening = (opening: IOpening): IOpening => {
    return {
        type: opening.type,
        size: opening.size,
        customSize: opening.customSize,
    };
};
const copyRoomItem = (roomItem: IRoomItem): IRoomItem => {
    return {
        type: roomItem.type,
        putFlooring: roomItem.putFlooring,
        putPlinthes: roomItem.putPlinthes,
        width: roomItem.width,
        height: roomItem.height,
        dimensId: roomItem.dimensId,
        isMoveableOutside: roomItem.isMoveableOutside,
    };
};

//#region HOOKS

type UndoRedoController<T> = {
    getState(): Partial<UndoRedoState<T>>;
    canUndo: boolean;
    undo(): T | undefined;
    canRedo: boolean;
    redo(): T | undefined;
    store(s: T): void;
    clear(futureOnly?: boolean): void;
};

export type UndoRedoState<T> = { past?: T[]; present?: T; future?: T[] };

type UndoRedoParams<T> = { getPresent: () => T };
function useUndoRedo<T>({ getPresent }: UndoRedoParams<T>): UndoRedoController<T> {
    const {
        state: { past = [], future = [] },
        getState,
        update,
    } = useViewState<UndoRedoState<T>>({ past: [], future: [] });

    const clear = (futureOnly?: boolean) => {
        const past = getState().past || [];
        update(
            futureOnly
                ? { past: past.slice(0, past.length - 1), future: [], present: undefined }
                : { past: [], future: [], present: undefined }
        );
    };
    const store = (present: T) => update({ past: [...past, present], future: [], present: undefined });

    const undo = (): T | undefined => {
        const { past = [], future = [], present } = getState();

        const newPresent = present || getPresent();
        const previous = ObjectUtils.clone(past[past.length - 1]);
        const newPast = ObjectUtils.clone(past.slice(0, past.length - 1));
        const newFuture = ObjectUtils.clone([newPresent, ...future]);
        update({ past: newPast, present: previous, future: newFuture });

        return previous;
    };

    const redo = (): T | undefined => {
        const { past = [], future = [], present } = getState();

        const newPresent = present || getPresent();
        const next = ObjectUtils.clone(future[0]);
        const newFuture = ObjectUtils.clone(future.slice(1));
        const newPast = ObjectUtils.clone([...past, newPresent]);

        update({ past: newPast, present: next, future: newFuture });

        return next;
    };

    return {
        getState,
        undo,
        redo,
        store,
        clear,
        canUndo: Boolean(past.length),
        canRedo: Boolean(future.length),
    };
}

//#endregion
