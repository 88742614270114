import React from 'react';

import { useCurrentProject } from '../../../../Configs/CurrentProjectContext';
import { useMe } from '../../../../Configs/PolStore';
import { ObjectUtils } from '../../../../Helpers/ObjectUtils';
import { StoreHelper } from '../../../../Helpers/StoreHelper';
import { MapContextState, useMapContext } from '../../../../Plugins/FloorPlan/Context/MapContext';
import { OpenSections } from '../../../../Plugins/FloorPlan/Models/IMapItem';
import { GTM, GtmStepEvent, MapTaggage, PushSaveOrCreateProjectEvent, RoomTaggage } from '../../../../Plugins/GTM/GTM';
import { MeState } from '../../../../Services/Me/MeReducer';
import { EnhancedViewState } from '../../../Hooks/ViewState/ViewState';
import { QuoteState } from '../QuoteState';
import { useQuoteProvider } from './QuoteProviderController';

const GtmStep = (event: Partial<GtmStepEvent> & { needContextId?: boolean }) => {
    const sent = React.useRef<boolean>(false);
    const gtm = useGtmStepEventController();
    const data = React.useMemo(() => JSON.stringify(event), [event]);

    React.useEffect(() => {
        const hasContextId = event.room_id || event.opening_id || event.room_item_id;
        if (!event.needContextId || (hasContextId && !sent.current)) {
            sent.current = true;
            gtm.pushStepViewEvent(event);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    return <span id="GtmStep" data-content={data} style={{ position: 'absolute' }} />;
};

export const GtmStepTag = React.memo(GtmStep, (prev, next) => ObjectUtils.deepEqual(prev, next));

export const useGtmStepEventController = () => {
    const quoteContext = useQuoteProvider();
    const currentProject = useCurrentProject();
    const map = useMapContext();
    const me: MeState = useMe();

    const pushStepViewEvent = (event: Partial<GtmStepEvent>) => {
        const generalContext = quoteContext.getGeneralContext();
        const mapState = quoteContext.map.getState();
        const { quote } = quoteContext.getState();

        const toMapTaggage = ({ rooms = [], global_surface_area = 0 }: MapContextState): MapTaggage => {
            const roomsTaggage: Array<RoomTaggage> = rooms.map((room) => {
                const produitPrincipal = room.questionProduitPrincipal?.produitValue;
                return {
                    room_id: room.roomId!,
                    type: room.roomType,
                    surface: room.surface_area,
                    etage: room.roomFloor,
                    nom: room.name,
                    product: produitPrincipal?.libelle,
                    product_id: produitPrincipal?.code,
                    sous_famille_produit:
                        produitPrincipal?.infos_produit.groupe_article.famille_article.sous_famille_article.libelle,
                };
            });
            return {
                totalArea: global_surface_area,
                roomCount: rooms.length,
                rooms: roomsTaggage,
            };
        };
        GTM.pushStepViewEvent({
            ...event,
            context: generalContext,
            mapTaggage: toMapTaggage(mapState),
            projetId: currentProject.getState().project?.id,
            totalPrice: quote?.detail_total_price?.total_price_ttc,
            zipcode: StoreHelper.getProjectStore(quoteContext.getState())?.zipcode,
            products: GTM.getGTMProducts(map.getState().rooms ?? []),
            user_mail: me?.data?.customer_properties?.email ?? '',
        });
    };
    return { pushStepViewEvent };
};

export const useGtmSaveController = () => {
    const quote = useQuoteProvider();

    const toStepName = (step?: OpenSections): string => {
        if (step?.infos) {
            return 'Ma pièce';
        } else if (step?.questionsPose) {
            return 'Ma pose';
        } else if (step?.questionsSupport) {
            return 'Mon support';
        } else if (step?.questionsPreparation) {
            return 'Ma préparation';
        } else if (step?.questionsFinition) {
            return 'Mes finitions';
        } else if (step?.questionsServices) {
            return 'Mes services';
        } else if (step?.amenagementsSection) {
            return 'Mes aménagements';
        }
        return '';
    };

    const pushSaveOrCreateProjectEvent = (event: Partial<PushSaveOrCreateProjectEvent>) => {
        const { rooms = [], selectedRoomId } = quote.map.getState();
        const room = rooms.find((r) => r.roomId === selectedRoomId);
        GTM.pushSaveOrCreateProjectEvent({
            ...event,
            piece: room?.name,
            etape: toStepName(room?.openSections),
        });
    };

    return { pushSaveOrCreateProjectEvent };
};

export const useGtmMapEventController = (map: MapContextState, quote: EnhancedViewState<QuoteState>) => {
    const gtm = useGtmStepEventController();

    React.useEffect(() => {
        if (map.selectRoomShapeOpen) {
            GTM.pushAddRoomEvent();
        }
    }, [map.selectRoomShapeOpen]);

    React.useEffect(() => {
        if (map.selectAmenagementOpen) {
            GTM.pushAddAmenagementEvent();
        }
    }, [map.selectAmenagementOpen]);

    React.useEffect(() => {
        if (quote.initialized && map.rooms && map.rooms.length === 0) {
            gtm.pushStepViewEvent({ step: 'MapEmpty' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map.rooms]);

    React.useEffect(() => {
        if (quote.initialized && quote.habitationQuestionsOpen && !map.rooms?.length) {
            gtm.pushStepViewEvent({ step: 'OnboardingStart' });
        }

        if (quote.initialized && !quote.habitationQuestionsOpen) {
            gtm.pushStepViewEvent({ step: 'OnboardingEnd' });
            if (!map.rooms?.length) {
                gtm.pushStepViewEvent({ step: 'MapEmpty' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quote.habitationQuestionsOpen]);

    React.useEffect(() => {
        if (map.showHabitation) {
            gtm.pushStepViewEvent({ step: 'OnboardingStart' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map.showHabitation]);

    return gtm;
};
