import React from 'react';
import { useVersion } from '../../ProjectsUtils';
import { LocationDescriptor } from 'history';
import { AppRoutes } from 'Configs/AppRoutes';
import { EstimationUrlHelper } from 'Helpers';
import { Link } from 'react-router-dom';
import { Assets } from 'Web/assets/Assets';

import { styled } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';

export const ButtonBack = () => {
    const version = useVersion();
    const to: LocationDescriptor<unknown> = {
        pathname: AppRoutes.MY_PROJECTS,
        search: EstimationUrlHelper.toQueryParam({ version }).toString(),
    };
    return (
        <Link to={to}>
            <Content>
                <Assets.IconArrowPrevious style={{ width: 7, height: 13 }} />
            </Content>
        </Link>
    );
};
const Content = styled(IconButton)({ width: 41, height: 41, paddingTop: 8 });
