import { RoomType } from './Models/IRoom';
import { WallLine } from './Utils/Types';

//* GLOBAL VARIABLES
interface IMapConstants {
    meter: number;
    wallMinSize: (type?: RoomType) => number;
    grid_snap: string;
    mode?: string; //* select_mode

    roomDefaultSpacing: number;

    gridStep: number;
    snapGridMagnet: number;
    snapGridMagnetSensitivity: number;

    originX_viewbox: number;
    originY_viewbox: number;
    zoom: number;
    factor: number;

    tolerance_round_value: number;
    wallThickness: number;

    PreviousNearestWall?: WallLine;
    PreviousHorVNearestWall?: WallLine;
}

export const MapMode = {
    GRID_SNAP_ON: 'on',
    GRID_SNAP_OFF: 'off',

    MODE_TYPE_RENAMING_ROOM_NAME: 'MODE_TYPE_RENAMING_ROOM_NAME',

    MODE_TYPE_OPENING_PLACING: 'MODE_TYPE_OPENING_PLACING', //* Placing opening,mode===door_mode in v2
    MODE_TYPE_OPENING_MOVE: 'MODE_TYPE_OPENING_MOVE', //* Moving opening

    MODE_TYPE_MOVE_ROOM: 'MODE_TYPE_MOVE_ROOM',
    MODE_TYPE_MOVE_ROOM_WALL: 'MODE_TYPE_MOVE_ROOM_WALL',
    MODE_TYPE_MOVE_ROOM_WALL_POINT: 'MODE_TYPE_MOVE_ROOM_WALL_POINT',

    MODE_TYPE_MOVE_ROOM_ITEM: 'MODE_TYPE_MOVE_ROOM_ITEM',
    MODE_TYPE_MOVE_ROOM_ITEM_SIDE: 'MODE_TYPE_MOVE_ROOM_ITEM_SIDE',
    MODE_TYPE_ROOM_ITEM_PLACING: 'MODE_TYPE_ROOM_ITEM_PLACING', //* Placing room item ,mode===object_mode_room_item in v2
};

export const MapConstants: IMapConstants = {
    meter: 100,
    wallMinSize: (type: RoomType = RoomType.Sol) => (type === RoomType.Sol ? 0 : 7),
    //action: 0,
    //construc: 0,
    mode: undefined, //* select_mode

    roomDefaultSpacing: 50,

    gridStep: 1,
    snapGridMagnet: 50,
    snapGridMagnetSensitivity: 10,
    grid_snap: 'on',

    // pox: 0,
    // poy: 0,

    originX_viewbox: 0,
    originY_viewbox: 0,
    zoom: 9,
    factor: 1,

    tolerance_round_value: 0.00000000001,
    wallThickness: 1,

    PreviousNearestWall: undefined,
    PreviousHorVNearestWall: undefined,
};

window.MapConstants = { MapMode, MapConstants };
