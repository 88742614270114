import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCurrentProject } from '../../../Configs/CurrentProjectContext';
import { AppState, useMe } from '../../../Configs/PolStore';
import { Logger } from '../../../Errors/Logger';
import { wrapError } from '../../../Errors/PolErrorHandler';
import { EstimationUrlHelper } from '../../../Helpers/EstimationUrlHelper';
import { ProductHelper } from '../../../Helpers/ProductHelper';
import { QuoteQuestionUtils } from '../../../Helpers/QuoteQuestionUtils';
import { useWindowLog } from '../../../Hooks/WindowLog';
import { I18n } from '../../../Locales/I18nService';
import { QuestionStep } from '../../../Models/Questions/QuestionDto';
import { MapContextState, UpdateMapParams, useMapContext } from '../../../Plugins/FloorPlan/Context/MapContext';
import { MapError, MapHelper } from '../../../Plugins/FloorPlan/Helpers/MapHelper';
import { LogLevelType } from '../../../Services/Analytics/LogLevelType';
import { ApiError } from '../../../Services/XHR/ApiError';
import { QuoteLocationState, RouteQueryParams } from '../../../Types/RouteParams';
import { useQuery } from '../../Hooks/URL/URLUtils';
import { useIsVendeur } from '../../Hooks/Vendeur/useVendeur';
import { EnhancedViewState, useViewState } from '../../Hooks/ViewState/ViewState';
import { NavigationService } from '../../Services/NavigationService';
import { Toast } from '../../Services/ToastService';
import { useGtmMapEventController } from './Controllers/GtmEventController';
import { useQuoteLoadController } from './Controllers/QuoteLoadController';
import { upadateQuestionProduitPrincipalIfNeeded } from './Helpers/ProduitPrincipalHelper';
import { QuestionPrepare } from './Helpers/QuestionPrepare';
import { VersionLoader } from './Helpers/VersionLoader';
import { LoadingStep, QuoteState } from './QuoteState';
import { toUpdateGeneralQuestions, toUpdateMapItemQuestions } from './Utils/UpdateQuestions';

type UpdateQuoteParams = {
    quoteValues?: EnhancedViewState<QuoteState>;
    mapValues?: EnhancedViewState<MapContextState>;
    mapModified?: boolean;
    refreshLaizeCalc?: boolean;
    questionsModified?: boolean; //!\ can be quote question on MapItem info

    logs?: any;
};
export interface QuoteContextValue {
    state: EnhancedViewState<QuoteState>;
    getState: () => EnhancedViewState<QuoteState>;
    updateQuote: (values: Partial<QuoteState>) => void;
    update: (params: UpdateQuoteParams) => void;

    openFinDeParcours: () => void;
}

const QuoteContext = React.createContext<QuoteContextValue>({
    state: {},
    getState: () => ({}),
    updateQuote: () => {},
    update: () => {},
    openFinDeParcours: () => {},
});
export const useQuoteContext = () => React.useContext(QuoteContext);
export const useQuoteContextState = () => useQuoteContext().state;
export const useVersionId = () => React.useContext(QuoteContext).state.versionId;

export const QuoteProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const map = useMapContext();
    const isVendeur = useIsVendeur();
    const currentProject = useCurrentProject();
    const { version: versionId, ref: produitRef } = useQuery<RouteQueryParams>();
    const location = useLocation<QuoteLocationState>();

    const quoteContext = useViewState<QuoteState>({ loadingStep: LoadingStep.Auth });
    useWindowLog(() => (window.QuoteState = quoteContext.getState));

    const loader = useQuoteLoadController({ updateQuote: quoteContext.update });
    useGtmMapEventController(map.state, quoteContext.state);

    const load = () => {
        wrapError(
            async () => {
                const state = quoteContext.getState();
                if (!state.initialized) {
                    quoteContext.update({ loadingStep: LoadingStep.Version, loading: false });
                    const { version, versionData } = await VersionLoader.loadVersion(versionId);
                    NavigationService.replaceParams(
                        EstimationUrlHelper.toQueryParam({ version: version!, ref: produitRef })
                    );
                    versionData.questions
                        .filter((q) => q.related_step === QuestionStep.StepHabitation)
                        .forEach((q) => (q.showQuestion = true));
                    let questions = QuoteQuestionUtils.filterSellersQuestions(versionData.questions, isVendeur);
                    questions = QuoteQuestionUtils.markFirstProduitAsFirstProduct(questions);

                    currentProject.reset();

                    if (location.state?.configId) {
                        quoteContext.update({ loadingStep: LoadingStep.Configuration });

                        const {
                            mapState,
                            stepHabitation,
                            stepServices,
                            stepSynthese,
                            habitationQuestionsOpen,
                            projetStore,
                        } = await loader.loadConfiguration({ configId: location.state.configId, questions });

                        quoteContext.update({
                            versionId: version,
                            loadingStep: undefined,
                            versionData,
                            questions,
                            stepHabitation,
                            stepServices,
                            stepSynthese,
                            projetStore,
                            habitationQuestionsOpen,
                            initialized: true,
                        });

                        map.update({ values: mapState, logs: { event: 'load plan from config' } });
                        map.zoomToFit();

                        //TODO à REVOIR : on a plus le chargment automatique du devis
                        // const currentProjectState = currentProject.getState();
                        // if (!isVendeur && mapState.roomsCompleted && currentProjectState.isUpdateAllowed) {
                        //     const mapState = map.getState();
                        //     const { bodyRequest, quotePrixContextDebugMap } = await QuoteLoadUtils.getGetQuotationDto({
                        //         habitationQuestions: stepHabitation.questions,
                        //         exportImageMap,
                        //         mapState,
                        //         versionId: version!,
                        //         versionData,
                        //         projetStore,
                        //     });

                        //     if (!quoteContext.getState().loadingQuotation) {
                        //         const handleQuotationError = (error: any): Promise<boolean> => {
                        //             quoteContext.update({ loadingQuotation: false });
                        //             if (RoomHeatingTypeErrorHelper.isErrorFromRoomHeatingTypeHelper(error)) {
                        //                 return RoomHeatingTypeErrorHelper.handleError(error);
                        //             } else {
                        //                 //this.handleDeadEndError();
                        //                 return createCustomErrorHandler(AuthHelper.isAuthenticatedAsStoreOrBOUser())(
                        //                     error
                        //                 );
                        //             }
                        //         };
                        //         await wrapError<QuotationReplyBaseDto>(
                        //             async () => {
                        //                 quoteContext.update({ loadingQuotation: true });
                        //                 const response = await QuotationsApi.computeQuotation(bodyRequest);
                        //                 const quote = response.data;
                        //                 currentProject.update({ isDirty: false });
                        //                 quoteContext.update({
                        //                     loadingQuotation: false,
                        //                     quote,
                        //                     quotePrixContextDebugMap,
                        //                 });
                        //                 return quote;
                        //             },
                        //             { errorHandler: handleQuotationError }
                        //         );
                        //     }
                        // }
                    } else {
                        const stepHabitation = QuestionPrepare.toStepQuestions(questions, QuestionStep.StepHabitation);
                        const stepServices = QuestionPrepare.toStepQuestions(questions, QuestionStep.StepServices);
                        const stepSynthese = QuestionPrepare.toStepQuestions(questions, QuestionStep.StepSynthese);

                        quoteContext.update({
                            versionId: version,
                            loadingStep: undefined,
                            versionData,
                            questions,
                            stepHabitation,
                            stepServices,
                            stepSynthese,
                            habitationQuestionsOpen: true,
                            loading: false,
                            initialized: true,
                        });
                    }
                }
            },
            {
                errorHandler: (loadError) => {
                    quoteContext.update({ loadingStep: undefined });
                    Logger.error('QUOTE initialization', loadError);
                    if ((loadError as ApiError).isApiError) {
                        Toast.showError({
                            title: I18n.get('Toast_TitleError'),
                            content: I18n.get('COMMON_ERROR_MESSAGE'),
                            duration: 5000,
                        });
                        return Promise.resolve(true);
                    }
                    if ((loadError as MapError).isMapError) {
                        const mapError = loadError as MapError;
                        Logger.logAnalytics({
                            message: MapHelper.LOAD_MAP_ERROR_MESSAGE,
                            level: LogLevelType.Error,
                            value: mapError.error,
                        });
                        Toast.showError({
                            title: I18n.get('Toast_TitleError'),
                            content: I18n.get('ERROR_LOADING_MAP'),
                            duration: 5000,
                        });
                        return Promise.resolve(true);
                    }
                    return Promise.resolve(false);
                },
            }
        );
    };

    const update = (params: UpdateQuoteParams) => {
        let { quoteValues, mapValues, questionsModified, refreshLaizeCalc, mapModified, logs } = params;
        let mapState: MapContextState = { ...map.getState(), ...mapValues };
        Logger.log('QuoteProvider -> update', params);

        //!\ WHEN UPDATE habitations/question produit principale
        if (quoteValues) {
            const quoteState = quoteContext.getState();
            let mapState: MapContextState = map.getState();
            const nextQuoteState = { ...quoteState, ...quoteValues };

            if (mapState.showFinDeParcours) {
                quoteContext.update(toUpdateGeneralQuestions(mapState, nextQuoteState, isVendeur));
            } else {
                quoteContext.update(nextQuoteState);
            }
        }

        if (mapValues) {
            //!\ OCCURS WHEN UPDATE PRODUIT PRINCIPAL OR HABITATION QUESTIONS OR MAP_ITEM INFO CHANGES
            if (questionsModified || mapModified || refreshLaizeCalc) {
                const quoteState = quoteContext.getState();
                const {
                    laizeProps,
                    refreshLaizeCalcNeeded,
                    logs: refreshLaizeCalcLogs,
                } = isRefreshLaizeCalcNeeded(mapState, quoteState, logs);
                const needComputeLaizeUpdate = Boolean(mapModified || refreshLaizeCalc || refreshLaizeCalcNeeded);
                mapState = needComputeLaizeUpdate ? map.withComputeLaize(mapState) : mapState;
                const { modified, ...updatedMapValues } = toUpdateMapItemQuestions(mapState, quoteState, isVendeur);
                const nextMapState = { ...mapState, ...updatedMapValues, laizeProps };
                map.update({
                    values: nextMapState,
                    logs: { ...refreshLaizeCalcLogs, mapValues, questionsModified },
                });
                if (mapState.showFinDeParcours) {
                    quoteContext.update(toUpdateGeneralQuestions(nextMapState, quoteState, isVendeur));
                }
            } else {
                map.update({
                    values: mapValues,
                    logs: { ...logs, combineWith: 'QuoteProvider update', mapValues, questionsModified },
                });
            }
        }

        if (!currentProject.getState().isDirty && (mapModified || questionsModified)) {
            currentProject.update({ isDirty: true });
        }
    };

    const openFinDeParcours = () => {
        const mapState: MapContextState = map.getState();
        const quoteState = quoteContext.getState();
        quoteContext.update(toUpdateGeneralQuestions(mapState, quoteState, isVendeur), () => {
            map.setPanel({ values: { showFinDeParcours: true } });
        });
    };

    const combineMapState = (mapState: MapContextState): Partial<MapContextState> => {
        if (!currentProject.getState().isDirty) {
            currentProject.update({ isDirty: true });
        }
        //!\ WHEN UPDATE rooms, openings, roomItems from PANELS or map gestures
        mapState = map.withComputeLaize(mapState);
        const quoteState = quoteContext.getState();
        const { modified, ...updatedMapValues } = toUpdateMapItemQuestions(mapState, quoteState, isVendeur);
        return { ...mapState, ...updatedMapValues, showFinDeParcours: false };
    };

    React.useEffect(() => map.setStateCombiner({ merge: combineMapState }), [map, combineMapState]);

    useReady(load);
    useLoadProduitFromRef(versionId, produitRef, quoteContext.state, update);
    useInvalidQuoteIfNeeded(quoteContext.state, quoteContext.update, map.update);

    return (
        <QuoteContext.Provider
            value={{
                state: quoteContext.state,
                getState: quoteContext.getState,
                updateQuote: quoteContext.update,
                update,
                openFinDeParcours,
            }}>
            {children}
        </QuoteContext.Provider>
    );
};

const useReady = (load: VoidFunction) => {
    //* So we wait until the MeState is ready to load versionData.
    const isAuthenticated = useSelector((state: AppState) => state.Authentication)?.isAuthenticated;
    const isMeInitialized = useMe()?.data;

    const isMeReady = !isAuthenticated || (isAuthenticated && isMeInitialized);
    React.useEffect(() => {
        if (isMeReady) {
            load();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMeReady]);

    return isMeReady;
};

const useLoadProduitFromRef = (
    versionId: string,
    produitRef: string,
    state: EnhancedViewState<QuoteState>,
    update: (params: UpdateQuoteParams) => void
) => {
    React.useEffect(() => {
        const { initialized } = state;
        if (produitRef && initialized) {
            // question.produitReadonlyReference = produitRef;
            // QuestionsHelper.loadProduits({
            //     question,
            //     context: question.context,
            //     versionId: versionId!,
            //     updateQuestion: ({ question: updatedQuestion }: UpdateQuestionAfterLoadProduitsParameters) => {
            //         if (updatedQuestion.produitValue?.is_default) {
            //             updatedQuestion.isValid = true;
            //         }
            //         update({
            //             quoteValues: { produitRef },
            //             logs: { event: 'from useLoadProduitFromRef', produitRef },
            //         });
            //     },
            // });
            update({
                quoteValues: { produitRef },
                logs: { event: 'from useLoadProduitFromRef', produitRef },
            });
            NavigationService.replaceParams(EstimationUrlHelper.toQueryParam({ version: versionId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [produitRef, state.initialized]);
};

const useInvalidQuoteIfNeeded = (
    state: Partial<QuoteState>,
    updateQuote: (values: Partial<QuoteState>) => void,
    updateMap: (params: UpdateMapParams) => void
) => {
    const currentProject = useCurrentProject();
    React.useEffect(() => {
        if (state.quote && currentProject.state?.isDirty) {
            updateQuote({ quote: undefined });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.quote, currentProject.state?.isDirty]);
};

const isRefreshLaizeCalcNeeded = (mapState: Partial<MapContextState>, quoteState: Partial<QuoteState>, logs?: any) => {
    //!\ call computelaize if any produitPrincipal changes
    let refreshLaizeCalcNeeded = false;
    let laizeProps = mapState.laizeProps;

    mapState.rooms?.forEach((room) => {
        room.questionProduitPrincipal = upadateQuestionProduitPrincipalIfNeeded(
            room,
            quoteState.questions,
            quoteState.stepHabitation?.questions
        );
    });

    const refreshLaizeCalcRoomIds = mapState.rooms?.some((room) => {
        const produitPrincipal = room.questionProduitPrincipal?.produitValue;
        const laizeProps = mapState.laizeProps?.find((x) => x.roomIds?.some((y) => y === room.roomId));
        return produitPrincipal?.code !== laizeProps?.productCode;
    });
    if (refreshLaizeCalcRoomIds) {
        laizeProps = ProductHelper.ToArrayLaizeProps(mapState.rooms);
        logs = { ...logs, logLaizeProps: `laizeProps updated`, laizeProps };
        refreshLaizeCalcNeeded = true;
    }

    Logger.log('refreshLaizeCalc', { mapState, refreshLaizeCalcNeeded, laizeProps });
    return { laizeProps, refreshLaizeCalcNeeded, logs };
};
