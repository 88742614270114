import { IconButton, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { styled } from '@material-ui/styles';
import React from 'react';

import { Constants } from '../../../Constants';
import { Assets } from '../../assets/Assets';
import { useStmacLoginDialogController } from './StmacLoginDialogController';
import { MOBILE_LIMIT } from 'Web/Context/PlatformContext/PlatformContext';
import { Stack } from 'Web/Components/Widgets/Stack';
import { GlendaleTitle } from 'Web/Components/Widgets/Typographies';

export interface StmacLoginDialogProps {
    open: boolean;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    onConnected?: VoidFunction;
    onClose?: VoidFunction;
    closeDisabled?: boolean;
}

export const StmacLoginDialog = ({
    open,
    title,
    subtitle,
    onConnected,
    onClose,
    closeDisabled,
}: StmacLoginDialogProps) => {
    useStmacLoginDialogController({ onConnected });

    const paperClasses = withPaperClasses();

    return (
        <Dialog
            id="LoginDialog"
            open={open}
            onClose={closeDisabled ? undefined : onClose}
            classes={{ paper: paperClasses.paper }}>
            <Container>
                {!closeDisabled && (
                    <ButtonClose onClick={onClose}>
                        <Assets.IconClose />
                    </ButtonClose>
                )}
                {title && subtitle && (
                    <Header>
                        <Assets.IconLaptop />
                        <TitleContainer>
                            <Title>{title}</Title>
                            <Subtitle>{subtitle}</Subtitle>
                        </TitleContainer>
                    </Header>
                )}
                <IFrame id="IFrameLogin" src={Constants.STMAC_LOGIN_URL} />
            </Container>
        </Dialog>
    );
};

const Container = styled(Stack)({
    width: '100%',
    position: 'relative',
    height: '100%',
    background: 'rgb(246, 246, 246)',
});
const ButtonClose = styled(IconButton)({
    position: 'absolute',
    width: 64,
    height: 64,
    right: 16,
    top: 10,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_600}px)`]: {
        position: 'relative',
        right: 0,
        top: 0,
        margin: 4,
        'align-self': 'flex-end',
    },
});
const Header = styled('div')({
    background: 'rgb(246, 246, 246)',
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    marginTop: 36,
    width: '100%',
    paddingLeft: '12.5%',
    paddingRight: '10%',

    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_600}px)`]: {
        position: 'absolute',
        top: 21,
        flexDirection: 'column',
        textAlign: 'center',
    },
});
const TitleContainer = styled(Stack)({
    color: '#1c1a37',
    marginLeft: 28,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_600}px)`]: { marginTop: 15, marginLeft: 0 },
});
const Title = styled(GlendaleTitle)({
    fontSize: 20,
    lineHeight: 1.41,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_600}px)`]: { lineHeight: 1.2 },
});
const Subtitle = styled('span')({
    fontSize: 17,
    marginTop: 2,
    lineHeight: 1.41,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_600}px)`]: { lineHeight: 1.25, fontSize: 16 },
});
const IFrame = styled('iframe')({
    height: 650,
    border: 0,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_600}px)`]: { marginTop: 21 },
});

const withPaperClasses = makeStyles({
    paper: {
        'min-width': '800px',
        [`@media (max-width: ${MOBILE_LIMIT.WIDTH_600}px)`]: {
            height: '100%',
            'min-width': '100%',
            'max-height': '100%',
        },
    },
});
