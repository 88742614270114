import { ConditionHelper } from '../../../../Helpers/ConditionHelper';
import { ObjectUtils } from '../../../../Helpers/ObjectUtils';
import { QuestionContextHelper } from '../../../../Helpers/QuestionContextHelper';
import { QuestionItem, QuestionStep, QuestionType } from '../../../../Models/Questions/QuestionDto';
import { IRoom } from '../../../../Plugins/FloorPlan/Models/IRoom';
import { QuestionContextUtils } from '../Utils/QuestionContextUtils';

export function getQuestionProduitPrincipal(room: IRoom, questions: QuestionItem[] = [], habitationQuestions: QuestionItem[] = []) {
    const questionsProduitPrincipal = questions.filter((q) => q.related_step === QuestionStep.StepProduit && q.type === QuestionType.Produit);
    const questionProduitPrincipal = questionsProduitPrincipal.find((question, index) => {
        const condition = question.visibility_condition;
        const rawContext = QuestionContextHelper.mergeContext(
            QuestionContextHelper.getBaseQuestionsContext(habitationQuestions),
            QuestionContextUtils.createRoomContext(room)
        )
        const isVisible = !question.visibility_condition
            ? true :
            ConditionHelper.isConditionEvaluateToTrue({ question, condition, rawContext });
        return isVisible;
    });
    return ObjectUtils.clone(questionProduitPrincipal);
}

export function upadateQuestionProduitPrincipalIfNeeded(room: IRoom, questions: QuestionItem[] = [], habitationQuestions: QuestionItem[] = []) {
    const questionsProduitPrincipal = questions.filter((q) => q.related_step === QuestionStep.StepProduit && q.type === QuestionType.Produit);
    const questionProduitPrincipal = questionsProduitPrincipal.find((question, index) => {
        const condition = question.visibility_condition;
        const rawContext = QuestionContextHelper.mergeContext(
            QuestionContextHelper.getBaseQuestionsContext(habitationQuestions),
            QuestionContextUtils.createRoomContext(room)
        )
        const isVisible = !question.visibility_condition
            ? true :
            ConditionHelper.isConditionEvaluateToTrue({ question, condition, rawContext });
        return isVisible;
    });

    if (room.questionProduitPrincipal?.id !== questionProduitPrincipal?.id) {
        return ObjectUtils.clone(questionProduitPrincipal);
    }
    return room.questionProduitPrincipal;
}

export function getQuestionProduitPrincipalLogData(room: IRoom, questions: QuestionItem[] = [], habitationQuestions: QuestionItem[] = []) {
    const questionsProduitPrincipal = questions.filter((q) => q.related_step === QuestionStep.StepProduit && q.type === QuestionType.Produit);
    const rawContext = QuestionContextHelper.mergeContext(
        QuestionContextHelper.getBaseQuestionsContext(habitationQuestions),
        QuestionContextUtils.createRoomContext(room)
    )
    return { questionsProduitPrincipal, rawContext, room };
}