import Dialog from '@material-ui/core/Dialog';
import { styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';

import { IconButton, useMediaQuery } from '@material-ui/core';
import { useStopGesture } from '../../../Events/withEvent';
import { MOBILE_LIMIT } from 'Web/Context/PlatformContext/PlatformContext';
import { AvenirTitle, GlendaleTitle } from 'Web/Components/Widgets/Typographies';
import { Stack } from 'Web/Components/Widgets/Stack';

export type BaseDialogProps = {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    onClose?: VoidFunction;
    fullScreen?: boolean;
    enableCloseOutside?: boolean;
    cancelDisabled?: boolean;
};

export const BaseDialog: React.FC<BaseDialogProps> = ({
    title,
    subtitle,
    onClose,
    cancelDisabled = false,
    enableCloseOutside = true,
    fullScreen,
    children,
}) => {
    const matches = useMediaQuery('@media (max-width: 600px)');
    return (
        <Dialog
            open
            fullScreen={fullScreen}
            onClose={enableCloseOutside && !cancelDisabled ? onClose : undefined}
            PaperProps={{ style: matches ? PaperMobile : Paper }}
            BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.4)' } }}>
            <Content {...useStopGesture()}>
                <Stack>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                    {!cancelDisabled && (
                        <ButtonClose onClick={onClose} style={{ top: 24, right: 24 }}>
                            <IconClose />
                        </ButtonClose>
                    )}
                </Stack>
                {children}
            </Content>
        </Dialog>
    );
};
const Paper: CSSProperties = {
    borderRadius: 0,
    position: 'relative',
    background: '#FFFFFF',
    minWidth: 461,
};
const PaperMobile: CSSProperties = {
    margin: '0px 16px',
    minWidth: '100%',
    position: 'absolute',
    bottom: 0,
};

const Content = styled('div')({
    position: 'relative',
    padding: '68px 69px 69px 68px',
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_600}px)`]: { padding: 16 },
});

const Title = styled(GlendaleTitle)({ fontSize: 20, lineHeight: '24px', color: '#1C1A37' });
const Subtitle = styled(AvenirTitle)({ marginTop: 20, fontSize: 16, lineHeight: '22px', whiteSpace: 'break-spaces' });

const ButtonClose = styled(IconButton)({ position: 'absolute' });
const IconClose = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="black">
            <path d="M0.781413 16C0.465384 16.0001 0.180447 15.8097 0.0594998 15.5177C-0.0614474 15.2258 0.00542109 14.8897 0.228917 14.6663L14.6663 0.228852C14.9715 -0.0762838 15.4662 -0.0762838 15.7713 0.228852C16.0765 0.533987 16.0765 1.02871 15.7713 1.33384L1.33391 15.7713C1.1875 15.918 0.988675 16.0003 0.781413 16Z" />
            <path d="M15.2189 16C15.0116 16.0003 14.8128 15.918 14.6664 15.7713L0.228974 1.33384C-0.0761618 1.02871 -0.0761618 0.533987 0.228974 0.228852C0.534109 -0.0762838 1.02883 -0.0762838 1.33397 0.228852L15.7714 14.6663C15.9949 14.8897 16.0617 15.2258 15.9408 15.5177C15.8198 15.8097 15.5349 16.0001 15.2189 16Z" />
        </svg>
    );
};
