import { Logger } from '../../../../Errors/Logger';
import { QuestionsHelper } from '../../../../Helpers/QuestionsHelper';
import { QuoteQuestionUtils } from '../../../../Helpers/QuoteQuestionUtils';
import { QuestionItem } from '../../../../Models/Questions/QuestionDto';

export type ScrollToQuestionParams = {
    scrollerId: string,
    itemId: string,
    toQuestion?: QuestionItem,
    questions?: QuestionItem[],
    toQuestionId?: string;
    prevQuestion?: QuestionItem,
    delay?: number,
    logs?: any;
}

export class Scroller {

    public static ID = 'SCROLLER_VIEW';

    public static ShortDelay = 250;
    public static LongDelay = 500;

    public static EXTRA_SCROLL = 100;

    public static isInViewport(element?: HTMLElement, extraPadding: number = 0) {
        if (element) {
            const rect: DOMRect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom + extraPadding <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
        return false;
    }
}

export const useScroller = () => {

    //#region //* SCROLL HABIATION QUESTIONS ON MOBILE DIALOG

    const scrollToHabitationQuestionDiv = (prevQuestionDivId: string, toQuestionDivId: string) => {
        //* SCROLL TO SPECIFIC POSITION
        const questionDiv: HTMLElement | null = document.getElementById(toQuestionDivId ?? '');

        //! 56 = footer height
        if (questionDiv && !Scroller.isInViewport(questionDiv, 56)) {
            const questionDivHeight: number = questionDiv?.offsetHeight ?? 0;

            const viewportHeight: number = window.innerHeight || document.documentElement.clientHeight;
            const viewportContainerHeight: number = viewportHeight;

            const questionDivRectInDom: DOMRect = questionDiv.getBoundingClientRect();
            let elDistanceToTop: number = (window.pageYOffset + questionDivRectInDom.top);

            //* Scroll to top of questionDivHeight
            let toTopScrollPosition: number = elDistanceToTop;

            //* Scroll to bottom of questionDivHeight
            if (viewportContainerHeight > questionDivHeight) {
                toTopScrollPosition = (elDistanceToTop - viewportContainerHeight + questionDivHeight);
            }

            //* handle if previous infoBulleListDiv exist
            const previousQuestionListInfobulleDivId: string = QuoteQuestionUtils.withtoListInfobulleId(prevQuestionDivId);
            const infoBulleListDiv: HTMLElement | null = document.getElementById(previousQuestionListInfobulleDivId);

            if (infoBulleListDiv) {
                const infoBulleListDivHeight: number = infoBulleListDiv?.offsetHeight ?? 0;
                const questionDivWithPreviousInfobulleHeight: number = questionDivHeight + infoBulleListDivHeight;

                //* Scroll to top of previous infobulle
                if (questionDivWithPreviousInfobulleHeight > viewportContainerHeight) {
                    const previousInfoBulleListDivRectInDom: DOMRect = infoBulleListDiv.getBoundingClientRect();
                    elDistanceToTop = (window.pageYOffset + previousInfoBulleListDivRectInDom.top);
                    toTopScrollPosition = elDistanceToTop;
                }
            }

            //* 98 = container padding bottom
            const EXTRA_SCROLL = 98;
            toTopScrollPosition = toTopScrollPosition + EXTRA_SCROLL + 48;

            const questionsContainer: HTMLElement | null = document.getElementById(Scroller.ID);
            questionsContainer?.scrollBy({ behavior: "smooth", top: toTopScrollPosition });
        }
    }

    //#endregion

    //#region //* SCROLL TO INFO FIELD ROOM, OPENING, ROOM_ITEM

    const scrollToDiv = (scrollerId: string, toDivId: string, isMobile: boolean = false) => {
        Logger.log('scrollToDiv', { scrollerId, toDivId, isMobile });
        //* SCROLL TO TOP
        if (!toDivId) {
            const questionsContainer: HTMLElement | null = document.getElementById(scrollerId);
            questionsContainer?.scrollTo({ behavior: 'smooth', top: 0 });
        } else {
            //* SCROLL TO SPECIFIC POSITION
            const div: HTMLElement | null = document.getElementById(toDivId ?? '');

            //if (div && !Scroller.isInViewport(div)) {
            if (div) {
                const divHeight: number = div?.offsetHeight ?? 0;

                const MIN_TOP: number = isMobile ? 64 : 99; //* HEADER

                const viewportHeight: number = window.innerHeight || document.documentElement.clientHeight;

                const divRectInDom: DOMRect = div.getBoundingClientRect();
                let elDistanceToTop: number = window.pageYOffset + divRectInDom.top - MIN_TOP;

                //* Scroll to top of divHeight
                let toTopScrollPosition: number = elDistanceToTop;

                //* Scroll to bottom of divHeight
                const viewportContainerHeight: number = viewportHeight - MIN_TOP;
                if (viewportContainerHeight > divHeight) {
                    toTopScrollPosition = elDistanceToTop - viewportContainerHeight + divHeight;
                }

                const EXTRA_SCROLL = 48;
                toTopScrollPosition = toTopScrollPosition + EXTRA_SCROLL;

                const scroller: HTMLElement | null = document.getElementById(scrollerId);

                // const scrollerRectInDom: DOMRect = scroller!.getBoundingClientRect();
                // const scollerCenterY = scrollerRectInDom.top + scrollerRectInDom.height / 2;
                // toTopScrollPosition = toTopScrollPosition > scollerCenterY
                //     ? toTopScrollPosition - (toTopScrollPosition - scollerCenterY)
                //     : scollerCenterY - (scollerCenterY - toTopScrollPosition)

                scroller?.scrollBy({ behavior: 'smooth', top: toTopScrollPosition });
            }
        }
    };

    //#endregion

    //#region //* SCROLL TO QUESTION ROOM, OPENING, ROOM_ITEM

    const scrollToQuestion = ({
        scrollerId,
        itemId,
        toQuestion,
        questions = [],
        toQuestionId,
        prevQuestion: lastShownQuestion,
        delay = 10,
        logs,
    }: ScrollToQuestionParams
    ) => {

        //! SCROLL RULES :
        //! 1 - Si la question suivante peut être affichée en entier sur le panneau
        //! RULE 1 * on scrolle pour aligner le bas de la question au bas du panneau d'édition
        //! 2 - Si la question ne peut pas être affichée en entier
        //! RULE 2 * on scrolle pour aligner le haut de la question au haut du panneau
        //! 3 - Exception : Si la question n-1 affiche un conseil
        //!        * lorsque je réponds, le scroll se fait de sorte à ce que le conseil soit aligné en haut du panneau,
        //! RULE 3 * avec la question n en dessous (la suivante à laquelle je dois répondre)

        setTimeout(() => {
            const toQuestionDivId = toQuestionId || QuoteQuestionUtils.toQuestionItemId(itemId, toQuestion?.id);
            const prevQuestion = lastShownQuestion || QuestionsHelper.getPreviousVisibleQuestion(toQuestion!, questions);
            const prevQuestionDivId = QuoteQuestionUtils.toQuestionItemId(itemId, prevQuestion?.id);
            const scroller: HTMLElement | null = document.getElementById(scrollerId);

            if (scroller && toQuestionDivId) {
                const scrollerRect: DOMRect | undefined = scroller.getBoundingClientRect();
                //* SCROLL TO SPECIFIC POSITION
                const questionDiv: HTMLElement | null = document.getElementById(toQuestionDivId ?? '');
                const questionDivInViewPort = questionDiv && Scroller.isInViewport(questionDiv);

                if (questionDiv && !questionDivInViewPort) {
                    const questionDivHeight: number = questionDiv?.offsetHeight ?? 0;

                    const questionRect: DOMRect = questionDiv.getBoundingClientRect();
                    const viewportHeight: number = window.innerHeight || document.documentElement.clientHeight;

                    //* Scroll to top of questionDivHeight
                    const SCROLLER_TOP = scrollerRect.top;

                    let toTopScrollPosition: number = 0;

                    if (scrollerRect.height > questionRect.height) {
                        //const alignBottom = questionDivRectInDom.bottom > scrollerRect.height;

                        //!\\ GAP between two questions (DEFAULT = 24)
                        const includeGap = questions.filter(q => Boolean(q.showQuestion)).length > 1;
                        const GAP = includeGap ? 24 : 0;
                        toTopScrollPosition = questionRect.bottom - SCROLLER_TOP - scrollerRect.height + GAP;
                        //! Quand on aligne sur le bas de la question
                        //! Laisser une marge pour que l'on puisse voir qu'il n'y a pas d'autres réponses possibles en dessous
                        Logger.log('RULE 1', { logs });
                        //toTopScrollPosition = addExtraScrollIfNeeded(toTopScrollPosition, scrollerRect);
                    } else {
                        //! 2 - Si la question ne peut pas être affichée en entier
                        //! --- on scrolle pour aligner le haut de la question au haut du panneau
                        toTopScrollPosition = questionRect.top - SCROLLER_TOP;
                        Logger.log('RULE 2', { logs });
                    }

                    //* handle if previous infoBulleListDiv exist
                    const prevQuestionInfobullesDivId: string = QuoteQuestionUtils.withtoListInfobulleId(prevQuestionDivId);
                    const infobulleListDiv: HTMLElement | null = document.getElementById(prevQuestionInfobullesDivId);

                    if (infobulleListDiv) {
                        const prevInfobulleListRect: DOMRect = infobulleListDiv.getBoundingClientRect();
                        const QUESTION_GAP = 24;//! 24px = gap between 2 questions
                        const prevInfobulleWithQuestionHeight = prevInfobulleListRect.height + QUESTION_GAP + questionRect.height;

                        if (scrollerRect.height > prevInfobulleWithQuestionHeight) {
                            toTopScrollPosition = questionRect.bottom - SCROLLER_TOP - scrollerRect.height + (prevInfobulleListRect.height + 24);

                            Logger.log('RULE 3-a')
                        } else {
                            //! 2 - Si les conseils +  la question ne peut pas être affichée en entier
                            //! --- on scrolle pour aligner le haut des conseils avec le haut du panneau
                            toTopScrollPosition = prevInfobulleListRect.top - SCROLLER_TOP;
                            Logger.log('RULE 3-b', { prevInfobulleWithQuestionHeight, SCROLLER_TOP, toTopScrollPosition, scroller, viewportHeight, prevInfobulleListRect, questionRect, questionDivHeight, scrollerRect });
                        }
                    }

                    //* 48 = next step button height
                    //* 24 = margin top
                    //* 48 = margin bottom
                    // const EXTRA_SCROLL = 48 + 24 + 48;
                    // toTopScrollPosition = toTopScrollPosition + EXTRA_SCROLL + 24;
                    scroller?.scrollBy({ behavior: 'smooth', top: toTopScrollPosition });

                    const scrollContent = scrollerRect.height + scrollerRect.top;
                    const scrollHeight = scroller.scrollHeight + SCROLLER_TOP;
                    Logger.isLogEnabled() && Logger.log('toTopScrollPosition', { scrollContent, SCROLLER_TOP, toTopScrollPosition, scroller, scrollHeight, viewportHeight, questionRect, questionDivHeight, scrollerRect, top: toTopScrollPosition, logs });
                }
            }
        }, delay);
    };

    //#endregion

    //#region //* SCROLL TO QUESTION BOTTOM
    const scrollToAlignBottomQuestionOnBottom = (
        scrollerId: string,
        itemId: string,
        toQuestion: QuestionItem,
        delay: number = 10
    ) => {
        setTimeout(() => {
            const toQuestionDivId = QuoteQuestionUtils.toQuestionItemId(itemId, toQuestion.id);
            const scroller: HTMLElement | null = document.getElementById(scrollerId);
            if (scroller && toQuestionDivId) {
                const scrollerRect: DOMRect | undefined = scroller.getBoundingClientRect();
                //* SCROLL TO SPECIFIC POSITION
                const questionDiv: HTMLElement | null = document.getElementById(toQuestionDivId ?? '');

                if (questionDiv && !Scroller.isInViewport(questionDiv)) {
                    const questionRect: DOMRect = questionDiv.getBoundingClientRect();
                    //* Scroll to top of questionDivHeight
                    const SCROLLER_TOP = scrollerRect.top;
                    let toTopScrollPosition = questionRect.bottom - SCROLLER_TOP - scrollerRect.height + 2;
                    //! Quand on aligne sur le bas de la question
                    //! Laisser une marge pour que l'on puisse voir qu'il n'y a pas d'autres réponses possibles en dessous
                    toTopScrollPosition = addExtraScrollIfNeeded(toTopScrollPosition, scrollerRect);
                    scroller?.scrollBy({ behavior: 'smooth', top: toTopScrollPosition });
                }
            }
        }, delay);
    };

    //#endregion

    const scollToBottom = (scrollerId: string, extraScroll: number = 0) => {
        Logger.log('scrollToDiv', { scrollerId, extraScroll });
        const scroller: HTMLElement | null = document.getElementById(scrollerId);
        if (scroller) {
            const toTopScrollPosition = scroller.scrollHeight + extraScroll;
            scroller?.scrollBy({ behavior: 'smooth', top: toTopScrollPosition });
        }
    }

    return { scrollToHabitationQuestionDiv, scrollToDiv, scrollToQuestion, scrollToAlignBottomQuestionOnBottom, scollToBottom }
}

function addExtraScrollIfNeeded(toTopScrollPosition: number, scrollerRect: DOMRect) {
    const addExtraScroll = toTopScrollPosition + scrollerRect.height + Scroller.EXTRA_SCROLL < scrollerRect.bottom;
    Logger.log('rule 1 -> remove EXTRA_SCROLL', {
        topScrollWithHeight: toTopScrollPosition + scrollerRect.height,
        addExtraScroll, toTopScrollPosition,
        scrollerRect,
        testAddExtraScroll: `addExtraScroll : (toTopScrollPosition + EXTRA_SCROLL + scrollerRect.height) ${toTopScrollPosition + Scroller.EXTRA_SCROLL + scrollerRect.height} < ${scrollerRect.bottom} (scrollerRect.bottom)`,
    });
    return addExtraScroll ? toTopScrollPosition + Scroller.EXTRA_SCROLL : toTopScrollPosition + 2;
}