import React from 'react';

import { useWindowLog } from '../../../Hooks/WindowLog';
import { useViewState } from '../../../Web/Hooks/ViewState/ViewState';
import { MapConstants, MapMode } from '../MapConstants';
import { CoordPoint } from '../Utils/Types';

export type AnchorLine = { id: string; start: CoordPoint; end: CoordPoint };

export type GestureState = {
    isPressDown?: boolean;
    openingId?: string;
    roomItemId?: string;

    menuContextOpenRoomId?: string;

    verticalAnchorLine?: AnchorLine;
    horizontalAnchorLine?: AnchorLine;
    invalidRoomIds?: string[];
    invalidOpeningIds?: string[];
    invalidRoomItemIds?: string[];
};

type GestureContextValue = {
    state: GestureState;
    getState: () => GestureState;
    update: (values: Partial<GestureState>) => void;
    clear: () => void;
    timeoutPressDown: () => void;
    clearAnchorLines: () => void;
    clearMenuContext: () => void;
};

export const GestureContext = React.createContext<GestureContextValue>({
    state: {},
    getState: () => ({}),
    update: () => {},
    clear: () => {},
    timeoutPressDown: () => {},
    clearAnchorLines: () => {},
    clearMenuContext: () => {},
});
export const useGestureContext = () => React.useContext(GestureContext);

export const InitialGestureContextState: GestureState = {
    isPressDown: undefined,
    openingId: undefined,
    roomItemId: undefined,
    menuContextOpenRoomId: undefined,
};

export const GestureProvider: React.FC = ({ children }) => {
    const { state, getState, update } = useViewState<GestureState>(InitialGestureContextState);
    useWindowLog(() => (window.GestureState = getState));

    const clear = () => update(InitialGestureContextState);
    const timeoutPressDown = () => {
        if (getState().isPressDown) {
            update({ isPressDown: false });
        }
    };

    const clearAnchorLines = () => {
        const { verticalAnchorLine, horizontalAnchorLine } = getState();
        if (verticalAnchorLine || horizontalAnchorLine) {
            update({ verticalAnchorLine: undefined, horizontalAnchorLine: undefined });
        }
    };

    const clearMenuContext = () => {
        const { menuContextOpenRoomId } = getState();
        if (menuContextOpenRoomId) {
            update({ menuContextOpenRoomId: undefined });
        }
    };

    return (
        <GestureContext.Provider
            value={{ state, getState, update, clear, timeoutPressDown, clearAnchorLines, clearMenuContext }}>
            {children}
        </GestureContext.Provider>
    );
};

export const useMultiTouch = () => {
    const handleMultiTouch = (touchEvent: React.TouchEvent) => {
        if (touchEvent?.touches?.length > 1) {
            const modes = [
                MapMode.MODE_TYPE_MOVE_ROOM,
                MapMode.MODE_TYPE_MOVE_ROOM_WALL,
                MapMode.MODE_TYPE_MOVE_ROOM_ITEM,
                MapMode.MODE_TYPE_MOVE_ROOM_ITEM_SIDE,
            ];
            if (modes.includes(MapConstants.mode!)) {
                MapConstants.mode = undefined;
            }
            return true;
        }
        return false;
    };
    return { handleMultiTouch };
};
