import { ObjectUtils } from '../../../../Helpers/ObjectUtils';
import { IsStepCompletedResult, QuestionsHelper } from '../../../../Helpers/QuestionsHelper';
import { ProduitDto } from '../../../../Models/Questions/ProduitDto';
import {
    OpeningsContextPropertiesDto,
    QuestionContextType,
    QuestionItem,
    QuestionStep,
    RoomElementsContextPropertiesDto,
} from '../../../../Models/Questions/QuestionDto';
import { ContextQuestionList, IMapItem } from '../../../../Plugins/FloorPlan/Models/IMapItem';
import { OpeningType } from '../../../../Plugins/FloorPlan/Models/IOpening';
import { RoomItemType } from '../../../../Plugins/FloorPlan/Models/IRoomItem';


type ToContextQuestionsParams = {
    step: QuestionStep;
    questions: Array<QuestionItem>;
    contextType: QuestionContextType;
    openingType?: OpeningType;
    roomItemType?: RoomItemType;
};

export interface IsQuestionInQuestionsItemParameters {
    question: QuestionItem;
    contextType: QuestionContextType;
    openingType?: OpeningType;
    roomItemType?: RoomItemType;
}

type VerifyIfQuestionsFinishedParams = {
    contextQuestions: ContextQuestionList,
    produitPrincipal?: ProduitDto,
    isVendeur?: boolean
}

export interface IsStepQuestionsFinishedResult {
    firstStepPlanItemNotCompleted?: {
        item: ContextQuestionList;
        result: IsStepCompletedResult;
    };
    isStepCompleted: boolean;
    isEmpty: boolean; //pas de question visible (question respiration exclue)
}

type SetFocusQuestionParams = { contextQuestions: ContextQuestionList, questions: QuestionItem[], focusQuestion?: QuestionItem }

//* COPY OF  QuoteStepPlanQuestionUtils
export class ContextQuestionsUtils {
    //#region //* PREPARATION QUESTIONS ITEM

    public static isQuestionInQuestionsItem = ({
        question,
        contextType,
        openingType,
        roomItemType,
    }: IsQuestionInQuestionsItemParameters): boolean => {
        const isQuestionWithContext = (): boolean => contextType === question.context_type;
        let questionIsInContext: boolean = false;
        if (question.openings_context_properties) {
            const openingsContext: OpeningsContextPropertiesDto = question.openings_context_properties;
            if (contextType === QuestionContextType.Openings && openingType) {
                if (openingType === OpeningType.Door) {
                    questionIsInContext = isQuestionWithContext() && openingsContext.is_for_porte;
                }
                if (openingType === OpeningType.ArmoredDoor) {
                    questionIsInContext = isQuestionWithContext() && openingsContext.is_for_porte_blindee;
                }
                if (openingType === OpeningType.FrontDoor) {
                    questionIsInContext = isQuestionWithContext() && openingsContext.is_for_porte_entree;
                }
                if (openingType === OpeningType.FrenchDoor) {
                    questionIsInContext = isQuestionWithContext() && openingsContext.is_for_porte_fenetre;
                }
                if (openingType === OpeningType.Opening) {
                    questionIsInContext = isQuestionWithContext() && openingsContext.is_for_passage_ouvert;
                }
                if (openingType === OpeningType.PatioDoor) {
                    questionIsInContext = isQuestionWithContext() && openingsContext.is_for_baie_vitree;
                }
            }
        } else if (question.room_elements_context_properties) {
            const roomElementsContext: RoomElementsContextPropertiesDto = question.room_elements_context_properties;
            if (contextType === QuestionContextType.RoomElements && roomItemType) {
                if (roomItemType === RoomItemType.ShowerBathtub) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_shower_bathtub;
                }
                if (roomItemType === RoomItemType.FixedFurniture) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_fixed_furniture;
                }
                if (roomItemType === RoomItemType.Cupboard) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_cupboard;
                }
                if (roomItemType === RoomItemType.KitchenFurniture) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_kitchen_furniture;
                }
                if (roomItemType === RoomItemType.Trap) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_trap;
                }
                if (roomItemType === RoomItemType.Fireplace) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_fireplace;
                }
                if (roomItemType === RoomItemType.Opening) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_opening;
                }
                if (roomItemType === RoomItemType.Stairs) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_stairs;
                }
                if (roomItemType === RoomItemType.Divider) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_divider;
                }
                if (roomItemType === RoomItemType.HeavyElement) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_heavy_element;
                }
                if (roomItemType === RoomItemType.SewerPlate) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_sewer_plate;
                }
                if (roomItemType === RoomItemType.Door) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_door;
                }
                if (roomItemType === RoomItemType.Window) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_window;
                }
                if (roomItemType === RoomItemType.UnlaidArea) {
                    questionIsInContext = isQuestionWithContext() && roomElementsContext.is_for_unlaid_area;
                }
            }
        } else {
            questionIsInContext = isQuestionWithContext();
        }
        return questionIsInContext;
    };

    public static toContextQuestions = ({
        step,
        questions,
        contextType,
        openingType,
        roomItemType,
    }: ToContextQuestionsParams): ContextQuestionList => {
        const contextQuestions: Array<QuestionItem> = ObjectUtils.clone(
            questions.filter((question) =>
                this.isQuestionInQuestionsItem({ question, contextType, openingType, roomItemType })
            )
        );
        return { step, questions: contextQuestions };
    };

    public static addPreviousSelectedProductSuggestions(room: IMapItem, initialQuestionItem: QuestionItem) {
        //TODO: @mmc
        // const previousStepPlanItem = this.getPreviousStepPlanItem(stepPlanItem);
        // if (previousStepPlanItem) {
        //     const previousSuggestion = previousStepPlanItem.questions?.find(
        //         (q) => q.id === initialQuestionItem.id
        //     )?.produitValue;

        //     if (previousSuggestion) {
        //         return {
        //             ...initialQuestionItem,
        //             produit_properties: {
        //                 ...initialQuestionItem.produit_properties,
        //                 have_suggestions: true,
        //             },
        //             produits: [
        //                 {
        //                     ...previousSuggestion,
        //                     suggestion: {
        //                         ...(previousSuggestion.suggestion ?? []),
        //                         description_produit: I18n.get('Quote_ProductSelect_Suggest_SameAsBefore'),
        //                     },
        //                 },
        //                 ...(initialQuestionItem.produits ?? []).filter((p) => p.code !== previousSuggestion.code),
        //             ],
        //         };
        //     }
        // }

        return initialQuestionItem;
    }

    public static verifyIfQuestionsFinished({
        contextQuestions,
        produitPrincipal,
        isVendeur
    }: VerifyIfQuestionsFinishedParams): IsStepQuestionsFinishedResult {
        const isStepFinishedResult: IsStepQuestionsFinishedResult = {
            isStepCompleted: true,
            isEmpty: true,
            firstStepPlanItemNotCompleted: undefined,
        };

        const isFinished = (item: ContextQuestionList): IsStepCompletedResult => {
            return QuestionsHelper.isStepCompleted({
                questions: item.questions,
                produitPrincipal,
                isVendeur,
            });
        };

        const result = isFinished(contextQuestions);
        if (isStepFinishedResult.isEmpty && !result.isEmpty) {
            isStepFinishedResult.isEmpty = false;
        }
        if (isStepFinishedResult.isStepCompleted && !result.isStepCompleted) {
            isStepFinishedResult.isStepCompleted = false;
        }

        return isStepFinishedResult;
    }


    public static setFocusQuestion = ({ contextQuestions, questions, focusQuestion }: SetFocusQuestionParams) => {
        let questionsUpdated = questions;
        questions.forEach((q) => (q.focusQuestion = false));
        if (focusQuestion) {
            focusQuestion.focusQuestion = true;
            questionsUpdated = QuestionsHelper.updateQuestions(questions, focusQuestion);
        }
        contextQuestions.endQuestion = false;
        contextQuestions.isEmpty = false;
        contextQuestions.isStepCompleted = false;
        contextQuestions.questions = questionsUpdated;
    }
}
