import { MapConstants } from '../../MapConstants';
import { IRoom } from '../../Models/IRoom';
import { IRoomItem, RoomItemType, RoomItemWithAngle } from '../../Models/IRoomItem';
import { CollisionRoomUtils } from '../Collisions/CollisionRoomUtils';
import { Editor } from '../Editor';
import { PointUtils } from '../PointUtils';
import { qSVG } from '../qSVG';
import { SATUtils } from '../SAT/SATUtils';
import { CoordPoint, SnapGesture, Wall, WallLine, WallSelected, WallWithCorrection } from '../Types';
import { WallUtils } from '../Wall/WallUtils';

export const RoomItemConstants = {
    RoomItemInConstruction: null,
    PreviousNearestWall: null,
    PreviousHorVNearestWall: null,

    ROOM_ITEM_MIN_SIZE: 20,
    ROOM_ITEM_MIN_CONTACT_LENGTH: 20,
    ROOM_ITEM_DIVIDER_MIN_SIZE: 7,
    ROOM_ITEM_MAX_SIZE: 3999,
};

export type RoomItemLimits = {
    horizontal: { min: number; max: number };
    vertical: { min: number; max: number };
};

export class RoomItemUtils {
    //#region //! DELETE (why ?: rendered by react)

    // public static roomItemMaker = (
    //     rooms: Array<IRoom>,
    //     roomItems: Array<IRoomItem>,
    //     walls: Array<Wall>,

    //     id: string,
    //     width: number,
    //     height: number,
    //     name: string,
    //     type: RoomItemType,
    //     putFlooring: YesOrNo,
    //     putPlinthes: string,
    //     isMoveableOutside: boolean,
    //     graphTranslation?: CoordPoint,
    //     roomId?: string
    // ): IRoomItem => {
    //     const item: IRoomItem = {
    //         roomItemId: id,
    //         roomId,
    //         type,
    //         name,
    //         putFlooring,
    //         putPlinthes,
    //         isMoveableOutside, // #20350
    //         width,
    //         height,
    //         graphTranslation: graphTranslation ?? { x: 3300, y: 1700 },
    //     };
    //     const halfWidth = width / 2;
    //     const halfHeight = height / 2;

    //     item.coords = [
    //         { x: -halfWidth, y: -halfHeight },
    //         { x: halfWidth, y: -halfHeight },
    //         { x: halfWidth, y: halfHeight },
    //         { x: -halfWidth, y: halfHeight },
    //         { x: -halfWidth, y: -halfHeight },
    //     ];

    //     item.coordsReal = item.coords.map((x) => PointUtils.translation(x, item.graphTranslation!));
    //     //item.graph = this.createRoomItemSvg(item.coords, id, item.graphTranslation!, item.roomItemType!);
    //     item.collisions = RoomCollisionsUtils.initCollisionPolygon(item.coordsReal.slice(0, -1), RoomShape.Rectangle);

    //     // item.update = () => {
    //     //     item.graph.children('use').replaceWith(this.createIcon(item.roomItemType!));
    //     //     //TODO binder.graph.children('use').replaceWith(this.createIcon(item.roomItemType));
    //     //     for (const room of rooms) {
    //     //         RoomDiffUtils.roomComputeDifference(room, roomItems, walls);
    //     //     }
    //     //     const wallPolygones: Array<WallPolygon> = qSVG.polygonize(walls);
    //     //     Editor.roomMaker(wallPolygones, rooms, roomItems, walls);
    //     // };
    //     return item;
    // };

    // public static createRoomItemSvg(points: Array<CoordPoint>, id: string, graphTranslation: CoordPoint, type: string) {
    //     var roomItemWrapper = qSVG.create('none', 'g', {
    //         id: 'roomitem-' + id,
    //         class: 'roomitem-' + id + '-class',
    //         transform: 'translate(' + graphTranslation.x + ',' + graphTranslation.y + ')',
    //     });

    //     var area = qSVG.create('none', 'polygon', {
    //         points: points.map((p) => p.x + ',' + p.y).join(' '),
    //         fill: '#18335e7f',
    //         stroke: '#18335e',
    //         'stroke-width': 3,
    //         'stroke-dasharray': '12 4',
    //         // strokeWidth: 3,
    //         // strokeDasharray: '12 4',
    //     });

    //     var iconWrapper = qSVG.create('boxDebug', 'circle', { r: 23, fill: 'white' });

    //     var icon = this.createIcon(type);
    //     roomItemWrapper.append(area);
    //     roomItemWrapper.append(iconWrapper);
    //     roomItemWrapper.append(icon);

    //     return roomItemWrapper;
    // }

    // public static createIcon = (type: string) => {
    //     const iconColor = '#0f0e2d';
    //     let iconHref;
    //     let iconTransform;
    //     let fill;
    //     let stroke;
    //     switch (type) {
    //         case 'shower_bathtub':
    //             iconHref = '#shower_bathtub-picto';
    //             iconTransform = 'rotate(0,0,0), translate(-16, -16)';
    //             fill = iconColor;
    //             stroke = 'transparent';
    //             break;
    //         case 'fixed_furniture':
    //             iconHref = '#fixed_furniture-picto';
    //             iconTransform = 'rotate(0,0,0), translate(-16, -16)';
    //             fill = iconColor;
    //             stroke = 'transparent';
    //             break;
    //         case 'cupboard':
    //             iconHref = '#cupboard-picto';
    //             iconTransform = 'rotate(0,0,0), translate(-16, -16)';
    //             fill = iconColor;
    //             stroke = 'transparent';
    //             break;
    //         case 'kitchen_furniture':
    //             iconHref = '#kitchen_furniture-picto';
    //             iconTransform = 'rotate(0,0,0), translate(-16, -16)';
    //             fill = iconColor;
    //             stroke = 'transparent';
    //             break;
    //         case 'fireplace':
    //             iconHref = '#fireplace-picto';
    //             iconTransform = 'rotate(0,0,0), translate(-14, -12)';
    //             fill = iconColor;
    //             stroke = 'transparent';
    //             break;
    //         case 'opening':
    //             iconHref = '#opening_roomitem-picto';
    //             iconTransform = 'rotate(0,0,0), translate(-22, -12)';
    //             fill = 'transparent';
    //             stroke = iconColor;
    //             break;
    //         case 'stairs':
    //             iconHref = '#stairs-picto';
    //             iconTransform = 'rotate(0,0,0), translate(-16, -10)';
    //             fill = iconColor;
    //             stroke = 'transparent';
    //             break;
    //         case 'divider':
    //             iconHref = '#divider-picto';
    //             iconTransform = 'rotate(0,0,0), translate(-20, -20)';
    //             fill = 'transparent';
    //             stroke = iconColor;
    //             break;
    //         case 'trap':
    //         default:
    //             iconHref = '#trap-picto';
    //             iconTransform = 'rotate(0,0,0), translate(-22, -12)';
    //             fill = 'transparent';
    //             stroke = iconColor;
    //     }

    //     return qSVG.create('none', 'use', {
    //         fill: fill,
    //         stroke: stroke,
    //         href: iconHref,
    //         transform: iconTransform,
    //     });
    // };

    // public static highlightRoomItem(roomItemTarget: IRoomItem) {
    //     //roomItemTarget.highlight = true;
    //     // binder = _.clone(roomItemTarget); // new roomItemMaker(roomItemTarget);
    //     // var originalRoomItem = $('#roomitem-' + roomItemTarget.id);
    //     // var existingBinder = $('#roomitem-' + roomItemTarget.id + '-highlight');
    //     // if (existingBinder.length == 1) {
    //     //     binder.graph = existingBinder;
    //     // } else {
    //     //     binder.graph = originalRoomItem.clone();
    //     //     binder.graph.attr('id', 'roomitem-' + roomItemTarget.id + '-highlight');
    //     //     binder.graph.appendTo('#boxbind');
    //     // }
    //     // // originalRoomItem.attr('transform', 'translate(' + (roomItemTarget.graphTranslation.x) + "," + (roomItemTarget.graphTranslation.y) + ')')
    //     // binder.graph.attr(
    //     //     'transform',
    //     //     'translate(' + roomItemTarget.graphTranslation.x + ',' + roomItemTarget.graphTranslation.y + ')'
    //     // );
    //     // binder.graph.children('polygon').attr('fill', 'var(--roomItem-select-color)');
    // }

    //#endregion

    public static toPosition = (roomItem: IRoomItem): CoordPoint | undefined => {
        if (roomItem.graphTranslation) {
            return { x: roomItem.graphTranslation.x, y: roomItem.graphTranslation.y };
        }

        return undefined;
    };

    public static updateRoomItems = (room: IRoom, roomItems: Array<IRoomItem>) => {
        room.roomItems = roomItems.filter((x) => x.roomId === room.roomId);
    };

    public static rayCastingRoomItemsSides(customSnap: SnapGesture, roomItems: Array<IRoomItem>) {
        let margin = 1;
        let sideList: Array<RoomItemWithAngle> = [];
        let angleOrder = [0, 90, 180, -90];
        let normalVectorAngle = [-90, 0, 90, 180];
        for (let i = 0; i < roomItems.length; i++) {
            const itemCoords = PointUtils.inflatePolygon(roomItems[i].coordsReal, 5).reverse();
            for (let j = 0; j < 4; j++) {
                let polygon = [];
                //angle order => 0, 90, 180, -90
                //normal vector angle => -90, 0, 90, 180
                polygon = [
                    {
                        x: itemCoords[j].x + Math.cos(PointUtils.degreeToRad(normalVectorAngle[j]) * -margin),
                        y: itemCoords[j].y + Math.sin(PointUtils.degreeToRad(normalVectorAngle[j]) * -margin),
                    },
                    {
                        x: itemCoords[(j + 1) % 4].x + Math.cos(PointUtils.degreeToRad(normalVectorAngle[j]) * -margin),
                        y: itemCoords[(j + 1) % 4].y + Math.sin(PointUtils.degreeToRad(normalVectorAngle[j]) * -margin),
                    },
                    {
                        x: itemCoords[(j + 1) % 4].x + Math.cos(PointUtils.degreeToRad(normalVectorAngle[j]) * margin),
                        y: itemCoords[(j + 1) % 4].y + Math.sin(PointUtils.degreeToRad(normalVectorAngle[j]) * margin),
                    },
                    {
                        x: itemCoords[j].x + Math.cos(PointUtils.degreeToRad(normalVectorAngle[j]) * margin),
                        y: itemCoords[j].y + Math.sin(PointUtils.degreeToRad(normalVectorAngle[j]) * margin),
                    },
                ];
                if (qSVG.rayCasting(customSnap, polygon, margin)) {
                    sideList.push({ roomItem: roomItems[i], angle: angleOrder[j] }); // Return EDGES Index
                }
            }
        }

        if (sideList.length === 0) {
            return false;
        } else {
            if (sideList.length === 1) {
                return sideList[0];
            } else {
                return sideList;
            }
        }
    }

    public static calcRoomItemMinContactLength(roomItemType?: RoomItemType) {
        return roomItemType === RoomItemType.Cupboard ? RoomItemConstants.ROOM_ITEM_MIN_CONTACT_LENGTH : 0;
    }

    public static areInflatedRoomsNeeded(
        isMoveableOutside: boolean,
        nearestWall: WallLine,
        polyPoints: Array<CoordPoint>,
        walls: Array<Wall>
    ) {
        return (
            isMoveableOutside &&
            WallUtils.isWallHorV(nearestWall) &&
            !Editor.testIfPolygonCrossesDiagonalWall(polyPoints, walls)
        );
    }

    public static roomItemNearestWall(walls: Array<Wall>, snap: CoordPoint, roomItemType?: RoomItemType) {
        const nearestWall = WallUtils.nearWallFrom(
            snap,
            Infinity,
            walls,
            MapConstants.PreviousNearestWall,
            roomItemType === 'cupboard'
        )!;
        MapConstants.PreviousNearestWall = nearestWall?.wall;
        if (WallUtils.isWallHorV(nearestWall.wall!)) {
            MapConstants.PreviousHorVNearestWall = nearestWall.wall;
        }
        return nearestWall;
    }

    public static getMovedRoomItemPoint(
        rooms: Array<IRoom>,
        walls: Array<Wall>,
        snap: CoordPoint,
        roomId: string,
        roomItem: IRoomItem,
        minContactLength: number,
        nearestWall: WallLine,
        distanceToWall: number
    ) {
        let wallsToUse: Array<WallLine> | undefined = this.getWallsToUse(
            rooms,
            roomId,
            roomItem,
            minContactLength,
            nearestWall,
            distanceToWall
        );
        let point = !wallsToUse
            ? snap
            : WallUtils.nearWallFrom(
                  snap,
                  Infinity,
                  wallsToUse as Array<Wall>,
                  undefined,
                  roomItem.type === RoomItemType.Cupboard
              );

        const pointAsWall = point as WallSelected;

        if (pointAsWall?.wall?.angle !== nearestWall.angle) {
            const roomWall = WallUtils.nearWallFrom(
                point as SnapGesture,
                Infinity,
                walls,
                MapConstants.PreviousNearestWall,
                true
            );
            wallsToUse = this.getWallsToUse(rooms, roomId, roomItem, minContactLength, roomWall?.wall!, distanceToWall);
            point = !wallsToUse
                ? snap
                : WallUtils.nearWallFrom(snap, Infinity, wallsToUse as Array<Wall>, undefined, true);
        }

        return point;
    }

    public static getWallsToUse(
        rooms: Array<IRoom>,
        roomId: string,
        roomItem: IRoomItem,
        minContactLength: number,
        nearestWall: WallLine,
        distanceToWall: number
    ) {
        const roomItemSizeLengthToUse =
            WallUtils.wallDirection(nearestWall) === 'h' ? roomItem.height! / 2 : roomItem.width! / 2;

        if (roomId) {
            if (
                roomItem.isMoveableOutside &&
                distanceToWall <= roomItemSizeLengthToUse &&
                distanceToWall > roomItemSizeLengthToUse - 20
            ) {
                return this.GetWallsWithInnerPaddingForRoomItem(roomItem, rooms);
            } else {
                return undefined;
            }
        } else {
            return this.GetWallsWithInnerPaddingForRoomItem(roomItem, rooms);
        }
    }

    //! PEUT FORMER UN POLYGONE CROISE, COMPORTEMENT LAISSE
    //! https://ideine-my.sharepoint.com/:i:/g/personal/thierry_levisse_ideine_fr/ETHvT2vMyMZCjRwQL5iiOUUBuKRUr7iM0T8wMG9qjE4LTg?e=U4FwXF
    public static GetWallsWithInnerPaddingForRoomItem(roomItem: IRoomItem, rooms: Array<IRoom>) {
        const tmpWalls = [];
        for (const room of rooms) {
            const rawInflatedPolygon = this.findPaddingPoints(room, roomItem);

            for (let i = 0; i < rawInflatedPolygon.length; i++) {
                const wall: WallLine = {
                    start: rawInflatedPolygon[i],
                    end: rawInflatedPolygon[(i + 1) % rawInflatedPolygon.length],
                    roomId: room.roomId,
                };
                wall.angle = Math.atan2(wall.end.y - wall.start.y, wall.end.x - wall.start.x);
                tmpWalls.push(wall);
            }
        }
        return tmpWalls;
    }

    public static findPaddingPoints(room: IRoom, roomItem: IRoomItem) {
        let paddingPoints = [];
        const bissectorAngles = this.findBissectorAngle(room);
        for (let i = 0; i < room.walls!.length; i++) {
            if (bissectorAngles[i] > -90 && bissectorAngles[i] < 0) {
                paddingPoints.push(PointUtils.translation(room.coords![i], roomItem.coords![1]));
            } else if (bissectorAngles[i] > 0 && bissectorAngles[i] < 90) {
                paddingPoints.push(PointUtils.translation(room.coords![i], roomItem.coords![2]));
            } else if (bissectorAngles[i] > 90 && bissectorAngles[i] < 180) {
                paddingPoints.push(PointUtils.translation(room.coords![i], roomItem.coords![3]));
            } else if (bissectorAngles[i] > -180 && bissectorAngles[i] < -90) {
                paddingPoints.push(PointUtils.translation(room.coords![i], roomItem.coords![4]));
            }
        }
        return paddingPoints;
    }

    public static findBissectorAngle(room: IRoom) {
        let bissectorAngles = [];
        const angles = this.determinePaddingPoints(room);
        for (let i = 0; i < room.walls!.length; i++) {
            const bissectorAngleRad =
                ((room.walls![i].angle + Math.PI + PointUtils.degreeToRad(angles[i] / 2)) % (Math.PI * 2)) - Math.PI;
            bissectorAngles.push(qSVG.angleRadToDeg(bissectorAngleRad));
        }
        return bissectorAngles;
    }

    //! WARNING FOR NEW ROOM SHAPE, DOT NOT WORK WITH ANGLES DIFFERENT FROM 45° 90° 0° 180° etc.
    public static determinePaddingPoints(room: IRoom) {
        let interiorAngles = [];
        for (const wall of room.walls!) {
            let interiorAngle = 0;

            do {
                interiorAngle += 45;
                var radAngle =
                    (Math.abs(wall.angle + Math.PI + PointUtils.degreeToRad(interiorAngle)) % (Math.PI * 2)) - Math.PI;
                if (radAngle === -Math.PI) {
                    radAngle = Math.PI;
                }
            } while (
                !qSVG.strictlyBetween(this.getInvertedAngle(wall.parent!.angle), radAngle - 0.02, radAngle + 0.02)
            ); // angle or inverse of angle

            interiorAngles.push(interiorAngle);
        }
        return interiorAngles;
    }

    //! Generate a padding to get a correct placement of roomItems on rooms.
    //! Result is used with method nearWallFrom()
    //! https://ideine-my.sharepoint.com/:i:/g/personal/thierry_levisse_ideine_fr/EWQoEso_93JIs0fx03yQrykBUDaLflKToMspxv6eOIpBZQ?e=rrHAey
    public static getInvertedAngle(angle: number) {
        let invertedAngle = ((angle + Math.PI * 2) % (Math.PI * 2)) - Math.PI;
        return invertedAngle === -Math.PI ? Math.PI : invertedAngle;
    }

    public static isRoomItemInsideRoom(roomItem: IRoomItem, room: IRoom, checkIntersection: boolean = true) {
        //* Cupboards can intersect with a wall
        if (roomItem.type === RoomItemType.Cupboard) {
            return false;
        }

        const isRoomItemContainedInRoom = CollisionRoomUtils.testIfPolygonAContainedInPolygonB(
            roomItem.coordsReal?.slice(0, -1),
            room.coords?.slice(0, -1)
        );

        console.log('isRoomItemInsideRoom', isRoomItemContainedInRoom);

        return checkIntersection
            ? !isRoomItemContainedInRoom && PointUtils.testPolygonsIntersect(room.coords, roomItem.coordsReal)
            : !isRoomItemContainedInRoom;
    }

    /**
     *! Tests if the room item is partially inside the room.
     *! If it is, resize the room item in the correct direction to fit inside the room.
     *! In some cases, the room item need to be resized in more than one direction (when more than two corners of
     *! the room item are outside the room).
     *! The function is recursive in order to treat those cases.
     * TODO : Fix the known bug where when all four corners of the room item are outside the room, the resizing
     *! places the room item outside of the room. The bug is caused by the resizing function roomItemMoveSide().
     *! PBI Ref #23398 : https://ideine.visualstudio.com/XBacklog/_workitems/edit/23398
     */
    public static resizeRoomItemIfNeeded = (
        rooms: Array<IRoom>,
        roomItems: Array<IRoomItem>,
        roomItem: IRoomItem,
        snap: SnapGesture,
        previousAngle?: number,
        triesCount: number = 1
    ): boolean => {
        const room = rooms.find((r) => r.roomId === roomItem.roomId);
        if (!room || this.isRoomItemInsideRoom(roomItem, room)) {
            return true;
        }

        if (triesCount > 4) {
            console.error('Floorplan error : could not resize roomItem properly.', { roomItem });
            return false;
        }

        let angle = this.getRoomItemResizeAngle(roomItem, room)!;
        if (previousAngle && previousAngle === angle) {
            switch (previousAngle) {
                default:
                case 0:
                case 90:
                    angle! += 90;
                    break;
                case 180:
                    angle = -180;
                    break;
                case -180:
                    angle = 0;
                    break;
            }
        }
        this.roomItemMoveSide(roomItem, angle, snap);
        return this.resizeRoomItemIfNeeded(rooms, roomItems, roomItem, snap, angle, ++triesCount);
    };

    /**
     *! Finds which of the room item's corners are not within the room.
     *! Returns the corresponding resize angle.
     *! (Points 0 & 1 outside the room (top corners) => 0)
     *! (Points 1 & 2 outside the room (right points) => 90)
     *! (Points 2 & 3 outside the room (bottom points) => 180)
     *! (Points 3 & 0 outside the room (left points) => -180)
     */
    public static getRoomItemResizeAngle(roomItem: IRoomItem, room: IRoom) {
        let arePointsOutside: Array<boolean> = [];
        roomItem.coordsReal?.slice(0, -1).forEach((point, index) => {
            arePointsOutside[index] = !PointUtils.testPointWithinRoom(point, room);
        });

        const pointsOutsideCount = arePointsOutside.filter((x) => x).length;

        if (pointsOutsideCount === 1) {
            if (arePointsOutside[0]) {
                return 0;
            } else if (arePointsOutside[1]) {
                return 90;
            } else if (arePointsOutside[2]) {
                return 180;
            } else if (arePointsOutside[3]) {
                return -90;
            }
        }
        if (pointsOutsideCount > 1) {
            if (arePointsOutside[0] && arePointsOutside[1]) {
                return 0;
            } else if (arePointsOutside[1] && arePointsOutside[2]) {
                return 90;
            } else if (arePointsOutside[2] && arePointsOutside[3]) {
                return 180;
            } else if (arePointsOutside[3] && arePointsOutside[0]) {
                return -90;
            }
        }
    }

    public static roomItemMoveSide(roomItem: IRoomItem, angle: number, snap: SnapGesture) {
        const minsize = 15;

        let cursor;
        if (angle === 0) {
            cursor = snap.y;
            cursor = roomItem.coordsReal![2].y - cursor < minsize ? roomItem.coordsReal![2].y - minsize : cursor;
            roomItem.coordsReal![4].y = cursor;
            roomItem.coordsReal![0].y = cursor;
            roomItem.coordsReal![1].y = cursor;
        } else if (angle === 90) {
            cursor = snap.x;
            cursor = cursor - roomItem.coordsReal![3].x < minsize ? roomItem.coordsReal![3].x + minsize : cursor;
            roomItem.coordsReal![1].x = cursor;
            roomItem.coordsReal![2].x = cursor;
        } else if (angle === 180) {
            cursor = snap.y;
            cursor = snap.y - roomItem.coordsReal![0].y < minsize ? roomItem.coordsReal![0].y + minsize : cursor;
            roomItem.coordsReal![2].y = cursor;
            roomItem.coordsReal![3].y = cursor;
        } else if (angle === -90) {
            cursor = snap.x;
            cursor = roomItem.coordsReal![1].x - cursor < minsize ? roomItem.coordsReal![1].x - minsize : cursor;
            roomItem.coordsReal![3].x = cursor;
            roomItem.coordsReal![4].x = cursor;
            roomItem.coordsReal![0].x = cursor;
        }

        const width = qSVG.measure(roomItem.coordsReal![0], roomItem.coordsReal![1]);
        const height = qSVG.measure(roomItem.coordsReal![1], roomItem.coordsReal![2]);
        roomItem.width = width;
        roomItem.height = height;
        roomItem.coords = this.roomItemCoordsRealToCoords(width, height);
        roomItem.graphTranslation = this.roomItemCoordsRealToGraphTranslation(roomItem.coordsReal!, width, height);

        return { roomItem };
    }

    public static roomItemCoordsRealToCoords(width: number, height: number) {
        return [
            { x: -width / 2, y: -height / 2 },
            { x: width / 2, y: -height / 2 },
            { x: width / 2, y: height / 2 },
            { x: -width / 2, y: height / 2 },
            { x: -width / 2, y: -height / 2 },
        ];
    }

    public static roomItemCoordsRealToGraphTranslation(
        coordsReal: Array<CoordPoint> = [],
        width: number,
        height: number
    ) {
        return { x: coordsReal[0].x + width / 2, y: coordsReal[0].y + height / 2 };
    }

    //roomItem¤22618616¤26286632¤0¤140
    public static roomItemResizeViaCote(roomItem: IRoomItem, angle: number, sizeInput: number, uncap: boolean) {
        if (angle === 0 || angle === 180) {
            //* resize horizontal
            roomItem.coords = [
                { x: -sizeInput / 2, y: roomItem.coords![0].y },
                { x: sizeInput / 2, y: roomItem.coords![1].y },
                { x: sizeInput / 2, y: roomItem.coords![2].y },
                { x: -sizeInput / 2, y: roomItem.coords![3].y },
                { x: -sizeInput / 2, y: roomItem.coords![4].y },
            ];
        } else if (angle === 90 || angle === -90) {
            roomItem.coords = [
                { x: roomItem.coords![0].x, y: -sizeInput / 2 },
                { x: roomItem.coords![1].x, y: -sizeInput / 2 },
                { x: roomItem.coords![2].x, y: sizeInput / 2 },
                { x: roomItem.coords![3].x, y: sizeInput / 2 },
                { x: roomItem.coords![4].x, y: -sizeInput / 2 },
            ];
        }

        // const min = parseInt($('#EditCoteDialog-minValue', window.parent.document).val());
        // const max = parseInt($('#EditCoteDialog-maxValue', window.parent.document).val());

        //todo: fix min and max
        // if ((!uncap && sizeInput > max) || sizeInput < min) {
        //     return;
        // }

        roomItem.coordsReal = roomItem.coords?.map((x) => PointUtils.translation(x, roomItem.graphTranslation!));

        roomItem.width = qSVG.measure(roomItem.coordsReal![0], roomItem.coordsReal![1]);
        roomItem.height = qSVG.measure(roomItem.coordsReal![1], roomItem.coordsReal![2]);
        roomItem.coords = this.roomItemCoordsRealToCoords(roomItem.width, roomItem.height);
        roomItem.graphTranslation = this.roomItemCoordsRealToGraphTranslation(
            roomItem.coordsReal,
            roomItem.width,
            roomItem.height
        );

        // binder.coordsReal = roomItem.coordsReal;
        // binder.coords = roomItem.coords;
        // binder.collisions = roomItem.collisions;

        // $('.roomitem-' + roomItemId + '-class > polygon').attr(
        //     'points',
        //     roomItem.coords.map((p) => p.x + ',' + p.y).join(' ')
        // );

        //roomItemCoteCreation(roomItem); //todo: show cote
        //recenterRoomItem(roomItem, roomItem.graphTranslation);
        //applyLaize();
    }
    public static recenterRoomItem(roomItem: IRoomItem, customSnap: CoordPoint, rooms: Array<IRoom>) {
        const polyCollision = SATUtils.getPolyCollision();
        const polyPoints = roomItem.coords!.map((p) => PointUtils.translation(p, customSnap));
        polyCollision.setPoints?.(polyPoints);

        let isInsideRoomId = CollisionRoomUtils.testIfPolygonAContainedIn(polyPoints, rooms);
        let point = isInsideRoomId
            ? customSnap
            : WallUtils.nearWallFrom(customSnap, Infinity, this.GetWallsWithInnerPaddingForRoomItem(roomItem, rooms));
        if (!isInsideRoomId) {
            isInsideRoomId = (point as WallSelected).roomId;
        }
        roomItem.roomId = isInsideRoomId;
        roomItem.coordsReal = roomItem.coords?.map((x) => PointUtils.translation(x, point as CoordPoint));
        roomItem.graphTranslation = point as CoordPoint;
    }

    // public static roomItemCoteCreation(ribRoomItem:IRoomItem, sideToShow?:boolean) {
    //     if (!ribRoomItem) {
    //         return;
    //     }
    //     $('#boxRib').empty();

    //     var degreeCoords = [0, 90, 180, -90]; //roomItem coords should always follow this order

    //     const adjacentRoomItems = getRoomItemAdjacentList(ribRoomItem);

    //     if (ribRoomItem.id && !$('#ribs-room-' + ribRoomItem.id).length) {
    //         $('#boxRib').append(
    //             qSVG.create('ici', 'g', {
    //                 id: 'ribs-room-item-' + ribRoomItem.roomId,
    //                 class: 'room-item-' + ribRoomItem.id,
    //             })
    //         );
    //     }

    //     for (var i = 0; i < ribRoomItem.coordsReal.length - 1; i++) {
    //         if (sideToShow === undefined || sideToShow.includes(degreeCoords[i])) {
    //             var ribStart = ribRoomItem.coordsReal[i];
    //             var ribEnd = ribRoomItem.coordsReal[i + 1];

    //             var adjRoomItem = adjacentRoomItems.find((x) => x[0].id === ribRoomItem.id + '-' + degreeCoords[i]);

    //             createRoomItemCote(
    //                 ribRoomItem.roomId,
    //                 ribRoomItem.id,
    //                 ribStart,
    //                 ribEnd,
    //                 adjRoomItem ? 'inside' : 'outside',
    //                 (qSVG.measure(ribStart, ribEnd) / meter) * 100,
    //                 degreeCoords[i]
    //             );

    //             if (adjRoomItem) {
    //                 createAdjacentCotes(adjRoomItem);
    //             }
    //         }
    //     }

    //     if (adjRoomItem) {
    //         coteMode = 'adjacent';
    //     } else {
    //         coteMode = 'wall';
    //     }
    // }

    public static getRoomItemSizeLimits(type: RoomItemType): RoomItemLimits {
        let hMinValue = RoomItemConstants.ROOM_ITEM_MIN_SIZE;
        const hMaxValue = RoomItemConstants.ROOM_ITEM_MAX_SIZE;

        let vMinValue = RoomItemConstants.ROOM_ITEM_MIN_SIZE;
        const vMaxValue = RoomItemConstants.ROOM_ITEM_MAX_SIZE;

        if (type === RoomItemType.Divider) {
            hMinValue = RoomItemConstants.ROOM_ITEM_DIVIDER_MIN_SIZE;
            vMinValue = RoomItemConstants.ROOM_ITEM_DIVIDER_MIN_SIZE;
        }

        return {
            horizontal: { min: hMinValue, max: hMaxValue },
            vertical: { min: vMinValue, max: vMaxValue },
        };
    }

    //! temporary. Change IRoomItem to class
    public static getRoomItemPartialWalls(roomItem: IRoomItem) {
        const walls = [];
        for (let i = 0; i < roomItem.coordsReal!.length - 1; i++) {
            const wallStart = roomItem.coordsReal![i];
            const wallEnd = roomItem.coordsReal![i + 1];
            walls.push({
                start: wallStart,
                end: wallEnd,
                angle: Math.atan2(wallEnd.y - wallStart.y, wallEnd.x - wallStart.x),
            });
        }
        return walls;
    }

    //! temporary
    public static getRoomItemWallByAngle(roomItem: IRoomItem, angle: number) {
        if (angle === 0) {
            return { start: roomItem.coordsReal![0], end: roomItem.coordsReal![1], angle: PointUtils.degreeToRad(0) };
        } else if (angle === 90) {
            return { start: roomItem.coordsReal![1], end: roomItem.coordsReal![2], angle: PointUtils.degreeToRad(90) };
        } else if (angle === 180) {
            return { start: roomItem.coordsReal![2], end: roomItem.coordsReal![3], angle: PointUtils.degreeToRad(180) };
        } else if (angle === -90) {
            return { start: roomItem.coordsReal![3], end: roomItem.coordsReal![4], angle: PointUtils.degreeToRad(-90) };
        }
    }
}
