import { ObjectUtils } from "../../../Helpers/ObjectUtils";
import { MapContextState } from "../Context/MapContext";
import { IRoom } from "../Models/IRoom";
import { IRoomItem } from "../Models/IRoomItem";
import { CollisionRoomUtils } from "../Utils/Collisions/CollisionRoomUtils";
import { JstsUtils } from "../Utils/JstsUtils";
import { PointUtils } from "../Utils/PointUtils";
import { RoomItemUtils } from "../Utils/RoomItem/RoomItemUtils";
import { SATUtils } from "../Utils/SAT/SATUtils";
import { CoordPoint, WallSelected } from "../Utils/Types";

export const fixRoomItemPositionIfNeeded = (exportMap: MapContextState) => {

    //console.log('fixRoomItemPositionIfNeeded');
    const computeRoomItemPosition = (roomItem: IRoomItem, room: IRoom, snap: CoordPoint) => {
        const polyCollision = SATUtils.getPolyCollision();
        let polyPoints = roomItem.coords!.map((p) => PointUtils.translation(p, snap!));
        polyCollision.setPoints?.(polyPoints);

        const roomItemMinContactLength = RoomItemUtils.calcRoomItemMinContactLength(roomItem.type);
        const nearestWall = RoomItemUtils.roomItemNearestWall(room.walls!, snap, roomItem.type);

        let isInsideRoomId = CollisionRoomUtils.testIfPolygonAContainedIn(
            polyPoints,
            [room]
        );

        let point = RoomItemUtils.getMovedRoomItemPoint(
            [room],
            room.walls!,
            snap,
            isInsideRoomId!,
            roomItem,
            roomItemMinContactLength,
            nearestWall.wall!,
            nearestWall.distance!
        );
        if (!isInsideRoomId && point) {
            isInsideRoomId = (point as WallSelected).roomId;
        }
        roomItem.roomId = isInsideRoomId;

        roomItem.graphTranslation = point as CoordPoint;
        roomItem.coordsReal = roomItem.coords!.map((x) => PointUtils.translation(x, point as CoordPoint));
    };

    exportMap.rooms?.forEach((room: IRoom) => {
        const roomPolygon = JstsUtils.createJstsPolygon(room.coords!);
        const roomItemsToFix = (room.roomItems || []).filter(ri => {
            const roomItemPolygon = JstsUtils.createJstsPolygon(ri.coordsReal!);
            return !roomPolygon.contains(roomItemPolygon);
        })
        roomItemsToFix.forEach((roomItem, index) => {
            console.log('-----------------------------------------------------');
            console.log('fixRoomItemPositionIfNeeded room', room.roomId, ObjectUtils.clone({ room, roomItem }));
            console.log('-----------------------------------------------------');
            //snapPosition(roomItem, PointUtils.bboxCenter(room.coords!));
            const snap = room.coords![index];
            snapPosition(roomItem, snap);
            computeRoomItemPosition(roomItem, room, snap);

            //snapPosition(roomItem, room.coords![1]);
            console.log('after set item in room', { room, roomItem });

        });
    });
}

function snapPosition(item: IRoomItem, snap: CoordPoint) {
    item.graphTranslation = snap;
    const halfWidth = item.width! / 2;
    const halfHeight = item.height! / 2;
    item.coords = [
        { x: -halfWidth, y: -halfHeight },
        { x: halfWidth, y: -halfHeight },
        { x: halfWidth, y: halfHeight },
        { x: -halfWidth, y: halfHeight },
        { x: -halfWidth, y: -halfHeight },
    ];
    item.coordsReal = item.coords.map((x) => PointUtils.translation(x, item.graphTranslation!));
    return item;
};