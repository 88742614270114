import { frFloorPlanMessages } from './frFloorPlanMessages';

//🇫🇷
export const frMessages = {
    ApplicationName: 'Clic & Pose',

    //* COMMON
    COMMON_SAVE: 'Enregistrer',
    COMMON_DELETE: 'Supprimer',
    COMMON_CONTINUE: 'Continuer',
    COMMON_CANCEL: 'Annuler',
    COMMON_QUIT: 'Quitter',
    COMMON_YES: 'Oui',
    COMMON_NO: 'Non',
    COMMON_OK: 'OK',
    COMMON_OR: 'ou',
    COMMON_VALIDATE: 'Valider',
    COMMON_CLEAR: 'Effacer',
    COMMON_DATE_DEFAULT_VALUE: '?',
    COMMON_USER_FULLNAME: '{firstName} {lastName}',
    COMMON_WARNING: 'Attention !',
    COMMON_NEXT: 'Suivant',
    COMMON_OTHER: 'Autre',
    COMMON_PREVIOUS: 'Précédent',
    COMMON_LOADING: 'Chargement...',

    COMMON_ADD: 'Ajouter',
    COMMON_EDIT: 'Éditer',
    COMMON_FINISH: 'Terminer',

    COMMON_ERROR_TITLE: 'Erreur : {code}',
    COMMON_SORRY_TITLE: 'Désolé,',
    COMMON_SORRY_TITLE_WITHOUT_COMMA: 'Désolé',
    COMMON_ERROR_MESSAGE: "Quelque chose ne s'est pas passé comme prévu, merci de réessayer.",

    TEST_STORE_USER_FULLNAME: 'Test vendeur: {firstName} {lastName}',
    TEST_CUSTOMER_USER_FULLNAME: 'Test client: {firstName} {lastName}',

    //* TOAST
    Toast_TitleSuccess: 'Bravo',
    Toast_TitleError: 'Erreur',
    Toast_TitleInfo: 'Info',

    ERROR_LOADING_CONFIGURATION: "La configuration n'a pas pu être chargé.",
    ERROR_AUTHENTICATION_IFRAME_TIMEOUT: "Impossible de joindre le serveur d'authentification, merci de réessayer.",
    Error_FORBIDDEN: "Quelque chose ne s'est pas passé comme prévu, merci de réessayer.",
    ERROR_UPDATE_UNAUTHORIZED: "Vous n'avez pas les droits nécessaire pour éditer ce projet.",
    ERROR_LOADING_MAP: "Quelque chose ne s'est pas passé comme prévu lors du chargment du plan.",

    ERROR_STORE_TOKEN_IN_FUTUR: 'Problème de synchro NTP',
    ERROR_CUSTOMER_TOKEN_IN_FUTUR: "Veuillez vérifier l'heure de votre appareil.",
    ERROR_INVALID_WALL_MOVE: 'Ce déplacement rentre en collision avec une autre pièce',

    //* SNACKBAR

    CopyToClipboardButton_CopiedSnakbarMessage: 'Copié dans le presse-papier',

    //* DIALOG

    Dialog_LogoutTitle: 'Déconnexion',
    Dialog_LogoutMessageHeader: 'Vous êtes sur le point de vous déconnecter',
    Dialog_LogoutMessageBody: "Confirmez-vous l'opération ?",
    Dialog_Error_CantCompleteProject: 'Nous sommes désolés mais nous ne pouvons pas finaliser votre devis estimatif en ligne.\nEnregistrez votre projet dans votre compte client, un conseiller vous contactera pour le finaliser.',
    DialogLogin_Error_CantCompleteProject: 'Nous sommes désolés mais nous ne pouvons pas finaliser votre devis estimatif en ligne.\nUn conseiller va prendre contact avec vous afin de finaliser votre projet.',

    CreateProjectDialog_InputLabel: 'Donnez un nom à ce projet.',
    CreateProjectDialog_InputPlaceholder: 'Ex : Chambre',

    CreateOrUpdateProjectDialog_Message: 'Sur quel projet voulez-vous enregistrer ? ',
    CreateOrUpdateProjectDialog_SaveProject: 'Enregistrer sur le projet « {name} »',
    CreateOrUpdateProjectDialog_NewProject: 'Créer un nouveau projet et enregistrer',
    CreateOrUpdateProjectDialog_InputLabel: 'Nom du projet',

    PermissionDialog_NotAuthenticated: "Vous n'êtes pas authentifié",

    ProjectDetails_deleteDialogTitle: 'Supprimer le projet',
    ProjectDetails_deleteDialogHeader: 'Vous êtes sur le point de supprimer un projet',
    ProjectDetails_deleteDialogBody: "Confirmez-vous l'opération ?",
    ProjectDetails_Description_Placeholder: 'Saisissez votre description ici',

    //! COMPONENTS
    //* Header
    Header_MySpace: 'Mon compte',
    Header_Logout: 'Se déconnecter',
    Header_Login: 'Se connecter',
    Header_MyProjects: 'Mes projets',
    Header_Version: '{environment} - {version_number}',
    Header_Environment_localdev: 'local development',
    Header_Environment_dev: 'development',
    Header_Environment_test: 'test',
    Header_Environment_beta: 'beta',
    Header_Environment_uat: 'uat',
    Header_Environment_preprod: 'pp',
    Header_Environment_prod: 'production',

    MonthYearPicker_Short_Label_January: 'Jan',
    MonthYearPicker_Short_Label_February: 'Fév',
    MonthYearPicker_Short_Label_March: 'Mars',
    MonthYearPicker_Short_Label_April: 'Avr',
    MonthYearPicker_Short_Label_May: 'Mai',
    MonthYearPicker_Short_Label_June: 'Juin',
    MonthYearPicker_Short_Label_July: 'Juil',
    MonthYearPicker_Short_Label_August: 'Août',
    MonthYearPicker_Short_Label_September: 'Sept',
    MonthYearPicker_Short_Label_October: 'Oct',
    MonthYearPicker_Short_Label_November: 'Nov',
    MonthYearPicker_Short_Label_December: 'Déc',

    MapContextMenu_Modify: 'Modifier',
    MapContextMenu_Complete: 'Compléter',
    MapContextMenu_Add: 'Ajouter',
    MapContextMenu_Move: 'Déplacer',
    MapContextMenu_Duplicate: 'Dupliquer',
    MapContextMenu_Delete: 'Supprimer',
    MapContextMenu_DeleteRoom: 'Supprimer la pièce {name}',

    //! PAGES

    //* Quote
    Quote_Advice: 'Notre conseil',
    Quote_MultipleOptionsLabel_SubText: '(plusieurs choix possibles)',

    Quote_QuestionNumberError: 'la valeur doit être comprise entre {min} et {max}',
    Quote_ProductSelect_Search: 'Chercher un autre produit',
    Quote_ProductSelect_Search_Catalog: 'Chercher dans notre catalogue',
    Quote_ProductSelect_Ref: 'Réf: {produit}',
    Quote_ProductSelect_Search_Instruction: 'Trouvez-votre produit dans notre catalogue',
    Quote_ProductSelect_Search_NoResult: 'Le produit « {search} » n’existe pas dans notre catalogue.',
    Quote_ProductSelect_Search_Empty: 'Aucun produit selectionné',
    Quote_ProductSelect_Suggest_SameAsBefore: 'Le même que choisi précédemment',
    Quote_ProductCodeShort: '{code_article}/{code_coloris}',

    Quote_IncompatibilityAnswer: "Oups, ce choix n'est pas possible avec ce que vous avez sélectionné auparavant. Ok pour changer cette réponse",
    Quote_IncompatibilityAnswer_Title: 'Attention',
    Quote_IncompatibilityAnswer_RedirectButton: 'Choisir un autre produit dans le catalogue',
    Quote_IncompatibilityAnswer_FinDeParcours_RightAction: 'Retour',
    QuoteStepViewModel_ReadonlyProduct_Error: 'Le produit souhaité n’existe pas dans le catalogue.',
    QuoteStepViewModel_TravelExpense_Unallowed_Error: "Il n'est pas possible d'expédier une commande à cette adresse. Nous livrons exclusivement en France Métropolitaine.",
    QuoteStepViewModel_TravelExpense_Error: "Le code postal saisi n'a pas été trouvé.",

    Quote_Alert_ConfigNotSavedTitle: "Votre configuration de pose n'est pas enregistrée.",
    Quote_Alert_ConfigNotSavedSubTitle: 'En continuant, vous allez perdre vos modifications non enregistrées.',

    Quote_Project_Created: 'Le projet « {name} » a été crée avec succès',
    Quote_Project_ConfigurationUpdated: 'La configuration du projet « {name} » a été mis à jour',

    //* Steps 4
    Quote_Step4_TotalPriceTitle: 'Montant Global',
    Quote_Step4_PriceFormat: '{price} €',
    QuoteEstimation_ProduitDetails_Title: 'MON PRODUIT A POSER',
    QuoteEstimation_ProjectDetails_Title: 'Mon projet',

    QuoteEstimation_Recap_Title: 'Récapitulatif',
    QuoteEstimation_Recap_DownloadPdfLabel: 'Télécharger le devis estimatif de mon projet',
    QuoteEstimation_Recap_PromoTva: 'Remise TVA offerte sur le produit posé',

    QuoteEstimation_Recap_Produit_Ref: 'Référence',
    QuoteEstimation_Recap_Produit_Label: 'Désignation',
    QuoteEstimation_Recap_Produit_Unit: 'Prix unitaire',
    QuoteEstimation_Recap_Produit_Unit_MainProduit: 'Prix unitaire m²',
    QuoteEstimation_Recap_Produit_Conditionnement: 'Conditionnement',
    QuoteEstimation_Recap_Produit_Conditionnement_PaquetDe_Format: 'Paquet(s) de {value} {unit}',
    QuoteEstimation_Recap_Produit_Conditionnement_Unitaire: 'Unitaire',
    QuoteEstimation_Recap_Produit_Quantity: 'Quantité',
    QuoteEstimation_Recap_Produit_Quantity_LongText: 'Quantité de produit ',
    QuoteEstimation_Recap_Produit_Total: 'Total produit',

    QuoteEstimation_Recap_MainProduit_Title: 'Produit principal à poser',
    QuoteEstimation_Recap_TotalMainProduit_Title: 'Total produit pincipal',

    QuoteEstimation_Recap_MainOeuvre_Title: 'Main d’œuvre',
    QuoteEstimation_Recap_CategorieOptionsServices_Depose: 'Dépose',
    QuoteEstimation_Recap_CategorieOptionsServices_PreparationDuSupportAvantPose: 'Préparation du support avant pose',
    QuoteEstimation_Recap_CategorieOptionsServices_PoseDuRevetementPrincipal: 'Pose du revêtement principal',
    QuoteEstimation_Recap_CategorieOptionsServices_PoseAccessoires: 'Pose accessoires',
    QuoteEstimation_Recap_CategorieOptionsServices_ComplementForfaitDivers: 'Complément forfait divers',
    QuoteEstimation_Recap_CategorieOptionsServices_Nettoyage: 'Nettoyage',
    QuoteEstimation_Recap_CategorieOptionsServices_Autres: 'Autres',
    QuoteEstimation_Recap_CategorieOptionsServices_ForfaitBesoinSupplementaire: 'Forfait besoin supplémentaire',
    QuoteEstimation_Recap_CategorieOptionsServices_GestionDesDechets: 'Gestion des déchets',
    QuoteEstimation_Recap_TotalMainOeuvre_Title: 'Total main d’œuvre',

    QuoteEstimation_Recap_ProduitsComplementaires_Title: 'Produits complémentaires',
    QuoteEstimation_Recap_TotalProduitsComplementaires_Title: 'Total produits complémentaires ',

    QuoteEstimation_Recap_TotalLivraison: 'Livraison',
    QuoteEstimation_Recap_TotalLivraison_StoreQuotationNeeded: 'Sur devis en magasin',

    QuoteEstimation_Recap_Total: 'Total projet',
    QuoteEstimation_Recap_TotalHtPrice_Title: 'Total HT',
    QuoteEstimation_Recap_TotalTva_Title: 'TVA ({tva_percentage}%)',
    QuoteEstimation_Recap_TotalTtcPrice_Title: 'Total projet TTC',
    QuoteEstimation_Recap_TotalTtcPrice_Subtitle: 'Visite à domicile offerte.',

    QuoteEstimation_WhatNext_Unauthenticated_Title: 'Recevoir un devis estimatif',
    QuoteEstimation_WhatNext_Unauthenticated_Description:
        'Connectez-vous pour obtenir le détail de votre estimation et/ou prendre rendez-vous avec l’un de nos conseillers.',
    QuoteEstimation_WhatNext_LogInButton: 'Je me connecte',

    QuoteEstimation_WhatNext_AuthenticatedCustomer_Title: 'Finalisez votre projet !',
    QuoteEstimation_WhatNext_AuthenticatedCustomer_Description:
        'Prenez rendez-vous avec l’un de nos conseillers pour finaliser votre estimation.',
    QuoteEstimation_WhatNext_AppointmentButton: 'Prendre RDV',

    QuoteEstimation_WhatNext_AuthenticatedStore_Title: 'ET MAINTENANT ?',
    QuoteEstimation_WhatNext_AuthenticatedStore_Description:
        'Fermez votre navigateur pour retrouver votre configuration dans le CRM Vendeur. Si vous ne souhaitez pas que cette configuration soit transmise au CRM Vendeur, vous pouvez annuler sa récupération dans le CRM Vendeur.',
    QuoteEstimation_WhatNext_AuthenticatedStore_CancelButton: 'Annuler la récupération CRM',
    QuoteEstimation_WhatNext_AuthenticatedStore_CancelDialogTitle: 'Récupération annulée',
    QuoteEstimation_WhatNext_AuthenticatedStore_CancelDialogMessage:
        'La récupération de la configuration a été annulée. Vous pouvez fermer votre navigateur, la configuration ne sera pas transmise au CRM Vendeur.',

    QuoteEstimation_ProjectDetails_GeneralTitle: 'Général',
    QuoteEstimation_WhatNext_SaveButton: 'Enregistrer mon projet',

    QuoteEstimation_LegalNotice:
        'Ce devis estimatif ne vaut pas devis définitif, ni commande.{br}Un conseiller prendra contact avec vous pour finaliser votre projet. Les prix affichés sont des prix publics conseillés TTC.{br}Le devis estimatif inclut le taux de TVA réduite selon les conditions légales en vigueur.{br}Voir nos {cgvLink} et {cgpLink} pour plus d’informations.',
    QuoteEstimation_WithLivraison_LegalNotice:
        'Ce devis estimatif ne vaut pas devis définitif, ni commande.{br}Un conseiller prendra contact avec vous pour finaliser votre projet. Les prix affichés sont des prix publics conseillés TTC.{br}Le devis estimatif inclut le taux de TVA réduite selon les conditions légales en vigueur.{br}Voir nos {cgvLink} et {cgpLink} pour plus d’informations.',

    QuoteEstimation_PromoTvaMessagePart1: "Avec la TVA offerte sur les produits posés** jusqu'au {endDate}, ",
    QuoteEstimation_PromoTvaMessagePart2: ' vous ÉCONOMISEZ {price} EUROS sur votre projet',
    QuoteEstimation_LegalPromoTva:
        '**Offre valable du {startDate} au {endDate}. Faites une estimation pose sur {host}, enregistrez votre projet et bénéficiez de TVA offerte sur les produits posés si vous confiez la pose de votre projet à Saint Maclou. Offre valable en magasin Saint Maclou. Hors promotions et commandes en cours, hors accessoires et consommables, non cumulable. Remise valable uniquement pour les particuliers. Remise de 16,67% correspondant à la valeur de la TVA à 20%, ou remise de 9,09% correspondant à la valeur de la TVA à 10%, selon le taux de TVA applicable à votre projet.',

    QuoteEstimation_LegalNotice_CGV: 'CGV',
    QuoteEstimation_LegalNotice_CGP: 'CGP',
    QuoteEstimation_LegalNotice_CGV_VenteEnLigne: 'CGV de vente en ligne',
    QuoteEstimation_LegalNotice_CGU: 'CGU',
    QuoteEstimation_LegalPromoTva_STMAC: 'saint-maclou.com',

    Quote_Step9_EstimationTitle: 'Estimation',
    Quote_Step9_PriceFormat: '{price} €',
    Quote_Step9_PriceUnitFormat_M: 'le {unitPrice}',
    Quote_Step9_PriceUnitFormat_F: 'la {unitPrice}',
    Quote_Step9_PriceUnitFormat_m: 'm',
    Quote_Step9_PriceUnitFormat_m2: 'm²',
    Quote_Step9_PriceUnitFormat_Piece: 'pièce',
    Quote_Step9_PriceUnitPaquetFormat_Piece: 'pièces',
    Quote_Step9_PriceUnitPaquetFormat_m2: 'm²',

    //* FloorPlan
    Floorplan_Title: 'Éditeur de plan',

    Floorplan_ErrorNoContext:
        "Les évaluations 'Localisation' et/ou 'Type de local' sont absents dans la version de paramètrage utilisé",
    Floorplan_Error_NoLaizeDirection: "Aucun sens de pose n'a été sélectionné",
    Floorplan_Error_EmptyPlan: "Aucun élément de plan n'a été ajouté",
    Floorplan_Error_Collision: 'Certaines pièces se chevauchent, veuillez les replacer',
    Floorplan_Error_RoomItemOverlaping:
        'Certains autres éléments se chevauchent, ou débordent d’une pièce. Veuillez les replacer.',
    Floorplan_ErrorNoRoom: 'Veuillez créer une pièce afin de placer cet élément',

    Floorplan_QuoteMenu_TotalSurface: 'Le plan',
    Floorplan_QuoteMenu_TotalSurfaceValue: '{area} m²',
    Floorplan_QuoteMenu_LaizeDirection: 'Sens de pose',
    Floorplan_QuoteMenu_LaizeDirection_vertical: 'vertical',
    Floorplan_QuoteMenu_LaizeDirection_horizontal: 'horizontal',
    Floorplan_QuoteMenu_LaizeDirection_none: 'Aucun',
    Floorplan_QuoteMenu_Contournements: 'Contournements',
    Floorplan_QuoteMenu_OpeningCount: 'Ouverture(s)',
    Floorplan_QuoteMenu_OtherElementCount: 'Autres élément(s)',
    Floorplan_QuoteMenu_ContournementsCount: 'Contournement(s)',

    Floorplan_HowItWorksOnboarding_Title: 'Comment ça marche ?',
    Floorplan_HowItWorksOnboarding_0: 'Décrivez votre habitation',
    Floorplan_HowItWorksOnboarding_1: 'Dessinez votre plan',
    Floorplan_HowItWorksOnboarding_2: 'Détaillez votre projet',
    Floorplan_HowItWorksOnboarding_3: 'Visualisez votre devis',
    Floorplan_HowItWorksOnboarding_Start: 'Je réalise mon devis estimatif',

    Floorplan_Onboarding_Next: 'Suivant',
    Floorplan_Onboarding_Previous: 'Précédent',
    Floorplan_Onboarding_Skip: 'Passer',
    Floorplan_Onboarding_Final: 'Démarrer mon plan',
    Floorplan_Onboarding_Footer_Label: 'Ne plus afficher',
    Floorplan_Onboarding_Step1_Title: 'Mon plan',
    Floorplan_Onboarding_Step1_Text:
        "Dessinez votre pièce, placez vos ouvertures, vos éléments spécifiques, vos contournements ... Chaque élément a son importance afin d'estimer précisément votre projet.",
    Floorplan_Onboarding_Step2_Title: 'Pièce',
    Floorplan_Onboarding_Step2_Text:
        "Créez votre projet à partir d'une forme de pièce prédéfinie et ajustez les dimensions de vos murs en fonction de vos mesures. Ajoutez ou dupliquez une pièce, déplacez les murs ... Personnalisez votre plan afin qu'il soit le plus fidèle possible.",
    Floorplan_Onboarding_Step3_Title: 'Ouvertures',
    Floorplan_Onboarding_Step3_Text:
        "Placez vos ouvertures : porte, porte-fenêtre, baie vitrée ou même un passage ouvert vers une autre pièce, renseignez leurs dimensions et leurs matières. Vos dimensions ne sont pas standards ? C'est prévu ! Vous pouvez saisir la mesure exacte.",
    Floorplan_Onboarding_Step4_Title: 'Autres éléments',
    Floorplan_Onboarding_Step4_Text:
        "Un placard, une douche, des meubles de cuisine ... autant d'élements que nous déduisons de la surface totale de la pièce. Indiquez leurs dimensions, positionnez-les et le tour est joué !",

    Floorplan_Opening_door: 'Porte',
    Floorplan_Opening_armored_door: 'Porte blindée',
    Floorplan_Opening_front_door: "Porte d'entrée",
    Floorplan_Opening_french_door: 'Porte fenêtre',
    Floorplan_Opening_patio_door: 'Baie vitrée',
    Floorplan_Opening_opening: 'Passage ouvert',

    Floorplan_RoomElement_shower_bathtub: 'Douche / Baignoire',
    Floorplan_RoomElement_fixed_furniture: 'Meuble non déplaçable',
    Floorplan_RoomElement_cupboard: 'Placard',
    Floorplan_RoomElement_kitchen_furniture: 'Meuble de cuisine',
    Floorplan_RoomElement_trap: 'Trappe',
    Floorplan_RoomElement_fireplace: 'Cheminée',
    Floorplan_RoomElement_opening: 'Ouverture',
    Floorplan_RoomElement_stairs: 'Marche',
    Floorplan_RoomElement_divider: 'Cloison',

    Floorplan_RoomFloorList_RDC_Short: 'RDC',
    Floorplan_RoomFloorList_RDC_Long: 'Rez de chaussée',

    //* Projects
    Projects_Title: 'Mes projets',
    Projects_ListItemTitle: 'Créer un projet',
    Projects_CreatedBy: 'Créé par {user}',
    Projects_CreatedBy_Store: 'le magasin {storeId}',
    Projects_CreatedBy_Client: 'Client',
    Projects_CreatedByDate: 'le {date}',
    Projects_LastSave: 'Dernière sauvegarde :',
    Projects_Search_Placeholder: 'Rechercher un projet',

    Projects_Import: 'Importer un projet',
    Projects_Import_Success: '{imported_count} projet(s) importé(s) avec succès.',
    Projects_Import_Ignored: '{ignored_count} projet(s) ignoré(s)',
    Projects_Import_ExistedProjectIgnored: 'Le projet existe déjà.\n(ID CLIENT : {customerId})',
    Projects_Import_Error: "Erreur lors de l'import du projet",
    Projects_Import_CustomerOverrideDialogTitle: 'Import du projet',
    Projects_Import_CustomerOverrideDialogBody: 'Vous êtes connecté en tant que client.\n\nSouhaitez vous importer ce(s) projet(s) pour le client actuel ? (customer_id override)',

    Projects_SeeMore: 'Voir le détail',
    ProjectDetails_Resume: 'Continuer le projet',
    ProjectDetails_DeleteProject: 'Supprimer le projet',
    ProjectDetails_AreaValue: '{area} m²',
    ProjectDetails_AmountValue: '{amount} €',
    ProjectDetails_RoomCountValue: 'pièce(s)',
    ProjectDetails_ProjectSummary: 'Résumé du projet',
    ProjectDetails_Area: 'Superficie :',
    ProjectDetails_OpeningCount: "Nombre d'ouvertures :",
    ProjectDetails_RoomCount: 'Nombre de pièces :',
    ProjectDetails_LastSave: 'Dernière sauvegarde :',
    ProjectDetails_CreatedBy: 'Créé par :',
    ProjectDetails_CustomerId: 'ID Client :',
    ProjectDetails_Intitule: 'Intitulé du projet',
    ProjectDetails_Description: 'Description',
    ProjectDetails_HistoryList: 'Historique des versions du projet',
    ProjectDetails_Version_CreationDate: 'Date de création',
    ProjectDetails_Version_RoomCount: 'Nombre de pièces',
    ProjectDetails_Version_RoomCount_Short: 'Nbr pièces',
    ProjectDetails_version_Amount: 'Montant',
    ProjectDetails_DialogEditIntituleInformation: "Veuillez saisir l'intitulé du projet",
    ProjectDetails_DialogEditDescriptionInformation: 'Veuillez saisir la description du projet',
    ProjectDetails_DialogEditCustomerIdInformation: "Veuillez saisir l'ID Client du projet",
    ProjectDetails_Export: 'Exporter le projet',

    CardWithDetails_Reference: 'Réf: ',
    CardWithDetails_ReferenceFormat: 'Réf: {value}',
    CardWithDetails_ConditionnementPar: 'Paquet(s) de {value} {unit} ',
    CardWithDetails_Inclus: 'INCLUS',
    RecommendedChoiceBadge_Text: 'Conseillé',
    ZipCodeQuestion_DialogTitle: 'Votre code postal',
    ZipCodeQuestion_DescriptionLabel: 'Localité : ',

    //* when click on book an appointment
    Dialog_ProjectSaveHeader: "Votre projet n'est pas encore enregistré. Souhaitez-vous enregister votre projet ?\nPour obtenir un devis, vous devez enregistrer votre projet.",
    Dialog_ProjectSaveBody: 'Souhaitez-vous enregister votre projet ?',

    Dialog_ProjectSaveDirtyHeader: 'Des modifications ont été apportées à votre projet',
    Dialog_ProjectSaveDirtyBody: "Confirmez-vous l'enregistrement des modifications ?",

    DialogLogin_Title: 'Le plan, c’est fait!',
    DialogLogin_Subtitle: 'Assurez vous de bien sauvegarder votre projet  avant de passer aux prochaines étapes',

    CatalogDialog_BackButton_Label: 'Revenir sur Clic&Pose',

    AddressDialog_Title: 'L’adresse du lieu de pose',
    AddressDialog_Description: "Afin d'éditer votre devis estimatif, nous avons\nbesoin de connaître l'adresse du lieu de pose.",
    AddressDialog_Placeholder: "L'adresse du lieu de pose",
    AddressDialog_Error_Mistmatch_ZipCode: "Les codes postaux ne sont pas identiques.\nVous pouvez retourner à l'étape habitation pour changer votre code postal.",

    //AUTO SAVE : show dialog after 5 min
    Dialog_AutoSaveTitle: 'Enregistrer',
    Dialog_AutoSaveHeader: 'Souhaitez-vous enregister votre projet ?',

    ERROR_ROOM_HEATING_TYPE_MISSING_TITLE: 'Type de chauffage manquant',
    ERROR_ROOM_HEATING_TYPE_MISSING_CONTENT: 'Il semblerait que le type de chauffage soit manquant, merci de renseigner cette information dans votre projet avant de poursuivre',

    //* MESAGE FROM V3
    ...frFloorPlanMessages,
    //! PLAN
    FloorPlanTutorial_Add_Room: 'Ajoutez{br}une pièce',
    FloorPlanTutorial_Edit_Room: 'Modifiez les dimensions{br}de votre pièce',
    FloorPlanTutorial_Edit_Infos: '& Configurez votre pièce',
    FloorPlanTutorial_Edit_Infos_Mobile: '& Configurez la',
    Room_Area: '{area} m²',
    Room_AreaWithoutItems: '{area} m² / {areaWithoutRoomItems} m² utilisables',
    EditCoteDialog_Title: 'Édition de cote',
    EditCoteDialog_DimensionPropertyLabel: 'Dimension (cm)',
    EditCoteDialog_DimensionPropertyDescription: 'la cote doit être comprise entre ({min}) et ({max}) cm',

    Opening_Title: 'Ouvertures /{br}Aménagements',
    Habitation_Title: 'Mon habitation',

    //!PANELS
    RoomShapePannel_Title_Sol: 'Séléctionner la forme de votre pièce',
    RoomShapePannel_Title_Wall: 'Séléctionner la forme de votre mur',
    RoomShape_Other: 'Autre',
    RoomShape_Other_Header: 'Pièce atypique',
    RoomShape_Other_Title: 'Votre pièce a une forme atypique ?',
    RoomShape_Other_Subtitle: "Faites plutôt deviser votre projet par l'un de nos experts afin d'obtenir l'estimation la plus précise possible.",
    RoomShape_Other_SaveAdvice: 'Un conseiller vous contactera dans les plus brefs délais',
    RoomShape_Other_AppointmentBtn: 'Prendre RDV',

    Room_Title_Sol: 'Pièce',
    Room_Title_Wall: 'Murs',

    RoomEdit_Wall: 'Type de mur',
    RoomEdit_HeaderTitle_Sol: 'Ma pièce',
    RoomEdit_HeaderTitle_Wall: 'Mon mur',
    RoomEdit_Title_Sol: 'Affinez votre pièce',
    RoomEdit_Title_Wall: 'Affinez votre mur',
    RoomEdit_Subtitle_Sol: 'Complétez les informations manquantes sur votre pièce.',
    RoomEdit_Subtitle_Wall: 'Complétez les informations manquantes sur votre mur.',
    RoomEdit_Button: 'Revenir au plan',

    RoomEdit_Localisation_Dialog: `Les aménagements présents sur votre pièce ne sont pas compatibles avec votre changement. En continuant, vous allez les perdre.\nSouhaitez-vous continuer ?`,
    RoomEdit_Shape: 'Forme de la pièce',
    RoomEdit_Localisation: 'Situation de la pièce',
    RoomEdit_Type: 'Type de la pièce',
    RoomEdit_Floor: 'Etage',
    RoomEdit_RevetementAuSol_Sol: "Présence d'un revêtement au sol",
    RoomEdit_RevetementAuSol_Wall: "Présence d'un revêtement au mur",
    RoomEdit_FloorType: 'Revêtement actuel',
    RoomEdit_Heating: "Présence d'un chauffage au sol",
    RoomEdit_HeatingType: 'Type de chauffage au sol',
    RoomEdit_SearchProduit_BtnTitle: 'Chercher dans notre catalogue',
    RoomEdit_FlooringDirection: 'Sens de pose ?',
    RoomEdit_FlooringDirection_LaizeVertical: 'Sens de pose vertical',
    RoomEdit_FlooringDirection_LaizeHorizontal: 'Sens de pose horizontal',
    RoomEdit_FlooringDirection_LameVertical: 'Lames entières à la verticale',
    RoomEdit_FlooringDirection_LameHorizontal: "Lames entières à l'horizontal",
    RoomEdit_FlooringDirection_ClassiqueVertical: 'Optimisation des lames à la verticale',
    RoomEdit_FlooringDirection_ClassiqueHorizontal: "Optimisation des lames à l'horizontal",

    LaizeView_Description_Reference_Format: 'RÉF: {reference}',
    LaizeView_Description_Unit_Rouleau: 'Mètres linéaires',

    RoomEdit_Amenagements_Title: 'Mes aménagements',
    RoomEdit_Amenagements_Openings: 'Ouverture(s)',
    RoomEdit_Amenagements_RoomItems: 'Aménagement(s)',
    RoomEdit_Amenagements_Contournements: 'Autre aménagement(s)',
    RoomEdit_Amenagements_EmptyMessage: 'Vous n’avez pas encore ajouter des aménagements dans cette pièce ajouter un.',
    RoomEdit_Amenagements_AddAmenagement: 'Ajouter un aménagement',

    OpeningSelect_Title_Sol: 'Ajouter une ouverture ou{br}un aménagement à votre pièce',
    OpeningSelect_Title_Wall: 'Ajouter une ouverture ou{br}un aménagement à votre mur',
    OpeningSelect_Room_Title_Sol: 'Pièce attachée',
    OpeningSelect_Room_Title_Wall: 'Mur attaché',

    OpeningSelect_OpeningList_Title: 'Ouvertures',
    OpeningSelect_RoomElementList_Title_Sol: 'Aménagements',
    OpeningSelect_RoomElementList_Title_Wall: 'Ouvertures',
    OpeningSelect_ContournementList_Title_Sol: 'Autres aménagements',
    OpeningSelect_ContournementList_Title_Wall: 'Aménagements',

    OpeningSelect_Contournement_MountedRadiator: 'Radiateur {br}sur pied',
    OpeningSelect_Contournement_MountedElement: 'Element {br}sur pied',
    OpeningSelect_Contournement_Pillar: 'Pilier /{br}obstacle',
    OpeningSelect_Contournement_Stonewall: 'Murs en pierres{br}apparentes ou{br}arrondis',
    OpeningSelect_Contournement_Stairs: 'Escalier',
    OpeningSelect_Contournement_Pipe: 'Tuyau au sol',
    OpeningSelect_Contournement_Railing: 'Rambarde',
    OpeningSelect_Contournement_Tree: 'Arbre',

    OpeningSelect_Contournement_WallPipe: 'Tuyau filant /{br}gaine',
    OpeningSelect_Contournement_WallPipeHole: 'Trou tuyau',
    OpeningSelect_Contournement_ElectricEquipment: 'Equipement{br}électrique',
    OpeningSelect_Contournement_WallPillar: 'Pilier /{br}obstacle',
    OpeningSelect_Contournement_Hotspot: 'Point chaud',

    OpeningSelect_ContournementDialog_Stonewall_Count: 'Nombre de mètre(s) linéaire(s) dans cette pièce:',
    OpeningSelect_ContournementDialog_Count: 'Nombre de {label} dans cette pièce:',

    Opening_Info_TypeTitle: 'Le type d’ouverture*',
    Opening_Info_DimensTitle: 'Dimension*',
    Opening_Info_OtherTitle: 'Autre dimension*',
    OpeningEdit_Title: 'Détail d’Ouverture',
    OpeningEdit_Button: 'Revenir au plan',

    RoomItem_Info_TypeTitle: 'Le type d’aménagement*',
    RoomItem_Info_WidthTitle: 'Largeur*',
    RoomItem_Info_HeightTitle_Sol: 'Longueur*',
    RoomItem_Info_HeightTitle_Wall: 'Hauteur*',
    RoomItem_Info_DimensPlaceholder: 'Saisissez la dimension ici',
    RoomItem_Info_DimensUnit: 'cm',
    RoomItem_Info_Dimensions: 'Dimension*',
    RoomItem_Info_Dimensions_Format: 'H {height} x l {width}',
    RoomItemEdit_Button: 'Revenir au plan',
    RoomItemEdit_Title: 'Détail d’Aménagement',

    TagType_ToDo: 'à compléter',
    TagType_Done: 'complet',
    TagType_Error: 'erreur',

    Step_Title_StepPose: 'Ma pose',
    Step_Title_StepSupport: 'Mon support',
    Step_Title_StepPreparation: 'Ma préparation',
    Step_Title_StepFinitions: 'Mes finitions',

    Question_Help: 'En savoir plus',

    Product_Ref: 'Réf : {ref}',
    Product_TTC_Label: '*TTC',
    Product_Promo_Banner: 'Promo',

    Estimation_Header_Title: 'DEVIS ESTIMATIF',
    Estimation_Pdf_Title: 'Télécharger le devis estimatif',
    Estimation_Appointment_Title: "Plus qu'une étape !",
    Estimation_Appointment_SubTitle: `Un conseiller vous contactera prochainement pour vous aider à concrétiser votre projet !

    Mais vous pouvez aussi prendre rendez-vous dés à présent avec l'un de nos experts.`,
    Estimation_Appointment_Title2: "Besoin d'aide pour finaliser votre devis estimatif ?",
    Estimation_Appointment_SubTitle2: `Prenez rendez-vous en ligne avec un expert pour échanger sur votre projet.

    À domicile, en magasin ou par téléphone : on s'occupe de tout !`,

    //OBSOLETE
    Estimation_Store_Title: 'Le magasin le plus proche',
    Estimation_Store_Change: 'Changer de magasin',

    StoreLocator_HeaderTitle: 'Recherche Votre MAGASIN',
    StoreLocator_SelectStore: 'Choisir ce magasin',
    StoreLocator_NearMyProject: 'Proche de mon projet',
    StoreLocator_SearchPlaceholder: 'Ex: Paris 75000',
    StoreLocator_SearchTitle: 'Saisissez votre code postal ou le nom de votre ville.',
    StoreLocator_SearchResults: '{count} magasins à proximité',

    StoreLocator_Store_Days: 'Horaires',
    StoreLocator_Store_Day_1: 'Lundi :',
    StoreLocator_Store_Day_2: 'Mardi :',
    StoreLocator_Store_Day_3: 'Mercredi :',
    StoreLocator_Store_Day_4: 'Jeudi :',
    StoreLocator_Store_Day_5: 'Vendredi :',
    StoreLocator_Store_Day_6: 'Samedi :',
    StoreLocator_Store_Day_7: 'Dimanche :',
    StoreLocator_Store_Open: 'Ouvert',
    StoreLocator_Store_Closed: 'Fermé',

    Estimation_Price_Total_HT: 'Total H.T.',
    Estimation_Price: 'DÉTAIL PRIX',
    Estimation_Price_Total_TVA_PERCENTAGE: 'Total TVA ({percentage}%)',
    Estimation_Price_Total_TTC: 'Total TTC',
    Estimation_Price_Total_EcoContribution: 'Dont éco-contribution',
    Appointment_Button_Title: 'Je prends rendez-vous',
    Appointment_Button_ShortTitle: 'Prendre RDV',

    Estimation_Habitation: 'MON PROJET',
    Estimation_Habitation_Title: 'Infos générales sur mon projet',
    Estimation_Habitation_Edit: 'Modifier',
    Estimation_Habitation_Edit_Questions_Message:
        'Ces informations ont un impact direct sur le calcul de votre estimation.{br}Changer vos réponses nécessitera peut-être de répondre à nouveau à certaines questions.{br}Souhaitez-vous continuer ?',

    Estimation_Synthese_General: 'Infos générales',
    Estimation_NotCompleted: 'COMPLÉTEZ VOS PIÈCES POUR OBTENIR VOTRE DEVIS ESTIMATIF',
    Estimation_NotCompleted_Button: 'Complétez votre projet',
    Estimation_NotCompleted_NoRoom: 'CRÉEZ LE PLAN DE VOTRE HABITATION POUR OBTENIR UN DEVIS ESTIMATIF',
    Estimation_NotCompleted_NoRoom_Button: 'Reprendre mon parcours',
    Button_ShowEstimation: 'Voir mon devis',
    Estimation_Recap_Produit_Principal: 'Produit principal à poser',
    Estimation_Recap_Produits_Principaux: 'Produits principaux à poser',
    Estimation_Recap_Produit_Complementaire: 'Produits complémentaires',
    Estimation_Recap_Produit_Quantity: 'Quantité : ',
    Estimation_Recap_Produit_Paquet: 'Paquet(s) de {conditionnement} {unit}',
    Estimation_Recap_Produit_Unitaire: 'Unitaire',
    Estimation_Recap_Produit_TotalPrice: 'Soit ',

    Estimation_Recap_Main_Oeuvre: 'Main d’œuvre',
    Estimation_Recap_Pose_Depose: 'Dépose',
    Estimation_Recap_Pose_Preparation_Support_Avant_Pose: 'Préparation du support avant pose',
    Estimation_Recap_Pose_Revetement_Principal: 'Pose du revêtement principal',
    Estimation_Recap_Pose_Accessoires: 'Pose accessoires',
    Estimation_Recap_Pose_Complement_Forfait_Divers: 'Complément forfait divers',
    Estimation_Recap_Pose_Nettoyage: 'Nettoyage',
    Estimation_Recap_Pose_Autres: 'Autres',
    Estimation_Recap_Pose_Forfait_Besoin_Supplementaire: 'Forfait besoin supplémentaire',
    Estimation_Recap_Pose_Gestion_Dechets: 'Gestion des déchets',

    Estimation_Recap_Livraison: 'Livraison',

    HabitationEdit_Title: 'Informations générales',
    HabitationEdit_Subtitle: 'Complétez les informations manquantes sur votre projet.',

    CopyProperties_Copy: 'Recopier',
    CopyProperties_CopyValidate: 'Valider la recopie',
    CopyProperties_Continue: 'Passer',
    CopyProperties_Room_Sol: "Souhaitez-vous recopier les réponses d'une autre pièce ?",
    CopyProperties_Room_Wall: "Souhaitez-vous recopier les réponses d'un autre mur ?",
    CopyProperties_Dialog_Room_Sol: "Recopier les réponses d'une autre pièce",
    CopyProperties_Dialog_Room_Wall: "Recopier les réponses d'un autre mur",
    CopyProperties_Opening: "Souhaitez-vous recopier les réponses d'une autre ouverture ?",
    CopyProperties_Dialog_Opening: "Recopier les réponses d'une autre ouverture",
    CopyProperties_RoomItem: "Souhaitez-vous recopier les réponses d'un autre aménagement ?",
    CopyProperties_Dialog_RoomItem: "Recopier les réponses d'un autre aménagement",

    MapSwitch_Sol: 'Revêtement de sol',
    MapSwitch_Wall: 'Revêtement de mur',
    MapSwitch_Mobile_Sol: 'Sol',
    MapSwitch_Mobile_Wall: 'Mur',

    Room_DeleteDialog_Title_Sol: 'Supprimer la pièce "{name}"',
    Room_DeleteDialog_Title_Wall: 'Supprimer le mur "{name}"',

    //* Analyse calcul en lés
    LaizeCalcDialog_Title: 'Studio',
    LaizeCalcDialog_Back: 'Retour',
    LaizeCalcDialog_Params_Title: 'Paramètres',
    LaizeCalcDialog_Params_Type: 'Type :',
    LaizeCalcDialog_Params_Type_rouleau: 'Rouleau',
    LaizeCalcDialog_Params_Type_lamePleine: 'Lame pleine',
    LaizeCalcDialog_Params_LaizeMargin: 'Marge interne lé {readonly}',
    LaizeCalcDialog_Params_BandWidth: 'Largeur rouleau {readonly}',
    LaizeCalcDialog_Params_RaccordLength: 'Taille raccord {readonly}',
    LaizeCalcDialog_Params_MotifWidth: 'Largeur motif {readonly}',
    LaizeCalcDialog_Params_MotifLength: 'Longueur motif {readonly}',
    LaizeCalcDialog_Params_LongueurCm: 'Longueur {readonly}',
    LaizeCalcDialog_Params_LaizeConsecutiveBandsMargin: 'Marges supplémentaires {readonly}',
    LaizeCalcDialog_Params_ReadonlyValueFormat: '{value} cm',

    LaizeCalcDialog_ProductSelector_Title: 'Produit :',
    LaizeCalcDialog_IterationStep_Title: 'Étape :',

    LaizeCalcDialog_Band_Title: 'BANDE {index}',
    LaizeCalcDialog_Band_Lenght: 'Longueur :',
    LaizeCalcDialog_Band_Width: 'Largeur :',

    LaizeCalcDialog_LaizeResult_Total: 'TOTAL : ',
    LaizeCalcDialog_LaizeResult_RaccordLength: 'Longueur raccord  :',
    LaizeCalcDialog_LaizeResult_FormatM: '{value} m',
    LaizeCalcDialog_LaizeResult_FormatML: '{value} mL',

    LaizeCalcDialog_LaizeDirection_Part1: 'SENS',
    LaizeCalcDialog_LaizeDirection_Part2: 'DE POSE',
    //FIN DE PARCOURS
    FinDeParcours_Title: 'Finalisez votre projet',
    FinDeParcours_Step_Options: 'Options',
    FinDeParcours_Step_Syntheses: 'Synthèse',
    FinDeParcours_Step_Quote: 'Devis',

    FinDeParcours_Step_Options_Title: 'Mes Options',

    FinDeParcours_Step_Syntheses_Title: 'Synthèse de Mon projet',
    FinDeParcours_Step_Syntheses_StoreTitle: 'Le magasin qui prendra en charge votre projet : ',
    FinDeParcours_Step_Syntheses_StoreChange: 'Changer de magasin',
    FinDeParcours_Step_Syntheses_StoreMessage: 'Un conseiller va vous contacter pour vous accompagner sur votre projet.',

    FinDeParcours_Step_ChangesAlert: "Ces informations ont un impact direct sur le calcul de votre estimation.\nChanger vos réponses nécessitera peut-être de répondre à nouveau à certaines questions.\nSouhaitez-vous continuer ?",

    FinDeParcours_Step_Quote_Title: 'Mon Devis',
    FinDeParcours_Step_Quote_TotalTitle: 'Confiez la pose de vos produits à nos experts',
    FinDeParcours_Step_Quote_Subtitle: 'Prises de mesures, diagnostic, préparation des sols... nos experts assurent la pose de votre chantier !',

    FinDeParcours_Step_Congratulation_Title: 'félicitations',
    FinDeParcours_Step_Congratulation_Subtitle: 'vous avez terminé votre projet !',
    FinDeParcours_Step_Congratulation_Text: "Félicitations vous avez finalisé votre projet. Un conseiller du magasin de {ville} va recevoir votre\nprojet et vous rappeler dans les prochaines heures. Si vous le souhaitez, vous pouvez également\nprendre rendez-vous pour suivre votre projet (validation des produits, diagnostic technique, prises\nde mesure).",

    RoomDimens_Title: "Préciser les dimensions principales de votre pièce",
    RoomDimens_Shape: "La forme de votre pièce*",
    Dimens_WidthTitle: 'Largeur*',
    Dimens_HeightTitle: 'Longueur*',
    Dimens_InputPlaceholder: 'Saisissez la dimension ici',
    Dimens_DimensUnit: 'cm',
    Dimens_ErrorLimits: 'Veuillez saisir la largeur comprise entre ({min}) et ({max}) cm',
};

export type StringKey = keyof typeof frMessages;
