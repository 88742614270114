import Enumerable from 'linq';
import {
    ConfigurationReponseDto,
    ReponseContextTypeDto,
    ReponseQuestionTypeDto,
    SetConfigurationReponseDto,
} from '../Models/Projects/Configuration';
import { ProduitDto } from '../Models/Questions/ProduitDto';
import {
    EvaluationDto,
    OptionDto,
    QuestionContextType,
    QuestionItem,
    QuestionType,
} from '../Models/Questions/QuestionDto';
import { ConditionHelper } from './ConditionHelper';
import { ObjectUtils } from './ObjectUtils';
import { QuestionContext, QuestionContextHelper } from './QuestionContextHelper';
import { Logger } from 'Errors/Logger';

export interface ToConfigurationReponseParameters {
    readonly question: QuestionItem;
    readonly opening_id?: string;
    readonly room_id?: string;
    readonly room_element_id?: string;
}

export interface ApplyReponseToQuestionParameters {
    contextId?: string;
    question: QuestionItem;
    reponses: Array<ConfigurationReponseDto>;
    produitPrincipal?: ProduitDto;
    withLog?: boolean;
}

export class ConfigurationUtils {
    public static canBeSaved = (question: QuestionItem): boolean => {
        switch (question.type) {
            case QuestionType.Produit:
                return Boolean(question.produitValue || question.excludeResponseSelected);
            case QuestionType.SingleChoice:
                return Boolean(question.optionValue);
            case QuestionType.MultipleChoices:
                return Boolean(question.optionValues);
            case QuestionType.Number:
                return Boolean(question.numberValue);
            case QuestionType.Comment:
                return Boolean(question.commentValue);
            case QuestionType.ZipCode:
                return Boolean(question.zipCodeValue);
            default:
                return false;
        }
    };

    public static hasReponseContext = (reponse: SetConfigurationReponseDto): boolean => {
        switch (reponse.context_type) {
            case ReponseContextTypeDto.Rooms:
                return Boolean(reponse.rooms_context_properties);
            case ReponseContextTypeDto.Openings:
                return Boolean(reponse.openings_context_properties);
            case ReponseContextTypeDto.RoomElements:
                return Boolean(reponse.room_elements_context_properties);
            default:
                return true;
        }
    };

    public static toConfigurationReponses = (
        questions: Array<QuestionItem> = [],
        parameters?: Partial<ToConfigurationReponseParameters>
    ): Array<SetConfigurationReponseDto> => {
        const toConfigurationReponseDto = (
            parameters: ToConfigurationReponseParameters
        ): SetConfigurationReponseDto => {
            const { question } = parameters;

            const configurationReponse: SetConfigurationReponseDto = {
                question_id: question.id,
                type: ConfigurationUtils.toReponseQuestionTypeDto(question.type),
                context_type: question.isFirstProduct
                    ? ReponseContextTypeDto.Rooms
                    : ConfigurationUtils.toReponseContextTypeDto(question.context_type),
                exclude_response_selected: question.excludeResponseSelected,
            };
            if (question.type === QuestionType.SingleChoice && question.optionValue) {
                configurationReponse.single_choice_properties = { option_id: question.optionValue.id };
            }
            if (question.type === QuestionType.MultipleChoices && question.optionValues) {
                configurationReponse.multiple_choices_properties = {
                    option_id_list: question.optionValues.map((x) => x.id),
                };
            }
            if (question.type === QuestionType.Number && question.numberValue) {
                configurationReponse.number_properties = { value: question.numberValue };
            }
            if (question.type === QuestionType.Comment && question.commentValue) {
                configurationReponse.comment_properties = { value: question.commentValue };
            }
            if (question.type === QuestionType.Produit) {
                configurationReponse.produit_properties = {
                    code: question.produitValue?.code,
                    user_search: question.produitSearch,
                };
            }
            if (question.type === QuestionType.ZipCode && question.zipCodeValue) {
                configurationReponse.zip_code_properties = {
                    value: question.zipCodeValue.zipcode,
                    description: question.zipCodeValue.description,
                    name: question.zipCodeValue.name,
                };
            }
            if (parameters.room_id) {
                configurationReponse.rooms_context_properties = { room_id: parameters.room_id };
            }
            if (parameters.opening_id) {
                configurationReponse.openings_context_properties = { opening_id: parameters.opening_id };
            }
            if (parameters.room_element_id) {
                configurationReponse.room_elements_context_properties = { room_element_id: parameters.room_element_id };
            }

            return configurationReponse;
        };
        const results = questions.filter(ConfigurationUtils.canBeSaved)
            .map((question: QuestionItem) => toConfigurationReponseDto({
                question,
                room_id: parameters?.room_id,
                opening_id: parameters?.opening_id,
                room_element_id: parameters?.room_element_id,
            }));
        //sur fin de parcours avec des questions non à jours services/options liées à des pièces
        const ignoreReponses = results.filter((r) => !ConfigurationUtils.hasReponseContext(r));
        if (ignoreReponses.length) {
            Logger.log('toConfigurationReponses', { ignoreReponses });
        }
        return results.filter(ConfigurationUtils.hasReponseContext);
    };

    public static toReponseQuestionTypeDto(questionType: QuestionType): ReponseQuestionTypeDto {
        switch (questionType) {
            case QuestionType.SingleChoice:
                return ReponseQuestionTypeDto.SingleChoice;
            case QuestionType.MultipleChoices:
                return ReponseQuestionTypeDto.MultipleChoices;
            case QuestionType.Number:
                return ReponseQuestionTypeDto.Number;
            case QuestionType.Comment:
                return ReponseQuestionTypeDto.Comment;
            case QuestionType.Produit:
                return ReponseQuestionTypeDto.Produit;
            case QuestionType.ZipCode:
                return ReponseQuestionTypeDto.ZipCode;
            default:
                throw getNotSupportedError(questionType);
        }
    }

    public static toReponseContextTypeDto(contextType: QuestionContextType): ReponseContextTypeDto {
        switch (contextType) {
            case QuestionContextType.General:
                return ReponseContextTypeDto.General;
            case QuestionContextType.Rooms:
                return ReponseContextTypeDto.Rooms;
            case QuestionContextType.Walls:
                return ReponseContextTypeDto.Walls;
            case QuestionContextType.Openings:
                return ReponseContextTypeDto.Openings;
            case QuestionContextType.RoomElements:
                return ReponseContextTypeDto.RoomElements;
            default:
                throw getNotSupportedError(contextType);
        }
    }

    public static applyReponseToQuestion(applyReponseToQuestionParameters: ApplyReponseToQuestionParameters) {
        const { question, reponses, contextId, produitPrincipal, withLog } = applyReponseToQuestionParameters;

        const reponse: ConfigurationReponseDto | undefined =
            (contextId
                ? ConfigurationUtils.getReponseByQuestionAndContext(contextId, question.id, reponses)
                : ConfigurationUtils.getReponseByQuestionId(question.id, reponses)) ??
            ConfigurationUtils.getReponseInContextGeneral(question.id, reponses);

        withLog &&
            console.log(
                'applyReponseToQuestion 0',
                ObjectUtils.clone({ question, reponse, contextId, produitPrincipal, reponses })
            );

        if (reponse) {
            if (reponse.type === ReponseQuestionTypeDto.Number) {
                const numberValue = reponse.number_properties?.value as string;

                const { minimum, maximum } = question.number_properties;
                const valueAsNumber: number = parseInt(numberValue);
                const isValueAsNumberValid = valueAsNumber >= minimum && valueAsNumber <= maximum;
                question.numberValue = numberValue;
                question.showQuestion = true;

                if (!isValueAsNumberValid) {
                    //* question answer is not valid, show error
                    question.errorInvalidNumberValue = true;
                    question.isValid = false;
                } else {
                    question.errorInvalidNumberValue = false;
                    question.isValid = true;
                }
            }
            if (question.type === QuestionType.SingleChoice) {
                if (question.single_choice_properties!.option_list.length > 0) {
                    const optionValue: OptionDto | undefined = Enumerable.from(
                        question.single_choice_properties!.option_list
                    ).firstOrDefault((o) => o.id === reponse.single_choice_properties?.option_id);
                    if (optionValue) {
                        question.optionValue = optionValue;
                        question.isValid = true;
                        question.showQuestion = true;

                        withLog &&
                            console.log('applyReponseToQuestion optionValue', {
                                question,
                                optionValue,
                                reponse,
                                contextId,
                            });
                    }
                }
            }
            if (question.type === QuestionType.MultipleChoices) {
                if (question.multiple_choices_properties!.option_list?.length > 0) {
                    const reponseOptionValues: Array<string> =
                        reponse.multiple_choices_properties?.option_id_list ?? [];

                    const optionValues: Array<OptionDto> = Enumerable.from(
                        question.multiple_choices_properties!.option_list
                    )
                        .where((o: OptionDto) => reponseOptionValues.includes(o.id))
                        .toArray();

                    if (optionValues) {
                        question.optionValues = optionValues;
                        question.isValid = question.optionValues !== undefined;
                        question.showQuestion = true;
                    }
                }
            }
            if (question.type === QuestionType.Comment) {
                const commentValue = reponse.comment_properties?.value as string;
                question.commentValue = commentValue;
                question.isValid = true; //* no check on comment
                question.showQuestion = true;
            }
            if (question.type === QuestionType.Produit) {
                const produitValue = reponse.produit_properties?.produit;
                question.produitValue = produitValue;
                question.produitPrincipalCode = produitPrincipal?.code;
                question.produitSearch = reponse.produit_properties?.produit?.code;
                question.produitsLoaded = false;
                question.loadingProduits = false;
                question.isValid = question.produit_properties.is_required ? Boolean(question.produitValue) : true;
                question.showQuestion = true;
            }
            if (question.type === QuestionType.ZipCode) {
                const zipCodeValue = reponse.zip_code_properties?.locality;
                if (zipCodeValue) {
                    question.zipCodeData = {
                        found: true,
                        zipCode: reponse.zip_code_properties?.value,
                        localities: [zipCodeValue],
                    };

                    question.zipCodeValue = zipCodeValue;
                    question.isValid = Boolean(zipCodeValue);
                    question.showQuestion = true;
                }
            }
            question.excludeResponseSelected = reponse.exclude_response_selected;
        }
        withLog &&
            console.log(
                'applyReponseToQuestion 1',
                ObjectUtils.clone({ question, reponse, contextId, produitPrincipal, reponses })
            );
        return question;
    }

    public static getReponseInContextGeneral = (questionId: string, reponses: Array<ConfigurationReponseDto> = []) => {
        return reponses.length > 0
            ? Enumerable.from(reponses).firstOrDefault((q: ConfigurationReponseDto) => {
                return q.question_id === questionId && q.context_type === ReponseContextTypeDto.General;
            })
            : undefined;
    };

    public static getReponseByQuestionId = (
        questionId: string,
        questions: Array<ConfigurationReponseDto> = []
    ): ConfigurationReponseDto | undefined => {
        return questions.length > 0
            ? Enumerable.from(questions).firstOrDefault((q: ConfigurationReponseDto) => q.question_id === questionId)
            : undefined;
    };

    public static getReponseByQuestionAndContext = (
        contextId: string,
        questionId: string,
        questions: Array<ConfigurationReponseDto> = []
    ): ConfigurationReponseDto | undefined => {
        return questions.length > 0
            ? Enumerable.from(questions).firstOrDefault((q: ConfigurationReponseDto) => {
                if (q.question_id === questionId) {
                    if (q.room_elements_context_properties) {
                        return q.room_elements_context_properties.room_element_id === contextId;
                    }
                    if (q.rooms_context_properties) {
                        return q.rooms_context_properties.room_id === contextId;
                    }
                    if (q.openings_context_properties) {
                        return q.openings_context_properties.opening_id === contextId;
                    }
                }
                return false;
            })
            : undefined;
    };

    public static evalQuestionEvalutations = (question: QuestionItem, context: QuestionContext): QuestionContext => {
        //* evaluate attribute
        const evaluations: Array<EvaluationDto> = question.evaluation_list ?? [];
        const evaluationsContext = context.evaluations ?? {};

        evaluations.forEach((evaluation: EvaluationDto) => {
            if (evaluation.evaluation_condition) {
                //* AJOUT des evaluations dans le context
                const isEvaluationToAdd: boolean = ConditionHelper.isConditionEvaluateToTrue({
                    condition: evaluation.evaluation_condition,
                    rawContext: context,
                });
                if (isEvaluationToAdd) {
                    evaluationsContext[QuestionContextHelper.toEvaluationValue(evaluation.name)] = evaluation.value;
                }
            } else {
                evaluationsContext[QuestionContextHelper.toEvaluationValue(evaluation.name)] = evaluation.value;
            }
        });
        context.evaluations = evaluationsContext;

        return ObjectUtils.clone(context);
    };
}

export const getNotSupportedError = (value: any) => {
    return new Error(`${value} : NOT_SUPPORTED`);
};
