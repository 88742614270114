import cloneDeep from 'lodash/cloneDeep';

export interface ToObjectParameters {
    readonly origin: Record<string, any>;
    readonly path: string;
    readonly value: any;
    readonly withLog?: boolean;
}

export class ObjectUtils {
    public static clone<T>(source: T): T {
        return cloneDeep<T>(source);
    }

    public static toObject = (toObjectParameters: ToObjectParameters): Record<string, any> => {
        const { origin, path, value } = toObjectParameters;

        let keys: Array<string> = path.split('.');
        let objects: Array<Record<string, any>> = [];
        let currentObj: Record<string, any> = {};

        const getValueInOrigin = (key: string): any | undefined => {
            const value: any = origin[key];
            return value;
        };

        while (keys.length > 0) {
            //head = question_8e5149a3-05af-4f8c-bbe9-e33f1408f06c
            //tail = [infos_produit.groupe_article.famille_article.sous_famille_article.code]
            const [head, ...tail] = keys;

            if (currentObj[head] === undefined) {
                if (keys.length === 1) {
                    currentObj[head] = value;
                } else {
                    const headValue = getValueInOrigin(head);
                    currentObj[head] = ObjectUtils.clone({ ...headValue });
                }
                objects = [...objects, currentObj];
            }
            currentObj = currentObj[head];
            keys = tail;
        }
        const result = objects[0];
        return result;
    };

    public static deepEqual(object1: any, object2: any): boolean {
        if (object1 === undefined && object2 === undefined) {
            return true;
        }
        if (object1 !== undefined && object2 === undefined) {
            return false;
        }

        if (object1 === undefined && object2 !== undefined) {
            return false;
        }

        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = ObjectUtils.isObject(val1) && ObjectUtils.isObject(val2);
            if ((areObjects && !ObjectUtils.deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
                return false;
            }
        }

        return true;
    }

    public static isObject(object: any) {
        return object !== undefined && object !== null && typeof object === 'object';
    }

    public static hasProperties = <T>(object: any, keys: Array<keyof T> = []) => {
        if (!ObjectUtils.isObject(object)) {
            return false;
        }
        const objectKeys = Object.keys(object);
        const hasProperties = keys.every((key) => objectKeys.includes(key as string));
        return hasProperties;
    };
    public static toHashCode = (obj: any): number => {
        return this.hashCode(JSON.stringify(obj));
    }

    public static hashCode = (str: string): number => {
        let hash = 0;
        if (str.length === 0) {
            return hash;
        }
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }
        return hash;
    }

    public static size = (object: any) => {
        const objectList = [];
        const stack = [object];
        let bytes = 0;

        while (stack.length) {
            const value = stack.pop();

            if (typeof value === 'boolean') {
                bytes += 4;
            }
            else if (typeof value === 'string') {
                bytes += value.length * 2;
            }
            else if (typeof value === 'number') {
                bytes += 8;
            }
            else if
                (
                typeof value === 'object'
                && objectList.indexOf(value) === -1
            ) {
                objectList.push(value);

                for (let i in value) {
                    stack.push(value[i]);
                }
            }
        }

        const roundOff = (value: number) => Math.round(value * 100) / 100;

        const fileSize = bytes;
        const fileSizeKB = roundOff(fileSize * 0.001);
        const fileSizeMB = roundOff(fileSizeKB * 0.001);
        //const fileSizeGB = roundOff(fileSizeMB * 0.001);
        // console.log("File sizes: ");
        // console.log(fileSize + " Bytes");
        // console.log(fileSizeKB + "KB");
        // console.log(fileSizeMB + "MB");
        // console.log(fileSizeGB + "GB");

        return fileSizeMB > 0 ? `${fileSizeMB} Mb` : fileSizeKB > 0 ? `${fileSizeKB} Kb` : `${fileSize} b`;
    }

    public static filterDuplicate = <T>(arr: T[]): T[] => {
        return arr.filter((obj, i, arr) => arr.findIndex((obj2) => JSON.stringify(obj2) === JSON.stringify(obj)) === i);
    };
}
