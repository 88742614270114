import { CSSProperties, SVGAttributes } from 'react';

import { MapConstants } from '../MapConstants';
import { isObjectsEquals } from './Funcs';
import { PointUtils } from './PointUtils';
import { Angle, CoordPoint, Equation, Wall, WallPolygon } from './Types';
import { ZoomUtils } from './ZoomUtils';

export type Vertex = {
    segment: Array<any>;
    child: Array<any>;
    removed: Array<any>;

    x: number;
    y: number;

    bypass: number;
    type: string;
    roomShape: string;
    roomId: string;
};

type JQuerySVGAttributes = SVGAttributes<{}> & {
    id: string;
    class: string;
    fill: string;
    stroke: string;
    href: string;
    transform: string;
    style: string;
    [field: string]: any;
};

export class qSVG {
    //todo: render in component
    public static create = (id: string, shape: any, attrs: Partial<JQuerySVGAttributes> = {}) => {
        shape = $(document.createElementNS('http://www.w3.org/2000/svg', shape));
        for (let k in attrs) {
            shape.attr(k, attrs[k]);
        }
        if (id !== 'none') {
            $('#' + id).append(shape);
        }
        return shape;
    };

    public static perpendicularEquation = (equation: Equation, x1: number, y1: number): Equation | undefined => {
        if (typeof equation.A != 'string') {
            return { A: -1 / equation.A, B: y1 - (-1 / equation.A) * x1 };
        }
        if (equation.A === 'h') {
            return { A: 'v', B: x1 };
        }
        if (equation.A === 'v') {
            return { A: 'h', B: y1 };
        }
    };

    public static parallelEquation = (equation: Equation, point: CoordPoint): Equation | undefined => {
        if (typeof equation.A != 'string') {
            return { A: equation.A, B: point.y - equation.A * point.x };
        }
        if (equation.A === 'h') {
            return { A: 'h', B: point.y };
        }
        if (equation.A === 'v') {
            return { A: 'v', B: point.x };
        }
    };

    // type array return [x,y] ---- type object return {x:x, y:y}
    public static intersectionOfEquations = (equation1: Equation, equation2: Equation, type: string = 'array') => {
        var retArray;
        var retObj;

        if (equation1.A === equation2.A) {
            retArray = undefined;
            retObj = undefined;
        }
        if (equation1.A === 'v' && equation2.A === 'h') {
            retArray = [equation1.B, equation2.B]; //
            retObj = { x: equation1.B, y: equation2.B };
        }
        if (equation1.A === 'h' && equation2.A === 'v') {
            retArray = [equation2.B, equation1.B];
            retObj = { x: equation2.B, y: equation1.B };
        }
        if (equation1.A === 'h' && equation2.A !== 'v' && equation2.A !== 'h') {
            retArray = [(equation1.B - equation2.B) / equation2.A, equation1.B];
            retObj = { x: (equation1.B - equation2.B) / equation2.A, y: equation1.B };
        }
        if (equation1.A === 'v' && equation2.A !== 'v' && equation2.A !== 'h') {
            retArray = [equation1.B, equation2.A * equation1.B + equation2.B];
            retObj = { x: equation1.B, y: equation2.A * equation1.B + equation2.B };
        }
        if (equation2.A === 'h' && equation1.A !== 'v' && equation1.A !== 'h') {
            retArray = [(equation2.B - equation1.B) / equation1.A, equation2.B];
            retObj = { x: (equation2.B - equation1.B) / equation1.A, y: equation2.B };
        }
        if (equation2.A === 'v' && equation1.A !== 'v' && equation1.A !== 'h') {
            retArray = [equation2.B, equation1.A * equation2.B + equation1.B];
            retObj = { x: equation2.B, y: equation1.A * equation2.B + equation1.B };
        }
        if (equation1.A !== 'h' && equation1.A !== 'v' && equation2.A !== 'v' && equation2.A !== 'h') {
            const xT = (equation2.B - equation1.B) / (equation1.A - equation2.A);
            const yT = equation1.A * xT + equation1.B;
            retArray = [xT, yT];
            retObj = { x: xT, y: yT };
        }
        return type === 'array' ? retArray : retObj;
    };

    public static measure = (po: CoordPoint, pt: CoordPoint) => {
        return Math.sqrt(Math.pow(po.x - pt.x, 2) + Math.pow(po.y - pt.y, 2));
    };

    public static btwn = (a: number, b1: number, b2: number, round?: boolean | string, tolerance?: number) => {
        if (round) {
            a = Math.round(a);
            b1 = Math.round(b1);
            b2 = Math.round(b2);
        } else if (tolerance) {
            if (b1 <= b2) {
                b1 -= tolerance;
                b2 += tolerance;
            } else {
                b1 += tolerance;
                b2 -= tolerance;
            }
        }
        if (a >= b1 && a <= b2) {
            return true;
        }
        return a >= b2 && a <= b1;
    };

    public static nearPointOnEquation(equation: Equation, point: CoordPoint) {
        // Y = Ax + B ---- equation {A:val, B:val}
        const pointA: CoordPoint = { x: 0, y: 0 };
        const pointB: CoordPoint = { x: 0, y: 0 };
        if (equation.A === 'h') {
            return {
                x: point.x,
                y: equation.B,
                distance: Math.abs(equation.B - point.y!),
            };
        } else if (equation.A === 'v') {
            return {
                x: equation.B,
                y: point.y,
                distance: Math.abs(equation.B - point.x!),
            };
        } else {
            pointA.x = point.x;
            pointA.y = equation.A * point.x + equation.B;
            pointB.x = (point.y! - equation.B) / equation.A;
            pointB.y = point.y;
            return qSVG.pDistance(point, pointA, pointB);
        }
    }

    public static pDistance(point: CoordPoint, pointA: CoordPoint, pointB: CoordPoint) {
        const x = point.x;
        const y = point.y;
        const x1 = pointA.x;
        const y1 = pointA.y;
        const x2 = pointB.x;
        const y2 = pointB.y;
        const A = x - x1;
        const B = y - y1;
        const C = x2 - x1;
        const D = y2 - y1;
        const dot = A * C + B * D;
        const len_sq = C * C + D * D;
        let param = -1;
        if (len_sq !== 0)
            //in case of 0 length line
            param = dot / len_sq;
        let xx, yy;
        if (param < 0) {
            xx = x1;
            yy = y1;
        } else if (param > 1) {
            xx = x2;
            yy = y2;
        } else {
            xx = x1 + param * C;
            yy = y1 + param * D;
        }
        const dx = x - xx;
        const dy = y - yy;
        return {
            x: xx,
            y: yy,
            distance: Math.sqrt(dx * dx + dy * dy),
        };
    }

    public static gap = (po: CoordPoint, pt: CoordPoint) => {
        return Math.pow(po.x - pt.x, 2) + Math.pow(po.y - pt.y, 2);
    };

    public static getAngle = (el1: CoordPoint, el2: CoordPoint): Angle => {
        return {
            rad: Math.atan2(el2?.y - el1?.y, el2?.x - el1?.x),
            deg: (Math.atan2(el2?.y - el1?.y, el2?.x - el1?.x) * 180) / Math.PI,
        };
    };

    public static createEquation = (x0: number, y0: number, x1: number, y1: number, previousEquation?: Equation) => {
        const tolerance_round_value = MapConstants.tolerance_round_value;
        const isVertical = x1 - x0 < tolerance_round_value && x1 - x0 > -tolerance_round_value;
        const isHorizontal = y1 - y0 < tolerance_round_value && y1 - y0 > -tolerance_round_value;

        //Wall start = Wall end
        if (isVertical && isHorizontal && previousEquation) {
            return previousEquation;
        } else {
            if (isVertical) {
                return { A: 'v', B: x0 };
            }
            if (isHorizontal) {
                return { A: 'h', B: y0 };
            }
        }
        return {
            A: (y1 - y0) / (x1 - x0), // Coefficient directeur
            B: y1 - x1 * ((y1 - y0) / (x1 - x0)), // Ordonnée à l'origine
        };
    };

    public static polygonize = (segmentWall: Array<Wall>): Array<WallPolygon> => {
        //* Find rooms
        const roomIds: Array<string> = Array.from(new Set(segmentWall.map((x: Wall) => x.roomId!)));
        const wallByRooms = roomIds.map((x) => ({ roomId: x, walls: segmentWall.filter((y) => y.roomId === x) }));
        const completeRooms: Array<any> = [];
        wallByRooms.forEach((wallRoom) => {
            if (wallRoom.walls[0].parent) {
                completeRooms.push(wallRoom);
            }
        });

        //* Polygons
        if (completeRooms.length > 0) {
            //* segment.
            const polygons: Array<WallPolygon> = completeRooms.map((x) => {
                const coords: Array<CoordPoint> = x.walls.map((x: Wall) => x.start);
                coords.push(coords[0]);
                return {
                    area: qSVG.area(coords),
                    coords,
                    coordsInside: coords,
                    inside: [],
                    outsideArea: qSVG.area(coords),
                    realArea: qSVG.area(coords),
                    roomId: x.roomId,
                    roomShape: x.walls[0].roomShape,
                };
            });
            return polygons;
        }
        return [];
    };

    public static area = (coordss: Array<CoordPoint>): number | undefined => {
        if (coordss.length < 2) return undefined;
        let realArea: number = 0;
        let j = coordss.length - 1;
        for (let i = 0; i < coordss.length; i++) {
            realArea = realArea + (coordss[j].x + coordss[i].x) * (coordss[j].y - coordss[i].y);
            j = i;
        }
        realArea = realArea / 2;
        return Math.abs(parseInt(realArea.toFixed(2)));
    };

    public static angleBetweenEquations = (m1String: string | number, m2string?: string | number) => {
        let m1: number = typeof m1String === 'number' ? m1String : 0;
        let m2: number = typeof m2string === 'number' ? m2string : 0;

        if (m1String === 'h') m1 = 0;
        if (m2string === 'h') m2 = 0;

        if (m1String === 'v') m1 = 10000;
        if (m2string === 'v') m2 = 10000;

        const angleRad = Math.atan(Math.abs((m2 - m1) / (1 + m1 * m2)));
        return (360 * angleRad) / (2 * Math.PI);
    };

    public static diffArray = (arr1: Array<any>, arr2: Array<any>) => {
        return arr1.concat(arr2).filter((val) => !(arr1.includes(val) && arr2.includes(val)));
    };

    public static diffObjIntoArray = (arr1: Array<any> = [], arr2: Array<any> = []) => {
        let count = 0;
        for (let k = 0; k < arr1.length - 1; k++) {
            for (let n = 0; n < arr2.length - 1; n++) {
                if (isObjectsEquals(arr1[k], arr2[n])) {
                    count++;
                }
            }
        }
        let waiting = arr1.length - 1;
        if (waiting < arr2.length - 1) waiting = arr2.length;
        return waiting - count;
    };

    //todo : remove
    public static textOnDiv = (
        label: string,
        pos: CoordPoint,
        styled: CSSProperties,
        id: string,
        div: any,
        additionalAttrs: any = {}
    ) => {
        if (typeof pos != 'undefined') {
            const text = $(document.createElementNS('http://www.w3.org/2000/svg', 'text'));
            text.attr('id', id);
            text.attr('x', pos.x);
            text.attr('y', pos.y);
            text.attr(
                'style',
                'fill:' +
                styled.color +
                ';font-weight:' +
                styled.fontWeight +
                ';font-size:' +
                styled.fontSize +
                ';font-family:' +
                styled.fontFamily +
                ';text-anchor:' +
                (Boolean(styled.textAnchor) ? styled.textAnchor : 'middle')
            );
            text.attr('text-anchor', 'middle');
            for (let k in additionalAttrs) {
                text.attr(k, additionalAttrs[k]);
            }
            text.text(label);
            div.append(text);
        }
    };

    public static strictlyBetween = (a: number, b1: number, b2: number): boolean => {
        if (a > b1 && a < b2) {
            return true;
        }
        return a > b2 && a < b1;
    };

    public static middle = (xo: number, yo: number, xd: number, yd: number): CoordPoint => {
        const x1 = parseInt(xo.toString());
        const y1 = parseInt(yo.toString());
        const x2 = parseInt(xd.toString());
        const y2 = parseInt(yd.toString());
        const middleX = Math.abs(x1 + x2) / 2;
        const middleY = Math.abs(y1 + y2) / 2;
        return { x: middleX, y: middleY };
    };

    public static angleDeg = (cx: number, cy: number, ex: number, ey: number) => {
        const dy = ey - cy;
        const dx = ex - cx;
        let theta = Math.atan2(dy, dx); // range (-PI, PI]
        theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
        if (theta < 0) theta = 360 + theta; // range [0, 360)
        return theta;
    };

    public static angleRadToDeg = (angle: number) => (angle * 180) / Math.PI;

    public static vectorXY = (obj1: CoordPoint, obj2: CoordPoint): CoordPoint => {
        return { x: obj2.x - obj1.x, y: obj2.y - obj1.y };
    };

    public static vectorDeter = (v1: CoordPoint, v2: CoordPoint) => {
        return v1.x * v2.y - v1.y * v2.x;
    };

    public static rayCasting = (point: CoordPoint, polygon: Array<CoordPoint> = [], margin?: number) => {
        //note: outer margin detection
        let x = point.x;
        let y = point.y;
        let inside = false;

        if (margin) {
            polygon = PointUtils.inflatePolygon(polygon, ZoomUtils.sensibilityFormula());
        }

        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            let xi = polygon[i].x;
            let yi = polygon[i].y;
            let xj = polygon[j].x;
            let yj = polygon[j].y;

            const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) {
                inside = !inside;
            }
        }
        return inside;
    };
}
