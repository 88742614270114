import { ObjectUtils } from '../../../../Helpers/ObjectUtils';
import { QuestionContext, QuestionContextHelper } from '../../../../Helpers/QuestionContextHelper';
import { QuestionsHelper } from '../../../../Helpers/QuestionsHelper';
import { StringKey } from '../../../../Locales/fr';
import { I18n } from '../../../../Locales/I18nService';
import { QuestionStep } from '../../../../Models/Questions/QuestionDto';
import { FlooringDirection } from '../../../../Plugins/FloorPlan/Components/Laizes/Laize';
import { IMapItem, YesOrNo } from '../../../../Plugins/FloorPlan/Models/IMapItem';
import { IOpening } from '../../../../Plugins/FloorPlan/Models/IOpening';
import { IRoom, RoomType } from '../../../../Plugins/FloorPlan/Models/IRoom';
import { IRoomItem } from '../../../../Plugins/FloorPlan/Models/IRoomItem';

export class QuestionContextUtils {
    public static createRoomContext = (
        item: IRoom,
        flooringDirection?: FlooringDirection,
        fromContext: QuestionContext = {}
    ): QuestionContext => {
        const contextFromPreviousStep = ObjectUtils.clone(fromContext);

        contextFromPreviousStep.revetement_type = item.type;
        contextFromPreviousStep.connection_needed = item.raccord_length ? item.raccord_length !== 0 : false;
        contextFromPreviousStep.room_should_lay_plinthes_inside_room_element = item.should_lay_plinthes_inside_room_element;

        if (item.type === RoomType.Sol) {
            contextFromPreviousStep.localisation_piece = item.localisation;
            contextFromPreviousStep.room_type = item.roomType;
            contextFromPreviousStep.room_floor = QuestionContextHelper.transformIntoLongFloorName(item.roomFloor);
            contextFromPreviousStep.has_room_existing_flooring = item.roomExistingFlooring;
            contextFromPreviousStep.room_existing_floor_type = item.roomExistingFloorType;
            contextFromPreviousStep.flooring_direction = flooringDirection;
            contextFromPreviousStep.room_length = item.room_length;
            contextFromPreviousStep.room_surface = item.surface_area;

            if (item.roomHeating === YesOrNo.No) {
                contextFromPreviousStep.room_heating_type = I18n.get('RoomItemDialog_RoomHeatingTypeList_PasDeChauffage');
            } else if (item.roomHeating === YesOrNo.Yes) {
                contextFromPreviousStep.room_heating_type = item.roomHeatingType;
            }
        }
        if (item.type === RoomType.Wall) {
            contextFromPreviousStep.wall_type = item.roomType;
            contextFromPreviousStep.wall_floor = QuestionContextHelper.transformIntoLongFloorName(item.roomFloor);
            contextFromPreviousStep.room_wall = item.roomWall;
            contextFromPreviousStep.has_wall_existing_flooring = item.roomExistingFlooring;
            contextFromPreviousStep.wall_existing_floor_type = item.roomExistingFloorType;
            contextFromPreviousStep.wall_length = item.room_length;
            contextFromPreviousStep.wall_surface = item.surface_area;
        }

        contextFromPreviousStep.contournements = {
            pipe: item.contournements?.Pipe,
            mounted_element: item.contournements?.MountedElement,
            mounted_radiator: item.contournements?.MountedRadiator,
            pillar: item.contournements?.Pillar,
            stonewall: item.contournements?.Stonewall,
            stairs: item.contournements?.Stairs,
            railing: item.contournements?.Railing,
            tree: item.contournements?.Tree,
            wall_pipe: item.contournements?.WallPipe,
            wall_pipe_hole: item.contournements?.WallPipeHole,
            electric_equipment: item.contournements?.ElectricEquipment,
            wall_pillar: item.contournements?.WallPillar,
            hotspot: item.contournements?.Hotspot,
        };
        return contextFromPreviousStep;
    };

    public static createOpeningContext = (
        opening: IOpening,
        room: IRoom,
        flooringDirection?: FlooringDirection
    ): QuestionContext => {
        const contextFromPreviousStep: QuestionContext = {};
        contextFromPreviousStep.flooring_direction = flooringDirection;
        contextFromPreviousStep.are_rooms_contiguous = Boolean(opening.contiguous_between_rooms?.length);
        contextFromPreviousStep.opening_type_localized = I18n.get(`Floorplan_Opening_${opening.type}` as StringKey);
        contextFromPreviousStep.opening_dimension_centimetre = opening.size;
        const roomContext = this.getLastStepContext(room, QuestionStep.StepFinitions);
        return QuestionContextHelper.mergeContext(roomContext, contextFromPreviousStep);
    };

    public static createRoomItemContext = (
        roomItem: IRoomItem,
        room: IRoom,
        flooringDirection?: FlooringDirection
    ): QuestionContext => {
        const contextFromPreviousStep: QuestionContext = {};
        contextFromPreviousStep.room_element_type_localized = I18n.get(
            `Floorplan_RoomElement_${roomItem.type}` as StringKey
        );
        contextFromPreviousStep.flooring_direction = flooringDirection;
        contextFromPreviousStep.should_lay_flooring_inside = roomItem.putFlooring ?? QuestionContextHelper.NOT_SET;
        contextFromPreviousStep.should_lay_plinthes_inside = roomItem.putPlinthes ?? QuestionContextHelper.NOT_SET;
        const roomContext = this.getLastStepContext(room, QuestionStep.StepFinitions);
        return QuestionContextHelper.mergeContext(roomContext, contextFromPreviousStep);
    };

    public static getLastStepContext = (item: IMapItem, step: QuestionStep): QuestionContext => {
        if (step === QuestionStep.StepFinitions) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsFinition?.questions)?.lastShownQuestion?.context ||
                this.getLastStepContext(item, QuestionStep.StepPreparation)
            );
        }
        if (step === QuestionStep.StepPreparation) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsPreparation?.questions)?.lastShownQuestion
                    ?.context || this.getLastStepContext(item, QuestionStep.StepSupport)
            );
        }
        if (step === QuestionStep.StepSupport) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsSupport?.questions)?.lastShownQuestion?.context ||
                this.getLastStepContext(item, QuestionStep.StepPose)
            );
        }
        if (step === QuestionStep.StepPose) {
            return (
                QuestionsHelper.getLastShownQuestion(item.questionsPose?.questions)?.lastShownQuestion?.context ||
                this.getLastStepContext(item, QuestionStep.Infos)
            );
        }
        return item.questionsBaseContext || {};
    };
}
