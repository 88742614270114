import { styled } from '@material-ui/styles';
import React, { CSSProperties, useEffect } from 'react';
import { Stack } from 'Web/Components/Widgets/Stack';
import { NunitoSansBoldTitle } from 'Web/Components/Widgets/Typographies';

import { useCurrentProject } from '../../../../../Configs/CurrentProjectContext';
import { Constants } from '../../../../../Constants';
import { PromotionBandeauDto } from '../../../../../Models/Promos/PromotionBandeauDto';
import { Promo } from '../../../../../Plugins/Promos/PromoBandeau';
import { PromosApi } from '../../../../../Services/Api/PromosApi';
import { BrowserUtils } from '../../../../../Utils/BrowserUtils';
import { Assets } from '../../../../assets/Assets';
import { usePlatformContext } from '../../../../Context/PlatformContext/PlatformContext';
import { AppointmentButton, onClickAppointment } from '../../CustomButton/AppointmentButton';
import { HeaderButton, HeaderIconStyle } from './HeaderButton';
import { HeaderButtonUser } from './HeaderButtonUser';
import { useQuoteProvider } from 'Web/Pages/Quote/Controllers/QuoteProviderController';
import { StoreHelper } from 'Helpers';

interface HeaderLayoutProps {
    styles: { root: CSSProperties };
    ActionButtons?: React.ComponentType;
    //* mobile
    promotionBandeau?: PromotionBandeauDto;
}

export const Header = ({ ActionButtons, styles }: HeaderLayoutProps) => {
    const { isMobile } = usePlatformContext();
    const [promotionBandeau, setPromotionBandeau] = React.useState<PromotionBandeauDto | undefined>(undefined);
    useEffect(() => {
        load();
    }, []);

    //! todo: place in AppState (useSelector)
    const load = async () => {
        const res = await PromosApi.getCurrentPromotionBandeau();
        setPromotionBandeau(res.data);
    };

    const HeaderMobile = ({ promo }: { promo: PromotionBandeauDto | undefined }) => {
        return (
            <MobileContainer>
                <HeaderMobileContainer style={styles.root}>
                    <HeaderLogo />
                    <ButtonsContainer style={{ height: '100%' }}>
                        {ActionButtons && <ActionButtons />}
                        <HeaderButtonUser />
                        <ButtonAppointment />
                    </ButtonsContainer>
                </HeaderMobileContainer>
                {promo ? <Promo promo={promo} /> : undefined}
            </MobileContainer>
        );
    };
    return isMobile ? (
        <HeaderMobile promo={promotionBandeau} />
    ) : (
        <HeaderDesktop ActionButtons={ActionButtons} styles={styles} promotionBandeau={promotionBandeau} />
    );
};

const MobileContainer = styled(Stack)({ width: '100%' });

const HeaderDesktop = ({ ActionButtons, styles, promotionBandeau }: HeaderLayoutProps) => {
    const currentProject = useCurrentProject();
    return (
        <DesktopContainer>
            <HeaderContainer style={styles.root}>
                <HeaderLogo />
                <Title>{currentProject.state?.project?.intitule}</Title>
                <ButtonsContainer style={{ gap: 43, paddingRight: 40 }}>
                    {ActionButtons && <ActionButtons />}
                    <HeaderButtonUser />
                    <ButtonAppointment />
                </ButtonsContainer>
            </HeaderContainer>
            {promotionBandeau ? <Promo promo={promotionBandeau} /> : undefined}
        </DesktopContainer>
    );
};
export const HeaderLayout = React.memo(Header);
const DesktopContainer = styled(Stack)({ width: '100%' });

const Title = styled(NunitoSansBoldTitle)({
    color: '#13123A',
    width: '100%',
    height: 'auto',
    padding: 16,
    overflow: 'hidden',
    fontWeight: 900,

    display: '-webkit-box',
    fontSize: '2.26em',
    lineClamp: 2,
    maxHeight: '2.86em',
    boxOrient: 'vertical',
    lineHeight: '1.13em',
    textOverflow: 'ellipsis',
    textAlign: 'center',
});

const ButtonsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',

    marginLeft: 'auto',
});

//#region //* STYLE MOBILE

const HeaderContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 99,
    borderBottom: 'solid 1px #EDEDF0',

    paddingLeft: 36,
    paddingRight: 8,

    backgroundColor: '#ffffff',
    position: 'relative',
});

//#endregion

const HeaderMobileContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 64,
    paddingLeft: 8,
    borderBottom: 'solid 1px #EDEDF0',
    position: 'relative',
    backgroundColor: '#ffffff',
});

const HeaderLogo = () => {
    const { isMobile } = usePlatformContext();

    const goToHome = () => BrowserUtils.openUrlInNewTab(Constants.STMAC_URL);

    return isMobile ? (
        <Assets.Logo id="Logo" style={{ height: 40 }} onClick={goToHome} />
    ) : (
        <Assets.Logo id="Logo" style={{ width: 177, cursor: 'pointer' }} onClick={goToHome} />
    );
};

const ButtonAppointment = () => {
    const { getState } = useQuoteProvider();
    const { isMobile } = usePlatformContext();
    const projetStore = StoreHelper.getProjectStore(getState());
    return isMobile ? (
        <HeaderButton
            icon={<Assets.IconCalendar style={HeaderIconStyle} />}
            style={{ backgroundColor: '#13123A' }}
            onClick={() => onClickAppointment(StoreHelper.toBookAppointmentUrl(projetStore))}
        />
    ) : (
        <AppointmentButton type="light" style={{ marginLeft: 16, minHeight: 40, width: 'max-content' }} />
    );
};
