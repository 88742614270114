import { makeStyles, styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';

import { Theme } from '@material-ui/core';
import { useIsMobile } from '../../../../../Web/Context/PlatformContext/PlatformContext';
import { useGestureContext } from '../../../Context/GestureContext';
import { useMapContextState } from '../../../Context/MapContext';
import { MapConstants, MapMode } from '../../../MapConstants';
import { RoomType } from '../../../Models/IRoom';
import { IRoomItem, RoomItemType } from '../../../Models/IRoomItem';
import { RoomMove } from '../../../Utils/Room/RoomMove';
import * as ContextMenuItem from '../MenuContext/ContextMenuItems';
import { useRoomItemGestureController } from './RoomItemGestureController';
import * as IconFactory from './RoomItemIconFactory';
import {
    RoomItemDefaultColor,
    RoomItemInvalidColor,
    RoomItemSelectedColor,
    RoomItemSelectedInvalidColor,
    RoomItemStrokeColor,
} from './RoomItemIconFactory';
import { YesNoChoiceDialog } from '../../../../../Web/Pages/Projects/Components/ChoiceDialog/YesNoChoiceDialog';
import { formatPoint } from '../../Laizes/Dialog/Widgets/MapUIWalls';

type PlacingRoomItemProps = { item: IRoomItem; readonly?: boolean };
export const PlacingRoomItem = ({ item, readonly }: PlacingRoomItemProps) => {
    const gestureContext = useGestureContext();
    const position = item.graphTranslation!;
    const containerPosition = `translate(${position.x},${position.y})`;
    const { selectedRoomItemId, rooms = [] } = useMapContextState();
    const type = rooms.find((r) => r.roomId === item.roomId)?.type!;
    const selected = selectedRoomItemId === item.roomItemId;
    const { onMove, onDuplicate, onModify, onDelete, menuContext, deleteDialog, gestures } =
        useRoomItemGestureController(item);
    const isMobile = useIsMobile();
    const isValid = (gestureContext.state.invalidRoomItemIds ?? []).indexOf(item.roomItemId!) === -1;
    const classes = useStyles({
        isMoving: item.isMoving,
        selected,
        mode: MapConstants.mode,
        readonly,
        isMobile,
        isValid,
        otherItemMoving: gestureContext.state.roomItemId !== item.roomItemId,
        type,
    });

    const gesturesToUse = readonly ? {} : gestures;
    const readonlyStyle: CSSProperties = { fill: RoomItemDefaultColor[type], stroke: RoomItemStrokeColor[type] };
    return (
        <GroupMovable
            id={RoomMove.roomItemId(item.roomId!)}
            className={RoomMove.class(item.roomId!)}
            data-roomItemId={item.roomItemId}>
            <Group
                style={readonly ? readonlyStyle : {}}
                className={classes.root}
                transform={containerPosition}
                ref={menuContext.menuAnchorEl}
                {...gesturesToUse}>
                <polygon
                    points={item.coords!.map(formatPoint).join(' ')}
                    strokeWidth={3}
                    strokeDasharray="12 4"
                    data-realCoords={item.coordsReal!.map(formatPoint).join(' ')}
                />
                <g>
                    <circle r={23} fill="white" />
                    <g className="RoomItemPicto">
                        {/* Sol */}
                        {item.type === RoomItemType.ShowerBathtub && <IconFactory.ShowerBathtub />}
                        {item.type === RoomItemType.FixedFurniture && <IconFactory.FixedFurniture />}
                        {item.type === RoomItemType.Cupboard && <IconFactory.Cupboard />}
                        {item.type === RoomItemType.KitchenFurniture && <IconFactory.KitchenFurniture />}
                        {item.type === RoomItemType.Trap && <IconFactory.Trap />}
                        {item.type === RoomItemType.Fireplace && <IconFactory.Fireplace />}
                        {item.type === RoomItemType.Opening && <IconFactory.Opening />}
                        {item.type === RoomItemType.Stairs && <IconFactory.Stairs />}
                        {item.type === RoomItemType.Divider && <IconFactory.Divider />}
                        {item.type === RoomItemType.HeavyElement && <IconFactory.HeavyElement />}
                        {item.type === RoomItemType.SewerPlate && <IconFactory.SewerPlate />}
                        {/* Walls */}
                        {item.type === RoomItemType.Door && <IconFactory.Door />}
                        {item.type === RoomItemType.Window && <IconFactory.Window />}
                        {item.type === RoomItemType.UnlaidArea && <IconFactory.UnlaidArea />}
                    </g>
                    {!readonly && <IconFactory.Moving />}
                </g>
                <menuContext.PopupMenu>
                    <ContextMenuItem.Modify onClick={onModify} />
                    <ContextMenuItem.Divider />
                    <ContextMenuItem.Move onClick={onMove} />
                    <ContextMenuItem.Divider />
                    <ContextMenuItem.Duplicate onClick={onDuplicate} />
                    <ContextMenuItem.Divider />
                    <ContextMenuItem.Delete onClick={onDelete} />
                </menuContext.PopupMenu>
            </Group>
            {deleteDialog.showDelete && (
                <YesNoChoiceDialog
                    title="êtes -vous sûr ?"
                    subtitle={`Supprimer l'aménagement "${item.name}"`}
                    onClickYes={deleteDialog.onDelete}
                    onClickNo={() => deleteDialog.close('FROM_onClickNo')}
                    onClose={() => deleteDialog.close('FROM_onClose')}
                    enableCloseOutside={false}
                    noHighlight
                />
            )}
        </GroupMovable>
    );
};

const GroupMovable = styled('g')({});
const Group = styled('g')({ cursor: 'pointer', stroke: '#18335e' });

interface IStyleProps {
    isMoving?: boolean;
    selected?: boolean;
    mode?: string;
    readonly?: boolean;
    isMobile?: boolean;
    isValid?: boolean;
    otherItemMoving?: boolean;
    type?: RoomType;
}

const useStyles = makeStyles<Theme, IStyleProps>({
    root: (props: IStyleProps) => ({
        cursor: props.readonly ? 'default' : 'move',
        '& .RoomItemMoving': { display: props.isMoving ? 'inline' : 'none' },
        '& .RoomItemPicto': { display: props.isMoving ? 'none' : 'inline' },
        '&:hover':
            props.mode !== MapMode.MODE_TYPE_ROOM_ITEM_PLACING &&
            !props.readonly &&
            !props.isMobile &&
            !props.otherItemMoving
                ? {
                      '& .RoomItemMoving': { display: 'inline' },
                      '& .RoomItemPicto': { display: 'none' },
                      fill: props.isValid
                          ? RoomItemSelectedColor[props.type!]
                          : RoomItemSelectedInvalidColor[props.type!],
                  }
                : {},
        fill: getColor(props.selected!, props.isMoving!, props.readonly!, props.isValid!, props.type!),
        stroke: RoomItemStrokeColor[props.type!],
    }),
});
const getColor = (selected: boolean, isMoving: boolean, readonly: boolean, isValid: boolean, type: RoomType) => {
    if (!isValid && (isMoving || selected)) return RoomItemSelectedInvalidColor[type!];
    if (!isValid) return RoomItemInvalidColor[type!];
    if (isMoving) return RoomItemSelectedColor[type!];
    if (selected) return RoomItemSelectedColor[type!];
    if (readonly) return RoomItemDefaultColor[type!];
    return RoomItemDefaultColor[type!];
};
