import { VersionApi } from '../../../../Services/Api/VersionApi';
import { VersionParametrageResponse } from '../../../../Models/VersionParametrages/VersionParametrage';
import { ApiError } from '../../../../Services/XHR/ApiError';

export class VersionLoader {

    public static loadVersion = async (version?: string) => {
        const needCurrentVersion = Boolean(version === undefined || version === 'undefined');

        if (needCurrentVersion) {
            return this.loadCurrentVersion();
        }

        try {
            const response: VersionParametrageResponse = await VersionApi.getVersion(version!);
            return { version, versionData: response.data };
        } catch (error) {
            const apiError = error as ApiError;
            if (apiError.isApiError) {
                return this.loadCurrentVersion();
            }
            throw error;
        }
    };

    public static loadCurrentVersion = async () => {
        const currentVersionResponse = await VersionApi.getCurrentVersion();
        const version = currentVersionResponse.data.id;
        const response: VersionParametrageResponse = await VersionApi.getVersion(version);
        return { version, versionData: response.data };
    }
}