import React from 'react';

import { ProduitDto } from '../../../../../Models/Questions/ProduitDto';
import { useViewState } from '../../../../../Web/Hooks/ViewState/ViewState';
import { MapContextState, useMapContext } from '../../../Context/MapContext';
import { IOpening } from '../../../Models/IOpening';
import { IRoom, RoomType } from '../../../Models/IRoom';
import { IRoomItem } from '../../../Models/IRoomItem';
import { Wall } from '../../../Utils/Types';
import { LaizeCalculatorResult, LaizeProps, ProductType } from '../Laize';
import { CalculLaizeParams, FlooringDirectionByRoomId, LaizeCalc } from '../LaizeCalc';
import { LaizeBandItemV3 } from '../v3/LaizeBandTrace';
import { LaizeV3 } from '../v3/LaizeV3';

type MapInputs = Partial<{
    walls: Wall[];
    rooms: IRoom[];
    openings: IOpening[];
    roomItems: IRoomItem[];
    laizeProps?: LaizeProps[];
}>;

type LaizeCalcDialogValue = {
    laizeResult?: LaizeCalculatorResult;

    state: LaizeCalcDialogState;
    update: (values: LaizeCalcDialogState) => void;

    bandsFocused?: LaizeBandItemV3[];
    setBandsFocused: (bands?: LaizeBandItemV3[]) => void;

    bandMosaicFocused?: number;
    setBandMosaicFocused: (bandId?: number) => void;
};
const LaizeCalcDialogContext = React.createContext<LaizeCalcDialogValue>({
    state: toLaizeCalcDialogState({}),
    update: () => {},
    setBandsFocused: () => {},
    setBandMosaicFocused: () => {},
});
export const useLaizeCalcDialog = () => React.useContext(LaizeCalcDialogContext);
export const useLaizeCalcDialogState = () => React.useContext(LaizeCalcDialogContext).state;
export const useLaizeCalcDialogUpdate = () => React.useContext(LaizeCalcDialogContext).update;

type LaizeCalcDialogState = Partial<{
    mapInputs: MapInputs;

    showParams: boolean;

    roomType?: RoomType;
    rooms: IRoom[];
    openings: IOpening[];
    roomItems: IRoomItem[];

    produit: ProduitDto;
    productType?: ProductType;
    flooringDirectionByRoomId: FlooringDirectionByRoomId;

    //* EDITOR PARAMS
    laizeProps: LaizeProps;
    iteration: number;
    rotationAngle: number;
    bandWidth: number;
    longueurCm: number;
    raccordLength: number;
    laizeMargin: number;
    motifLength: number;
    motifWidth: number;
    laizeConsecutiveBandsMargin: number;
}>;

export const LaizeCalcDialogProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const originalMap = useMapContext().state;

    const [bandsFocused, setBandsFocused] = React.useState<LaizeBandItemV3[]>();
    const [bandMosaicFocused, setBandMosaicFocused] = React.useState<number>();
    const [laizeResult, setLaizeResult] = React.useState<LaizeCalculatorResult>();
    const { state, getState, update } = useViewState<LaizeCalcDialogState>(toLaizeCalcDialogState(originalMap));

    React.useEffect(() => {
        const { laizeProps = {}, rooms = [], openings = [], roomItems = [] } = getState();
        const laizeCalcProps = LaizeCalc.toLaizeCalcProps(laizeProps);
        laizeCalcProps.BAND_WIDTH = state.bandWidth || laizeCalcProps.BAND_WIDTH;
        laizeCalcProps.LAIZE_MARGIN = state.laizeMargin || laizeCalcProps.LAIZE_MARGIN;
        laizeCalcProps.RACCORD_SIZE = state.raccordLength || laizeCalcProps.RACCORD_SIZE;
        laizeCalcProps.MARGIN_WIDTH = state.motifWidth || laizeCalcProps.MARGIN_WIDTH;
        laizeCalcProps.MARGIN_LENGTH = state.motifLength || laizeCalcProps.MARGIN_LENGTH;
        laizeCalcProps.LAIZE_CONSECUTIVE_BANDS_MARGIN =
            state.laizeConsecutiveBandsMargin || laizeCalcProps.LAIZE_CONSECUTIVE_BANDS_MARGIN;
        laizeCalcProps.LONGUEUR_CM = state.longueurCm || laizeCalcProps.LONGUEUR_CM;
        laizeCalcProps.TYPE = state.productType || laizeCalcProps.TYPE;

        const params: CalculLaizeParams = {
            laizeCalcProps,
            rooms,
            openings,
            roomItems,
            walls: originalMap.walls || [],
            flooringDirectionByRoomId: state.flooringDirectionByRoomId,
        };

        const logName = 'from LaizeCalcDialogProvider';
        const laizeResult = LaizeV3.calculLaizeV3({
            ...params,
            iteration: state.iteration,
            logName,
            requestedBy: 'LaizeCalcDialogProvider',
        });

        const iteration = toCurrentIteration(state.iteration!, laizeResult);
        if (state.iteration !== iteration) {
            update({ iteration });
        }

        setLaizeResult(laizeResult);
    }, [state]);

    return (
        <LaizeCalcDialogContext.Provider
            value={{
                laizeResult,
                state,
                update,
                bandsFocused,
                setBandsFocused,
                bandMosaicFocused,
                setBandMosaicFocused,
            }}>
            {children}
        </LaizeCalcDialogContext.Provider>
    );
};

function toLaizeCalcDialogState(map: MapContextState): LaizeCalcDialogState {
    const { laizeProps = [], rooms = [], openings = [], roomItems = [] } = map;

    const roomType = RoomType.Sol;
    const solRooms = rooms.filter((r) => r.type === roomType);
    const laizePropsSol = laizeProps.filter((lp) =>
        lp.roomIds?.some((roomId) => solRooms.map((r) => r.roomId!).includes(roomId))
    );
    const laizePropsToUse = laizePropsSol[0];
    const roomsToUse = solRooms.filter((r) => laizePropsToUse.roomIds?.some((x) => x === r.roomId));
    const openingsToUse = openings.filter((o) => roomsToUse.some((r) => r.roomId === o.roomId));
    const roomItemsToUse = roomItems.filter((ri) => roomsToUse.some((r) => r.roomId === ri.roomId));
    const LAIZE_MARGIN = laizePropsToUse?.type === ProductType.Rouleau ? 10 : 0;
    const LAIZE_CONSECUTIVE_BANDS_MARGIN = laizePropsToUse?.type === ProductType.Rouleau ? 10 : 0;

    return {
        mapInputs: {
            laizeProps: laizeProps,
            walls: map.walls || [],
            rooms: map.rooms || [],
            openings: map.openings || [],
            roomItems: map.roomItems || [],
        },
        roomType,
        rooms: roomsToUse,
        openings: openingsToUse,
        roomItems: roomItemsToUse,

        iteration: 0,
        rotationAngle: 0,

        laizeProps: laizePropsToUse,
        bandWidth: laizePropsToUse?.largeurCm,
        laizeMargin: LAIZE_MARGIN,
        raccordLength: laizePropsToUse?.raccordCm,
        motifWidth: laizePropsToUse?.largeurMarginCm,
        motifLength: laizePropsToUse?.longueurMarginCm,
        longueurCm: laizePropsToUse?.longueurCm,
        laizeConsecutiveBandsMargin: LAIZE_CONSECUTIVE_BANDS_MARGIN,
        flooringDirectionByRoomId: LaizeCalc.getFlooringDirectionByRoomId(roomsToUse),
        produit: laizePropsToUse?.produit,
        productType: laizePropsToUse?.type || ProductType.Rouleau,
    };
}

function toCurrentIteration(stateIteration: number, laizeResult?: LaizeCalculatorResult) {
    if (laizeResult && stateIteration > laizeResult.roomsBandsDetailsContiguous.length) {
        const iteration = laizeResult.roomsBandsDetailsContiguous.length;
        return iteration;
    }
    return stateIteration;
}

export function updateRoomsFlooringDirection(
    mapRooms: IRoom[] = [],
    flooringDirectionByRoomId: FlooringDirectionByRoomId = {}
) {
    return mapRooms.map((mapRoom) => {
        const flooringDirection = flooringDirectionByRoomId[mapRoom.roomId!];
        if (Boolean(flooringDirection) && mapRoom.flooringDirection !== flooringDirection) {
            mapRoom.flooringDirection = flooringDirection;
        }
        return mapRoom;
    });
}
