import { AppBar } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import * as H from 'history';
import React, { CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import { useCurrentProject } from '../../../../Configs/CurrentProjectContext';
import { HeaderLayout } from './Header/HeaderLayout';

interface PageLayoutProps {
    handleQuitPage?: (onConfirm: () => void, onCancel: () => void) => React.ReactNode;
    ActionButtons?: React.ComponentType; //*navbar action
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children: content, handleQuitPage, ActionButtons }) => {
    return (
        <>
            <MyAppBar position="relative" elevation={0}>
                <HeaderLayout styles={{ root: HeaderStyle }} ActionButtons={ActionButtons} />
            </MyAppBar>
            <PageContent>{content}</PageContent>
            {Boolean(handleQuitPage) && <PreventExitPage handleQuitPage={handleQuitPage} />}
        </>
    );
};

const PageContent: React.FC = React.memo(
    ({ children }) => <MainContent id="PageContent">{children}</MainContent>,
    () => true
);

const MyAppBar = styled(AppBar)({});
const HeaderStyle: CSSProperties = { width: '100%' };

const MainContent = styled('main')({
    background: '#efefef',
    height: '100%',
    flex: 1,
    overflow: 'hidden',
    cursor: 'default',
});

type PreventExitPageProps = {
    handleQuitPage?: (onConfirm: VoidFunction, onCancel: VoidFunction) => void;
};
const PreventExitPage = ({ handleQuitPage }: PreventExitPageProps) => {
    const initialPathname = useLocation().pathname;
    const { getState } = useCurrentProject();
    const whenModified = (currentLocation: H.Location, nextLocation?: H.Location): boolean => {
        return (getState().isDirty ?? false) && !nextLocation?.pathname.startsWith(initialPathname);
    };

    return (
        <NavigationPrompt when={whenModified} disableNative>
            {({ onConfirm, onCancel }) => handleQuitPage?.(onConfirm, onCancel)}
        </NavigationPrompt>
    );
};
