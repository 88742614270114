import Redux from 'redux';
import { frMessages } from './fr';

export type StringsState = {
    defaultLocale: string;
    locale: string;
    supported_locales: string[];
    messages: Record<string, string>;
}

const initialStringsState: StringsState = {
    defaultLocale: 'fr',
    locale: 'fr',
    supported_locales: ['fr'],
    messages: frMessages,
};

export const I18nReducer = (state = initialStringsState, action: Redux.Action) => state;


