import MuiTableRow from '@material-ui/core/TableRow';
import { styled } from '@material-ui/styles';
import { AppRoutes } from 'Configs/AppRoutes';
import { EstimationUrlHelper } from 'Helpers';
import { LocationDescriptor } from 'history';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { QuotationSummaryDto } from '../../../../Models/Projects/ProjectDto';
import { DateUtils } from '../../../../Utils/DateUtils';
import { Assets } from '../../../assets/Assets';
import { PriceLabel } from '../../../Components/Views/PriceLabel/PriceLabel';
import { MOBILE_LIMIT, useIsMobile } from '../../../Context/PlatformContext/PlatformContext';
import { ThemeRes } from '../../../Themes/ThemeRes';
import { useVersion } from '../ProjectsUtils';

export type QuotationListProps = { items?: QuotationSummaryDto[] };
export const QuotationList = ({ items = [] }: QuotationListProps) => {
    return (
        <Table>
            <HeaderContainer>
                <tr>
                    <HeaderCell>
                        <FormattedMessage id="ProjectDetails_Version_CreationDate" />
                    </HeaderCell>
                    <HeaderCell>
                        <HeaderRoomCount />
                    </HeaderCell>
                    <HeaderCell>
                        <FormattedMessage id="ProjectDetails_version_Amount" />
                    </HeaderCell>
                </tr>
            </HeaderContainer>
            <tbody>
                {items.map((item) => (
                    <QuotationItemRow key={item.id} item={item} />
                ))}
            </tbody>
        </Table>
    );
};

const HeaderRoomCount = () => {
    const isMobile = useIsMobile();
    return isMobile ? (
        <FormattedMessage id="ProjectDetails_Version_RoomCount_Short" />
    ) : (
        <FormattedMessage id="ProjectDetails_Version_RoomCount" />
    );
};

const HeaderCell = styled('th')({
    fontFamily: ThemeRes.Fonts.fontFamilyAvenir,
    fontSize: 16,
    color: 'black',
    padding: '12px 0px 12px 16px',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    '&:first-child': {
        width: '70%',
    },
    '&:nth-child(2)': {
        width: '25%',
    },
    '&:last-child': {
        padding: 12,
    },
});
const HeaderContainer = styled('thead')({
    textAlign: 'left',
    borderBottom: '1px solid rgba(19, 19, 58, 0.15)',
    borderTop: '1px solid rgba(19, 19, 58, 0.15)',
});
const Table = styled('table')({
    maxWidth: '75%',
    borderCollapse: 'collapse',
    borderSpacing: '0 8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_900}px)`]: {
        maxWidth: '100%',
    },
});

export type QuotationItemProps = { item: QuotationSummaryDto };

export const QuotationItemRow = ({ item }: QuotationItemProps) => {
    const version = useVersion();
    const to: LocationDescriptor<unknown> = {
        pathname: AppRoutes.ESTIMATION,
        state: { configId: item.configuration_id },
        search: EstimationUrlHelper.toQueryParam({ version }).toString(),
    };
    return (
        <MuiTableRow component={(props: any) => <StyledLink {...props} to={to} />}>
            <Cell>
                <DateContainer>
                    <Assets.IconDevis />
                    {DateUtils.formatDateTime(item.created_date, <FormattedMessage id="COMMON_DATE_DEFAULT_VALUE" />)}
                </DateContainer>
            </Cell>
            <Cell>
                <RoomCountContainer>
                    <RoomCount>{item.room_count}</RoomCount>
                    <RoomLabel>
                        <FormattedMessage id="ProjectDetails_RoomCountValue" />
                    </RoomLabel>
                </RoomCountContainer>
            </Cell>
            <Cell>
                <PriceContainer>
                    <PriceLabel price={item.total_amount} variant="noComma" />
                </PriceContainer>
            </Cell>
            <Cell>
                <Assets.IconArrowRightThin style={{ width: 7, height: 14 }} />
            </Cell>
        </MuiTableRow>
    );
};
const StyledLink = styled(Link)({
    textDecoration: 'none !important',
    background: 'white',
    color: 'black',
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSansBold,
    fontSize: 14,
    alignItems: 'center',
    border: '0.5px solid #ffffff',
    borderStyle: 'inset',
    '@media (hover)': {
        '&:hover': {
            border: '1px solid #13123A',
        },
    },
});

const DateContainer = styled('div')({ display: 'flex', gap: 32, alignItems: 'center' });
const PriceContainer = styled('div')({ fontFamily: ThemeRes.Fonts.fontFamilyNunitoSansBold, fontSize: 18 });
const RoomCountContainer = styled('div')({ display: 'flex', gap: 5, alignItems: 'center' });
const RoomCount = styled('span')({ fontSize: 18 });
const RoomLabel = styled('span')({
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSans,
    fontSize: 16,
    fontWeight: 400,
});

const Cell = styled('td')({
    height: '71px',
    paddingLeft: 15,
    whiteSpace: 'nowrap',
    '&:first-child': {
        whiteSpace: 'normal',
    },
    '&:last-child': {
        textAlign: 'right',
        paddingLeft: 32,
        paddingRight: 24,
        [`@media (max-width: ${MOBILE_LIMIT.WIDTH_900}px)`]: {
            paddingLeft: 39,
            paddingRight: 17,
        },
    },
});
