import { Logger } from '../../../../Errors/Logger';
import { I18n } from '../../../../Locales/I18nService';
import { IContournements } from '../../Models/Contournements';
import { YesOrNo } from '../../Models/IMapItem';
import { IOpening, OpeningType } from '../../Models/IOpening';
import { IRoom, RoomDimensions, RoomLocalisation, RoomShape, RoomType } from '../../Models/IRoom';
import { IRoomItem, RoomItemType } from '../../Models/IRoomItem';
import { RoomCollision } from '../../Utils/SAT/SATUtils';
import { CoordPoint } from '../../Utils/Types';
import { ApiMapState, ExportMap } from './MapExportHelper';

type ExportMapV2Result = {
    rooms?: Partial<IExportRoom>[];
    openings?: Partial<IExportOpeningItem>[];
    room_elements?: Partial<IExportRoomItem>[];
    direction?: any;

    //* USELESS DATA
    overallWidth?: number;
    overallHeight?: number;
    laizes?: any;
    laizeHorizontal?: number;
    laizeVertical?: number;
    global_surface_area?: number;
    map_svg_preview_base64?: string;
    map_png_preview_base64?: string;
};
type ExportMapV2 = ExportMap & { result?: ExportMapV2Result; rooms?: IExportRoom[] };

export class MapExportV2 {
    public static fromApiJsonToState = ({ result, rooms: exportRooms = [] }: ExportMapV2): ApiMapState => {
        const rooms: IRoom[] = exportRooms.map((exportRoom: IExportRoom) => {
            const room: IRoom = this.toRoom(exportRoom);

            if (Boolean(room.roomHeatingType)) {
                room.roomHeating =
                    room.roomHeatingType === I18n.get('RoomItemDialog_RoomHeatingTypeList_PasDeChauffage')
                        ? YesOrNo.No
                        : YesOrNo.Yes;
            }

            room.openings = (exportRoom?.objects || [])
                .map((item) => {
                    const opening = this.toOpeningItem(item);
                    const openingWithRoomId = result?.openings?.find((x) => x.id === opening.openingId);
                    opening.roomId = openingWithRoomId?.room_id;
                    return opening;
                })
                .filter((o) => o.roomId === room.roomId);

            room.roomItems = (exportRoom.roomItems || [])
                .map((item) => {
                    const roomItem = this.toRoomItem(item);
                    const roomItemWithRoomId = result?.room_elements?.find((x) => x.id === roomItem.roomItemId);
                    roomItem.type = roomItemWithRoomId?.type;
                    return roomItem;
                })
                .filter((o) => o.roomId === room.roomId);

            return room;
        });
        this.updateOldFields(rooms);
        const exportMap = { state: { rooms }, direction: result?.direction };
        Logger.log('MapExportV2 : fromApiJsonToState', exportMap);
        return exportMap;
    };

    /**
     ** Replaces old labels in the saved floorplan object with new ones.
     ** This process is unfortunately necessary to keep older projects compatible with wording changes.
     **/
    private static updateOldFields(rooms: Partial<IRoom>[] = []) {
        const toUndefinedIfDefault = (value: string = '') => (value === 'default' ? undefined : value);
        rooms.forEach((room: IRoom) => {
            if (room.roomExistingFloorType === 'Revêtement plastique (vinyle, lino, dalle)') {
                room.roomExistingFloorType = 'Plastique (vinyle, lino, dalle, gazon)';
            }
            if (room.roomHeatingType === 'Je ne connais pas le type de chauffage') {
                room.roomHeatingType = 'Je ne connais pas le type de chauffage au sol';
            }
            room.roomExistingFloorType = toUndefinedIfDefault(room['roomExistingFloorType']);
        });
    }

    public static toRoom = (room: IExportRoom): IRoom => {
        if (!room.type) {
            room.type = RoomType.Sol;
        }
        return {
            type: RoomType.Sol,
            roomId: room.roomId,
            name: room.name,
            shape: room.shape,

            localisation: room.localisation,
            roomType: room.extra_props?.room_type,
            roomFloor: room.extra_props?.room_floor,
            roomHeatingType: room.extra_props?.room_heating_type,
            roomExistingFlooring: room.extra_props?.room_existing_flooring,
            roomExistingFloorType: room.extra_props?.room_existing_floor_type,

            contournements: room.contournements_numbers,

            //* COORDS
            dimensions: room.dimensions,
            center: room.center,
            coords: room.coords,

            //sides: room.sides,
            //objects: room.objects, //todo @mmc, why is this here?
            // calcul_en_laize: room.calcul_en_laize, //todo @mmc
            // calcul_en_laize_sub_room_elements: room.calcul_en_laize_sub_room_elements,//todo @mmc

            // surface_area: room.surface_area,
            // surface_area_without_room_item: room.surface_area_without_room_item,
            // perimeter: room.perimeter,
            // perimeter_plinthes: room.perimeter_plinthes,
            // raccord_length: room.raccord_length,
            // room_length: room.room_length,
            // surface_manipulee_prorata: room.surface_manipulee_prorata,
            // should_lay_plinthes_inside_room_element: room.should_lay_plinthes_inside_room_element,
        };
    };

    public static toOpeningItem = (opening: Partial<IExportOpeningItem>): IOpening => {
        return {
            openingId: opening.id,
            roomId: opening.room_id,

            type: opening.type,
            name: opening.name,
            size: opening.size! * 100,

            location_side: opening.location_side,
            location_position_from_edge: opening.location_position_from_edge,

            x: opening.x,
            y: opening.y,
            limit: opening.limit,
            angle: opening.angle,

            //* USELESS
            // contiguous_between_rooms: opening.contiguous_between_rooms, //!\ warning on load

            // hinge: opening.hinge,
            // scale: opening.scale,
            // thick: opening.thick,
            // value: opening.value,
            // position: opening.position,
            // angleSign: opening.angleSign,
            // width: opening.width,
            // height: opening.height,
        };
    };

    public static toRoomItem = (roomItem: Partial<IExportRoomItem>): IRoomItem => {
        if (roomItem.dimensId === undefined) {
            roomItem.dimensId = 0;
        }
        return {
            type: roomItem.type,
            roomItemId: roomItem.id,
            roomId: roomItem.roomId,
            name: roomItem.name,

            putFlooring: roomItem.putFlooring,
            putPlinthes: roomItem.putPlinthes,
            isMoveableOutside: roomItem.isMoveableOutside,

            width: roomItem.width,
            height: roomItem.height,
            coords: roomItem.coords,
            coordsReal: roomItem.coordsReal,
            graphTranslation: roomItem.graphTranslation,

            //collisions: roomItem.collisions,
            // position: roomItem.position,
            // surface: roomItem.surface,
            // horizontal_size: roomItem.horizontal_size,
            // vertical_size: roomItem.vertical_size,
            // room_elements_length: roomItem.room_elements_length,
        };
    };
}

//#region //* EXPORT MODELS
interface IExportRoom {
    roomId?: string;
    name?: string;
    shape?: RoomShape;
    localisation?: RoomLocalisation;
    type?: RoomType;
    contournements_numbers?: IContournements;
    extra_props?: {
        //provenant d'une question/réponse en dur (#20009)
        room_type?: string;
        room_existing_flooring?: string;
        room_existing_floor_type?: string;
        room_floor?: string;
        room_heating_type?: string;
    };

    //* injected by MapResult -> calculate
    raccord_length?: number;
    room_length?: number;
    surface_area?: number;
    surface_area_without_room_item?: number;
    surface_manipulee_prorata?: number;
    perimeter?: number;
    perimeter_plinthes?: number;
    should_lay_plinthes_inside_room_element?: boolean;

    center?: CoordPoint;
    dimensions?: RoomDimensions;

    sides?: Map<string, Array<CoordPoint>>;
    coords?: Array<CoordPoint>;

    objects?: IExportOpeningItem[];
    roomItems?: IExportRoomItem[];
}

interface IExportOpeningItem {
    id: string;
    room_id: string;
    type: OpeningType;
    name: string;
    size: number;
    location_side?: string;
    location_position_from_edge?: number;

    //* OpeningProperties
    door_properties: { door_type?: string; unhingeable_opening?: string }; //!\ unhingeableOpening: Oui ou Non
    contiguous_between_rooms?: Array<string>;

    x?: number;
    y?: number;
    limit?: Array<CoordPoint>;
    angle?: number;

    // position?: CoordPoint;
    // customSize?: boolean;
    // family?: string; //like inWall, stick, collision, free
    // class?: string; // door, window, energy, stair, measure, text ?
    // classe?: string, // door, window, energy, stair, measure, text ?

    //hinge?: string;
    //thick?: number;
    //value?: number;

    // scale?: CoordPoint;
    // angleSign?: number;
    // width?: string;
    // height?: string;
}

interface IExportRoomItem {
    id: string;
    roomId: string;
    type: RoomItemType;
    name: string;

    putFlooring?: YesOrNo;
    putPlinthes?: string;
    isMoveableOutside?: boolean; // #20350
    dimensId?: number;
    width?: number;
    height?: number;

    coords?: Array<CoordPoint>;
    coordsReal?: Array<CoordPoint>;
    graphTranslation?: CoordPoint;

    collisions?: Array<RoomCollision>;
    angle?: number;

    //injected in context
    // surface?: number;
    // horizontal_size?: number;
    // vertical_size?: number;
    // room_elements_length?: number;
    // position?: CoordPoint;
}

//#endregion
