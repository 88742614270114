import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';
import { FormattedNumber } from 'react-intl';
import { Stack } from 'Web/Components/Widgets/Stack';
import { GlendaleTitle, NunitoSansBoldTitle } from 'Web/Components/Widgets/Typographies';
import { MOBILE_LIMIT } from 'Web/Context/PlatformContext/PlatformContext';
import { useIsBoUser } from 'Web/Hooks/useIsBoUser';
import { useIsVendeur } from 'Web/Hooks/Vendeur/useVendeur';

import { I18n, Message } from '../../../../Locales/I18nService';
import { CreatedByTypeDto, ProjectDto } from '../../../../Models/Projects/ProjectDto';
import { DateUtils } from '../../../../Utils/DateUtils';
import { Assets } from '../../../assets/Assets';
import { CustomLoader } from '../../../Components/Templates/CustomLoader';
import { PageLayout } from '../../../Components/Views/PageLayout/PageLayout';
import { ThemeRes } from '../../../Themes/ThemeRes';
import { QuotationList } from '../Components/QuotationList';
import { ButtonBack } from './components/ButtonBack';
import { ButtonDeleteProject } from './components/ButtonDeleteProject';
import { ButtonExportProject } from './components/ButtonExportProject';
import { ButtonResume } from './components/ButtonResume';
import { ProjectDetailsViewState, useProjectDetailsPageController } from './ProjectDetailsPageController';

export const ProjectDetailsPage = () => {
    return (
        <PageLayout>
            <ProjectDetails />
        </PageLayout>
    );
};

const ProjectDetails = () => {
    const { state, events } = useProjectDetailsPageController();
    return (
        <Container>
            <Header>
                <HeaderTitleContainer>
                    <ButtonBack />
                    <HeaderTitle>
                        <Message id="ProjectDetails_ProjectSummary" />
                    </HeaderTitle>
                </HeaderTitleContainer>
                <ButtonResume project={state.project} disabled={state.loading} />
            </Header>
            {state.loading ? (
                <CustomLoader visible={true} darkColor styles={{ loader: LoaderStyle }} />
            ) : (
                <Content state={state} {...events} />
            )}
        </Container>
    );
};

type BodyProps = {
    state: ProjectDetailsViewState;
    onEditIntitule: VoidFunction;
    onEditDescription: VoidFunction;
    onEditCustomerId: VoidFunction;
};
const Content = ({ state, onEditIntitule, onEditDescription, onEditCustomerId }: BodyProps) => {
    return (
        <>
            <SummaryContainer>
                <LeftColumn>
                    <SummaryInfoLabel>
                        <Message id="ProjectDetails_Area" />
                        <SummaryInfoValue>
                            <Message
                                id="ProjectDetails_AreaValue"
                                values={{
                                    area: (
                                        <FormattedNumber
                                            value={state.project?.total_surface || 0}
                                            maximumFractionDigits={2}
                                        />
                                    ),
                                }}
                            />
                        </SummaryInfoValue>
                    </SummaryInfoLabel>
                    <SummaryInfoLabel>
                        <Message id="ProjectDetails_OpeningCount" />
                        <SummaryInfoValue>{state.project?.opening_count}</SummaryInfoValue>
                    </SummaryInfoLabel>
                    <SummaryInfoLabel>
                        <Message id="ProjectDetails_RoomCount" />
                        <SummaryInfoValue>{state.project?.room_count}</SummaryInfoValue>
                    </SummaryInfoLabel>
                    <SummaryInfoLabel>
                        <Message id="ProjectDetails_LastSave" />
                        <SummaryInfoValue>
                            <Message
                                id="Projects_CreatedByDate"
                                values={{
                                    date: DateUtils.formatDateTime(
                                        state.project?.last_saved_date,
                                        <Message id="COMMON_DATE_DEFAULT_VALUE" />
                                    ),
                                }}
                            />
                        </SummaryInfoValue>
                    </SummaryInfoLabel>
                    <SummaryInfoLabel>
                        <Message id="ProjectDetails_CreatedBy" />
                        <SummaryInfoValue>
                            <CreatedBy project={state.project!} />
                        </SummaryInfoValue>
                    </SummaryInfoLabel>
                    <AuthorIdInput
                        customerId={state.customerId}
                        isAllowedToUpdateMetaData={state.isAllowedToUpdateMetaData}
                        onEditCustomerId={onEditCustomerId}
                    />
                </LeftColumn>
                <RightColumn>
                    <InputContainer>
                        <InputLabelContainer>
                            <Title>
                                <Message id="ProjectDetails_Intitule" />
                            </Title>
                            {state.isAllowedToUpdateMetaData ? (
                                <IconButton onClick={onEditIntitule}>
                                    <Assets.IconEditPen />
                                </IconButton>
                            ) : undefined}
                        </InputLabelContainer>
                        <InputValue style={InputTitleStyle} onClick={onEditIntitule}>
                            {state.project?.intitule}
                        </InputValue>
                    </InputContainer>
                    <InputContainer>
                        <InputLabelContainer>
                            <Title>
                                <Message id="ProjectDetails_Description" />
                            </Title>
                            {state.isAllowedToUpdateMetaData ? (
                                <IconButton onClick={onEditDescription}>
                                    <Assets.IconEditPen />
                                </IconButton>
                            ) : undefined}
                        </InputLabelContainer>
                        <InputValue
                            style={{
                                ...DescriptionStyle,
                                color: !!state.project?.description ? 'black' : '#979797',
                            }}
                            onClick={onEditDescription}>
                            {state.project?.description ?? I18n.get('ProjectDetails_Description_Placeholder')}
                        </InputValue>
                    </InputContainer>
                </RightColumn>
            </SummaryContainer>
            {Boolean(state.project?.quotation_list?.length) && (
                <>
                    <HistoryTitle>
                        <Message id="ProjectDetails_HistoryList" />
                    </HistoryTitle>
                    <QuotationList items={state.project?.quotation_list} />
                </>
            )}
            <Separator />
            <DeleteButtonContainer>
                <ButtonExportProject projectId={state.project?.id!} disabled={state.saving || state.loading} />
                <ButtonDeleteProject projectId={state.project?.id!} disabled={state.saving || state.loading} />
            </DeleteButtonContainer>
        </>
    );
};

const DeleteButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 24,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_900}px)`]: { justifyContent: 'center' },
});
const HistoryTitle = styled(GlendaleTitle)({
    fontSize: 24,
    color: '#464646',
    marginBottom: 24,
    textAlign: 'center',
    paddingRight: 16,
    paddingLeft: 16,
});
const LoaderStyle: CSSProperties = {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    top: 52,
};
const LeftColumn = styled(Stack)({
    width: '30%',
    paddingTop: 10,
    gap: 36,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_900}px)`]: {
        gap: 24,
        width: '100%',
        marginBottom: 12,
    },
});
const RightColumn = styled(Stack)({
    minWidth: 328,
    flex: 1,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_900}px)`]: { gap: 12 },
});
const Container = styled('div')({
    color: '#464646',
    backgroundColor: '#fff',
    paddingBottom: '143px',
    paddingLeft: '62px',
    paddingRight: '62px',
    height: '100%',
    width: '100%',
    overflowY: 'auto',

    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_900}px)`]: {
        paddingBottom: '24px',
        paddingLeft: 0,
        paddingRight: 0,
    },
});

const Header = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginTop: 42,
    marginBottom: 15,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_900}px)`]: { flexDirection: 'column' },
});

const HeaderTitleContainer = styled('div')({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_900}px)`]: { marginBottom: 16 },
});

const HeaderTitle = styled(GlendaleTitle)({ fontSize: 24, color: '#464646', lineHeight: '44px', marginLeft: 7 });

const SummaryContainer = styled('div')({
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    gap: 41,
    paddingLeft: 48,
    marginBottom: 72,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_900}px)`]: {
        flexDirection: 'column',
        paddingRight: 16,
        paddingLeft: 16,
        gap: 0,
    },
});

const SummaryInfoLabel = styled(NunitoSansBoldTitle)({
    display: 'flex',
    fontSize: 14,
    color: '#464646',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
});
const SummaryInfoValue = styled('span')({
    marginLeft: 6,
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSans,
    fontWeight: 400,
    fontSize: 16,
    color: '#464646',
    whiteSpace: 'initial',
});

const InputContainer = styled('div')({
    marginBottom: 24,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_900}px)`]: { marginBottom: 0 },
});
const InputLabelContainer = styled('div')({ display: 'flex', alignItems: 'center' });
const InputValue = styled('div')({
    background: '#fff',
    border: '1px solid #13123A',
    padding: '10px 16px 10px 16px',
    height: 42,
    minWidth: 328,
    color: 'black',
    fontSize: 16,
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSans,
    [`@media (max-width: ${MOBILE_LIMIT.WIDTH_900}px)`]: {
        width: '100%',
        maxWidth: '100% !important',
    },
});
const InputStyle: CSSProperties = { cursor: 'pointer' };
const InputTitleStyle: CSSProperties = {
    ...InputStyle,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 328,
};
const DescriptionStyle: CSSProperties = {
    ...InputStyle,
    height: 132,
    maxWidth: 677,
    width: '100%',
    background: '#EFEFEF',
    border: 'unset',
    overflowY: 'auto',
};

const Title = styled(NunitoSansBoldTitle)({ fontSize: 14 });

const Separator = styled('div')({
    background: 'rgba(19, 19, 58, 0.15)',
    width: '100%',
    height: '2px',
    marginTop: '32px',
    marginBottom: '25px',
});

//#region //* NESTED COMPONENTS

type CreatedByProps = { project: ProjectDto };
const CreatedBy = ({ project }: CreatedByProps) => {
    let createdByLabel = '';
    const { created_by_type, created_by_customer, created_by_store } = project || {};
    if (created_by_type === CreatedByTypeDto.Customer && created_by_customer) {
        createdByLabel = I18n.format('COMMON_USER_FULLNAME', {
            firstName: created_by_customer.user.first_name ?? '',
            lastName: created_by_customer.user.last_name ?? '',
        });
    } else if (created_by_type === CreatedByTypeDto.Store && created_by_store) {
        createdByLabel = created_by_store.store.id;
    }
    return <>{createdByLabel}</>;
};

type AuthorIdInputProps = {
    customerId?: string;
    isAllowedToUpdateMetaData?: boolean;
    onEditCustomerId: VoidFunction;
};

const AuthorIdInput = ({ customerId = '', isAllowedToUpdateMetaData, onEditCustomerId }: AuthorIdInputProps) => {
    const isBoUser = useIsBoUser();
    const isVendeur = useIsVendeur();
    return !(isBoUser || isVendeur) ? null : (
        <InputContainer style={{ marginTop: -10 }}>
            <InputLabelContainer>
                <Title>
                    <Message id="ProjectDetails_CustomerId" values={{ authorType: customerId }} />
                </Title>
                {isAllowedToUpdateMetaData && (
                    <IconButton onClick={onEditCustomerId}>
                        <Assets.IconEditPen />
                    </IconButton>
                )}
            </InputLabelContainer>
            <InputValue style={InputTitleStyle} onClick={onEditCustomerId}>
                {customerId}
            </InputValue>
        </InputContainer>
    );
};

//#endregion
