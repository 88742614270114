import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import { styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import { Stack } from 'Web/Components/Widgets/Stack';

export enum InputType {
    Text = 'text',
    TextEmail = 'email',
    Password = 'password',
    Number = 'number',
}

export interface CustomTextFieldProps {
    label?: React.ReactNode;
    placeholder?: string;
    errorMessage?: string;
    error?: boolean;
    type?: InputType;
    value?: string;
    onChange?: (value: string) => void;
    multiline?: boolean;
    maxLength?: number;
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    disabled?: boolean;
    autoFocus?: boolean;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;

    styles?: {
        root?: CSSProperties;
        label?: CSSProperties;
        input?: CSSProperties;
    };
}

export const CustomTextField = ({ value, onChange, ...props }: CustomTextFieldProps) => {
    const [localValue, setLocalValue] = React.useState(value);

    React.useEffect(() => {
        if (localValue !== value) {
            setLocalValue(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onInternalChange = (newValue: string) => {
        setLocalValue(newValue);
        onChange?.(newValue);
    };

    return <StateLessCustomTextField {...props} value={localValue} onChange={onInternalChange} />;
};

// PGU: CustomTextFieldProps ne devrait pas contenir de state local...
// J'ajoute ce composant pour éviter des effets de bords en corrigant CustomTextField.
export const StateLessCustomTextField = ({
    label,
    value,
    placeholder = '',
    errorMessage,
    error: hasError,
    type = InputType.Text,
    multiline,
    maxLength,
    onChange,
    onBlur,
    onClick,
    disabled,
    autoFocus,
    endAdornment,
    startAdornment,
    styles,
    ...rest
}: CustomTextFieldProps) => {
    const intl = useIntl();

    if (!value && (hasError || errorMessage)) {
        const requiredMessage: string = intl.formatMessage({ id: 'COMMON_REQUIRED_FIELD' });
        placeholder = requiredMessage;
    }

    const error = hasError || errorMessage;

    return (
        <Container style={styles?.root}>
            <Content>
                {label && (
                    <Label
                        style={{
                            ...withLabelTextStyle(Boolean(error)),
                            ...styles?.label,
                        }}>
                        {label}
                    </Label>
                )}
                <Input
                    {...rest}
                    style={{
                        ...withInputStyle(Boolean(error)),
                        ...styles?.input,
                    }}
                    placeholder={placeholder}
                    value={value}
                    onChange={(evt) => onChange?.(evt.target.value)}
                    onBlur={onBlur}
                    onClick={onClick}
                    type={type}
                    multiline={multiline}
                    inputProps={{ maxLength }}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    startAdornment={startAdornment}
                    endAdornment={endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
                />
            </Content>
            {error && <Error>{errorMessage}</Error>}
        </Container>
    );
};

const Container = styled(Stack)({ alignItems: 'flex-start' });

const Content = styled(FormControl)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
});
const Label = styled('div')({
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '16px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#303d4d', //$textFieldLabelTextColor;$darkGreyBlue: #303d4d;
});

// $textFieldErrorColor;$lipstick: #d62341;
const withLabelTextStyle = (withError: boolean) => ({ color: withError ? '#d62341' : '#303d4d' });

const Input = styled(InputBase)({
    width: '100%',
    minHeight: '52px',
    padding: '15px 24px 16px 24px', //TRBL
    background: '#ebeef0',
});
const withInputStyle = (withError: boolean): CSSProperties => ({
    color: withError ? '#d6234' : '#46464',
    border: '1px solid #d6234',
});

const Error = styled('div')({ color: '#d62341', marginTop: '4px' });

const EndAdornment = styled('div')({ paddingRight: '8px' });

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }

// /* Firefox */
// input[type='number'] {
//     -moz-appearance: textfield;
// }
