import { CreateCSSProperties, makeStyles } from '@material-ui/styles';

export class ThemeRes {

    //* https://fonts.google.com/specimen/Nunito+Sans
    public static Fonts = {
        fontFamily: "'NunitoSans', sans-serif",
        fontFamilyNunitoSans: "'NunitoSans', sans-serif",                    //!\\ Font Weight = 400 = Regular
        fontFamilyNunitoSansSemiBold: "'NunitoSans-SemiBold', sans-serif",   //!\\ Font Weight = 600
        fontFamilyNunitoSansBold: "'NunitoSans-Bold', sans-serif",           //!\\ Font Weight = 700
        fontFamilyNunitoSansExtraBold: "'NunitoSans-ExtraBold', sans-serif", //!\\ Font Weight = 800

        fontFamilyGlendale: "'Glendale', sans-serif",

        fontFamilyAvenir: "'Avenir', sans-serif",
        fontFamilyRoboto: "'Roboto', sans-serif",
        fontFamilyBelyDisplay: "'BelyDisplay', sans-serif",
    };

    public static Dimens = {
        spacing: '16px',
        spacingSmall: '4px',
        spacingMedium: '10px',
        spacingLarge: '20px',

        fontSize: '16px',
        fontSizeMedium: '16px',

        headerHeight: '78px',
        navbarWidth: '86px',
        navbarMenuItemHeight: '86px',
    };

    public static LinkButtonStyle: CreateCSSProperties = { 'text-decoration': 'none' };

    public static createNumberStyles = makeStyles({
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield',
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
        },
    });
}
