import { Button, Dialog, Divider, IconButton, Popover, StandardTextFieldProps, Tooltip } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { styled } from '@material-ui/styles';
import React, { CSSProperties, HTMLAttributes, useEffect } from 'react';

import { useVisibility } from '../../../../../Hooks/Visibility';
import { ProduitDto } from '../../../../../Models/Questions/ProduitDto';
import { ButtonSave } from '../../../../../Web/Components/Views/CustomButton/ButtonsFactory';
import { DebugDialog } from '../../../../../Web/Context/Debug/DebugDialog';
import { useViewState } from '../../../../../Web/Hooks/ViewState/ViewState';
import { useThrottle } from '../../../../../Web/Hooks/useDebounce';
import { EditInputDialog } from '../../../../../Web/Pages/Projects/Components/EditInputDialog';
import { Assets } from '../../../../../Web/assets/Assets';
import { useMapContext } from '../../../Context/MapContext';
import { MapHelper } from '../../../Helpers/MapHelper';
import { IOpening } from '../../../Models/IOpening';
import { IRoom } from '../../../Models/IRoom';
import { IRoomItem } from '../../../Models/IRoomItem';
import { Wall } from '../../../Utils/Types';
import { DimensInput } from '../../../Widgets/DimensInput';
import { SelectField } from '../../../Widgets/SelectField';
import { ExportMapImageHelper } from '../../Images/Helpers/ExportMapPngHelper';
import { LaizeDebugMapSvgV3 } from '../../Images/LaizeDebugMapSvgV3';
import { SvgByRoom } from '../../Images/Laizes/LaizeExportBands';
import { LaizeExportRoomBandsSvgV3 } from '../../Images/Laizes/LaizeExportRoomBandsSvgV3';
import { FlooringDirection, LaizeCalculatorResult, LaizeDirection, LaizeProps, ProductType } from '../Laize';
import { CalculLaizeParams, LaizeCalc } from '../LaizeCalc';
import { LaizeV3 } from '../v3/LaizeV3';
import { LaizeDebugTest } from './Tests/LaizeDebugTest';
import { TestCase } from './Tests/TestCases';
import { Stack } from 'Web/Components/Widgets/Stack';

type DialogDebuggerV3Props = { onClose: () => void };

type EditLaizeProps = {
    type?: ProductType;
    direction: LaizeDirection;

    bandWidth?: number;
    longueurCm?: number;
    raccordLength?: number;
    laizeMargin?: number;
    motifLength?: number;
    motifWidth?: number;
    laizeConsecutiveBandsMargin?: number;

    iteration?: number;
    rotationAngle?: number;
    roomId?: string;

    //V3
    flooringDirectionByRoomId: { [roomId: string]: FlooringDirection | undefined };
    produit?: ProduitDto;
};

const PLAN_GENERAL_KEY = 'plan_general';

type MapDebugState = {
    laizeProps: LaizeProps;
    walls: Array<Wall>;
    rooms: Array<IRoom>;
    openings: Array<IOpening>;
    roomItems: Array<IRoomItem>;

    selectedCase?: TestCase;
};

export const LaizeV3DebugDialog = ({ onClose }: DialogDebuggerV3Props) => {
    const map = useMapContext();
    const editMap = useViewState<MapDebugState>({});
    const { state: editProps, update: updateProps } = useViewState<EditLaizeProps>({
        type: ProductType.Rouleau,
        direction: LaizeDirection.None,
        iteration: 0,
        rotationAngle: 0,
    });

    React.useEffect(() => {
        const { laizeProps = [], walls, rooms, openings, roomItems } = map.getState();

        const laizePropsToUse = laizeProps[0];
        const roomsToUse = rooms?.filter((r) => laizePropsToUse?.roomIds?.some((x) => x === r.roomId)) || [];
        const openingsToUse = openings?.filter((o) => roomsToUse.some((r) => r.roomId === o.roomId)) || [];
        const roomItemsToUse = roomItems?.filter((ri) => roomsToUse.some((r) => r.roomId === ri.roomId)) || [];
        const LAIZE_MARGIN = laizePropsToUse?.type === ProductType.Rouleau ? 10 : 0;
        const LAIZE_CONSECUTIVE_BANDS_MARGIN = laizePropsToUse?.type === ProductType.Rouleau ? 10 : 0;
        updateProps({
            type: laizePropsToUse?.type || ProductType.Rouleau,
            bandWidth: laizePropsToUse?.largeurCm,
            laizeMargin: LAIZE_MARGIN,
            raccordLength: laizePropsToUse?.raccordCm,
            motifWidth: laizePropsToUse?.largeurMarginCm,
            motifLength: laizePropsToUse?.longueurMarginCm,
            longueurCm: laizePropsToUse?.longueurCm,
            laizeConsecutiveBandsMargin: LAIZE_CONSECUTIVE_BANDS_MARGIN,
            flooringDirectionByRoomId: LaizeCalc.getFlooringDirectionByRoomId(roomsToUse),
            produit: laizePropsToUse?.produit,
        });

        editMap.update({
            laizeProps: laizePropsToUse,
            walls,
            rooms: roomsToUse,
            openings: openingsToUse,
            roomItems: roomItemsToUse,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [laizeResult, setLaizeResult] = React.useState<LaizeCalculatorResult>();
    React.useEffect(() => {
        const { laizeProps = {}, walls = [], rooms = [], openings = [], roomItems = [] } = editMap.getState();
        const laizeCalcProps = LaizeCalc.toLaizeCalcProps(laizeProps);
        laizeCalcProps.BAND_WIDTH = editProps.bandWidth || laizeCalcProps.BAND_WIDTH;
        laizeCalcProps.LAIZE_MARGIN = editProps.laizeMargin || laizeCalcProps.LAIZE_MARGIN;
        laizeCalcProps.RACCORD_SIZE = editProps.raccordLength || laizeCalcProps.RACCORD_SIZE;
        laizeCalcProps.MARGIN_WIDTH = editProps.motifWidth || laizeCalcProps.MARGIN_WIDTH;
        laizeCalcProps.MARGIN_LENGTH = editProps.motifLength || laizeCalcProps.MARGIN_LENGTH;
        laizeCalcProps.LAIZE_CONSECUTIVE_BANDS_MARGIN =
            editProps.laizeConsecutiveBandsMargin || laizeCalcProps.LAIZE_CONSECUTIVE_BANDS_MARGIN;
        laizeCalcProps.LONGUEUR_CM = editProps.longueurCm || laizeCalcProps.LONGUEUR_CM;
        laizeCalcProps.TYPE = editProps.type || laizeCalcProps.TYPE;

        const params: CalculLaizeParams = {
            laizeCalcProps,
            rooms,
            openings,
            roomItems,
            walls,
            flooringDirectionByRoomId: editProps.flooringDirectionByRoomId,
        };

        const logName = 'from LaizeV3DebugDialog';
        const laizeResult = LaizeV3.calculLaizeV3({
            ...params,
            iteration: editProps.iteration,
            logName,
            requestedBy: 'LaizeV3DebugDialog',
        });
        if (laizeResult) {
            if (editProps.iteration! > laizeResult.roomsBandsDetailsContiguous.length) {
                const iteration = laizeResult.roomsBandsDetailsContiguous.length;
                updateProps({ iteration });
            }
        }
        setLaizeResult(laizeResult);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editProps]);

    const onSelectTestCase = (selectedCase: TestCase) => {
        const testCase = selectedCase.savedPlan || selectedCase;
        const importRooms = LaizeDebugTest.convertLaizeCaseToImportableRoomObj(testCase);
        const mapResults = MapHelper.importRooms({ importRooms });
        editMap.resetState({ ...mapResults, selectedCase });
        updateProps({ iteration: 0, roomId: undefined });
    };

    const saveTestCase = (name: string) => {
        const { rooms = [] } = editMap.getState();
        const roomToSave = rooms.map(LaizeDebugTest.toSaveRoom);
        const testCaseToSave: TestCase = { rooms: roomToSave };
        LaizeDebugTest.saveTestCase(testCaseToSave, name);
    };

    const onCloseTestCase = () => {
        const { walls, rooms, openings, roomItems } = map.getState();
        editMap.update({ walls, rooms, openings, roomItems, selectedCase: undefined });
        updateProps({ iteration: 0, roomId: undefined });
    };

    const reachMaxIteration =
        editProps.iteration !== 0 && editProps.iteration === laizeResult?.roomsBandsDetailsContiguous.length;
    const incrementDisabled = laizeResult?.isPossible && reachMaxIteration;
    const showResult = !laizeResult?.isPossible || incrementDisabled;
    return (
        <Dialog open PaperProps={{ style: PaperStyle }} onClose={onClose}>
            <Container id="LaizeV3DebugDialog-Content">
                <Header id="Header1" style={{ marginTop: 8 }}>
                    <ButtonClose onClick={onClose} />
                    <Title data={{ editProps, laizeResult, map: editMap.getState() }} />
                    <ButtonSaveTestCase onValidate={saveTestCase} />
                </Header>
                <SelectedTestCase testCase={editMap.state.selectedCase} onCloseTest={onCloseTestCase} />
                <Header id="Header2">
                    <HeaderSelect>
                        <SelectField
                            values={[
                                { value: PLAN_GENERAL_KEY, label: 'Plan' },
                                ...(editMap.state.rooms || []).map((r) => ({ value: r.roomId!, label: r.name })),
                            ]}
                            value={editProps.roomId || PLAN_GENERAL_KEY}
                            onChangeValue={(value) => {
                                updateProps({ roomId: value === PLAN_GENERAL_KEY ? undefined : value });
                            }}
                            styles={{ root: { height: 40, width: 200, alignSelf: 'center' } }}
                        />
                        <SelectField
                            values={
                                map.state.laizeProps?.map((x) => ({
                                    value: x.productCode!,
                                    label: x.produit?.libelle || '',
                                })) || []
                            }
                            value={editProps.produit?.code || ''}
                            onChangeValue={(value) => {
                                const laizeProps = map.state.laizeProps?.find((x) => x.productCode === value);
                                const rooms =
                                    map.state.rooms?.filter((x) => laizeProps?.roomIds?.some((r) => r === x.roomId)) ||
                                    [];
                                const openings =
                                    map.state.openings?.filter((x) => rooms.some((r) => r.roomId === x.roomId)) || [];
                                const roomItems =
                                    map.state.roomItems?.filter((x) => rooms.some((r) => r.roomId === x.roomId)) || [];
                                editMap.update({ rooms, openings, roomItems, laizeProps });
                                updateProps({
                                    produit: laizeProps?.produit,
                                    type: laizeProps?.type || ProductType.Rouleau,
                                    bandWidth: laizeProps?.largeurCm,
                                    raccordLength: laizeProps?.raccordCm,
                                    motifWidth: laizeProps?.largeurMarginCm,
                                    motifLength: laizeProps?.longueurMarginCm,
                                    longueurCm: laizeProps?.longueurCm,
                                    laizeMargin: laizeProps?.type === ProductType.Rouleau ? 10 : 0,
                                    laizeConsecutiveBandsMargin: laizeProps?.type === ProductType.Rouleau ? 10 : 0,
                                    flooringDirectionByRoomId: LaizeCalc.getFlooringDirectionByRoomId(rooms),
                                });
                            }}
                            styles={{ root: { height: 40, width: 500, alignSelf: 'center' } }}
                        />
                    </HeaderSelect>
                    <Counter
                        value={editProps.iteration}
                        onChange={(iteration) => updateProps({ iteration })}
                        incrementDisabled={incrementDisabled}
                    />
                    <ButtonOptions selectedCase={editMap.state.selectedCase} onValidate={onSelectTestCase} />
                </Header>
                <Divider id="Divider" style={{ marginTop: 12 }} />
                <Body id="Body" style={{ paddingTop: 12 }}>
                    <MapImage
                        rooms={editMap.state.rooms}
                        walls={editMap.state.walls}
                        openings={editMap.state.openings}
                        roomItems={editMap.state.roomItems}
                        roomId={editProps.roomId}
                        laizeResult={laizeResult}
                    />
                    <MenuContainer id="MenuContainer">
                        <Menu id="Menu">
                            <Field title="Type :">
                                <SelectField
                                    values={[
                                        { value: ProductType.Rouleau, label: 'Rouleau' },
                                        { value: ProductType.LamePleine, label: 'Lame pleine' },
                                    ]}
                                    value={editProps.type}
                                    onChangeValue={(type) => updateProps({ type: type as ProductType })}
                                    styles={{ root: { height: 40 } }}
                                />
                            </Field>
                            {/* <Field title="Sens de pose :">
                                <SelectField
                                    values={[
                                        { value: LaizeDirection.None, label: 'Aucun' },
                                        { value: LaizeDirection.Vertical, label: 'Vertical' },
                                        { value: LaizeDirection.Horizontal, label: 'Horizontal' },
                                    ]}
                                    value={editProps.direction}
                                    onChangeValue={(direction) => {
                                        updateProps({ direction: direction as LaizeDirection });
                                    }}
                                    styles={{ root: { height: 40 } }}
                                />
                            </Field> */}
                            <Field title="Code produit :">
                                <Tooltip title={editMap.state.laizeProps?.produit?.libelle || ''}>
                                    <ProductCode>{editMap.state.laizeProps?.productCode}</ProductCode>
                                </Tooltip>
                            </Field>
                            <Field id="laizeProps-bandWidth" title="Largeur rouleau :">
                                <Input
                                    value={editProps.bandWidth}
                                    onInputChange={(value) => updateProps({ bandWidth: parseInt(value) })}
                                />
                            </Field>
                            <Field id="laizeProps-laizeMargin" title="Marge interne lé :">
                                <Input
                                    value={editProps.laizeMargin}
                                    onInputChange={(value) => updateProps({ laizeMargin: parseInt(value) })}
                                />
                            </Field>
                            <Field id="laizeProps-raccordLength" title="Taille raccord :">
                                <Input
                                    value={editProps.raccordLength}
                                    onInputChange={(value) => updateProps({ raccordLength: parseInt(value) })}
                                />
                            </Field>
                            <Field id="laizeProps-motifWidth" title="Largeur motif :">
                                <Input
                                    a
                                    value={editProps.motifWidth}
                                    onInputChange={(value) => updateProps({ motifWidth: parseInt(value) })}
                                />
                            </Field>
                            <Field id="laizeProps-motifLength" title="Longueur motif :">
                                <Input
                                    value={editProps.motifLength}
                                    onInputChange={(value) => updateProps({ motifLength: parseInt(value) })}
                                />
                            </Field>
                            <Field id="laizeProps-longueur" title="Longueur :">
                                <Input
                                    value={editProps.longueurCm}
                                    onInputChange={(value) => updateProps({ longueurCm: parseInt(value) })}
                                />
                            </Field>
                            <Field id="laizeProps-laizeConsecutiveBandsMargin" title="Consecutive Bands Margin :">
                                <Input
                                    value={editProps.laizeConsecutiveBandsMargin}
                                    onInputChange={(value) =>
                                        updateProps({ laizeConsecutiveBandsMargin: parseInt(value) })
                                    }
                                />
                            </Field>
                            {editMap.state.rooms?.map((room) => (
                                <Field key={room.roomId} title={`Sens de pose ${room.name} :`}>
                                    <SelectField
                                        values={
                                            editProps.type === ProductType.LamePleine
                                                ? [
                                                      { value: FlooringDirection.LameVertical, label: 'Vertical' },
                                                      {
                                                          value: FlooringDirection.LameHorizontal,
                                                          label: 'Horizontal',
                                                      },
                                                      {
                                                          value: FlooringDirection.ClassiqueVertical,
                                                          label: 'Classique V.',
                                                      },
                                                      {
                                                          value: FlooringDirection.ClassiqueHorizontal,
                                                          label: 'Classique H.',
                                                      },
                                                  ]
                                                : [
                                                      { value: FlooringDirection.LaizeVertical, label: 'Vertical' },
                                                      {
                                                          value: FlooringDirection.LaizeHorizontal,
                                                          label: 'Horizontal',
                                                      },
                                                  ]
                                        }
                                        value={editProps.flooringDirectionByRoomId?.[room.roomId!]}
                                        onChangeValue={(direction) => {
                                            const flooringDirectionByRoomId = editProps.flooringDirectionByRoomId ?? {};
                                            flooringDirectionByRoomId[room.roomId!] = direction as FlooringDirection;
                                            updateProps({ flooringDirectionByRoomId });
                                        }}
                                        styles={{ root: { height: 40 } }}
                                    />
                                </Field>
                            ))}
                        </Menu>
                        {showResult && (
                            <LaizeResult>
                                {!laizeResult?.isPossible && <Impossible>Impossible</Impossible>}
                                {laizeResult?.isPossible && (
                                    <React.Fragment>
                                        <ExportLaizeBands>
                                            {editMap.state.rooms?.map((room) => (
                                                <ExportRoomLaizeBand
                                                    key={room.roomId}
                                                    room={room}
                                                    rooms={editMap.state.rooms || []}
                                                    roomItems={editMap.state.roomItems || []}
                                                    title={`Export bande ${room.name}`}
                                                    laizeResult={laizeResult}
                                                />
                                            ))}
                                        </ExportLaizeBands>
                                        <Field title="Total (m):" style={{ marginTop: 12 }}>
                                            <LaizeDebugProps>{laizeResult?.laizeResult.toFixed(2)}</LaizeDebugProps>
                                        </Field>
                                        <Field title="Longueur raccord (m) :">
                                            <LaizeDebugProps>
                                                {laizeResult?.roomsRaccordLength
                                                    .reduce((a, b) => a + b.length, 0)
                                                    .toFixed(2)}
                                            </LaizeDebugProps>
                                        </Field>
                                    </React.Fragment>
                                )}
                            </LaizeResult>
                        )}
                    </MenuContainer>
                </Body>
            </Container>
        </Dialog>
    );
};

//#region //* STYLES & COMPONENTS

const PaperStyle: CSSProperties = {
    position: 'relative',
    width: '100%',
    minWidth: 'calc(100% - 64px)',
    height: '100%',
    borderRadius: 0,
};

const Container = styled(Stack)({
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: '0px 0px 0px 16px',
});

const Title = ({ data }: { data: any }) => {
    const visibility = useVisibility();
    const Container = styled('div')({
        display: 'flex',
        justifyContent: 'center',
        fontSize: 26,
        cursor: 'pointer',
    });
    return (
        <React.Fragment>
            <Container onClick={visibility.show}>Analyse calcul en lés</Container>
            {visibility.visible && <DebugDialog title="Laize result" data={data} onClose={visibility.hide} />}
        </React.Fragment>
    );
};

const Header = styled('div')({ position: 'relative', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' });
const HeaderSelect = styled('div')({ display: 'flex', gap: 16 });
const RightButton = styled(Button)({
    borderRadius: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
    width: 200,
    fontWeight: 500,
    fontSize: 16,
});

const Body = styled('div')({ display: 'flex', width: '100%', height: '100%', flex: 1, gap: 16, overflowY: 'scroll' });
const MenuContainer = styled('div')({ width: 'max-content', marginRight: 16 });
const Menu = styled(Stack)({ gap: 10 });

const LaizeResult = styled(Stack)({
    marginTop: 20,
    border: '2px black solid',
    padding: 16,
    borderRadius: 5,
    gap: 8,
});

const Impossible = styled('div')({ color: 'red', fontSize: 16, textAlign: 'center', width: '100%' });
const LaizeDebugProps = styled('div')({ fontWeight: 500, fontSize: 16, color: 'green', textAlign: 'right', flex: 1 });

//#endregion

//#region //* ITERATION COUNTER

type CounterProps = {
    value?: number;
    onChange: (value: number) => void;
    incrementDisabled?: boolean;
};
const Counter = ({ value = 0, onChange, incrementDisabled }: CounterProps) => {
    const increment = () => onChange(value + 1);
    const decrement = () => {
        const result = value - 1;
        onChange(result >= 0 ? result : 0);
    };

    const Value = styled('div')({
        width: 50,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '22px',
        textAlign: 'center',
        color: '#000000',
    });

    return (
        <ValueContainer id="Counter">
            <IncButton onClick={decrement} id="Minus">
                <Assets.IconMinus />
            </IncButton>
            <Value id="CounterValue">{value}</Value>
            <IncButton
                onClick={increment}
                id="Plus"
                disabled={incrementDisabled}
                style={{
                    opacity: incrementDisabled ? 0.3 : 1,
                    background: incrementDisabled ? '#808080' : 'transparent',
                    cursor: incrementDisabled ? 'not-allowed' : 'pointer',
                    pointerEvents: incrementDisabled ? 'auto' : 'auto',
                }}>
                <Assets.IconPlus />
            </IncButton>
        </ValueContainer>
    );
};
const ValueContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: 50,
    border: '2px solid rgb(19, 18, 58)',
    justifySelf: 'center',
});

const IncButton = styled(Button)({ borderRadius: 0, width: 50, height: '100%' });

//#endregion

//#region //* MAP

type MapImageProps = {
    roomId?: string;
    rooms?: Array<IRoom>;
    openings?: Array<IOpening>;
    walls?: Array<Wall>;
    roomItems?: Array<IRoomItem>;
    laizeResult?: LaizeCalculatorResult;
    exportMode?: boolean;
};
const MapImage = ({
    rooms = [],
    openings = [],
    roomItems = [],
    walls = [],
    roomId,
    laizeResult,
    exportMode,
}: MapImageProps) => {
    const svgWrapper = React.useRef<SVGSVGElement>(null);
    return (
        <ImageContainer id="MapImage">
            <LaizeDebugMapSvgV3
                svgWrapper={svgWrapper}
                walls={walls}
                rooms={rooms}
                roomItems={roomItems}
                openings={openings}
                roomId={roomId}
                bands_v3={laizeResult?.bands_v3}
                style={{ display: 'flex' }}
                exportMode={exportMode}
            />
        </ImageContainer>
    );
};

const ImageContainer = styled('div')({
    position: 'relative',
    display: 'flex',
    flex: 1,
    overflow: 'scroll',
    overflowY: 'scroll', //! force show vertical  scrollbar  => not work*
    overflowX: 'scroll', //! force show horizontal scrollbar => not work
});

//#endregion

//#region //* FIELD PROPS

type FieldProps = { id?: string; title?: React.ReactNode; style?: CSSProperties };
const Field: React.FC<FieldProps> = ({ id, title, style, children }) => {
    const Title = styled('div')({
        fontWeight: 700,
        fontSize: 16,
        color: '#1D1D1F',
        textAlign: 'start',
        flex: 1,
        width: 'max-content',
    });

    return (
        <FieldContainer id={id} style={style}>
            <Title>{title}</Title>
            <FieldContent>{children}</FieldContent>
        </FieldContainer>
    );
};
const FieldContainer = styled('div')({ display: 'flex', position: 'relative', alignItems: 'center', gap: 8 });
const FieldContent = styled('div')({ display: 'flex', alignItems: 'center', width: 160 });

type InputProps = { onInputChange: (value: string) => void; a?: boolean } & StandardTextFieldProps;
const Input = ({ value, onInputChange, a = false, ...props }: InputProps) => {
    const InputStyle: CSSProperties = { marginTop: 0, height: 32, padding: '8px  12px' };
    const { liveValue, onChange } = useThrottle<string>({
        initialValue: value as string,
        onDelayChange: onInputChange,
        delay: 250,
    });

    useEffect(() => {
        if (value !== liveValue) {
            onChange(value as string);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <DimensInput
            value={liveValue}
            onChange={(e) => onChange(e.target.value)}
            style={InputStyle}
            placeholder=""
            {...props}
        />
    );
};

//#endregion

const ProductCode = styled('div')({
    fontSize: 16,
    fontWeight: 900,
    textAlign: 'center',
    width: '100%',
    color: 'green',
});

//#region //* Export Room Laize Band

const ExportLaizeBands = styled(Stack)({ width: 'max-content', gap: 2 });

type ExportRoomLaizeBandProps = {
    title: string;
    room: IRoom;
    rooms: Array<IRoom>;
    roomItems: Array<IRoomItem>;
    laizeResult: LaizeCalculatorResult;
};
const ExportRoomLaizeBand = ({ title, room, rooms, roomItems, laizeResult }: ExportRoomLaizeBandProps) => {
    const svgHolder = React.useRef<SvgByRoom>({ roomId: room.roomId! });

    const exporter = LaizeExportRoomBandsSvgV3({
        room,
        rooms,
        roomItems,
        laizeResult,
        getHolder: () => svgHolder.current,
    });
    const Container = styled(Button)({
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: '#007bff',
        borderRadius: 0,
        padding: '2px 16px 2px 0px',
    });
    return (
        <Container onClick={() => ExportMapImageHelper.openLink(room.name!, exporter.captureSvg())}>
            <DownloadIcon style={{ marginRight: 6 }} />
            {title}
            {exporter.renderView()}
        </Container>
    );
};

//#endregion

//#region //* SAVE TEST CASE

type ButtonSaveTestCaseProps = { onValidate: (name: string) => void };
const ButtonSaveTestCase = ({ onValidate }: ButtonSaveTestCaseProps) => {
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <RightButton onClick={() => setOpen(true)}>Enregistrer le cas test</RightButton>
            {open && (
                <EditInputDialog
                    open
                    title={'Cas de test'}
                    subtitle="Enregister le cas de test"
                    description=""
                    initialValue=""
                    placeholder="Saisissez ici le nom du cas de test"
                    onValidate={(input) => {
                        onValidate(input);
                        setOpen(false);
                    }}
                    onClose={() => setOpen(false)}
                />
            )}
        </React.Fragment>
    );
};

//#endregion

//#region //* OPTIONS TEST CASES

type ButtonOptionsProps = { selectedCase?: TestCase; onValidate: (testCase: TestCase) => void };
const ButtonOptions = ({ selectedCase, onValidate }: ButtonOptionsProps) => {
    const menuAnchorEl = React.useRef(null);
    const options = useVisibility();

    const testCases: Array<TestCase> = LaizeDebugTest.getTestCases();
    const [option, setOption] = React.useState<string>();

    React.useEffect(() => {
        if (!selectedCase) {
            setOption(undefined);
        } else if (option !== selectedCase.name) {
            setOption(selectedCase.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.visible]);

    const OptionContent = styled('div')({ padding: '0px 32px 32px 32px', width: 410 });
    const OptionHeader = styled('div')({
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid #454545',
        height: 64,
        fontSize: 26,
    });

    const OptionBody = styled('div')({
        marginTop: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16,
        fontSize: 16,
        fontWeight: 700,
    });

    return (
        <React.Fragment>
            <RightButton ref={menuAnchorEl} onClick={options.show}>
                Options
            </RightButton>
            <Popover
                open={options.visible}
                onClose={options.hide}
                anchorEl={menuAnchorEl.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: -15, horizontal: 'right' }}>
                <OptionContent>
                    <OptionHeader>Cas de test</OptionHeader>
                    <OptionBody>
                        <SelectField
                            value={option}
                            values={testCases.map((item) => ({ value: item.name, label: item.name }))}
                            onChangeValue={setOption}
                            styles={{ root: { flex: 1 } }}
                            placeholder="Sélectionner un cas de test"
                        />
                    </OptionBody>
                    <ButtonSave
                        onClick={() => {
                            options.hide();
                            onValidate(testCases.find((c) => c.name === option)!);
                        }}
                        disabled={!option || option === selectedCase?.name}
                        title="Valider"
                        styles={{ root: { width: '100%', marginTop: 24 } }}
                    />
                    <ButtonClose
                        onClick={options.hide}
                        style={{ width: 36, height: 36, position: 'absolute', top: 5, right: 5 }}
                    />
                </OptionContent>
            </Popover>
        </React.Fragment>
    );
};

type SelectedTestCaseProps = { testCase?: TestCase; onCloseTest: VoidFunction };
const SelectedTestCase = ({ testCase, onCloseTest }: SelectedTestCaseProps) => {
    const Container = styled('div')({
        display: 'flex',
        width: '100%',
        marginBottom: 16,
        justifyContent: 'center',
        fontSize: 18,
        alignItems: 'center',
    });
    const Name = styled('span')({ fontWeight: 800, marginLeft: 2 });

    return !testCase ? null : (
        <Container id="SelectedTestCase">
            Cas de test :<Name>{testCase.name}</Name>
            <Tooltip title="Fermer le cas de test">
                <ButtonClose onClick={onCloseTest} />
            </Tooltip>
        </Container>
    );
};

//#endregion

const ButtonClose = ({ onClick, style }: HTMLAttributes<any>) => {
    const IconClose = () => {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="black">
                <path d="M0.781413 16C0.465384 16.0001 0.180447 15.8097 0.0594998 15.5177C-0.0614474 15.2258 0.00542109 14.8897 0.228917 14.6663L14.6663 0.228852C14.9715 -0.0762838 15.4662 -0.0762838 15.7713 0.228852C16.0765 0.533987 16.0765 1.02871 15.7713 1.33384L1.33391 15.7713C1.1875 15.918 0.988675 16.0003 0.781413 16Z" />
                <path d="M15.2189 16C15.0116 16.0003 14.8128 15.918 14.6664 15.7713L0.228974 1.33384C-0.0761618 1.02871 -0.0761618 0.533987 0.228974 0.228852C0.534109 -0.0762838 1.02883 -0.0762838 1.33397 0.228852L15.7714 14.6663C15.9949 14.8897 16.0617 15.2258 15.9408 15.5177C15.8198 15.8097 15.5349 16.0001 15.2189 16Z" />
            </svg>
        );
    };
    return (
        <IconButton onClick={onClick} style={{ alignSelf: 'center', width: 50, height: 50, ...style }}>
            <IconClose />
        </IconButton>
    );
};
