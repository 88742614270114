import React from 'react';

import { Logger } from '../../../Errors/Logger';
import { ProductHelper } from '../../../Helpers/ProductHelper';
import { useWindowLog } from '../../../Hooks/WindowLog';
import { useIsMobile } from '../../../Web/Context/PlatformContext/PlatformContext';
import { useViewState } from '../../../Web/Hooks/ViewState/ViewState';
import { LaizeDebugProvider } from '../Components/Laizes/Debug/LaizeDebugProvider';
import { LaizeCalculatorResult, LaizeProps, LaizeResult } from '../Components/Laizes/Laize';
import { RoomFactory } from '../Helpers/BaseRoomFactory';
import { CompleteHelper } from '../Helpers/CompleteHelper';
import { MapResult } from '../Helpers/MapResult';
import { MapItemContants } from '../Models/IMapItem';
import { IOpening } from '../Models/IOpening';
import { IRoom, RoomType } from '../Models/IRoom';
import { IRoomItem, RoomItemWithAngle } from '../Models/IRoomItem';
import { Editor } from '../Utils/Editor';
import { OpeningUtils } from '../Utils/Opening/OpeningUtils';
import { RoomDiffUtils } from '../Utils/Room/RoomDiffUtils';
import { RoomMoveInit } from '../Utils/Room/RoomMove';
import { CreateRoomFactory } from '../Utils/Room/RoomUtils';
import { RoomItemUtils } from '../Utils/RoomItem/RoomItemUtils';
import { Wall, WallMoveInit } from '../Utils/Types';
import { WallUtils } from '../Utils/Wall/WallUtils';
import { ZoomUtils } from '../Utils/ZoomUtils';
import { EditNameProvider } from './EditNameContext';
import { GestureProvider } from './GestureContext';
import { LaizeViewProvider } from './LaizeDirectionContext';
import { MapExportProvider } from './MapExportContext';
import { UndoRedoMapProvider, useUndoRedoMap } from './UndoRedoMapContext';
import { MapKeybordEventsWrapper } from './MapKeybordEvents';
import { MapReadyProvider } from './MapReadyContext';
import { DebugLineProps, DebugPointProps } from '../../../Web/Context/Debug/Commands/DebugCommands';

//#region //* STATES

export const ClearPanelState: Partial<MapContextState> = {
    selectedRoomId: undefined,
    selectedOpeningId: undefined,
    selectedRoomItemId: undefined,
    selectAmenagementOpen: undefined,
    selectRoomShapeOpen: false,
    showTutorial: false,
    showHabitation: false,
    showFinDeParcours: false,
    showStoreLocator: false,
};
export type AmenagementOpen = { roomId?: string };
export type MapPanelsState = {
    selectedRoomId?: string;
    selectRoomShapeOpen?: boolean;
    selectAmenagementOpen?: AmenagementOpen;

    selectedOpeningId?: string;
    selectedRoomItemId?: string;

    showFinDeParcours?: boolean;
    showHabitation?: boolean;
    showStoreLocator?: boolean;
};

export type MapGestureState = {
    panZoomDisabled?: boolean;

    wallMove?: Wall;
    wallMoveInit?: WallMoveInit;

    roomItemMoveSide?: RoomItemWithAngle;

    roomMove?: string;
    roomMoveInit?: RoomMoveInit;
    openingMoveOrPlacingInit?: IOpening;
    roomItemMoveOrPlacingInit?: IRoomItem;
};

export type MapContextState = {
    walls?: Array<Wall>;
    rooms?: Array<IRoom>;
    openings?: Array<IOpening>;
    roomItems?: Array<IRoomItem>;
    roomsCompleted?: boolean;

    //* from enhance
    global_surface_area?: number;

    laizeProps?: Array<LaizeProps>;
    laizeResults?: Array<LaizeResult>;
    laizeDebugResults?: Array<LaizeCalculatorResult>;

    showTutorial?: boolean;
    addRoomTutorialShown?: boolean;
    type?: RoomType;

    busy?: boolean;
    geometryPointsDebug?: Array<DebugPointProps>;
    geometryLinesDebug?: Array<DebugLineProps>;

    logs?: any;
    //mapModified?: boolean;
} & MapPanelsState &
    MapGestureState;

//#endregion

type AddRoomsParams = { openRoomPanel?: boolean; mapModified?: boolean; logs?: any } & MapContextState;

export type UpdateMapParams = {
    values: Partial<MapContextState>;
    mapModified?: boolean;
    logs?: any;
    afterUpdate?: VoidFunction;
};

type StateCombiner = {
    merge: (state: MapContextState, mapModified?: boolean) => Partial<MapContextState>;
};
type MapContextValue = {
    state: MapContextState;
    getState: () => MapContextState;
    resetState: (state: MapContextState) => void;
    update: (params: UpdateMapParams) => void;
    setPanel: (params: UpdateMapParams) => void;

    addRooms: (params: AddRoomsParams) => void;
    withComputeLaize: (mapState: MapContextState) => Partial<MapContextState>;
    zoomToFit: () => void;

    setStateCombiner: (stateCombiner: StateCombiner) => void;
};

const MapContext = React.createContext<MapContextValue>({
    state: { panZoomDisabled: true },
    getState: () => ({}),
    resetState: () => ({}),
    update: () => {},
    addRooms: () => {},
    setPanel: () => {},
    withComputeLaize: () => ({}),
    zoomToFit: () => {},
    setStateCombiner: () => {},
});

export const useMapContext = () => React.useContext(MapContext);
export const useMapContextState = () => React.useContext(MapContext).state;
export const useUpdateMapContext = () => React.useContext(MapContext).update;

export const MapProvider: React.FC<any> = ({ children }) => {
    const isMobile = useIsMobile();
    const map = useViewState<MapContextState>({
        laizeProps: [],
        laizeResults: [],
        laizeDebugResults: [],
        type: RoomType.Sol,
    });

    useWindowLog(() => (window.MapState = map.getState));

    const stateCombiner = React.useRef<StateCombiner>();
    const setStateCombiner = (value: StateCombiner) => (stateCombiner.current = value);

    const zoomToFit = () => ZoomUtils.zoomFitAllRooms(map.getState().rooms?.filter((x) => x.type === map.state.type));

    const update = ({ values, mapModified, logs, afterUpdate }: UpdateMapParams) => {
        Logger.isLogEnabled() && console.group('MapProvider update');

        const oldState = map.getState();
        const nextState: MapContextState = { ...oldState, ...values };
        const updatedValues = mapModified && stateCombiner.current ? stateCombiner.current.merge(nextState) : {};

        const mapState = { ...nextState, ...updatedValues, logs };
        Logger.log('map will be update with', { mapState, logs });
        map.update(mapState, afterUpdate);

        Logger.isLogEnabled() && console.groupEnd();
    };

    const setPanel = ({ values, logs, ...params }: UpdateMapParams) => {
        update({
            ...params,
            values: { ...ClearPanelState, ...values },
            logs: { ...logs, setPanel: 'map updateBy setPanel' },
        });
    };

    const addRooms = ({ rooms = [], openRoomPanel, mapModified, logs, ...rest }: AddRoomsParams) => {
        const { rooms: oldRooms = [], walls: oldWalls = [], openings = [], roomItems = [] } = map.getState();

        let updatedWalls = oldWalls;
        let updatedRooms = oldRooms;
        let updatedOpenings = openings;
        let updatedRoomItems = roomItems;

        rooms.forEach((baseRoom) => {
            const roomsToUse = updatedRooms.filter((x) => x.type === map.state.type);
            const wallsToUse = roomsToUse.flatMap((x) => x.walls ?? []);
            baseRoom.name = baseRoom.name || RoomFactory.defaultName(roomsToUse.length, map.state.type);
            baseRoom.type = map.state.type;
            const { room, roomWalls = [] } = CreateRoomFactory.createRoom(baseRoom, wallsToUse);
            updatedWalls = [...updatedWalls, ...roomWalls];
            room.walls = roomWalls;

            updatedRooms = [...updatedRooms, room];
            Editor.architect(updatedWalls, updatedRooms);

            //! fix opening position from location_position_from_edge (occurs when duplicate room or import room)
            OpeningUtils.fixPositionOpenings(room, room.openings, updatedWalls);

            updatedOpenings = [...updatedOpenings, ...(room.openings || [])];
            updatedRoomItems = [...updatedRoomItems, ...(room.roomItems || [])];
            WallUtils.refreshWallsMinimumSizeAndOriginalAngle(updatedWalls, updatedOpenings, updatedRooms);
            room.openSections = { infos: openRoomPanel };
        });

        updatedRooms.forEach((room) => {
            if (CompleteHelper.isOnlyRoomQuestionsCopiedAnswerMising(room)) {
                room.questionsCopiedFromId = MapItemContants.QuestionsCopiedFromId_NONE;
            }
        });

        if (rooms.length) {
            const openRoomIdPanel = openRoomPanel ? rooms[0].roomId : undefined;
            update({
                values: {
                    walls: updatedWalls,
                    rooms: updatedRooms,
                    openings: updatedOpenings,
                    roomItems: updatedRoomItems,
                    ...ClearPanelState,
                    selectedRoomId: isMobile ? undefined : openRoomIdPanel,
                    ...rest,
                },
                mapModified,
                logs,
            });
            ZoomUtils.zoomFitAllRooms(updatedRooms.filter((x) => x.type === map.state.type));
            if (oldRooms.length === 0 && !map.state.addRoomTutorialShown && !isMobile) {
                map.update({ showTutorial: true, addRoomTutorialShown: true });
            }
        }
    };

    //#region //* COMPUTE LAIZE

    const withComputeLaize = (mapState: MapContextState): Partial<MapContextState> => {
        const { rooms = [], openings = [], roomItems = [], walls = [] } = mapState;
        if (!rooms.length) {
            return {};
        } else {
            Editor.architect(walls, rooms);
            rooms.forEach((room) => {
                OpeningUtils.updateRoomOpenings(room, openings);
                RoomItemUtils.updateRoomItems(room, roomItems);
                RoomDiffUtils.roomComputeDifference(room, roomItems, walls);
            });
            WallUtils.refreshWallsMinimumSizeAndOriginalAngle(walls, openings, rooms);

            const newLaizeProps = ProductHelper.ToArrayLaizeProps(rooms);
            const {
                rooms: updatedRooms,
                openings: updatedOpenings,
                roomItems: updatedRoomItems,
                ...rest
            } = MapResult.calculate({
                rooms,
                roomItems,
                walls,
                openings,
                laizeProps: newLaizeProps,
                logs: 'called withComputeLaize',
            });
            return {
                rooms: updatedRooms,
                openings: updatedOpenings,
                roomItems: updatedRoomItems,
                laizeProps: newLaizeProps,
                ...rest,
            };
        }
    };

    //#endregion

    //Logger.log('MapContext', { state: map.state, mode: MapConstants.mode });

    return (
        <MapReadyProvider>
            <GestureProvider>
                <LaizeViewProvider>
                    <EditNameProvider>
                        <MapContext.Provider
                            value={{
                                ...map,
                                update,
                                setStateCombiner,
                                addRooms,
                                setPanel,
                                withComputeLaize,
                                zoomToFit,
                            }}>
                            <UndoRedoMapProvider>
                                <MapKeybordEventsWrapper>
                                    <LaizeDebugProvider>
                                        <MapExportProvider>{children}</MapExportProvider>
                                    </LaizeDebugProvider>
                                </MapKeybordEventsWrapper>
                            </UndoRedoMapProvider>
                        </MapContext.Provider>
                    </EditNameProvider>
                </LaizeViewProvider>
            </GestureProvider>
        </MapReadyProvider>
    );
};

export const useMapProvider = () => {
    const map = useMapContext();
    const undoMap = useUndoRedoMap();

    const deleteRoom = (roomId: string) => {
        const mapState = map.getState();
        undoMap.store(mapState);
        const { walls = [], rooms = [], openings = [], roomItems = [], selectedRoomId } = mapState;
        map.update({
            values: {
                rooms: rooms.filter((r) => r.roomId !== roomId),
                openings: openings.filter((o) => o.roomId !== roomId),
                roomItems: roomItems.filter((ri) => ri.roomId !== roomId),
                walls: walls.filter((w) => w.roomId !== roomId).filter((w) => w.roomId),
                selectedRoomId: selectedRoomId === roomId ? undefined : selectedRoomId,
                busy: false,
            },
            mapModified: true,
            logs: { event: 'deleteRoom', roomId },
        });
    };

    const deleteOpening = (openingId: string) => {
        const mapState = map.getState();
        undoMap.store(mapState);
        const { openings = [], selectedOpeningId } = mapState;
        map.update({
            values: {
                openings: openings.filter((item) => item.openingId !== openingId),
                selectedOpeningId: selectedOpeningId === openingId ? undefined : selectedOpeningId,
                busy: false,
            },
            mapModified: true,
            logs: { event: 'deleteOpening', openingId },
        });
    };

    const deleteRoomItem = (roomItemId: string) => {
        const mapState = map.getState();
        undoMap.store(mapState);
        const { roomItems = [], selectedRoomItemId } = mapState;
        map.update({
            values: {
                roomItems: roomItems.filter((item) => item.roomItemId !== roomItemId),
                selectedRoomItemId: selectedRoomItemId === roomItemId ? undefined : selectedRoomItemId,
                busy: false,
            },
            mapModified: true,
            logs: { event: 'deleteRoomItem', roomItemId },
        });
    };

    return { ...map, deleteRoom, deleteOpening, deleteRoomItem };
};
