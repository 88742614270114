import React from 'react';

import { useMapContextState } from '../../../Context/MapContext';
import { IOpening } from '../../../Models/IOpening';
import { RoomType } from '../../../Models/IRoom';
import { PlacingOpening } from './BoxBindOpening';
import { useGestureContext } from '../../../Context/GestureContext';

type CarpentryItemsProps = { readonly?: boolean; roomId?: string; openings?: Array<IOpening>; type?: RoomType };
export const MapBoxCarpentryItems = () => {
    const { type, openings = [], rooms = [] } = useMapContextState();
    const roomIds = rooms.filter((room) => room.type === type).map((room) => room.roomId!);
    const openingsToDisplay = openings.filter((item) => roomIds.includes(item.roomId!) || !item.roomId);
    return <RawCarpentryItems openings={openingsToDisplay} />;
};

export const RawCarpentryItems = ({ openings = [], readonly, roomId, type }: CarpentryItemsProps) => {
    const gestureContext = useGestureContext();
    const { rooms = [] } = useMapContextState();
    let openingsToDisplay = openings;
    if (type) {
        const roomIds = rooms.filter((room) => room.type === type).map((room) => room.roomId!);
        openingsToDisplay = openingsToDisplay.filter((item) => roomIds.includes(item.roomId!) || !item.roomId);
    }
    return (
        <React.Fragment>
            {openingsToDisplay
                .filter((item) => {
                    return roomId
                        ? item.roomId === roomId && !gestureContext.state.invalidOpeningIds?.includes(item.openingId!)
                        : true;
                })
                .map((opening) => (
                    <CarpentryOpeningItem key={opening.openingId} item={opening} readonly={readonly} />
                ))}
        </React.Fragment>
    );
};

type CarpentryOpeningItemProps = { item: IOpening; readonly?: boolean };
const CarpentryOpeningItem = ({ item, readonly }: CarpentryOpeningItemProps) => {
    return (
        <React.Fragment>
            <PlacingOpening key={item.openingId} item={item} readonly={readonly} />
        </React.Fragment>
    );
};
