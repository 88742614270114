import { Button } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import React, { CSSProperties } from 'react';
import { MOBILE_LIMIT } from 'Web/Context/PlatformContext/PlatformContext';

export const HeaderIconStyle: CSSProperties = { width: 'auto', height: 'auto' };

type HeaderButtonProps = {
    icon?: React.ReactNode;
    label?: React.ReactNode;
    onClick?: VoidFunction;
    disabled?: boolean;
    style?: CSSProperties;
};

export const HeaderButton = ({ icon, label, onClick, disabled, style }: HeaderButtonProps) => {
    const classes = createClasses();
    return !onClick ? null : (
        <Button
            onClick={onClick}
            disabled={disabled}
            style={style}
            classes={{ root: classes.root, label: classes.content }}>
            {icon}
            {label && <Label>{label}</Label>}
        </Button>
    );
};
const Label = styled('div')({ color: '#424448', fontFamily: 'NunitoSans', fontSize: 14 });

const createClasses = makeStyles(() => ({
    root: {
        minWidth: 'max-content',
        width: 'max-content',
        //height: 64,
        minHeight: 64,
        borderRadius: 0,
        [`@media (max-width : ${MOBILE_LIMIT.WIDTH_600}px)`]: {
            minWidth: 48,
            width: 56,
            height: '100%',
            minHeight: 48,
        },
    },
    content: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
}));
