import { useCurrentProject } from '../../../../Configs/CurrentProjectContext';
import { useMe } from '../../../../Configs/PolStore';
import { Logger } from '../../../../Errors/Logger';
import { createCustomErrorHandler, wrapError } from '../../../../Errors/PolErrorHandler';
import { ConfigurationUtils, ToConfigurationReponseParameters } from '../../../../Helpers/ConfigurationUtils';
import { QuestionsHelper } from '../../../../Helpers/QuestionsHelper';
import { PrixContextDebugMap, QuotationHelper } from '../../../../Helpers/QuotationHelper';
import { RoomHeatingTypeErrorHelper } from '../../../../Helpers/RoomHeatingTypeErrorHelper';
import { SetConfigurationDto, SetConfigurationReponseDto } from '../../../../Models/Projects/Configuration';
import { ComputeQuotationsResponse, QuotationReplyBaseDto } from '../../../../Models/Quotations/Quotations';
import { TauxTvaDto } from '../../../../Models/VersionParametrages/VersionParametrage';
import { MapContextState, useMapProvider } from '../../../../Plugins/FloorPlan/Context/MapContext';
import { MapExportContextValue, useMapExport } from '../../../../Plugins/FloorPlan/Context/MapExportContext';
import { MapExportHelper } from '../../../../Plugins/FloorPlan/Helpers/MapExport/MapExportHelper';
import { IMapItem } from '../../../../Plugins/FloorPlan/Models/IMapItem';
import { IOpening } from '../../../../Plugins/FloorPlan/Models/IOpening';
import { IRoom } from '../../../../Plugins/FloorPlan/Models/IRoom';
import { IRoomItem } from '../../../../Plugins/FloorPlan/Models/IRoomItem';
import { GTM, GtmSaveType } from '../../../../Plugins/GTM/GTM';
import { QuotationsApi } from '../../../../Services/Api/QuotationsApi';
import { AuthHelper } from '../../../../Services/Authentication/AuthHelper';
import { MeState } from '../../../../Services/Me/MeReducer';
import { useQuoteContext } from '../QuotePageController';
import { QuoteState } from '../QuoteState';
import { useHabitationContextController } from './HabitationContextController';
import { useQuoteSaveController } from './QuoteSaveController';

type OnRefreshQuotationParams = { forceSave?: boolean };
type RefreshQuotationPricesParams = { nextAction?: VoidFunction; saveConfig?: () => Promise<boolean> };

export const useQuoteProvider = () => {
    const map = useMapProvider();
    const quoteContext = useQuoteContext();
    const habitation = useHabitationContextController(quoteContext.getState().stepHabitation?.questions);
    const exportImageMap = useMapExport();
    const currentProject = useCurrentProject();
    const me: MeState = useMe();

    const getTauxTva = (): TauxTvaDto => quoteContext.getState().versionData?.taux_tva!;

    const getGeneralContext = () => {
        const { stepHabitation } = quoteContext.getState();
        return QuestionsHelper.getLastShownQuestion(stepHabitation?.questions).lastShownQuestion?.context || {};
    };

    const getConfiguration = async (): Promise<SetConfigurationDto> => {
        return QuoteLoadUtils.getConfiguration({
            mapState: map.getState(),
            quoteState: quoteContext.getState()
        });
    };

    const getGetQuotationDto = async () => {
        return QuoteLoadUtils.getGetQuotationDto({
            exportImageMap,
            mapState: map.getState(),
            quoteState: quoteContext.getState()
        });
    };

    const handleQuotationError = (error: any): Promise<boolean> => {
        quoteContext.updateQuote({ loadingQuotation: false });
        if (RoomHeatingTypeErrorHelper.isErrorFromRoomHeatingTypeHelper(error)) {
            return RoomHeatingTypeErrorHelper.handleError(error);
        } else {
            //this.handleDeadEndError();
            return createCustomErrorHandler(AuthHelper.isAuthenticatedAsStoreOrBOUser())(error);
        }
    };

    const refreshQuotationPrices = ({
        nextAction,
        saveConfig,
    }: RefreshQuotationPricesParams): Promise<QuotationReplyBaseDto> => {
        if (quoteContext.getState().loadingQuotation) return Promise.resolve({} as QuotationReplyBaseDto);
        return wrapError<QuotationReplyBaseDto>(
            async () => {
                const getQuote = async (): Promise<{
                    quote: QuotationReplyBaseDto;
                    quotePrixContextDebugMap: PrixContextDebugMap;
                }> => {
                    const currentProjectState = currentProject.getState();
                    const { bodyRequest, quotePrixContextDebugMap } = await getGetQuotationDto();
                    if (currentProjectState.project) {
                        const response = await QuotationsApi.createQuotation(
                            currentProjectState.project.id,
                            bodyRequest
                        );
                        return { quote: response.data, quotePrixContextDebugMap };
                    } else {
                        const response: ComputeQuotationsResponse = await QuotationsApi.computeQuotation(bodyRequest);
                        return { quote: response.data, quotePrixContextDebugMap };
                    }
                };

                const loadQuote = async () => {
                    quoteContext.updateQuote({ loadingQuotation: true });
                    const { quote, quotePrixContextDebugMap } = await getQuote();
                    GTM.pushEstimationEvent({
                        projetId: currentProject.getState().project?.id,
                        totalPrice: quote.detail_total_price.total_price_ttc,
                        products: GTM.getGTMProducts(map.getState().rooms ?? []),
                        user_mail: me?.data?.customer_properties?.email ?? '',
                    });

                    currentProject.update({ isDirty: false });
                    quoteContext.updateQuote({ loadingQuotation: false, quote, quotePrixContextDebugMap });
                    nextAction?.();
                    return quote;
                };

                if (saveConfig) {
                    await saveConfig();
                }
                return loadQuote();
            },
            { errorHandler: handleQuotationError }
        );
    };

    return {
        loadingQuotation: quoteContext.getState().loadingQuotation,
        getTauxTva,
        habitation,
        getGeneralContext,
        getConfiguration,
        refreshQuotationPrices,
        getState: quoteContext.getState,
        updateQuote: quoteContext.updateQuote,
        update: quoteContext.update,
        openFinDeParcours: quoteContext.openFinDeParcours,
        map,
    };
};

type GetSetConfigurationDtoParams = { mapState: MapContextState; quoteState: Partial<QuoteState>; };

type GetGetQuotationDtoParams = { mapState: MapContextState; quoteState: Partial<QuoteState>; exportImageMap: MapExportContextValue };

export class QuoteLoadUtils {

    public static getConfiguration = ({ quoteState, mapState, }: GetSetConfigurationDtoParams): SetConfigurationDto => {
        const { versionId, projetStore, stepHabitation, stepServices, stepSynthese } = quoteState;
        const toConfigurationResponses = <T extends IMapItem>(
            items: Array<T> = [],
            getParams: (item: T) => Partial<ToConfigurationReponseParameters>
        ): Array<SetConfigurationReponseDto> => {
            let responses: Array<SetConfigurationReponseDto> = [];
            items.forEach((item) => {
                const {
                    questionsTva,
                    questionsPose,
                    questionsSupport,
                    questionsFinition,
                    questionsPreparation,
                } = item;
                const params = getParams(item);

                //* ROOM
                const room = item as IRoom;
                if (room.questionProduitPrincipal) {
                    responses.push(
                        ...ConfigurationUtils.toConfigurationReponses([room.questionProduitPrincipal], params)
                    );
                }
                responses.push(...ConfigurationUtils.toConfigurationReponses(questionsTva?.questions, params));
                responses.push(...ConfigurationUtils.toConfigurationReponses(questionsPose?.questions, params));
                responses.push(...ConfigurationUtils.toConfigurationReponses(questionsSupport?.questions, params));
                responses.push(...ConfigurationUtils.toConfigurationReponses(questionsPreparation?.questions, params));
                responses.push(...ConfigurationUtils.toConfigurationReponses(questionsFinition?.questions, params));
            });
            return responses;
        };
        const responseList: Array<SetConfigurationReponseDto> = [
            ...ConfigurationUtils.toConfigurationReponses(stepHabitation?.questions),
            ...toConfigurationResponses<IRoom>(mapState.rooms, (item) => ({ room_id: item.roomId })),
            ...toConfigurationResponses<IOpening>(mapState.openings, (item) => ({
                opening_id: item.openingId,
                room_id: item.roomId,
            })),
            ...toConfigurationResponses<IRoomItem>(mapState.roomItems, (item) => ({
                room_element_id: item.roomItemId,
                room_id: item.roomId,
            })),
            ...ConfigurationUtils.toConfigurationReponses(stepServices?.questions),
            ...ConfigurationUtils.toConfigurationReponses(stepSynthese?.questions)
        ];

        const planJson = MapExportHelper.toApiJson(mapState);

        Logger.isLogEnabled() && console.groupCollapsed('configuration');
        Logger.log('planJson', JSON.parse(planJson));
        Logger.log('responseList', responseList);
        const bodyRequest: SetConfigurationDto = {
            version_parametrage_id: versionId!,
            floor_plan_json: planJson,
            reponse_list: responseList,
            total_surface: mapState.global_surface_area!,
            opening_count: mapState.openings?.length ?? 0,
            room_count: mapState.rooms?.length ?? 0,
            room_element_count: mapState.roomItems?.length ?? 0,
            store_id: projetStore?.store_id!,
        };
        Logger.log('bodyRequest', bodyRequest);
        Logger.isLogEnabled() && console.groupEnd();
        return bodyRequest;
    };

    public static getGetQuotationDto = async ({ quoteState, mapState, exportImageMap }: GetGetQuotationDtoParams) => {
        const bodyRequest = await QuotationHelper.getGetQuotationDto({
            map: mapState, quoteState, exportImageMap,
        });
        return bodyRequest;
    };
}

export const useRefreshQuote = ({ nextAction }: { nextAction?: VoidFunction }) => {
    const currentProject = useCurrentProject();
    const { refreshQuotationPrices: refresh } = useQuoteProvider();
    const { requestCreateProject, updateProject } = useQuoteSaveController();

    const saveConfig = async (): Promise<boolean> => {
        return new Promise(async (resolve, reject) => {
            const currentProjectState = currentProject.getState();
            if (currentProjectState.project) {
                const { id, intitule } = currentProjectState.project;
                await updateProject({
                    projectId: id,
                    projectName: intitule,
                    withPostMessage: true,
                    saveType: GtmSaveType.DevisEstimatif,
                });
                resolve(true);
            } else {
                resolve(false);
            }
        });
    };

    const onRefreshQuotationPrices = ({ forceSave }: OnRefreshQuotationParams) => {
        const currentProjectState = currentProject.getState();
        if (currentProjectState?.project && !currentProjectState.isUpdateAllowed) {
            requestCreateProject({
                callback: () => refresh({ nextAction }),
            });
        } else {
            refresh({ nextAction, saveConfig: forceSave ? saveConfig : undefined });
        }
    };

    return { onRefreshQuotationPrices };
};
