import React, { CSSProperties } from 'react';
import { Logo } from '../Templates/Logo';
import { CustomLoader } from '../Templates/CustomLoader';
import { styled } from '@material-ui/styles';
import { Stack } from './Stack';

type LoadingViewProps = { loading?: boolean; style?: CSSProperties; darkColor?: boolean };
export const LoadingView: React.FC<LoadingViewProps> = ({ loading, style, darkColor, children }) => {
    return !loading ? null : (
        <Loading id="LoadingView" style={style}>
            <Logo />
            <CustomLoader darkColor={darkColor} styles={{ root: { marginTop: 24 } }} />
            {children}
        </Loading>
    );
};
const Loading = styled(Stack)({
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.3)',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
});

export const LoadingDescription = styled('div')({
    color: '#FFFFFF',
    fontFamily: 'Glendale',
    textTransform: 'uppercase',
    marginTop: 24,
    fontSize: 20,
    textAlign: 'center',
});
