import _ from 'lodash';

import { ObjectUtils } from '../../../../Helpers/ObjectUtils';
import { MapConstants } from '../../MapConstants';
import { IOpening } from '../../Models/IOpening';
import { IRoom, RoomShape, RoomType } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { Editor } from '../Editor';
import { isObjectsEquals } from '../Funcs';
import { PointUtils } from '../PointUtils';
import { CoordPoint, Equation, EquationOpening, LineCoordPoint, Wall, WallLine } from '../Types';
import { qSVG } from '../qSVG';
import { WallTools } from './WallTools';
import { WallUtils } from './WallUtils';

type WallMoveParams = {
    binder: Wall;
    walls: Array<Wall>;
    rooms: Array<IRoom>;
    openings: Array<IOpening>;
    equationFollowers?: Array<any>;

    equation1: Equation;
    equation2: Equation;
    equation3: Equation;
    equationsObj: Array<EquationOpening>;

    simulation?: boolean;
};

export type WallMoveResult = {
    walls: Array<Wall>;
    rooms: Array<IRoom>;
    openings: Array<IOpening>;
    wallMoveSuccess?: boolean;
};
export class WallMove {
    public static MAX_UPPER_SIMULATE_LIMIT = 3999; //in cm
    public static SIZE_INCREMENT = 20; //in cm

    //** Simulate moving a wall to get the the upper size limit */
    public static wallResizeSimulate(
        originTargetWall: Wall,
        originOpenings: Array<IOpening> = [],
        originRooms: Array<IRoom> = [],
        originRoomItems: Array<IRoomItem>,
        originWalls: Array<Wall> = []
    ) {
        const {
            originTargetWall: targetWall,
            originOpenings: openings,
            originRooms: rooms,
            originRoomItems: roomItems,
            originWalls: walls,
        } = ObjectUtils.clone({ originTargetWall, originOpenings, originRooms, originRoomItems, originWalls });
        targetWall.child = ObjectUtils.clone(originTargetWall.child);
        targetWall.parent = ObjectUtils.clone(originTargetWall.parent);

        const meter = MapConstants.meter;
        const wall = this.getResizeBindedWall(targetWall, true)!; //binding one of the siblings wall to make the resize;
        const originalWallSize = (qSVG.measure(targetWall.start, targetWall.end) * 100) / meter;

        const room = rooms.find((x) => x.roomId === targetWall.roomId)!;
        const SIZE_INCREMENT = room.type === RoomType.Sol ? 20 : 7; //in cm

        const boudingBox = PointUtils.calculateBoundingBox({ walls, roomItems })!; //optimisation pour la simulation
        switch (wall.angle) {
            case 0:
                if (boudingBox.yMin === wall.start.y) {
                    return this.MAX_UPPER_SIMULATE_LIMIT;
                }
                break;
            case Math.PI / 2:
                if (boudingBox.xMax === wall.start.x) {
                    return this.MAX_UPPER_SIMULATE_LIMIT;
                }
                break;
            case Math.PI:
                if (boudingBox.yMax === wall.start.y) {
                    return this.MAX_UPPER_SIMULATE_LIMIT;
                }
                break;
            case -Math.PI / 2:
                if (boudingBox.xMin === wall.start.x) {
                    return this.MAX_UPPER_SIMULATE_LIMIT;
                }
                break;
        }

        let oldSize, maxSize;
        oldSize = originalWallSize;

        for (let j = 0; j < this.MAX_UPPER_SIMULATE_LIMIT; j += SIZE_INCREMENT) {
            this.prepareWallMove(targetWall, wall, oldSize + SIZE_INCREMENT, openings, rooms, walls, true);
            const wallSize = WallUtils.wallSize(targetWall);
            if (oldSize === wallSize) {
                break;
            }
            oldSize = wallSize;
        }
        maxSize = oldSize;

        this.prepareWallMove(targetWall, wall, originalWallSize, openings, rooms, walls);

        return maxSize;
    }

    public static prepareWallMove = (
        targetWall: Wall,
        binder: Wall,
        newSize: number,
        openings: Array<IOpening>,
        rooms: Array<IRoom>,
        walls: Array<Wall>,
        simulation?: boolean
    ): WallMoveResult => {
        const meter = MapConstants.meter;
        let moveSize;
        if (targetWall.sideName === 'table' && binder.sideName === 'table') {
            moveSize = (newSize * meter) / 100;
        } else if (
            (targetWall.sideName === 'left_crown' || targetWall.sideName === 'right_crown') &&
            binder.sideName === 'table'
        ) {
            // const wall_right_crown = ROOM.find(x => x.roomId === targetWall.roomId).walls.find(x => x.sideName === 'right_crown');
            //  qSVG.measure(wall_right_crown.start, wall_right_crown.end);
            const wallTable = binder;
            const sideSize = qSVG.measure({ x: wallTable.start.x, y: wallTable.end.y }, wallTable.start);
            const newSideSize = sideSize - (newSize - WallUtils.wallSize(targetWall));
            const newTableSize = Math.sqrt(newSideSize * newSideSize + newSideSize * newSideSize);
            moveSize = newTableSize;
        } else {
            moveSize = (newSize * meter) / 100 - WallUtils.wallSize(targetWall); //relative new size
        }

        const newWallCoords = this.getNewWallCoordinates(binder, targetWall, moveSize, walls); //1 meter
        if (binder.sideName === 'right_crown') {
            // exception for diagonal wall (e.g. table)
            let tableEquation = Editor.createEquationFromWall(binder.parent!);
            let newRightCrownEquation = Editor.createEquationFromWall(newWallCoords);
            newWallCoords.start = qSVG.intersectionOfEquations(
                tableEquation,
                newRightCrownEquation,
                'obj'
            ) as CoordPoint;
        } else if (binder.sideName === 'left_crown') {
            let tableEquation = Editor.createEquationFromWall(binder.child!);
            let newLeftCrownEquation = Editor.createEquationFromWall(newWallCoords);
            newWallCoords.end = qSVG.intersectionOfEquations(tableEquation, newLeftCrownEquation, 'obj') as CoordPoint;
        }

        const equation1 = qSVG.createEquation(
            binder.parent!.start.x,
            binder.parent!.start.y,
            newWallCoords.start.x,
            newWallCoords.start.y
        );
        const equation2 = qSVG.createEquation(
            newWallCoords.start.x,
            newWallCoords.start.y,
            newWallCoords.end.x,
            newWallCoords.end.y
        );
        //!NOT USED
        const equation3 = qSVG.createEquation(
            newWallCoords.end.x,
            newWallCoords.end.y,
            binder.child!.end.x,
            binder.child!.end.y
        );

        const equationFollowers: Array<any> = [];

        let equationsObj: Array<EquationOpening> = [];
        const wallOpenings = Editor.objFromWall(binder, openings); // LIST Opening ON EDGE
        wallOpenings.forEach((opening) => {
            equationsObj.push({
                obj: opening,
                wall: binder,
                eq: qSVG.perpendicularEquation(equation2, opening.x!, opening.y!)!,
            });
        });
        const result = this.wallMove({
            binder,
            rooms,
            openings,
            walls,
            equationFollowers,
            equationsObj,
            equation1,
            equation2,
            equation3,
            simulation,
        });
        return result;
    };

    public static getResizeBindedWall(targetWall: Wall, simulation?: boolean, reset?: boolean) {
        switch (targetWall.roomShape) {
            case RoomShape.Rectangle:
                return targetWall.child;
            case RoomShape.Diamond_topleft:
            case RoomShape.Diamond_topright:
            case RoomShape.Diamond_bottomleft:
            case RoomShape.Diamond_bottomright:
                switch (targetWall.sideName) {
                    case 'table':
                        return this.chooseWallNotYetMoved([targetWall], simulation, reset);
                    case 'left_crown':
                    case 'left_pavilion':
                        return this.chooseWallNotYetMoved([targetWall.parent!, targetWall.child!], simulation, reset);
                    case 'right_crown':
                    case 'right_pavilion':
                        return this.chooseWallNotYetMoved([targetWall.child!, targetWall.parent!], simulation, reset);
                }
                break;

            case RoomShape.Flyingwing_topleft:
            case RoomShape.Flyingwing_topright:
            case RoomShape.Flyingwing_bottomleft:
            case RoomShape.Flyingwing_bottomright:
                switch (targetWall.sideName) {
                    case 'left_leading_edge':
                    case 'right_wingtip':
                    case 'right_trailing_edge':
                        return this.chooseWallNotYetMoved([targetWall.parent!, targetWall.child!], simulation, reset);
                    case 'right_leading_edge':
                    case 'left_trailing_edge':
                    case 'left_wingtip':
                        return this.chooseWallNotYetMoved([targetWall.child!, targetWall.parent!], simulation, reset);
                }
                break;

            case RoomShape.U_top:
            case RoomShape.U_right:
            case RoomShape.U_bottom:
            case RoomShape.U_left:
                switch (targetWall.sideName) {
                    case 'left_tip':
                    case 'interior_base':
                    case 'right_exterior_edge':
                    case 'right_interior_edge':
                        return this.chooseWallNotYetMoved([targetWall.parent!, targetWall.child!], simulation, reset);
                    case 'left_interior_edge':
                    case 'right_tip':
                    case 'exterior_base':
                    case 'left_exterior_edge':
                        return this.chooseWallNotYetMoved([targetWall.parent!, targetWall.child!], simulation, reset);
                }
                break;

            case RoomShape.Custom:
            default:
                return targetWall.child;
        }
    }

    public static chooseWallNotYetMoved(wallsSelection: Array<Wall>, simulation?: boolean, reset?: boolean) {
        // choose a wall not yet moved, if possible
        if (reset) {
            this.resetCoteManuallySetted(wallsSelection);
        }
        for (const wall of wallsSelection) {
            if (!wall.coteManuallySetted) {
                if (!simulation) {
                    wall.coteManuallySetted = true;
                }
                return wall;
            }
        }
        return wallsSelection[wallsSelection.length - 1];
    }

    public static resetCoteManuallySetted(wallsSelection: Array<Wall>) {
        for (const wall of wallsSelection) {
            wall.coteManuallySetted = false;
        }
    }

    public static getNewWallCoordinates(wall: Wall, originWall: Wall, moveSize: number, walls: Array<Wall>): WallLine {
        //in internal unit (relative) except for table side (absolute)
        if (wall.sideName === 'table') {
            const eqParent = Editor.createEquationFromWall(wall.parent!);
            const eqChild = Editor.createEquationFromWall(wall.child!);
            const projectedpoint = qSVG.intersectionOfEquations(eqParent, eqChild, 'object') as CoordPoint;

            const distanceProjectPointToWallEdges = Math.sqrt((moveSize * moveSize) / 2);

            return {
                start: {
                    x:
                        projectedpoint.x +
                        _.round(Math.cos(wall.child!.child!.angle) * distanceProjectPointToWallEdges, 5),
                    y:
                        projectedpoint.y +
                        _.round(Math.sin(wall.child!.child!.angle) * distanceProjectPointToWallEdges, 5),
                },
                end: {
                    x: projectedpoint.x + _.round(Math.cos(wall.child!.angle) * distanceProjectPointToWallEdges, 5),
                    y: projectedpoint.y + _.round(Math.sin(wall.child!.angle) * distanceProjectPointToWallEdges, 5),
                },
            };
        } else if (wall.sideName === 'interior_base') {
            const minSize = MapConstants.wallMinSize();
            const leftExteriorEdge = walls.find(
                (x) => x.roomId === wall.roomId && x.sideName === 'left_exterior_edge'
            )!;
            const parentMaxSize = WallUtils.wallSize(leftExteriorEdge) - minSize;
            const parentSize = WallUtils.wallSize(wall.parent!);
            const newParentSize = parentSize + moveSize;

            if (newParentSize > parentMaxSize) {
                moveSize = parentMaxSize - parentSize;
            }
        }

        let directionAngle =
            (wall.sideName === 'left_trailing_edge' && originWall.sideName === 'right_trailing_edge') ||
            (wall.sideName === 'right_trailing_edge' && originWall.sideName === 'left_trailing_edge')
                ? this.Add90Clockwise(wall.angle)
                : this.Add90CounterClockwise(wall.angle);
        return {
            start: {
                x: wall.start.x + _.round(Math.cos(directionAngle) * moveSize, 5),
                y: wall.start.y + _.round(Math.sin(directionAngle) * moveSize, 5),
            },
            end: {
                x: wall.end.x + _.round(Math.cos(directionAngle) * moveSize, 5),
                y: wall.end.y + _.round(Math.sin(directionAngle) * moveSize, 5),
            },
        };
    }

    public static Add90CounterClockwise(angle: number) {
        switch (angle) {
            case 0:
                return -Math.PI / 2;
            case Math.PI / 2:
                return 0;
            case Math.PI:
                return Math.PI / 2;
            case -Math.PI / 2:
                return Math.PI;
            default:
                return 0;
        }
    }

    public static Add90Clockwise = (angle: number) => {
        switch (angle) {
            case 0:
                return Math.PI / 2;
            case Math.PI / 2:
                return Math.PI;
            case Math.PI:
                return -Math.PI / 2;
            case -Math.PI / 2:
                return 0;
            default:
                return 0;
        }
    };

    public static ribWallMove(wall: Wall) {
        const binderAngle = [
            wall.parent!.angle * (180 / Math.PI),
            wall.angle * (180 / Math.PI),
            wall.child!.angle * (180 / Math.PI),
        ];

        const ribWalls = [wall.parent];
        if (binderAngle.find((x) => x !== 0 && x !== 90 && x !== 180 && x !== -90)) {
            ribWalls.push(wall);
        }
        ribWalls.push(wall.child);

        //todo: rerender wall
        //wallsCoteCreation(ribWalls);
    }

    public static checkIfRightAngled(angle: number) {
        return angle === 0 || angle === -90 || angle === 90 || angle === 180;
    }

    public static equalWithTolerance(valueA: number, valueB: number, tolerance: number) {
        return valueA > valueB - tolerance && valueA < valueB + tolerance;
    }

    public static wallLimitResize(
        targetWall: Wall,
        newSize: number,
        openings: IOpening[],
        rooms: IRoom[],
        walls: Wall[]
    ) {
        const wall = this.getResizeBindedWall(targetWall)!; //binding one of the siblings wall to make the resize;
        return this.prepareWallMove(targetWall, wall, newSize, openings, rooms, walls); // #20794 stackoverflow fix may produce not desired behaviour
    }

    // On prend la différence entre la nouvelle équation2 et l'équation2 actuelle, on execute wallMove tous les 10cm
    // Cela évite les chevauchement avec des mouvements de souris vif
    public static wallMoveSmooth(params: WallMoveParams): WallMoveResult {
        const { binder, equation1: previousEquation1, equation2, equation3: previousEquation3 } = params;
        const equation1 = Editor.createEquationFromWall(binder.parent!, previousEquation1);
        const previous_equation2 = Editor.createEquationFromWall(binder);
        const equation3 = Editor.createEquationFromWall(binder.child!, previousEquation3);

        const ten_cm = MapConstants.meter * 0.1;
        const difference = equation2.B - previous_equation2.B;
        const sectionModulo = difference % ten_cm;
        const differenceWithoutModulo = difference - sectionModulo;
        const number_of_sections = Math.abs(differenceWithoutModulo !== 0 ? differenceWithoutModulo / ten_cm : 0);
        const iterator = differenceWithoutModulo / number_of_sections;

        let moveResult: WallMoveResult = { walls: params.walls, rooms: params.rooms, openings: params.openings };
        equation2.B -= differenceWithoutModulo;
        moveResult = this.wallMove({ ...params, equation1, equation3 });
        for (let i = 0; i < number_of_sections; i++) {
            equation2.B += iterator;
            moveResult = this.wallMove({ ...params, equation1, equation3 });
            if (!moveResult.wallMoveSuccess) {
                break;
            }
        }

        return moveResult;
    }

    public static wallMove(params: WallMoveParams): WallMoveResult {
        let {
            binder,
            walls,
            rooms,
            openings,
            equationFollowers = [],
            equation1,
            equation2,
            equation3,
            equationsObj,
            simulation = false,
        } = params;
        let intersection1 = qSVG.intersectionOfEquations(equation1, equation2, 'obj') as CoordPoint;
        let intersection2 = qSVG.intersectionOfEquations(equation2, equation3, 'obj') as CoordPoint;

        if (binder.parent) {
            if (isObjectsEquals(binder.parent.end, binder.start)) {
                binder.parent.end = intersection1;
            } else if (isObjectsEquals(binder.parent.start, binder.start)) {
                binder.parent.start = intersection1;
            } else {
                binder.parent.end = intersection1;
            }
        }

        if (binder.child) {
            if (isObjectsEquals(binder.child.start, binder.end)) {
                binder.child.start = intersection2;
            } else if (isObjectsEquals(binder.child.end, binder.end)) {
                binder.child.end = intersection2;
            } else {
                binder.child.start = intersection2;
            }
        }

        binder.start = intersection1;
        binder.end = intersection2;

        // THE EQ FOLLOWED BY eq (PARENT EQ1 --- CHILD EQ3)
        if (equation1.follow) {
            if (!qSVG.rayCasting(intersection1, equation1.backUp!.coords)) {
                // IF OUT OF WALL FOLLOWED
                var distanceFromStart = qSVG.gap(equation1.backUp!.start, intersection1);
                var distanceFromEnd = qSVG.gap(equation1.backUp!.end, intersection1);
                if (distanceFromStart > distanceFromEnd) {
                    // NEAR FROM End
                    equation1.follow.end = intersection1;
                } else {
                    equation1.follow.start = intersection1;
                }
            } else {
                equation1.follow.end = equation1.backUp!.end;
                equation1.follow.start = equation1.backUp!.start;
            }
        }
        if (equation3.follow) {
            if (!qSVG.rayCasting(intersection2, equation3.backUp!.coords)) {
                // IF OUT OF WALL FOLLOWED
                const distanceFromStart = qSVG.gap(equation3.backUp!.start, intersection2);
                const distanceFromEnd = qSVG.gap(equation3.backUp!.end, intersection2);
                if (distanceFromStart > distanceFromEnd) {
                    // NEAR FROM End
                    equation3.follow.end = intersection2;
                } else {
                    equation3.follow.start = intersection2;
                }
            } else {
                equation3.follow.end = equation3.backUp!.end;
                equation3.follow.start = equation3.backUp!.start;
            }
        }

        equationFollowers.forEach((equationFollower, i) => {
            const intersectionFollower = qSVG.intersectionOfEquations(
                equationFollower.eq,
                equation2,
                'obj'
            ) as CoordPoint;
            if (
                qSVG.btwn(intersectionFollower.x, binder.start.x, binder.end.x, 'round') &&
                qSVG.btwn(intersectionFollower.y, binder.start.y, binder.end.y, 'round')
            ) {
                const size = qSVG.measure(equationFollower.wall.start, equationFollower.wall.end);
                if (equationFollower.type === 'start') {
                    equationFollower.wall.start = intersectionFollower;
                    if (size < 5) {
                        if (!equationFollower.wall.child) {
                            walls.splice(walls.indexOf(equationFollower.wall), 1);
                            equationFollowers.splice(i, 1);
                        }
                    }
                }
                if (equationFollower.type === 'end') {
                    equationFollower.wall.end = intersectionFollower;
                    if (size < 5) {
                        if (!equationFollower.wall.parent) {
                            walls.splice(walls.indexOf(equationFollower.wall), 1);
                            equationFollowers.splice(i, 1);
                        }
                    }
                }
            }
        });

        if (!simulation) {
            Editor.wallsComputing(walls, 'move');
            const wallPolygons = qSVG.polygonize(walls);
            Editor.setRoomCoordsFromWall(wallPolygons, rooms, walls);

            // OBJDATA(s) FOLLOW 90° EDGE SELECTED
            equationsObj.forEach((equationObj) => {
                const objTarget = equationObj.obj;
                equation2 = Editor.createEquationFromWall(binder);
                equationObj.eq = qSVG.perpendicularEquation(equation2, objTarget.x!, objTarget.y!)!;
                const intersectionObj = qSVG.intersectionOfEquations(equationObj.eq, equation2) as Array<number>;
                // NEW COORDS OBJDATA[o]
                objTarget.x = intersectionObj[0];
                objTarget.y = intersectionObj[1];
                const limits = PointUtils.limitObj(equation2, objTarget.size!, objTarget as CoordPoint);

                //SI objtaget n'est pas sur un mur
                //chercher l'intersection le plus proche

                //ou faire des simulations

                if (!qSVG.btwn(limits[0].x, binder.start.x, binder.end.x, false, MapConstants.tolerance_round_value)) {
                    if (
                        qSVG.btwn(binder.start.x, limits[0].x, limits[1].x, false, MapConstants.tolerance_round_value)
                    ) {
                        //take start wall point as reference
                        const measure = qSVG.measure(
                            { x: objTarget.x! - objTarget.size! / 2, y: objTarget.y! },
                            binder.start
                        );
                        objTarget.x! += measure;
                    } else if (
                        qSVG.btwn(binder.end.x, limits[0].x, limits[1].x, false, MapConstants.tolerance_round_value)
                    ) {
                        //take end wall point as reference
                        const measure = qSVG.measure(limits[0], binder.end);
                        objTarget.x! -= measure;
                    }
                }
                if (!qSVG.btwn(limits[1].x, binder.start.x, binder.end.x, false, MapConstants.tolerance_round_value)) {
                    if (
                        qSVG.btwn(binder.start.x, limits[0].x, limits[1].x, false, MapConstants.tolerance_round_value)
                    ) {
                        //take start wall point as reference
                        const measure = qSVG.measure(limits[1], binder.start);
                        objTarget.x! -= measure;
                    } else if (
                        qSVG.btwn(binder.end.x, limits[0].x, limits[1].x, false, MapConstants.tolerance_round_value)
                    ) {
                        //take end wall point as reference
                        const measure = qSVG.measure(limits[1], binder.end);
                        objTarget.x! += measure;
                    }
                }

                if (!qSVG.btwn(limits[0].y, binder.start.y, binder.end.y, false, MapConstants.tolerance_round_value)) {
                    if (
                        qSVG.btwn(binder.start.y, limits[0].y, limits[1].y, false, MapConstants.tolerance_round_value)
                    ) {
                        //take start wall point as reference
                        const measure = qSVG.measure(limits[0], binder.start);
                        objTarget.y! += measure;
                    } else if (
                        qSVG.btwn(binder.end.y, limits[0].y, limits[1].y, false, MapConstants.tolerance_round_value)
                    ) {
                        //take end wall point as reference
                        const measure = qSVG.measure(limits[0], binder.end);
                        objTarget.y! += measure;
                    }
                }

                if (!qSVG.btwn(limits[1].y, binder.start.y, binder.end.y, false, MapConstants.tolerance_round_value)) {
                    if (
                        qSVG.btwn(binder.start.y, limits[0].y, limits[1].y, false, MapConstants.tolerance_round_value)
                    ) {
                        //take start wall point as reference
                        const measure = qSVG.measure(limits[1], binder.start);
                        objTarget.y! -= measure;
                    } else if (
                        qSVG.btwn(binder.end.y, limits[0].y, limits[1].y, false, MapConstants.tolerance_round_value)
                    ) {
                        //take end wall point as reference
                        const measure = qSVG.measure(limits[1], binder.end);
                        objTarget.y! += measure;
                    }
                }

                objTarget.limit = limits;

                //* FIX NEW COORD
                openings.forEach((opening) => {
                    if (opening.openingId === objTarget.openingId) {
                        opening.x = objTarget.x;
                        opening.y = objTarget.y;
                        opening.limit = objTarget.limit;
                    }
                });
            });

            const siblingWalls: Array<WallLine> = [
                { start: intersection1, end: intersection2, roomId: binder.roomId },
                { start: binder.parent!.start, end: intersection1, roomId: binder.roomId },
                { start: intersection2, end: binder.child!.end, roomId: binder.roomId },
            ];

            siblingWalls.forEach((siblingWall) => {
                const objWall = Editor.objFromWall(siblingWall, openings); // LIST OBJ ON EDGE
                for (let ob in objWall) {
                    const objTarget = objWall[ob];
                    const eq = Editor.createEquationFromWall(siblingWall);
                    const limits = PointUtils.limitObj(eq, objTarget.size!, objTarget as CoordPoint);
                    if (
                        !qSVG.btwn(limits[0].x, siblingWall.start.x, siblingWall.end.x) ||
                        !qSVG.btwn(limits[0].y, siblingWall.start.y, siblingWall.end.y) ||
                        !qSVG.btwn(limits[1].x, siblingWall.start.x, siblingWall.end.x) ||
                        !qSVG.btwn(limits[1].y, siblingWall.start.y, siblingWall.end.y)
                    ) {
                        WallTools.slideObjOnWall(siblingWall, openings);
                    }
                }
            });
        }

        return { walls, rooms, openings, wallMoveSuccess: true };
    }

    public static wallMoveMinSizeBlock(targetWall: Wall) {
        const parentWall = targetWall.parent;
        const childWall = targetWall.child;

        if (
            (parentWall &&
                WallUtils.wallSize(parentWall) + MapConstants.tolerance_round_value < (parentWall.minimumSize ?? 0)) ||
            (childWall &&
                WallUtils.wallSize(childWall) + MapConstants.tolerance_round_value < (childWall.minimumSize ?? 0))
        ) {
            return true;
        }
        return false;
    }

    public static direction(a: CoordPoint, b: CoordPoint, c: CoordPoint) {
        const val = (b.y - a.y) * (c.x - b.x) - (b.x - a.x) * (c.y - b.y);
        if (val === 0) {
            return 0;
        } else if (val < 0) {
            return 2;
        }
        return 1;
    }

    public static isIntersect(l1: LineCoordPoint, l2: LineCoordPoint) {
        let dir1 = this.direction(l1.p1, l1.p2, l2.p1);
        let dir2 = this.direction(l1.p1, l1.p2, l2.p2);
        let dir3 = this.direction(l2.p1, l2.p2, l1.p1);
        let dir4 = this.direction(l2.p1, l2.p2, l1.p2);

        if (dir1 !== dir2 && dir3 !== dir4) {
            return true;
        }
        if (dir1 === 0 && this.onLine(l1, l2.p1)) {
            return true;
        }
        if (dir2 === 0 && this.onLine(l1, l2.p2)) {
            return true;
        }
        if (dir3 === 0 && this.onLine(l2, l1.p1)) {
            return true;
        }
        if (dir4 === 0 && this.onLine(l2, l1.p2)) {
            return true;
        }
        return false;
    }

    public static onLine(l1: LineCoordPoint, p: CoordPoint) {
        return (
            p.x <= Math.max(l1.p1.x, l1.p2.x) &&
            p.x <= Math.min(l1.p1.x, l1.p2.x) &&
            p.y <= Math.max(l1.p1.y, l1.p2.y) &&
            p.y <= Math.min(l1.p1.y, l1.p2.y)
        );
    }

    public static intersect(l1: LineCoordPoint, l2: LineCoordPoint) {
        // Check if none of the lines are of length 0
        if ((l1.p1.x === l1.p2.x && l1.p1.y === l1.p2.y) || (l2.p1.x === l2.p2.x && l2.p1.y === l2.p2.y)) {
            return false;
        }

        const denominator = (l2.p2.y - l2.p1.y) * (l1.p2.x - l1.p1.x) - (l2.p2.x - l2.p1.x) * (l1.p2.y - l1.p1.y);

        // Lines are parallel
        if (denominator === 0) {
            return false;
        }

        let ua = ((l2.p2.x - l2.p1.x) * (l1.p1.y - l2.p1.y) - (l2.p2.y - l2.p1.y) * (l1.p1.x - l2.p1.x)) / denominator;
        let ub = ((l1.p2.x - l1.p1.x) * (l1.p1.y - l2.p1.y) - (l1.p2.y - l1.p1.y) * (l1.p1.x - l2.p1.x)) / denominator;

        // is the intersection along the segments
        if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
            return false;
        }

        // Return a object with the x and y coordinates of the intersection
        let x = l1.p1.x + ua * (l1.p2.x - l1.p1.x);
        let y = l1.p1.y + ua * (l1.p2.y - l1.p1.y);

        return { x, y };
    }
}
