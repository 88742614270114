import * as _ from 'lodash';

import { Logger } from '../../../../Errors/Logger';
import { ObjectUtils } from '../../../../Helpers/ObjectUtils';
import { OpeningFactory } from '../../Helpers/BaseOpeningFactory';
import { RoomItemFactory } from '../../Helpers/BaseRoomItemFactory';
import { MapConstants } from '../../MapConstants';
import { IOpening } from '../../Models/IOpening';
import { IRoom, RoomDimensions, RoomLocalisation, RoomShape, RoomType } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { IdGenerator } from '../IdGenerator';
import { OpeningUtils } from '../Opening/OpeningUtils';
import { PointUtils } from '../PointUtils';
import { CoordPoint, Wall } from '../Types';
import { WallUtils } from '../Wall/WallUtils';
import { qSVG } from '../qSVG';

type CreateResult = { room: IRoom; roomWalls?: Array<Wall> };
export class CreateRoomFactory {
    public static createRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        room.roomId = room.roomId ?? IdGenerator.generate();
        if (room.type === RoomType.Wall) {
            room.localisation = RoomLocalisation.Inside;
        }
        switch (room.shape) {
            case RoomShape.Rectangle:
                return this.createRectangleShapeRoom(room, currentWalls);
            case RoomShape.Open_bottom_rectangle:
                return this.createOpenRectangleShapeRoom(room, currentWalls);
            case RoomShape.Diamond_topleft:
            case RoomShape.Diamond_topright:
            case RoomShape.Diamond_bottomleft:
            case RoomShape.Diamond_bottomright:
                return this.createDiamondShapeRoom(room, currentWalls);
            case RoomShape.Flyingwing_topleft:
            case RoomShape.Flyingwing_topright:
            case RoomShape.Flyingwing_bottomleft:
            case RoomShape.Flyingwing_bottomright:
                return this.createFlyingWingShapeRoom(room, currentWalls);
            case RoomShape.U_top:
            case RoomShape.U_right:
            case RoomShape.U_bottom:
            case RoomShape.U_left:
                return this.createUShapeRoom(room, currentWalls);
            case RoomShape.Plus:
                return this.createPlusShapeRoom(room, currentWalls);
            case RoomShape.Custom:
                return this.createCustomShapeRoom(room, currentWalls);
        }
        room.sides = this.toRoomSides(room);
        room.sidesObject = Object.fromEntries(room.sides);
        return { room, roomWalls: [] };
    };

    public static exportRoom = (room: IRoom): IRoom => {
        switch (room.shape) {
            case RoomShape.Rectangle:
                return this.exportRectangleShapeRoom(room);
            case RoomShape.Open_bottom_rectangle:
                return this.exportOpenRectangleShapeRoom(room)!;
            case RoomShape.Diamond_topleft:
            case RoomShape.Diamond_topright:
            case RoomShape.Diamond_bottomleft:
            case RoomShape.Diamond_bottomright:
                return this.exportDiamondShapeRoom(room)!;
            case RoomShape.Flyingwing_topleft:
            case RoomShape.Flyingwing_topright:
            case RoomShape.Flyingwing_bottomleft:
            case RoomShape.Flyingwing_bottomright:
                return this.exportFlyingWingShapeRoom(room)!;
            case RoomShape.U_top:
            case RoomShape.U_right:
            case RoomShape.U_bottom:
            case RoomShape.U_left:
                return this.exportUShapeRoom(room)!;
            default:
                return this.exportCustomShapeRoom(room)!;
        }
    };

    public static duplicate(
        room: IRoom,
        name: string,
        walls: Array<Wall>,
        openings: Array<IOpening>,
        roomItems: Array<IRoomItem>
    ): CreateResult {
        let exportedRoom: IRoom = this.exportRoom(room);
        if (exportedRoom) {
            exportedRoom.name = name;
            exportedRoom.contournements = ObjectUtils.clone(exportedRoom.contournements);

            const bbox = PointUtils.calculateBoundingBox({ points: room.coords! })!;
            const overallBbox = PointUtils.calculateBoundingBox({ walls })!;
            const offset = {
                x: qSVG.measure({ x: bbox.xMin, y: bbox.yMin }, { x: overallBbox.xMax, y: bbox.yMin }) / 100 + 1.8,
                y: 0,
            };
            const offsetInCm = {
                x: offset.x * 100,
                y: 0,
            };
            const centerPositionOffset = PointUtils.translation(
                { x: exportedRoom.center!.x, y: exportedRoom.center!.y },
                offset
            );
            exportedRoom.coords = exportedRoom.coords?.map((x) => PointUtils.translation(x, offsetInCm));
            exportedRoom.center = centerPositionOffset;
            PointUtils.smoothOutPoint(exportedRoom.center);

            const exportedRoomOpenings = OpeningUtils.updateRoomOpenings(room, openings);
            exportedRoom.roomId = IdGenerator.generate();
            exportedRoom.openings = ObjectUtils.clone(exportedRoomOpenings);
            const newOpenings = ObjectUtils.clone(openings);
            for (const opening of exportedRoom.openings!) {
                opening.openingId = IdGenerator.generate();
                opening.roomId = exportedRoom.roomId;
                opening.name = OpeningFactory.getNewName(opening, newOpenings);
                newOpenings.push(opening);
                //!maybe set opening position here,
                //! do not set location_position_from_edge (and avoid using fixPositionOpenings mehtod in OpeningUtils when import/or add room)
            }

            exportedRoom.roomItems = ObjectUtils.clone(exportedRoom.roomItems);
            const newRoomItems = ObjectUtils.clone(roomItems);
            for (const roomItem of exportedRoom.roomItems!) {
                roomItem.roomItemId = IdGenerator.generate();
                roomItem.roomId = exportedRoom.roomId;

                const positionOffset = { ...offset };
                positionOffset.x = offset.x * 100;
                roomItem.coordsReal = roomItem.coordsReal?.map((x) => PointUtils.translation(x, positionOffset));
                roomItem.graphTranslation = PointUtils.translation(roomItem.graphTranslation!, positionOffset);
                roomItem.name = RoomItemFactory.getNewName(roomItem, newRoomItems);
                newRoomItems.push(roomItem);
            }

            console.log({ newOpenings, 'exportedRoom.openings': exportedRoom.openings });
            //importRoomFunc(exportedRoom);
            return this.createRoom(exportedRoom, walls);
        }

        //refreshWallsMinimumSizeAndOriginalAngle(ROOM[ROOM.length - 1].walls);
        //WallUtils.refreshWallsMinimumSizeAndOriginalAngle(walls, newOpenings);

        return { room: exportedRoom! };
    }

    //#region //* CREATE ROOM SHAPE

    private static createRectangleShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        const points: Array<CoordPoint> = [
            { x: 0, y: 0 },
            { x: room.dimensions!.width!, y: 0 },
            { x: room.dimensions!.width!, y: room.dimensions!.height! },
            { x: 0, y: room.dimensions!.height! },
        ];
        if (room.center) {
            PointUtils.translationByMeanPoint(points, room.center);
        }
        points.forEach((point) => PointUtils.convertToCoordinates(point));
        if (!room.center) {
            PointUtils.placeToZero(points);
            PointUtils.placeAlongSideExistingRooms(points, currentWalls);
        }
        PointUtils.smoothOutPoints(points);

        const roomWalls: Array<Wall> = [
            WallUtils.createWall(points[0], points[1], room, 'top'),
            WallUtils.createWall(points[1], points[2], room, 'right'),
            WallUtils.createWall(points[2], points[3], room, 'bottom'),
            WallUtils.createWall(points[3], points[0], room, 'left'),
        ];
        return { room, roomWalls };
    };

    private static createOpenRectangleShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        //* TLE TODO FAIRE EN SORTE QUE CE SOIT UNE ROOM
        const points: Array<CoordPoint> = [
            { x: 0, y: 0 },
            { x: room.dimensions!.width!, y: 0 },
            { x: room.dimensions!.width!, y: room.dimensions!.height! },
            { x: 0, y: room.dimensions!.height! },
        ];
        if (room.center) {
            PointUtils.translationByMeanPoint(points, room.center);
        }

        points.forEach((point) => PointUtils.convertToCoordinates(point));

        if (!room.center) {
            PointUtils.placeToZero(points);
            PointUtils.placeAlongSideExistingRooms(points, currentWalls);
        }

        PointUtils.smoothOutPoints(points);

        const roomWalls: Array<Wall> = [
            WallUtils.createWall(points[0], points[1], room, 'top'),
            WallUtils.createWall(points[1], points[2], room, 'right'),
            WallUtils.createWall(points[3], points[0], room, 'left'),
        ];
        return { room, roomWalls };
    };

    private static createDiamondShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        const angleShape = new Map([
            [RoomShape.Diamond_topleft, 0],
            [RoomShape.Diamond_topright, 90],
            [RoomShape.Diamond_bottomright, 180],
            [RoomShape.Diamond_bottomleft, 270],
        ]);

        //* define points coordinates
        const tableCommonPoint = Math.sqrt(Math.pow(room.dimensions!.table!, 2) / 2);

        const points: Array<CoordPoint> = [
            { x: 0, y: tableCommonPoint },
            { x: tableCommonPoint, y: 0 },
            { x: tableCommonPoint + room.dimensions!.right_crown!, y: 0 },
            { x: tableCommonPoint + room.dimensions!.right_crown!, y: tableCommonPoint + room.dimensions!.left_crown! },
            { x: 0, y: tableCommonPoint + room.dimensions!.left_crown! },
        ];

        PointUtils.rotation(points, angleShape.get(room.shape!)!);

        //* move points to correct location
        if (room.center) {
            PointUtils.translationByMeanPoint(points, room.center);
        }

        //* convert meter to svg coordinates
        points.forEach((point) => PointUtils.convertToCoordinates(point, true));

        if (!room.center) {
            PointUtils.placeToZero(points);
            PointUtils.placeAlongSideExistingRooms(points, currentWalls);
        }

        PointUtils.smoothOutPoints(points);

        const roomWalls: Array<Wall> = [
            //* draw table
            WallUtils.createWall(points[0], points[1], room, 'table'),
            //* draw right crown
            WallUtils.createWall(points[1], points[2], room, 'right_crown'),
            //* draw right pavilion
            WallUtils.createWall(points[2], points[3], room, 'right_pavilion'),
            //* draw left pavilion
            WallUtils.createWall(points[3], points[4], room, 'left_pavilion'),
            //* draw left crown
            WallUtils.createWall(points[4], points[0], room, 'left_crown'),
        ];
        return { room, roomWalls };
    };

    private static createFlyingWingShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        const angleShape = new Map([
            ['flyingwing_topleft', 0],
            ['flyingwing_topright', 90],
            ['flyingwing_bottomright', 180],
            ['flyingwing_bottomleft', 270],
        ]);

        //* define points coordinates
        const points: Array<CoordPoint> = [
            { x: 0, y: 0 },
            { x: room.dimensions!.left_wingtip! + room.dimensions!.right_trailing_edge!, y: 0 },
            {
                x: room.dimensions!.left_wingtip! + room.dimensions!.right_trailing_edge!,
                y: room.dimensions!.right_wingtip!,
            },
            { x: room.dimensions!.left_wingtip!, y: room.dimensions!.right_wingtip! },
            {
                x: room.dimensions!.left_wingtip!,
                y: room.dimensions!.right_wingtip! + room.dimensions!.left_trailing_edge!,
            },
            { x: 0, y: room.dimensions!.right_wingtip! + room.dimensions!.left_trailing_edge! },
        ];

        PointUtils.rotation(points, angleShape.get(room.shape!)!);

        if (room.center) {
            PointUtils.translationByMeanPoint(points, room.center);
        }

        points.forEach((point) => PointUtils.convertToCoordinates(point));

        if (!room.center) {
            PointUtils.placeToZero(points);
            PointUtils.placeAlongSideExistingRooms(points, currentWalls);
        }
        PointUtils.smoothOutPoints(points);

        const roomWalls: Array<Wall> = [
            WallUtils.createWall(points[0], points[1], room, 'right_leading_edge'),
            WallUtils.createWall(points[1], points[2], room, 'right_wingtip'),
            WallUtils.createWall(points[2], points[3], room, 'right_trailing_edge'),
            WallUtils.createWall(points[3], points[4], room, 'left_trailing_edge'),
            WallUtils.createWall(points[4], points[5], room, 'left_wingtip'),
            WallUtils.createWall(points[5], points[0], room, 'left_leading_edge'),
        ];
        return { room, roomWalls };
    };

    private static createUShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        const angleShape = new Map([
            [RoomShape.U_top, 0],
            [RoomShape.U_right, 90],
            [RoomShape.U_bottom, 180],
            [RoomShape.U_left, 270],
        ]);
        const exteriorBase = room.dimensions!.exterior_base!;
        const leftExteriorEdge = room.dimensions!.left_exterior_edge!;
        const leftTip = room.dimensions!.left_tip!;
        const leftInteriorEdge = room.dimensions!.left_interior_edge!;
        const rightTip = room.dimensions!.right_tip!;
        const rightExteriorEdge = room.dimensions!.right_exterior_edge!;

        //* define points coordinates
        const points: Array<CoordPoint> = [
            { x: 0, y: 0 },
            { x: leftTip, y: 0 },
            { x: leftTip, y: leftInteriorEdge },
            { x: exteriorBase - rightTip, y: leftInteriorEdge },
            { x: exteriorBase - rightTip, y: leftExteriorEdge - rightExteriorEdge },
            { x: exteriorBase, y: leftExteriorEdge - rightExteriorEdge },
            { x: exteriorBase, y: leftExteriorEdge },
            { x: 0, y: leftExteriorEdge },
        ];

        PointUtils.rotation(points, angleShape.get(room.shape!)!);

        if (room.center) {
            PointUtils.translationByMeanPoint(points, room.center);
        }

        points.forEach((point) => PointUtils.convertToCoordinates(point));

        if (!room.center) {
            PointUtils.placeToZero(points);
            PointUtils.placeAlongSideExistingRooms(points, currentWalls);
        }
        PointUtils.smoothOutPoints(points);

        const roomWalls: Array<Wall> = [
            WallUtils.createWall(points[0], points[1], room, 'left_tip'),
            WallUtils.createWall(points[1], points[2], room, 'left_interior_edge'),
            WallUtils.createWall(points[2], points[3], room, 'interior_base'),
            WallUtils.createWall(points[3], points[4], room, 'right_interior_edge'),
            WallUtils.createWall(points[4], points[5], room, 'right_tip'),
            WallUtils.createWall(points[5], points[6], room, 'right_exterior_edge'),
            WallUtils.createWall(points[6], points[7], room, 'exterior_base'),
            WallUtils.createWall(points[7], points[0], room, 'left_exterior_edge'),
        ];
        return { room, roomWalls };
    };

    private static createPlusShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        //* define points coordinates

        let points: Array<CoordPoint>;
        if (room.coords) {
            points = room.coords;
        } else {
            points = room.customPoints!;

            if (room.center) {
                PointUtils.translationByMeanPoint(points, room.center);
            }
            points.forEach((point) => PointUtils.convertToCoordinates(point, true));
            if (!room.center) {
                PointUtils.placeToZero(points);
                PointUtils.placeAlongSideExistingRooms(points, currentWalls);
            }
            PointUtils.smoothOutPoints(points);
        }

        const roomWalls: Array<Wall> = [
            WallUtils.createWall(points[0], points[1], room, 'top_tip'),
            WallUtils.createWall(points[1], points[2], room, 'top_right'),
            WallUtils.createWall(points[2], points[3], room, 'right_left'),
            WallUtils.createWall(points[3], points[4], room, 'right_tip'),
            WallUtils.createWall(points[4], points[5], room, 'right_right'),
            WallUtils.createWall(points[5], points[6], room, 'bottom_left'),
            WallUtils.createWall(points[6], points[7], room, 'bottom_tip'),
            WallUtils.createWall(points[7], points[8], room, 'bottom_right'),
            WallUtils.createWall(points[8], points[9], room, 'left_left'),
            WallUtils.createWall(points[9], points[10], room, 'left_tip'),
            WallUtils.createWall(points[10], points[11], room, 'left_right'),
            WallUtils.createWall(points[11], points[0], room, 'top_left'),
        ];

        return { room, roomWalls };
    };

    private static createCustomShapeRoomOld = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        const points = _.cloneDeep(room.customPoints || []);
        const walls = PointUtils.toWalls(points);

        const sides = new Map();
        let wallCount = 0;
        walls.forEach((wall) => {
            sides.set(`side_${wallCount}`, [wall.start, wall.end]);
            wallCount++;
        });

        if (room.center) {
            PointUtils.translationByMeanPoint(points, room.center);
        }

        points.forEach((point) => PointUtils.convertToCoordinates(point));

        PointUtils.smoothOutPoints(points);

        const roomWalls: Array<Wall> = [];
        for (let i = 0; i < points.length; i++) {
            roomWalls.push(WallUtils.createWall(points[i], points[(i + 1) % points.length], room, ''));
        }

        if (!room.center) {
            PointUtils.placeToZero(points);
            PointUtils.placeAlongSideExistingRooms(points, currentWalls);
        }

        Logger.log('createCustomShapeRoom', { room, roomWalls });
        return { room, roomWalls };
    };

    private static createCustomShapeRoom = (room: IRoom, currentWalls: Array<Wall>): CreateResult => {
        const points = _.cloneDeep(room.coords?.slice(0, room.coords!.length - 1) || room.customPoints || []);
        if (!room.coords) {
            const walls = PointUtils.toWalls(points);
            const sides = new Map();
            let wallCount = 0;
            walls.forEach((wall) => {
                sides.set(`side_${wallCount}`, [wall.start, wall.end]);
                wallCount++;
            });
            room.sides = sides;

            if (room.center) {
                PointUtils.translationByMeanPoint(points, room.center);
            }

            points.forEach((point) => PointUtils.convertToCoordinates(point));

            PointUtils.smoothOutPoints(points);
        }
        room.shape = RoomShape.Custom;
        const roomWalls: Array<Wall> = [];
        for (let i = 0; i < points.length; i++) {
            roomWalls.push(WallUtils.createWall(points[i], points[(i + 1) % points.length], room, `side_${i}`));
        }
        Logger.log('createCustomShapeRoom', { room, roomWalls });
        return { room, roomWalls };
    };

    //#endregion

    //#region //* DUPLICATE ROOM SHAPE

    private static exportRectangleShapeRoom(room: IRoom) {
        const points = ObjectUtils.clone(room.coords!.slice(0, room.coords!.length - 1));
        const sides = this.toRoomSides(room);

        points.forEach((point) => (point = this.convertToMeters(point)));

        const mp = PointUtils.meanPoint(points);
        PointUtils.translationByMeanPoint(points, mp, true);
        const widthDistance =
            PointUtils.distanceBetweenPoints(sides.get('top')![0], sides.get('top')![1]) / MapConstants.meter;
        const heightDistance =
            PointUtils.distanceBetweenPoints(sides.get('left')![0], sides.get('left')![1]) / MapConstants.meter;

        return this.exportRoomObject(room, { width: widthDistance, height: heightDistance }, mp);
    }

    private static exportOpenRectangleShapeRoom(room: IRoom): IRoom | undefined {
        const points = ObjectUtils.clone(room.coords!.slice(0, room.coords!.length - 1));

        // points = rollArray(points, 2);

        if (room.walls?.length !== 3) {
            return undefined;
        }

        const sides = this.toRoomSides(room);
        points.forEach((point) => (point = this.convertToMeters(point)));
        const mp = PointUtils.meanPoint(points);
        PointUtils.translationByMeanPoint(points, mp, true);

        const widthDistance =
            PointUtils.distanceBetweenPoints(sides.get('top')![0], sides.get('top')![1]) / MapConstants.meter;
        const heightDistance =
            PointUtils.distanceBetweenPoints(sides.get('left')![0], sides.get('left')![1]) / MapConstants.meter;

        return this.exportRoomObject(room, { width: widthDistance, height: heightDistance }, mp);
    }

    private static exportDiamondShapeRoom(room: IRoom): IRoom | undefined {
        const points = ObjectUtils.clone(room.coords!.slice(0, room.coords!.length - 1));
        if (room.walls?.length !== 5) {
            return undefined;
        }

        const sides = this.toRoomSides(room);
        points.forEach((point) => (point = this.convertToMeters(point)));
        const mp = PointUtils.meanPoint(points);
        PointUtils.translationByMeanPoint(points, mp, true);

        const tableDistance =
            PointUtils.distanceBetweenPoints(sides.get('table')![0], sides.get('table')![1]) / MapConstants.meter;
        const left_crownDistance =
            PointUtils.distanceBetweenPoints(sides.get('left_crown')![0], sides.get('left_crown')![1]) /
            MapConstants.meter;
        const right_crownDistance =
            PointUtils.distanceBetweenPoints(sides.get('right_crown')![0], sides.get('right_crown')![1]) /
            MapConstants.meter;

        return this.exportRoomObject(
            room,
            { left_crown: left_crownDistance, table: tableDistance, right_crown: right_crownDistance },
            mp
        );
    }

    private static exportFlyingWingShapeRoom(room: IRoom): IRoom | undefined {
        const points = ObjectUtils.clone(room.coords!.slice(0, room.coords!.length - 1));

        if (room.walls?.length !== 6) {
            return undefined;
        }

        const sides = new Map([
            ['right_leading_edge', [room.walls[0].start, room.walls[0].end]],
            ['right_wingtip', [room.walls[1].start, room.walls[1].end]],
            ['right_trailing_edge', [room.walls[2].start, room.walls[2].end]],
            ['left_trailing_edge', [room.walls[3].start, room.walls[3].end]],
            ['left_wingtip', [room.walls[4].start, room.walls[4].end]],
            ['left_leading_edge', [room.walls[5].start, room.walls[5].end]],
        ]);

        points.forEach((point) => (point = this.convertToMeters(point)));
        const mp = PointUtils.meanPoint(points);
        PointUtils.translationByMeanPoint(points, mp, true);

        const right_wingtipDistance =
            PointUtils.distanceBetweenPoints(sides.get('right_wingtip')![0], sides.get('right_wingtip')![1]) /
            MapConstants.meter;
        const right_trailing_edgeDistance =
            PointUtils.distanceBetweenPoints(
                sides.get('right_trailing_edge')![0],
                sides.get('right_trailing_edge')![1]
            ) / MapConstants.meter;
        const left_trailing_edgeDistance =
            PointUtils.distanceBetweenPoints(sides.get('left_trailing_edge')![0], sides.get('left_trailing_edge')![1]) /
            MapConstants.meter;
        const left_wingtipDistance =
            PointUtils.distanceBetweenPoints(sides.get('left_wingtip')![0], sides.get('left_wingtip')![1]) /
            MapConstants.meter;

        return this.exportRoomObject(
            room,
            {
                right_wingtip: right_wingtipDistance,
                right_trailing_edge: right_trailing_edgeDistance,
                left_trailing_edge: left_trailing_edgeDistance,
                left_wingtip: left_wingtipDistance,
            },
            mp
        );
    }

    private static exportUShapeRoom(room: IRoom): IRoom | undefined {
        const points = ObjectUtils.clone(room.coords!.slice(0, room.coords!.length - 1));
        if (room.walls?.length !== 8) {
            return undefined;
        }

        const sides = this.toRoomSides(room);
        points.forEach((point) => (point = this.convertToMeters(point)));
        const mp = PointUtils.meanPoint(points);
        PointUtils.translationByMeanPoint(points, mp, true);

        const getDimens = (a: CoordPoint, b: CoordPoint) => PointUtils.distanceBetweenPoints(a, b) / MapConstants.meter;

        const dimensions = {
            exterior_base: getDimens(sides.get('exterior_base')![0], sides.get('exterior_base')![1]),
            left_exterior_edge: getDimens(sides.get('left_exterior_edge')![0], sides.get('left_exterior_edge')![1]),
            left_tip: getDimens(sides.get('left_tip')![0], sides.get('left_tip')![1]),
            left_interior_edge: getDimens(sides.get('left_interior_edge')![0], sides.get('left_interior_edge')![1]),
            right_tip: getDimens(sides.get('right_tip')![0], sides.get('right_tip')![1]),
            right_exterior_edge: getDimens(sides.get('right_exterior_edge')![0], sides.get('right_exterior_edge')![1]),
        };
        return this.exportRoomObject(room, dimensions, mp);
    }

    private static exportCustomShapeRoomOld(room: IRoom): IRoom | undefined {
        const roomWalls = PointUtils.toWalls(room.customPoints!);
        const points = ObjectUtils.clone(room.coords!.slice(0, room.coords!.length - 1));

        const sides = new Map();
        let wallCount = 0;
        roomWalls.forEach((wall) => {
            sides.set(`side_${wallCount}`, [wall.start, wall.end]);
            wallCount++;
        });

        points.forEach((point) => (point = this.convertToMeters(point)));
        const mp = PointUtils.meanPoint(points);
        PointUtils.translationByMeanPoint(points, mp, true);

        return this.exportRoomObject(room, {}, mp, points);
    }
    private static exportCustomShapeRoom(room: IRoom): IRoom | undefined {
        const points = ObjectUtils.clone(room.coords!.slice(0, room.coords!.length - 1));
        const roomWalls = PointUtils.toWalls(points);

        const sides = new Map();
        let wallCount = 0;
        roomWalls.forEach((wall) => {
            sides.set(`side_${wallCount}`, [wall.start, wall.end]);
            wallCount++;
        });

        points.forEach((point) => (point = this.convertToMeters(point)));
        const mp = PointUtils.meanPoint(points);
        PointUtils.translationByMeanPoint(points, mp, true);

        return this.exportRoomObject(room, room.dimensions, mp, points);
    }

    private static exportRoomObject(
        room: IRoom,
        dimensions: RoomDimensions = {}, //OBSOLETE IN PLAN LIBRE (//!\\ to delete)
        center: CoordPoint,
        points?: Array<CoordPoint>
    ): IRoom {
        return {
            ...room,
            dimensions,
            center: center,
            customPoints: points,
        };
    }

    //#endregion

    //#region //* GET ROOM SIDES

    public static toRoomSides = (room: IRoom): Map<string, Array<CoordPoint>> => {
        switch (room.shape) {
            case RoomShape.Rectangle:
                return this.toRectangleShapeRoomSides(room);
            case RoomShape.Open_bottom_rectangle:
                return this.OpenRectangleShapeRoomSides(room);
            case RoomShape.Diamond_topleft:
            case RoomShape.Diamond_topright:
            case RoomShape.Diamond_bottomleft:
            case RoomShape.Diamond_bottomright:
                return this.toDiamondShapeRoomSides(room);
            case RoomShape.Flyingwing_topleft:
            case RoomShape.Flyingwing_topright:
            case RoomShape.Flyingwing_bottomleft:
            case RoomShape.Flyingwing_bottomright:
                return this.toFlyingWingShapeRoomSides(room);
            case RoomShape.U_top:
            case RoomShape.U_right:
            case RoomShape.U_bottom:
            case RoomShape.U_left:
                return this.toUShapeRoomSides(room);

            case RoomShape.Plus:
                return this.toPlusShapeRoomSides(room);
        }
        return new Map();
    };

    private static toRectangleShapeRoomSides = (room: IRoom): Map<string, Array<CoordPoint>> => {
        return new Map([
            ['top', [room.walls![0].start, room.walls![0].end]],
            ['right', [room.walls![1].start, room.walls![1].end]],
            ['bottom', [room.walls![2].start, room.walls![2].end]],
            ['left', [room.walls![3].start, room.walls![3].end]],
        ]);
    };

    private static OpenRectangleShapeRoomSides = (room: IRoom): Map<string, Array<CoordPoint>> => {
        return new Map([
            ['left', [room.walls![0].start, room.walls![0].end]],
            ['top', [room.walls![1].start, room.walls![1].end]],
            ['right', [room.walls![2].start, room.walls![2].end]],
        ]);
    };

    private static toDiamondShapeRoomSides = (room: IRoom): Map<string, Array<CoordPoint>> => {
        return new Map([
            ['table', [room.walls![0].start, room.walls![0].end]],
            ['right_crown', [room.walls![1].start, room.walls![1].end]],
            ['right_pavilion', [room.walls![2].start, room.walls![2].end]],
            ['left_pavilion', [room.walls![3].start, room.walls![3].end]],
            ['left_crown', [room.walls![4].start, room.walls![4].end]],
        ]);
    };

    private static toFlyingWingShapeRoomSides = (room: IRoom): Map<string, Array<CoordPoint>> => {
        return new Map([
            ['right_leading_edge', [room.walls![0].start, room.walls![0].end]],
            ['right_wingtip', [room.walls![1].start, room.walls![1].end]],
            ['right_trailing_edge', [room.walls![2].start, room.walls![2].end]],
            ['left_trailing_edge', [room.walls![3].start, room.walls![3].end]],
            ['left_wingtip', [room.walls![4].start, room.walls![4].end]],
            ['left_leading_edge', [room.walls![5].start, room.walls![5].end]],
        ]);
    };

    private static toUShapeRoomSides = (room: IRoom): Map<string, Array<CoordPoint>> => {
        return new Map([
            ['left_tip', [room.walls![0].start, room.walls![0].end]],
            ['left_interior_edge', [room.walls![1].start, room.walls![1].end]],
            ['interior_base', [room.walls![2].start, room.walls![2].end]],
            ['right_interior_edge', [room.walls![3].start, room.walls![3].end]],
            ['right_tip', [room.walls![4].start, room.walls![4].end]],
            ['right_exterior_edge', [room.walls![5].start, room.walls![5].end]],
            ['exterior_base', [room.walls![6].start, room.walls![6].end]],
            ['left_exterior_edge', [room.walls![7].start, room.walls![7].end]],
        ]);
    };

    private static toPlusShapeRoomSides = (room: IRoom): Map<string, Array<CoordPoint>> => {
        return new Map([
            ['top_tip', [room.walls![0].start, room.walls![0].end]],
            ['top_right', [room.walls![1].start, room.walls![1].end]],
            ['right_left', [room.walls![2].start, room.walls![2].end]],
            ['right_tip', [room.walls![3].start, room.walls![3].end]],
            ['right_right', [room.walls![4].start, room.walls![4].end]],
            ['bottom_left', [room.walls![5].start, room.walls![5].end]],
            ['bottom_tip', [room.walls![6].start, room.walls![6].end]],
            ['bottom_right', [room.walls![7].start, room.walls![7].end]],
            ['left_left', [room.walls![8].start, room.walls![8].end]],
            ['left_tip', [room.walls![9].start, room.walls![9].end]],
            ['left_right', [room.walls![10].start, room.walls![10].end]],
            ['top_left', [room.walls![11].start, room.walls![11].end]],
        ]);
    };

    //#endregion

    public static convertToMeters(point: CoordPoint) {
        point.x /= MapConstants.meter;
        point.y /= MapConstants.meter;
        return point;
    }
}
