import React from 'react';
import { useParams } from 'react-router-dom';

import { useMe } from '../../../../Configs/PolStore';
import { handleError } from '../../../../Errors/PolErrorHandler';
import { ProjectHelper } from '../../../../Helpers/ProjectHelper';
import { I18n } from '../../../../Locales/I18nService';
import { ProjectDto, ProjectResponse } from '../../../../Models/Projects/ProjectDto';
import { ProjectsApi } from '../../../../Services/Api/ProjectsApi';
import { RouteParams, RouteQueryParams } from '../../../../Types/RouteParams';
import { EnhancedViewState, useViewState } from '../../../Hooks/ViewState/ViewState';
import { DialogService, InputValidateActionParams } from '../../../Services/DialogService';
import { NavigationService } from 'Web/Services/NavigationService';
import { EstimationUrlHelper } from 'Helpers';
import { useQuery } from 'Web/Hooks/URL/URLUtils';

export type ProjectDetailsViewState = EnhancedViewState<{
    project: ProjectDto;
    isAllowedToUpdateMetaData: boolean;
    exporting: boolean
    customerId?: string;
}>;

export enum EditDialogType {
    Intitule = 'Intitule',
    Description = 'Description',
    CustomerId = 'CustomerId',
}

export const useProjectDetailsPageController = () => {
    const me = useMe();
    const { projectId, configId } = useParams<RouteParams>();
    const { state, update } = useViewState<ProjectDetailsViewState>({ loading: true });

    //#region //* LOAD PROJECT

    React.useEffect(() => {
        if (Boolean(me.data)) {
            refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, configId, me.data]);

    const refresh = async () => {
        try {
            update({ loading: true });
            const projectResponse: ProjectResponse = await ProjectsApi.getProject(projectId);
            const project = projectResponse.data;
            update({
                project,
                loading: false,
                isAllowedToUpdateMetaData: ProjectHelper.isAllowedToUpdateMetaData({
                    me,
                    createdByStoreId: project.created_by_store?.store.id,
                    createdByCustomerID: project.created_by_customer?.user.id,
                }),
                customerId: project.customer_id,
            });
        } catch (error) {
            update({ loading: false });
            handleError(error);
        }
    };

    const version = useQuery<RouteQueryParams>().version;//review
    const onBack = (): void => NavigationService.goToMyProjects(EstimationUrlHelper.toQueryParam({ version }));

    const onValidateEditDialog = async (
        type: EditDialogType,
        { input, setValidating, closeDialog }: InputValidateActionParams
    ) => {
        const { intitule = '', description = '' } = state.project ?? {};
        try {
            setValidating(true);
            await ProjectsApi.updateProjectMetaData(projectId, {
                intitule: type === EditDialogType.Intitule ? input : intitule,
                description: type === EditDialogType.Description ? input : description,
                customer_id: type === EditDialogType.CustomerId ? input : state.customerId,
            });
            setValidating(false);
            closeDialog(() => {
                if (type === EditDialogType.CustomerId) {
                    onBack();
                } else {
                    refresh();
                }
            });
        } catch (error) {
            setValidating(false);
            handleError(error);
        }
    };

    const onEditIntitule = (): void => {
        DialogService.showEditInputDialog({
            title: I18n.get('ProjectDetails_Intitule'),
            description: I18n.get('ProjectDetails_DialogEditIntituleInformation'),
            initialValue: state.project?.intitule,
            onValidate: (validateParams) => onValidateEditDialog(EditDialogType.Intitule, validateParams),
        });
    };

    const onEditDescription = (): void => {
        DialogService.showEditInputDialog({
            title: I18n.get('ProjectDetails_Description'),
            description: I18n.get('ProjectDetails_DialogEditDescriptionInformation'),
            initialValue: state.project?.description,
            onValidate: (validateParams) => onValidateEditDialog(EditDialogType.Description, validateParams),
        });
    };

    const onEditCustomerId = (): void => {
        DialogService.showEditInputDialog({
            title: I18n.get('ProjectDetails_CustomerId'),
            description: I18n.format('ProjectDetails_DialogEditCustomerIdInformation'),
            initialValue: state.customerId,
            onValidate: (validateParams) => onValidateEditDialog(EditDialogType.CustomerId, validateParams),
        });
    };

    return { state, events: { update, onEditIntitule, onEditDescription, onEditCustomerId } };
};
