// {
//     "produit_list":[
//         {
//             "code-produit": "Moquette-16566516",
//             "quantité": 25,//m² <--- issu d'un calcul pour la piece 1 a partir de la surface
//             "mode-calcul": "m²"
//         },
//         {
//             "code-produit": "Moquette-16566516",
//             "quantité": 10//m² <--- issu d'un calcul pour la piece 2 a partir de la surface
//             "mode-calcul": "m²"
//         },
//         {//Issu d'une qustion produit un choix client
//             "code-produit": "Plinthe-465416419-3m_de_long",
//             "quantité": 10//Unité <--- issu du calcul du front
//             "mode-calcul": "Plinthe-like"
//         },
//         {//Issu d'un élémnt de prix associé a la plinthe
//             "code-produit": "Colle-pour-plinthe-465416419-3m_de_long",
//             "quantité": 60//Unité <--- issu du calcul du front
//             "mode-calcul": "Unité"
//         }
//     ],
//     "service_list":[
//         {
//             "référence-service": "dépose-moquette-1564654", // <--présent ou non selon la condition de visibilité.
//             "quantité": 25
//         },
//         {
//             "référence-service": "dépose-moquette-1564654",
//             "quantité": 10
//         }
//     ]
// }
// }
import { IContournements } from '../../Plugins/FloorPlan/Models/Contournements';
import { OpeningType } from '../../Plugins/FloorPlan/Models/IOpening';
import { RoomItemType } from '../../Plugins/FloorPlan/Models/IRoomItem';
import { ApiDataResponse } from '../ApiData';
import { SetConfigurationDto } from '../Projects/Configuration';
import { ProjectDto } from '../Projects/ProjectDto';
import { ConditionnementDto } from '../Questions/ProduitDto';

export enum ReponsePrixContextTypeDto {
    MetierDePose = 'MetierDePose',
    Rooms = 'Rooms',
    Openings = 'Openings',
    RoomElements = 'RoomElements',
    Walls = 'Walls',
}

export interface ReponsePrixMetierDePoseContextPropertiesDto {
    metier_de_pose: string;
}
export interface ReponsePrixRoomContextPropertiesDto {
    room_id: string;
}
export interface ReponsePrixOpeningsContextPropertiesDto {
    opening_id: string;
    room_id: string;
}
export interface ReponsePrixRoomElementsContextPropertiesDto {
    room_element_id: string;
    room_id: string;
}

export interface QuotationPrixDto {
    readonly prix_id: string;

    //* peut être //m² <--- issu d'un calcul pour la piece 1 a partir de la surface
    readonly base_calcul?: number; //! undefined BaseDeCalculDto.Manual

    context_type?: ReponsePrixContextTypeDto;
    metier_de_pose_context_properties?: ReponsePrixMetierDePoseContextPropertiesDto;
    rooms_context_properties?: ReponsePrixRoomContextPropertiesDto;
    openings_context_properties?: ReponsePrixOpeningsContextPropertiesDto;
    room_elements_context_properties?: ReponsePrixRoomElementsContextPropertiesDto;

    readonly commentaire?: string;
}

export interface GetQuotationsParameters {
    readonly projetId: string;
    readonly data: GetQuotationDto;
}

export interface EvaluationByPlanItemDto {
    //key : room_id : Messages
    [key: string]: Record<string, any>;
}

export interface QuotationEvaluationDto {
    readonly globals: Record<string, any>;
    readonly rooms: EvaluationByPlanItemDto;
    readonly openings: EvaluationByPlanItemDto;
    readonly room_elements: EvaluationByPlanItemDto;
}

export interface GetQuotationDto {
    readonly prix_list: Array<QuotationPrixDto>;
    readonly configuration: SetConfigurationDto;
    readonly evaluations: QuotationEvaluationDto;
    readonly additional_details: SetQuotationAdditionalDetailsDto;
}

export interface SetQuotationAdditionalDetailsDto {
    readonly general: DetailGeneralSection;
    readonly rooms?: RoomDetailSection[];
    readonly openings?: OpeningDetailSection[];
    readonly room_elements?: RoomItemDetailSection[];
}

export interface DetailGeneralSection {
    plan_png_base64?: string;
    plan_walls_png_base64?: string;
    formatted_syntheses: string[];
}
export interface DetailSection extends DetailGeneralSection {
    readonly id: string;
}
export interface RoomDetailSection extends DetailSection {
    properties: RoomProperties;
}
export interface OpeningDetailSection extends DetailSection {
    properties: OpeningProperties;
}
export interface RoomItemDetailSection extends DetailSection {
    properties: ElementProperties;
}

export interface RoomProperties {
    name?: string;
    type?: string;
    existing_flooring?: string;
    existing_floor_type?: string;
    floor?: string;
    heating_type?: string;
    contournements_numbers?: IContournements;
}

export interface OpeningProperties {
    type?: OpeningType;
    name?: string;
    size?: number;
    room_id?: string;
}
export interface ElementProperties {
    type?: RoomItemType;
    name?: string;
    room_id?: string;
}

export interface OrderLineDto {
    readonly code_produit: string;
    readonly libelle: string;
    readonly quantity: number; //quantity
    readonly prix_ttc: number;
    readonly calcul_log_list: Array<string>;
}

export interface QuotationProduitDto {
    readonly codeQCT: string;
    readonly largeur: number;
    readonly longueur: number;
    readonly qte: number;
    readonly pu_ttc: number;
    readonly total_ttc: number;
    readonly code_tva: string;
    readonly promo_taux_remise?: number;
}

export interface QuotationRubriqueDePoseDto {
    readonly NoRub: number;
    readonly qte: number;
    readonly pu_ht: number;
    readonly total_ht: number;
    readonly code_tva: string;
    readonly texte: string;
}

export interface QuotationDto {
    readonly token: string;
    readonly id_config_origin: string;
    readonly id_config: string;
    readonly produits: Array<QuotationProduitDto>;
    readonly rubriques_pose: Array<QuotationRubriqueDePoseDto>;
}

export enum QuotationPromoTypeDto {
    TvaOfferte = 'TvaOfferte',
}

export interface QuotationPromoDto {
    readonly type: QuotationPromoTypeDto;
    readonly starting_date: Date;
    readonly ending_date: Date;
    readonly discount_amount: number;
}

export interface PoseOrderLineDto {
    readonly NoRub: string;
    readonly qte: string;
    readonly pu_ht: string;
    readonly total_ht: string;
    readonly code_tva: string;
    readonly texte: string;
}

export interface DetailMainOeuvreCategoryDto {
    readonly total: number;
    readonly pose_lines: Array<PoseOrderLineDto>;
}

export interface DetailMainOeuvrePose {
    readonly total_pose: number;
    readonly depose: DetailMainOeuvreCategoryDto;
    readonly autres: DetailMainOeuvreCategoryDto;
    readonly complement_forfait_divers: DetailMainOeuvreCategoryDto;
    readonly forfait_besoin_supplementaire: DetailMainOeuvreCategoryDto;
    readonly gestion_des_dechets: DetailMainOeuvreCategoryDto;
    readonly nettoyage: DetailMainOeuvreCategoryDto;
    readonly pose_accessoires: DetailMainOeuvreCategoryDto;
    readonly pose_du_revetement_principal: DetailMainOeuvreCategoryDto;
    readonly preparation_du_support_avant_pose: DetailMainOeuvreCategoryDto;
}

export interface DetailQuotationProduitDto {
    readonly code_produit: string;
    readonly code_article: string;
    readonly code_coloris: string;
    readonly libelle_produit: string;
    readonly pu_ttc: number;
    quantity: number;
    total_ttc: number;
    readonly promo_taux_remise: number;
    readonly conditionnement: number;
    readonly detail_conditionnement: ConditionnementDto;
    readonly is_unitaire: number;
    eco_contribution: number;
}

export interface DetailProduitsComplementaires {
    readonly total_produits_complementaires_ttc: number;
    readonly items: Array<DetailQuotationProduitDto>;
}

export interface DetailTotalPriceDto {
    readonly total_price_ht?: number;
    readonly total_price_ttc?: number;
    readonly total_tva?: number;
    readonly tva_percentage?: number;
}

export interface QuotationReplyBaseDto {
    readonly id?: string;
    readonly projet: ProjectDto;
    readonly quotation?: QuotationDto;

    readonly detail_main_produit_list: Array<DetailQuotationProduitDto>;
    readonly detail_produits_complementaires: DetailProduitsComplementaires;
    readonly detail_main_oeuvre_pose: DetailMainOeuvrePose;
    readonly detail_livraison: QuotationLivraisonPropertiesDto;
    readonly detail_total_price: DetailTotalPriceDto;
    readonly detail_total_price_by_tva: Record<number, DetailTotalPriceDto>;
    readonly promos: Array<QuotationPromoDto>;
}

export type QuotationLivraisonPropertiesDto = { total_livraison_ttc: number; is_store_quotation_needed: boolean };

export type CreateQuotationResponse = ApiDataResponse<QuotationReplyBaseDto>;

export type ComputeQuotationsResponse = ApiDataResponse<QuotationReplyBaseDto>;
