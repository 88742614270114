import { Button, Popover, Switch } from '@material-ui/core';
import IconBugReport from '@material-ui/icons/BugReport';
import GetAppIcon from '@material-ui/icons/GetApp';
import LayersIcon from '@material-ui/icons/Layers';
import MapIcon from '@material-ui/icons/Map';
import QuestionIcon from '@material-ui/icons/QuestionAnswer';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles, styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';
import { CalculLaizeParams, LaizeCalc } from '../../../Plugins/FloorPlan/Components/Laizes/LaizeCalc';
import { useMapContextState } from '../../../Plugins/FloorPlan/Context/MapContext';
import { useQuoteContext } from '../../Pages/Quote/QuotePageController';
import { Toast } from '../../Services/ToastService';
import { useDebugContext } from './DebugContext';
import { DebugDialog } from './DebugDialog';
import { Stack } from 'Web/Components/Widgets/Stack';

export const ButtonDebug = () => {
    const debugState = useDebugContext().state;
    const showDebugButton =
        debugState.questionsContext ||
        debugState.questionsHabitation ||
        debugState.floorPlanLaizeTraceEnabled ||
        debugState.floorPlanEnabled;

    const [menuOpened, setOpen] = React.useState<boolean>(false);
    const anchorEl = React.useRef(null);

    const open = () => setOpen(true);
    const close = () => setOpen(false);

    return !showDebugButton ? null : (
        <Container ref={anchorEl} id="ButtonDebugControlCenter">
            <Popover
                open={menuOpened}
                anchorEl={anchorEl.current}
                anchorReference="anchorEl"
                anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                onClose={close}
                PaperProps={{ style: PaperStyle }}>
                <MenuPanel />
            </Popover>
            <IconBugReport
                onClick={open}
                style={{
                    cursor: 'pointer',
                    color: DebugColorContants.COLOR,
                    width: '32px',
                    height: '32px',
                    padding: '4px',
                }}
            />
        </Container>
    );
};

export const ButtonDebugControlCenter = React.memo(ButtonDebug);

export const DebugColorContants = {
    BACKGROUND: '#02D213',
    COLOR: '#FFFFFF',
    PANEL_CONTROL: '#013C01',
};
const PaperStyle: CSSProperties = {
    translate: '12px 0px',
    borderRadius: '16px',
    background: DebugColorContants.PANEL_CONTROL,
};

export const GlassMask = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backdropFilter: 'blur(20px)',
    background: 'rgba(255, 255, 255, 0.1)',
});

const Container = styled('div')({
    background: `${DebugColorContants.BACKGROUND} !important`,
    boxShadow: '0 10px 25px -5px #030308',
    width: 32,
    height: 32,
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

//#region  //* CenterPanelControl

const MenuContainer = styled(Stack)({
    position: 'relative',
    minHeight: 300,
    minWidth: 300,
    padding: 16,
});

export const MenuPanel = () => {
    const {
        state: { questionsHabitation, questionsContext, floorPlanEnabled, floorPlanLaizeTraceEnabled },
        update,
    } = useDebugContext();
    return (
        <MenuContainer>
            <GlassMask />
            <Item checked={questionsHabitation} onChange={(value) => update({ questionsHabitation: value })}>
                <Title>
                    <QuestionIcon /> Questions habitation
                </Title>
            </Item>
            <Item checked={questionsContext} onChange={(value) => update({ questionsContext: value })} divider>
                <Title>
                    <QuestionIcon /> Questions context
                </Title>
            </Item>
            <Item checked={floorPlanEnabled} onChange={(value) => update({ floorPlanEnabled: value })}>
                <Title>
                    <LayersIcon /> Plan / Traces (Magnétisme)
                </Title>
            </Item>

            <Item
                checked={floorPlanLaizeTraceEnabled}
                onChange={(value) => update({ floorPlanLaizeTraceEnabled: value })}
                divider>
                <Title>
                    <LayersIcon /> Plan / Traces (Lez)
                </Title>
            </Item>

            <LaizeParams />

            <VersionInspector />
            <MapInspector />
        </MenuContainer>
    );
};
type ItemProps = { checked?: boolean; onChange: (value: boolean) => void; divider?: boolean };
const Item: React.FC<ItemProps> = ({ checked = false, onChange, divider, children }) => {
    const classes = makeStyles((theme) => ({
        switch_track: { backgroundColor: '#F9CC98', opacity: 1 },
        switch_base: {
            color: '#FDF9FB',
            '&.Mui-checked': { color: '#FFFFFF' },
            '&.Mui-checked + .MuiSwitch-track': { backgroundColor: '#F79C32', opacity: 1 },
        },
    }))();

    return (
        <ItemContainer
            onClick={() => onChange(!checked)}
            style={{ borderBottom: divider ? '1px solid #E0E0E0' : 'none' }}>
            {children}
            <Switch
                checked={checked}
                classes={{
                    track: classes.switch_track,
                    switchBase: classes.switch_base,
                }}
            />
        </ItemContainer>
    );
};
const ItemContainer = styled(Button)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#FFFFFF',
    fontWeight: 400,
    cursor: 'pointer',
    height: 40,
    borderColor: '#FFFFFF',
    padding: '0px 0px 0px 12px',
    borderRadius: 0,
});
//#endregion

const VersionInspector = () => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const hide = () => setVisible(false);
    const show = () => setVisible(true);
    const data = useQuoteContext().state;
    return (
        <ItemContainer onClick={show} style={{ marginTop: 24 }}>
            <Title>
                <SettingsIcon /> Version State
            </Title>
            {visible && (
                <DebugDialog
                    title={
                        <Title>
                            <SettingsIcon /> {`Version de paramètrage : ${data.versionId}`}
                        </Title>
                    }
                    data={data}
                    onClose={hide}
                />
            )}
        </ItemContainer>
    );
};

const MapInspector = () => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const hide = () => setVisible(false);
    const show = () => setVisible(true);
    const data = useMapContextState();
    return (
        <ItemContainer onClick={show}>
            <Title>
                <MapIcon /> Map State
            </Title>
            {visible && (
                <DebugDialog
                    title={
                        <Title>
                            <MapIcon /> Map / Plan
                        </Title>
                    }
                    data={data}
                    onClose={hide}
                />
            )}
        </ItemContainer>
    );
};

const Title = styled('div')({ display: 'flex', alignItems: 'center', gap: 16 });

const LaizeParams = () => {
    const { laizeProps, rooms = [], openings = [], walls = [], roomItems = [] } = useMapContextState();
    return (
        <ItemContainer
            onClick={() => {
                const laizeCalcProps = laizeProps?.map(LaizeCalc.toLaizeCalcProps);
                const params = laizeCalcProps?.map((x) => {
                    return {
                        laizeCalcProps: x,
                        rooms,
                        openings,
                        walls,
                        roomItems,
                        flooringDirectionByRoomId: LaizeCalc.getFlooringDirectionByRoomId(rooms),
                    } as CalculLaizeParams;
                });
                const calcOptimalBandLayoutParams = params?.map(LaizeCalc.toCalculateOptimalBandsLayoutParams);
                navigator.clipboard
                    .writeText(JSON.stringify(calcOptimalBandLayoutParams))
                    .then(() => {
                        Toast.showSuccess({ content: 'Paramètres copiés.' });
                    })
                    .catch((error) => {
                        console.error('Failed to copy text to clipboard:', error);
                    });
            }}>
            <Title>
                <GetAppIcon /> Copy Laize Params
            </Title>
        </ItemContainer>
    );
};
