// * Magnetized walls (snap to wall) with anchor lines
import { AnchorLine, useGestureContext } from '../Context/GestureContext';
import { useMapContext } from '../Context/MapContext';
import { IRoom } from '../Models/IRoom';
import { qSVG } from './qSVG';
import { CoordPoint, SnapGesture, Wall } from './Types';
import { WallUtils } from './Wall/WallUtils';

export type WallDirection = { direction: string; x?: number; y?: number };

const MAGNETIZE_SENSIBILITY = 10;

function toVerticalAnchorLine(x: number, id: string) {
    const anchorLine: AnchorLine = { id, start: { x, y: 0 }, end: { x, y: 15000 } };
    return anchorLine;
}

function toHorizontalAnchorLine(y: number, id: string) {
    const anchorLine: AnchorLine = { id, start: { x: 0, y }, end: { x: 10000, y } };
    return anchorLine;
}

export const useMagnetize = () => {
    const gesture = useGestureContext();
    const map = useMapContext();
    const magnetizedWalls = (
        snap: CoordPoint,
        bindedWall: Wall | { start: CoordPoint; end: CoordPoint; angle: number; roomId: undefined },
        walls: Array<Wall>,
        withAnchorLines: boolean = true
    ): WallDirection | undefined => {
        const rooms = map.getState().rooms;
        const type = rooms?.find((x) => x.roomId === bindedWall.roomId)?.type;
        const roomsToUse = bindedWall.roomId
            ? rooms?.filter((x) => x.roomId !== bindedWall.roomId && x.type === type)
            : rooms;
        for (const wall of walls.filter((x) => roomsToUse?.some((r) => r.roomId === x.roomId))) {
            //* diagonal angles are not supported
            const wallDirection = WallUtils.angleDirection(qSVG.angleRadToDeg(wall.angle));
            const bindedWallDirection = WallUtils.angleDirection(qSVG.angleRadToDeg(bindedWall.angle));

            if (wallDirection === bindedWallDirection) {
                if (
                    wallDirection === 'v' &&
                    snap.x - MAGNETIZE_SENSIBILITY < wall.start.x &&
                    snap.x + MAGNETIZE_SENSIBILITY > wall.start.x
                ) {
                    //! vertical : color line markup
                    if (withAnchorLines) {
                        const anchorLineId = 'anchor¤' + wall.roomId + '¤' + wall.sideName;
                        gesture.update({ verticalAnchorLine: toVerticalAnchorLine(wall.start.x, anchorLineId) });
                    }

                    const offsetSnap = { direction: 'v', x: wall.start.x };
                    return offsetSnap;
                } else if (
                    wallDirection === 'h' &&
                    snap.y - MAGNETIZE_SENSIBILITY < wall.start.y &&
                    snap.y + MAGNETIZE_SENSIBILITY > wall.start.y
                ) {
                    //! horizontal : color line markup
                    if (withAnchorLines) {
                        const anchorLineId = 'anchor¤' + wall.roomId + '¤' + wall.sideName;
                        gesture.update({ horizontalAnchorLine: toHorizontalAnchorLine(wall.start.y, anchorLineId) });
                    }

                    const offsetSnap = { direction: 'h', y: wall.start.y };
                    return offsetSnap;
                } else {
                    gesture.clearAnchorLines();
                }
            }
        }
        return undefined;
    };

    // * Magnetized rooms (snap to wall) with anchor lines

    // * Description :
    // * Cette méthode renvoie un nouveau snap, qui doit être utiliser pour overrider le snap courant.
    // * Si la pièce n'est pas déplacée dans l'interval de sensibilité (MAGNETIZE_SENSIBILITY) d'un mur d'une autre pièce, les propriétés (x ou y) de forcedSnap sont undefined.

    // * Réalise un tri des murs de la pièce séléctionnée : murs verticaux et murs horizontaux (bindedRoomHorizontalWalls, bindedRoomHorizontalWalls)
    // * On regarde les murs des autres pièces,
    // * on prend le mur qui est le plus proche verticalement ou horizontalement dans les liste bindedRoomVerticalWalls ou bindedRoomHorizontalWalls
    // * on regarde si le mur qu'on a pris précédement rentre dans l'interval de sensibilité (MAGNETIZE_SENSIBILITY)
    // * si c'est le cas on sauvegarde sa coordonnée verticale ou horizontale et on calcul un nouveau snap : forceSnap

    const magnetizedRooms = (
        snap: CoordPoint,
        bindedRoom: IRoom | { walls: Wall[]; roomId: undefined; type: undefined },
        translation: CoordPoint,
        initialSnapPosition: CoordPoint,
        rooms: IRoom[],
        withAnchorLines: boolean = true
    ) => {
        const bindedRoomWalls = bindedRoom.walls || [];
        const bindedRoomVerticalWalls = bindedRoomWalls.filter(
            (x) => WallUtils.angleDirection(qSVG.angleRadToDeg(x.angle)) === 'v'
        );
        const bindedRoomHorizontalWalls = bindedRoomWalls.filter(
            (x) => WallUtils.angleDirection(qSVG.angleRadToDeg(x.angle)) === 'h'
        );

        let forcedSnapVertical;
        let forcedSnapHorizontal;
        let closestWallV: Wall;
        let closestWallH: Wall;
        let translateX: number, translateY: number;

        gesture.clearAnchorLines();

        const roomsFiltred =
            bindedRoom.roomId && bindedRoom.type
                ? rooms.filter((r) => r.type === bindedRoom.type && r.roomId !== bindedRoom.roomId)
                : rooms;
        for (const room of roomsFiltred) {
            const roomWalls = room.walls || [];
            for (const wall of roomWalls) {
                const wallDirection = WallUtils.angleDirection(qSVG.angleRadToDeg(wall.angle));
                if (wallDirection === 'v') {
                    closestWallV = bindedRoomVerticalWalls.reduce((previousWall, currentWall) => {
                        if (
                            Math.abs(previousWall.start.x + translation.x - wall.start.x) <
                            Math.abs(currentWall.start.x + translation.x - wall.start.x)
                        ) {
                            return previousWall;
                        } else {
                            return currentWall;
                        }
                    });

                    translateX = snap.x - initialSnapPosition.x;
                    if (Math.abs(closestWallV.start.x + translateX - wall.start.x) < MAGNETIZE_SENSIBILITY) {
                        if (withAnchorLines) {
                            const anchorLineId = 'anchor¤' + wall.roomId + '¤' + wall.sideName;
                            gesture.update({ verticalAnchorLine: toVerticalAnchorLine(wall.start.x, anchorLineId) });
                        }
                        forcedSnapVertical = wall.start.x - closestWallV.start.x + initialSnapPosition.x;
                    }
                } else if (wallDirection === 'h') {
                    closestWallH = bindedRoomHorizontalWalls.reduce((previousWall, currentWall) => {
                        if (
                            Math.abs(previousWall.start.y + translation.y - wall.start.y) <
                            Math.abs(currentWall.start.y + translation.y - wall.start.y)
                        ) {
                            return previousWall;
                        } else {
                            return currentWall;
                        }
                    });
                    translateY = snap.y - initialSnapPosition.y;

                    if (Math.abs(closestWallH.start.y + translateY - wall.start.y) < MAGNETIZE_SENSIBILITY) {
                        if (withAnchorLines) {
                            const anchorLineId = 'anchor¤' + wall.roomId + '¤' + wall.sideName;
                            gesture.update({
                                horizontalAnchorLine: toHorizontalAnchorLine(wall.start.y, anchorLineId),
                            });
                        }
                        forcedSnapHorizontal = wall.start.y - closestWallH.start.y + initialSnapPosition.y;
                    }
                }
            }
        }
        const forcedSnap = { x: forcedSnapVertical, y: forcedSnapHorizontal };
        return forcedSnap;
    };

    return { magnetizedWalls, magnetizedRooms };
};
