import React from 'react';

import { useViewState } from '../../Hooks/ViewState/ViewState';
import { Logger } from '../../../Errors/Logger';
import { useWindowLog } from '../../../Hooks/WindowLog';

export type DebugContextState = {
    questionsHabitation?: boolean;
    questionsContext?: boolean;
    floorPlanEnabled?: boolean;
    floorPlanLaizeTraceEnabled?: boolean;
    geometryDebugEnabled?: boolean;

    debugMapEnabled?: boolean;
};

type DebugContextValue = {
    state: DebugContextState;
    update: (values: Partial<DebugContextState>) => void;
};

const DebugContext = React.createContext<DebugContextValue>({ state: {}, update: () => {} });
export const useDebugContext = () => React.useContext(DebugContext);
export const useDebugContextState = () => React.useContext(DebugContext).state;

export const DebugProvider: React.FC = ({ children }) => {
    const activeInLocalStorage = Boolean(localStorage.getItem(Logger.LoggerId));
    const { state, getState, update } = useViewState<DebugContextState>({
        questionsHabitation: activeInLocalStorage,
        questionsContext: activeInLocalStorage,
        floorPlanEnabled: activeInLocalStorage,

        floorPlanLaizeTraceEnabled: false,
        debugMapEnabled: Boolean(localStorage.getItem(Logger.LoggerMapId)),
    });

    useWindowLog(() => (window.DebugState = getState));

    return <DebugContext.Provider value={{ state, update }}>{children}</DebugContext.Provider>;
};
