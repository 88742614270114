import 'react-toastify/dist/ReactToastify.min.css'; //!\\ MANDATORY

import { makeStyles, styled } from '@material-ui/styles';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { MOBILE_LIMIT } from 'Web/Context/PlatformContext/PlatformContext';

export const CustomToastContainer = () => {
    const classes = useToastClasses();
    return (
        <Container
            autoClose={3000}
            position="top-right"
            hideProgressBar
            closeOnClick={false}
            closeButton={false}
            toastClassName={classes.simpleToast}
        />
    );
};

const Container = styled(ToastContainer)({
    right: '0px !important',
    padding: 0,
    top: 99,
    width: 376,
    [`@media (max-width : ${MOBILE_LIMIT.WIDTH_600}px)`]: { top: 64, width: '100%' },
});

const useToastClasses = makeStyles({ simpleToast: { padding: 0, width: '100%' } });
