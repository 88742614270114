import React from 'react';

import { Logger } from '../../../../../../../Errors/Logger';
import { QuestionsHelper } from '../../../../../../../Helpers/QuestionsHelper';
import { useMapContext } from '../../../../../../../Plugins/FloorPlan/Context/MapContext';
import { IRoom } from '../../../../../../../Plugins/FloorPlan/Models/IRoom';
import { EnhancedViewState } from '../../../../../../Hooks/ViewState/ViewState';
import { useQuoteProvider } from '../../../../Controllers/QuoteProviderController';
import { useQuoteSaveController } from '../../../../Controllers/QuoteSaveController';
import { UpdateStepQuestionsParams, useStepQuestionsController } from '../../../../Controllers/StepQuestionsController';
import { QuestionPrepare } from '../../../../Helpers/QuestionPrepare';
import { Scroller, useScroller } from '../../../../Helpers/Scroller';
import { useRoomSections } from './RoomEditSectionController';
import { QuestionStep } from '../../../../../../../Models/Questions/QuestionDto';

export type UpdateRoomParams = {
    values: Partial<EnhancedViewState<IRoom>>;
    dimensChanged?: boolean;
    questionsModified?: boolean;
    refreshLaizeCalc?: boolean;
    logs?: any;
};

export type RoomEditContextValue = {
    open?: boolean;
    state: IRoom;
    getState: () => Partial<IRoom>;
    update: (params: UpdateRoomParams) => void;
    rooms: Array<IRoom>;
};
const RoomEditContext = React.createContext<RoomEditContextValue>({
    state: {},
    update: () => {},
    rooms: [],
    getState: () => ({}),
});
export const useRoomEdit = () => React.useContext(RoomEditContext);

export const toRoomSaveState = (item: Partial<IRoom> = {}): Partial<IRoom> => {
    return {
        roomId: item.roomId,
        //* INFOS
        name: item.name,
        namedByUser: item.namedByUser,
        shape: item.shape,
        localisation: item.localisation,
        roomType: item.roomType,
        roomWall: item.roomWall,
        roomFloor: item.roomFloor,
        roomExistingFlooring: item.roomExistingFlooring,
        roomExistingFloorType: item.roomExistingFloorType,
        roomHeating: item.roomHeating,
        roomHeatingType: item.roomHeatingType,
        flooringDirection: item.flooringDirection,
        type: item.type,
        questionsCopiedFromId: item.questionsCopiedFromId,

        //* PRODUIT PRINCIPAL
        questionProduitPrincipal: item.questionProduitPrincipal,

        //* QUESTIONS STEPS
        questionsTva: item.questionsTva,
        questionsBaseContext: item.questionsBaseContext,
        questionsPose: item.questionsPose,
        questionsSupport: item.questionsSupport,
        questionsPreparation: item.questionsPreparation,
        questionsFinition: item.questionsFinition,

        openSections: item.openSections,

        //* AMENAGEMENTS
        openings: item.openings,
        roomItems: item.roomItems,
        contournements: item.contournements,

        completionStatus: item.completionStatus,
    };
};

export const RoomEditProvider: React.FC = ({ children }) => {
    const quoteProvider = useQuoteProvider();
    const map = useMapContext();
    const { rooms = [], selectedRoomId } = map.getState();

    const room = rooms.find((item) => item.roomId === selectedRoomId);
    const open = Boolean(selectedRoomId) && Boolean(room);
    const state = toRoomSaveState(room);

    const update = ({ values, questionsModified, refreshLaizeCalc, logs }: UpdateRoomParams) => {
        const { rooms = [] } = map.getState();
        quoteProvider.update({
            mapValues: { rooms: rooms.map((item) => (item.roomId === room?.roomId ? { ...item, ...values } : item)) },
            questionsModified,
            refreshLaizeCalc,
            logs: { event: 'RoomEditProvider update', room, values, innerLogs: logs },
        });
    };

    const getState = () => map.getState().rooms?.find((item) => item.roomId === selectedRoomId) || {};

    return (
        <RoomEditContext.Provider value={{ open, rooms, state, update, getState }}>{children}</RoomEditContext.Provider>
    );
};

export const useRoomQuestions = (step: QuestionStep) => {
    const { state, update } = useRoomEdit();
    const scroller = useScroller();
    const roomSections = useRoomSections(step);
    const { onSaveAuto } = useQuoteSaveController();

    const updateStepQuestions = (params: UpdateStepQuestionsParams) => {
        const { contextQuestions, questionsModified, openNextSectionIfNeeded = true, logs } = params;

        if (step === QuestionStep.StepPose) {
            update({ values: { questionsPose: contextQuestions }, questionsModified, logs });
        }
        if (step === QuestionStep.StepSupport) {
            update({ values: { questionsSupport: contextQuestions }, questionsModified, logs });
        }
        if (step === QuestionStep.StepPreparation) {
            update({ values: { questionsPreparation: contextQuestions }, questionsModified, logs });
        }
        if (step === QuestionStep.StepFinitions) {
            update({ values: { questionsFinition: contextQuestions }, questionsModified, logs });
        }

        Logger.log('updateStepQuestions', {
            endQuestion: contextQuestions.endQuestion,
            step,
            openNextSectionIfNeeded,
            logs,
        });
        if (contextQuestions.endQuestion) {
            const lastShownQuestion = QuestionsHelper.getLastShownQuestion(
                contextQuestions.questions
            ).lastShownQuestion;
            const nextAction = () => {
                if (QuestionsHelper.hasInfobules(lastShownQuestion)) {
                    scroller.scrollToAlignBottomQuestionOnBottom(
                        Scroller.ID,
                        state.roomId!,
                        lastShownQuestion,
                        Scroller.ShortDelay
                    );
                } else if (openNextSectionIfNeeded) {
                    roomSections.openNextSection(step);
                }
            };
            onSaveAuto({ nextAction });
        }
    };

    const stepController = useStepQuestionsController<IRoom>({
        step,
        updateStepQuestions,
        getItemId: (item) => item.roomId!,
        handleScroll: () => {},
    });
    const stepQuestions = QuestionPrepare.getStepQuestions(state, step);

    return { state, stepQuestions, ...stepController, roomSections, update };
};
