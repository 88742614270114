import { Action, LocationState } from "history";

import { browserHistory } from "../../Configs/browserHistory";
import { AppRoutes } from "../../Configs/AppRoutes";
import { Constants } from "../../Constants";

export type GoToRouteParameter = {
  route: string,
  replace?: boolean;
  state?: any;
  search?: string;
}
export class NavigationService {

  public static initialize(): void {
    browserHistory.listen((location: LocationState, action: Action) => {
      //console.log("history listen", { location, action });
      //* can be used to reset state if url changed
    });
  }

  public static goTo = (goToRouteParameter: GoToRouteParameter): void => {
    const { route, replace, state, search } = goToRouteParameter;
    if (replace) {
      browserHistory.replace({ pathname: route, state, search });
    } else {
      browserHistory.push({ pathname: route, state, search });
    }
  };

  public replace = (newUrl: string): void => browserHistory.replace({ pathname: newUrl });

  public static replaceParams = (queryParams: URLSearchParams) => browserHistory.replace({ search: queryParams?.toString() });

  public goBack = () => browserHistory.goBack();

  public static goToHome = (): void => NavigationService.goTo({ route: Constants.STMAC_URL });

  public static goToMyProjects = (params: URLSearchParams) => NavigationService.goTo({ route: AppRoutes.MY_PROJECTS, search: params?.toString() });

}